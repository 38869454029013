import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

type Props = {
  readonly label: string;
  readonly loading: boolean;
  readonly options: $TSFixMe;
  readonly readOnly: $TSFixMe;
  readonly helperText: string;
  readonly endAdornment: $TSFixMe;
  readonly startAdornment: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { value: string, onChange: (value: $TSFixMe) => void };
};

// eslint-disable-next-line
const RenderCountrySelectField = ({
  input,
  label,
  loading,
  startAdornment,
  endAdornment,
  readOnly,
  options,
  helperText = '',
  meta: { touched, error },
  ...custom
}: Props) => {

  return (
    <Autocomplete
      // @ts-expect-error TODO: no property label
      label={label}
      options={options}
      placeholder={label}
      autoHighlight
      getOptionLabel={(option: $TSFixMe) => option.label}
      {...input}
      value={input.value || null}
      onChange={(event, value) => input.onChange(value)}
      {...custom}
      renderOption={(props, option: $TSFixMe) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => {
        return (
          <TextField
            style={{ marginTop: 5 }}
            variant="standard"
            error={Boolean(touched && error)}
            helperText={(touched && error) || helperText}
            {...params}
            label={<FormattedMessage id="common.label.select.country" />}
            inputProps={{
              ...params.inputProps,
            }}
          />
        );
      }}
    />
  );
};

export default RenderCountrySelectField;
