import { AnyAction } from 'redux';
import isEmpty from 'lodash-es/isEmpty';

interface SnackbarState {
  open: boolean;
  msgInfo: $TSFixMe;
  queue: $TSFixMe[];
  msg?: string;
}

const initialState: SnackbarState = {
  open: false,
  msgInfo: { msg: '', variant: 'success' },
  queue: [],
};

export default function reducer(state: SnackbarState = initialState, action: AnyAction): SnackbarState {
  switch (action.type) {
    case 'ADD_SNACKBAR': {
      return {
        ...state,
        queue: [
          ...state.queue,
          {
            msg: action.msg,
            variant: action.variant,
            key: new Date().getTime(),
          },
        ],
      };
    }
    case 'PROCESS_SNACKBAR_QUEUE': {
      const e = { ...state.queue.shift() };
      return {
        ...state,
        open: !isEmpty(e),
        msgInfo: isEmpty(e) ? { msg: '', variant: 'success' } : e,
        queue: state.queue.filter(item => item.key !== e.key),
      };
    }
    case 'CLOSE_SNACKBAR': {
      return {
        ...state,
        open: false,
        msg: '',
      };
    }
    default:
      return {
        ...state,
      };
  }
}
