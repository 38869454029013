/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminBalanceOverview = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/administration/balance" size="small" color="secondary">
            <FormattedMessage id="nav.administration.balance.overview" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'}>
            <FormattedMessage id="nav.administration.balance.overview" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.balance.overview" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpAdminBalanceOverview;
