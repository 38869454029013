import { AnyAction } from 'redux';

interface UbsTag {
  readonly tag: string;
  readonly action: string;
}

interface UbsTagsState {
  tags: UbsTag[];
}

const initialState: UbsTagsState = {
  tags: [
    { tag: "##flash=true", action: "Send the message as flash" },
    {
      tag: "##notify=success,failure",
      action:
        "Sends email notification back to sender on success or failure, can be set only for one event",
    },
    {
      tag: "##validity=<Integer>",
      action:
        "Sets sms validity period to the given value in hours, example: ##validity=72 will set the message validity to 72 hours",
    },
    {
      tag: "##logonly=true",
      action:
        "Does not deliver message to the mobile device, it can be found in CDRs with status of LOGONLY",
    },
  ],
};

export default function reducer(state: UbsTagsState = initialState, action: AnyAction): UbsTagsState {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
}
