import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  readonly label: string | object;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { value: string };
};

const RenderCheckboxField = ({
  label,
  input,
  meta: { touched, error },
}: Props) => {

  return (
    <FormControl error={Boolean(touched && error)}>
      <FormControlLabel
        control={
          <Checkbox {...input} checked={!!input.value} value="checked" color="secondary" />
        }
        label={label}
      />
      {Boolean(touched && error) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default RenderCheckboxField;
