import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import { AppThunkAction } from '../index';
import viberApi from '../../api/viber/viberApi';
import { ViberTemplate } from '../../types/ViberTemplate';
import { AnyAction } from 'redux';

export function getViberTemplates(): AppThunkAction {
  return (dispatch, getState) => {
    const tenantId = getState().login.userData.customerUuid!;
    dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: true });
    viberApi.templates.getAllByTenantId(tenantId)
      .then((viberTemplates: ViberTemplate[]) => {
        dispatch({ type: 'SET_VIBER_TEMPLATES', payload: viberTemplates });
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
      });
  };
}

export function deleteViberTemplate(accountId: string, id: string): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_VIBER_TEMPLATE', payload: true });
    const tenantId = getState().login.userData.customerUuid!;
    viberApi.templates.delete(tenantId, accountId, id)
      .then(() => {
        dispatch({ type: 'REMOVE_VIBER_TEMPLATE', payload: id });
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
        dispatch(getViberTemplates());
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
      });
  };
}

export function addViberTemplate(values: ViberTemplate): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_VIBER_TEMPLATE_LOADING', payload: true });
    const tenantId = getState().login.userData.customerUuid!;
    const accountId = getState().form.viberTemplateForm.values?.accountId;
    viberApi.templates.add(tenantId, accountId, values)
      .then(() => {
        dispatch({ type: 'SET_VIBER_TEMPLATE_LOADING', payload: false });
        dispatch(getViberTemplates());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_VIBER_TEMPLATE_LOADING', payload: false });
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function updateViberTemplate(item: Partial<ViberTemplate>): AppThunkAction {
  return (dispatch, getState) => {
    const id = getState().viberTemplates.selectedViberTemplate.id!;
    const accountId = getState().viberTemplates.selectedViberTemplate.accountId!;
    const tenantId = getState().login.userData.customerUuid!;
    viberApi.templates.update({tenantId, accountId, id, item})
      .then(() => {
        dispatch(getViberTemplates());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function resetViberTemplate(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_VIBER_TEMPLATE_FOR_FORM', payload: {} });
  };
}

export function getViberTemplateForForm(tenantId: string, accountId: string, id: string): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_VIBER_TEMPLATE_FORM_LOADING', payload: true });
    viberApi.templates.getById(tenantId, accountId, id)
      .then((selectedViberTemplate: ViberTemplate) => {
        selectedViberTemplate && dispatch({
          type: 'SET_VIBER_TEMPLATE_FOR_FORM',
          payload: selectedViberTemplate,
        });
        dispatch({ type: 'SET_VIBER_TEMPLATE_FORM_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_VIBER_TEMPLATE_FORM_LOADING', payload: false });
      });
  };
}

export function getViberTemplatesBySenderId(accountId: string): AppThunkAction {
  return (dispatch, getState) => {
    const { templatesFilter, templatesPageSize } = getState().chat;
    const encodedFilter = encodeURIComponent(templatesFilter);
    const tenantId = getState().login.userData.customerUuid!;
    viberApi.templates.getAllBySenderId({tenantId, accountId, templatesPageSize, encodedFilter})
      .then((viberTemplates: ViberTemplate[]) => {
        dispatch({ type: 'SET_MESSAGE_TEMPLATES', payload: viberTemplates });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function setUpdateViberTemplatelogOpen(flag: boolean) {
  return { type: 'SET_UPDATE_VIBER_TEMPLATE_DIALOG_OPEN', payload: flag };
}

export function getViberTemplatesForAdmin(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: true });
    viberApi.templates.getAllForAdmin()
      .then((viberTemplates: ViberTemplate[]) => {
        dispatch({
          type: 'SET_VIBER_TEMPLATES',
          payload: viberTemplates.filter((e: ViberTemplate) => e.status !== 'new' && e.status !== null),
        });
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'SET_VIBER_TEMPLATES_LOADING', payload: false });
      });
  };
}

export function updateViberTemplateStatus(item: ViberTemplate): AppThunkAction {
  return (dispatch, getState) => {
    const id = getState().viberTemplates.selectedViberTemplate.id!;
    const templateStatus = getState().form.updateViberTemplateStatus.values?.status;
    const status = templateStatus === 'approved' ? 'approve' : 'reject';
    viberApi.templates.updateStatus(id, status, item)
      .then(() => {
        dispatch(getViberTemplatesForAdmin());
        dispatch(getPendingForApprovalViberTemplatesForAdmin());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function sendViberTemplateForApproval(id: string): AppThunkAction {
  return (dispatch) => {
    viberApi.templates.sendForApproval(id)
      .then(() => {
        dispatch(getViberTemplates());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function getPendingForApprovalViberTemplatesForAdmin(): AppThunkAction {
  return (dispatch) => {
    viberApi.templates.getAllForAdmin()
      .then((viberTemplates: ViberTemplate[]) => {
        dispatch({
          type: 'SET_PENDING_FOR_APPROVAL_VIBER_TEMPLATES',
          payload: viberTemplates.filter((e: ViberTemplate) => e.status === 'readyForApproval'),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const findTemplates = (accountId: string): AppThunkAction => {
  return (dispatch, getState) => {
    getViberTemplatesBySenderId(accountId);
  };
};

export const getMoreTemplates = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_MESSAGE_TEMPLATES_LOADING', payload: true });
    const { templatesFilter, templatesPageSize } = getState().chat;

    dispatch({
      type: 'SET_CONTACTS_PAGE_SIZE',
      payload: templatesPageSize + 10,
    });
    dispatch(getViberTemplatesBySenderId(templatesFilter));
  };
};

export function setSelectedViberId(viberId: string): AnyAction {
  return { type: 'SET_SELECTED_VIBER_ID', payload: viberId };
}