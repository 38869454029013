import { PhoneNumberWithPlusSign } from './phoneNumber';
import { Timestamp } from './datetime';
import { SupportedChatChannel } from '../chats/supportedChatChannel';

export enum ChatClientType {
  NUMERIC = 'NUMERIC',
  ALPHANUMERIC = 'ALPHANUMERIC',
}

export interface ChatClient {
  readonly channel: SupportedChatChannel;
  readonly id: string;
  readonly type: ChatClientType;
  readonly name?: string;
  readonly number?: PhoneNumberWithPlusSign;
  readonly lastActivityTime?: Timestamp;
  readonly senderId: string;

  readonly agentId?: number;
  readonly foundInContacts: boolean;
}
