import { ChatChannel } from '../interface';
import { ChatApi } from '../interface';
import { ChatConfig } from '../interface';
import { ChatListener } from '../interface';
import { ChatPublisher } from '../interface';
import viberChatConfig from './viberChatConfig';
import ViberChatListener from './viberChatListener';
import ViberChatPublisher from './viberChatPublisher';
import ViberChatApi from './viberChatApi';

class ViberChatChannel implements ChatChannel {
  readonly api: ChatApi;
  readonly config: ChatConfig;
  readonly listener: ChatListener;
  readonly publisher: ChatPublisher;

  constructor() {
    this.config = viberChatConfig;
    this.api = new ViberChatApi();
    this.listener = new ViberChatListener();
    this.publisher = new ViberChatPublisher();
  }
}

export default new ViberChatChannel();
