import { createTheme } from '@mui/material/styles';
import { grey, orange, red, green, lightBlue } from '@mui/material/colors';

const adspark = createTheme({
  palette: {
    primary: {
      light: '#D4D4D4',
      main: '#133865',
      dark: '#7F0445',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#D4D4D4',
      main: '#00473b',
      dark: '#133865',
      contrastText: '#fff',
    },
    type: 'light',
    custom: {
      appBarColor: '#7F0445',
    },
    online: {
      main: '#4caf50',
    },
    away: {
      main: '#ffd700',
    },
    offline: {
      main: '#d32f2f',
    },
    unknown: {
      main: '#a9a9a9',
    },
    infobox: {
      color: {
        orange: orange[500],
        red: red[500],
        blue: lightBlue[500],
        green: green[500],
        grey: grey[500],
        viber: '#7360F2',
        whatsapp: '#25d366',
      }
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#0000004d",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 16,
            border: '2px solid #fff',
            backgroundColor: "#0000004d",
          },
        },
      },
    },
  },
});

export default adspark;
