import { HttpClient } from '../common/httpClient';
import { GetViberPricing, PostViberPricing, ViberCountry, ViberMonthlyFee } from '../../types/viber/viberPricing';

type GetViberPricingResponse = {
  readonly id: string;
  readonly tenantId: string;
  readonly countryCode: string;
  readonly countryName: string;
  readonly promotionalPrice: number;
  readonly transactionalPrice: number;
};

type ViberMonthlyFeeResponse = {
  readonly fee: number;
};

type ViberCountryResponse = {
  readonly dialCode: string;
  readonly name: string;
  readonly code: string;
};

export class ViberPricingApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToViberPricing(response: GetViberPricingResponse): GetViberPricing {
    return {
      id: response.id,
      tenantId: response.tenantId,
      countryCode: response.countryCode,
      countryName: response.countryName,
      promotionalPrice: response.promotionalPrice,
      transactionalPrice: response.transactionalPrice,
    };
  }

  private mapToViberMonthlyFee(response: ViberMonthlyFeeResponse): ViberMonthlyFee {
    return {
      fee: response.fee,
    };
  }

  private mapToViberCountry(response: ViberCountryResponse): ViberCountry {
    return {
      dialCode: response.dialCode,
      name: response.name,
      code: response.code,
    };
  }

  getPricing(tenantId: string): Promise<GetViberPricing[]> {
    return this.httpClient.get<GetViberPricingResponse[]>(`price-list/${tenantId}`)
      .then(data => (
        data.map(it => this.mapToViberPricing(it))
      ));
  }

  getMonthlyFee(tenantId: string): Promise<ViberMonthlyFee> {
    return this.httpClient.get<ViberMonthlyFeeResponse>(`price-list/${tenantId}/monthly-fee`)
      .then(data => this.mapToViberMonthlyFee(data));
  }

  getAll(tenantId: string): Promise<GetViberPricing[]> {
    return this.httpClient.get<GetViberPricingResponse[]>(`price-list/${tenantId}`)
      .then(data => (
        data.map(it => this.mapToViberPricing(it))
      ));
  }

  getCountries(): Promise<ViberCountry[]> {
    return this.httpClient.get<ViberCountry[]>(`price-list/countries`)
      .then(data => (
        data.map(it => this.mapToViberCountry(it))
      ));
  }

  async updateMonthlyFee(tenantId: string, fee: number): Promise<void> {
    await this.httpClient.put<ViberMonthlyFee>(`price-list/${tenantId}/monthly-fee`, { fee });
  }

  async add(tenantId: string, values: Partial<GetViberPricing>): Promise<void> {
    await this.httpClient.post<GetViberPricing>(`price-list/${tenantId}/entry`, values);
  }

  async update(tenantId: string, item: PostViberPricing): Promise<void> {
    await this.httpClient.put<PostViberPricing>(`price-list/${tenantId}/entry`, item);
  }

  async delete(tenantId: string, id: string): Promise<void> {
    await this.httpClient.delete<GetViberPricing>(`price-list/${tenantId}/entry/${id}`);
  }
}
