import { appConfig } from '../../config';
import { StompWsClient } from './stompWsClient';
import { ChatProxyInboundWsMessage, ChatProxyOutboundWsMessage } from './chatProxyWsMessage';
import { AbstractChatWsConnection } from './abstractChatWsConnection';

class ChatProxyWsConnection extends AbstractChatWsConnection {
  constructor(readonly wsClient: StompWsClient) {
    super(wsClient);
  }

  subscribe(customerId: string | number): string {
    return this.wsClient.subscribe<ChatProxyInboundWsMessage>(
      `/queue/chat/${customerId}`,
      wsMessage => this.notifyMessageListeners(wsMessage),
    );
  }

  sendMessage(message: ChatProxyOutboundWsMessage): void {
    this.wsClient.send(`/app/customer/${this.subscriptionDetails?.customerId}`, message);
  }
}

export default new ChatProxyWsConnection(
  new StompWsClient({
    wsUrl: appConfig.CHAT_PROXY_URL_WS!,
    debugMode: Boolean(appConfig.DEBUG_WS === 'true'),
    accessToken: () => localStorage.getItem('token')!,
    reconnectDelayInMs: 5000,
  })
);
