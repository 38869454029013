import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import React from 'react';
import InfoBox from '../InfoBox/InfoBox';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getAccountInfo } from '../../actions/login';
import { setTestAccountDrawerShowFlag } from '../../actions/testAccount';
import { AppState } from '../../reducers';

type Props = {
  readonly smsLimit: number;
  readonly accountInfoUrl: $TSFixMe;
  readonly getAccountInfo: (url: $TSFixMe) => void;
  readonly setTestAccountDrawerShowFlag: (flag: boolean) => void;
};

const TopBarTestAccountInfo = ({
  setTestAccountDrawerShowFlag,
  smsLimit,
  accountInfoUrl,
  getAccountInfo,
}: Props) => {
  const handleClick = () => {
    getAccountInfo(accountInfoUrl);
    setTestAccountDrawerShowFlag(true);
  };
  return (
    <>
      <InfoBox
        button={true}
        variant="mini"
        icon={<InfoRoundedIcon />}
        color="red"
        label={<FormattedMessage id="testAccount.infobox.label.test.account" />}
        value={`${smsLimit} messages remaining`}
        onClick={handleClick}
      />
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      smsLimit: store.testAccount.accountInfo.smsLimit,
      accountInfoUrl:
        store.login.userData._links.account_info &&
        store.login.userData._links.account_info.href,
    };
  },
  { setTestAccountDrawerShowFlag, getAccountInfo }
)(TopBarTestAccountInfo);
