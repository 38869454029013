import { AnyAction } from 'redux';
import { MtApiCountryOverride } from '../../types/mtApiCountryOverride';

interface MtApiCountryOverrideState {
  countries: MtApiCountryOverride[];
  selectedCountryEntry: Partial<MtApiCountryOverride>;
  mtApiCountryLoading: boolean;
}

const initialState: MtApiCountryOverrideState = {
  countries: [],
  selectedCountryEntry: {},
  mtApiCountryLoading: false,
};

export default function reducer(state: MtApiCountryOverrideState = initialState, action: AnyAction): MtApiCountryOverrideState {
  switch (action.type) {
    case 'SET_MT_API_COUNTRY_LOADING': {
      return {
        ...state,
        mtApiCountryLoading: action.payload,
      };
    }
    case 'ADD_MT_API_COUNTRY_ENTRY': {
      return {
        ...state,
        countries: [...state.countries, action.payload],
      };
    }
    case 'REMOVE_MT_API_COUNTRY_ENTRY': {
      return {
        ...state,
        countries: state.countries.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_MT_API_COUNTRY_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        country: action.payload.country,
        override: action.payload.override,
      };
      return {
        ...state,
        countries: [...state.countries.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_MT_API_COUNTRY_ENTRY_FOR_FORM': {
      const entry = state.countries.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedCountryEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_MT_API_COUNTRY_ENTRIES': {
      return {
        ...state,
        countries: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
