import React from 'react';
import { IntlProvider } from 'react-intl';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import 'typeface-roboto';
import DateFnsUtils from '@date-io/date-fns';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import configRoutes from './routes';
import SnackbarMsg from './components/snackbar/SnackbarMsg';
import { getIpAddressInfo } from './actions/app';
import IdleUserWarningDialog from './IdleUserWarningDialog';
import AppearanceProvider from './theme/AppearanceProvider';
import { dateLocales, formats, messages, SupportedLang } from './messages';
import { AppState } from './reducers';

type Props = {
  readonly lang: SupportedLang;
  readonly snackbarOpen: boolean;
  readonly snackbarMsgInfo: $TSFixMe;
  readonly customerId?: number;
  readonly authenticated: boolean;
  readonly getIpAddressInfo: () => void;
};

const App = (props: Props) => {
  const { lang, snackbarOpen, snackbarMsgInfo, customerId, authenticated } = props;
  props.getIpAddressInfo();

  return (
    <StyledEngineProvider injectFirst>
      <AppearanceProvider>
        <LocalizationProvider
          utils={DateFnsUtils}
          adapterLocale={dateLocales[lang]}
          dateAdapter={AdapterDateFns}
        >
          <IntlProvider locale={lang} messages={messages[lang]} formats={formats}>
            <>
              <CssBaseline />
              {configRoutes(customerId)}
              <SnackbarMsg open={snackbarOpen} msgInfo={snackbarMsgInfo} />
              <IdleUserWarningDialog authenticated={authenticated} />
            </>
          </IntlProvider>
        </LocalizationProvider>
      </AppearanceProvider>
    </StyledEngineProvider>
  );
};

function mapStateToProps(store: AppState) {
  return {
    lang: store.locale.lang,
    snackbarOpen: store.snackbar.open,
    snackbarMsgInfo: store.snackbar.msgInfo,
    customerId: store.login.userData.customerId,
    authenticated: store.login.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { getIpAddressInfo })(App));
