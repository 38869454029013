import React from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { appConfig } from '../../config';
import { SubmissionError } from 'redux-form';
import { SignUpFormSubmit } from './SignUpForm';

const submitForm: SignUpFormSubmit = async(values, dispatch, props) => {
  const reCaptchaToken = await props.googleReCaptchaProps.executeRecaptcha(
    'signup'
  );
  try {
    let response = await axios.post(
      `${appConfig.URL_REST}demo/registration/request`,
      {
        ...values,
        company: values.company
          ? values.company
          : `${values.firstName} ${values.lastName}`,
        reCaptchaToken,
      }
    );
    return {
      result: true,
      message: response.data.message,
      status: response.status,
    };
  } catch (error: $TSFixMe) {
    console.dir(error);
    if (error.response.data.message === "Email has already been registered") {
      throw new SubmissionError({
        email: props.intl.formatMessage({
          id: 'signup.confirmation.email.error',
        }),
      });
    }
    if (error.response.data.message === "Mobile phone has already been used") {
      throw new SubmissionError({
        mobilePhone: props.intl.formatMessage({
          id: 'signup.confirmation.mobile.number.error',
        }),
      });
    }
    if (error.response.status === 503) {
      throw new SubmissionError({
        _error: 
        <FormattedMessage
          id="signup.confirmation.error.message"
          values={{
            br: <br />,
          }}
        />
      });
    }
    return {
      result: false,
      message: error.response
        ? error.response.data.message
          ? error.response.data.message
          : error.response.data
        : error.message,
      status: error.response ? error.response.status : undefined,
    };
  }
}

export default submitForm;
