import { v4 as uuidv4 } from 'uuid';
import chatChannelProvider from '../../chats/chatChannelProvider';
import { AppThunkAction } from '../index';
import { Timestamp } from '../../types/datetime';
import { ChatClient, ChatClientType } from '../../types/chatClient';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { createChatChannelsAction } from './index';
import { selectChatClientById, selectChatClientBySenderIdAndPhoneNumber } from '../../reducers/chats/clients';
import { selectSelectedChatSenderId } from '../../reducers/chats/senders';
import phoneNumberService from '../../service/phoneNumberService';
import dateTimeService from '../../service/dateTimeService';

const NEW_CLIENT_PREFIX = 'NEW-';
const generateNewChatClientId = () => `${NEW_CLIENT_PREFIX}${uuidv4()}`;
const isNewChatClientId = (clientId: string) => clientId.startsWith(NEW_CLIENT_PREFIX);

const addOneChatClientAction = createChatChannelsAction<{ client: ChatClient }>('clients/addOne');
const upsertOneChatClientAction = createChatChannelsAction<{ client: ChatClient }>('clients/upsertOne');
const setSelectedChatClientIdAction = createChatChannelsAction<{ clientId: string }>('clients/set');
const replaceChatClientAction = createChatChannelsAction<{
  clientIdToReplace: string;
  client: ChatClient;
}>('clients/replace');

export const setSelectedChatClientId = (channel: SupportedChatChannel, clientId: string) => (
  setSelectedChatClientIdAction(channel, { clientId })
);

const chatClientsFetchPendingAction = createChatChannelsAction('clients/fetchPending');
const chatClientsFetchSuccessAction = createChatChannelsAction<ChatClient[]>('clients/fetchSuccess');
const chatClientsFetchFailureAction = createChatChannelsAction<string>('clients/fetchFailure');

export const fetchChatClients = (channel: SupportedChatChannel, senderId: string): AppThunkAction => {
  return async (dispatch) => {
    dispatch(chatClientsFetchPendingAction(channel));

    try {
      const chatClients: ChatClient[] = await chatChannelProvider.get(channel)
        .api.fetchClients(senderId);

      dispatch(chatClientsFetchSuccessAction(channel, chatClients));
    } catch {
      dispatch(chatClientsFetchFailureAction(channel, 'Failed to fetch chat clients'));
    }
  };
};

interface RecordChatClientActivityParams {
  readonly channel: SupportedChatChannel;
  readonly clientId: string;
  readonly activityTime: Timestamp;
}

export const recordChatClientActivity = (params: RecordChatClientActivityParams): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel, clientId, activityTime } = params;

    const chatClient = selectChatClientById(getState(), channel, clientId);
    if (chatClient) {
      dispatch(upsertOneChatClientAction(channel, {
        client: {
          ...chatClient,
          lastActivityTime: activityTime,
        },
      }));
    }
  };
};

export const selectOrCreateChatClientByContact = (params: {
  readonly channel: SupportedChatChannel;
  readonly contactName: string;
  readonly contactPhoneNumber: string;
}): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel, contactName, contactPhoneNumber } = params;
    const normalizedPhoneNumber = phoneNumberService.toPhoneNumberWithPlusSign(contactPhoneNumber);

    const selectedChatSenderId = selectSelectedChatSenderId(getState(), channel);
    if (!selectedChatSenderId) {
      throw Error('Could not select or create chat client for contact due to missing senderId');
    }

    const chatClient = selectChatClientBySenderIdAndPhoneNumber(getState(), {
      channel,
      senderId: selectedChatSenderId,
      phoneNumber: normalizedPhoneNumber,
    });

    const clientId = chatClient?.id || generateNewChatClientId();
    if (isNewChatClientId(clientId)) {
      dispatch(addOneChatClientAction(channel, {
        client: {
          channel,
          id: clientId,
          type: ChatClientType.NUMERIC,
          name: contactName,
          number: normalizedPhoneNumber,
          lastActivityTime: dateTimeService.getCurrentTimestamp(),
          senderId: selectedChatSenderId,
          foundInContacts: false,
        },
      }));
    }

    dispatch(setSelectedChatClientIdAction(channel, { clientId }));
  };
};

export const handleChatClientCreated = (client: ChatClient): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel, senderId, number } = client;
    const normalizedPhoneNumber = phoneNumberService.toPhoneNumberWithPlusSign(number!);

    const existingChatClient = selectChatClientBySenderIdAndPhoneNumber(getState(), {
      channel,
      senderId,
      phoneNumber: normalizedPhoneNumber,
    });

    if (existingChatClient && isNewChatClientId(existingChatClient.id)) {
      dispatch(replaceChatClientAction(channel, {
        clientIdToReplace: existingChatClient.id,
        client: {
          ...client,
          name: existingChatClient.name,
        },
      }));
    } else {
      dispatch(upsertOneChatClientAction(client.channel, { client }));
    }
  };
};
