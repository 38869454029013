import { AnyAction } from 'redux';
import { CustomerMtPricing } from '../../types/customerMtPricing';

interface MtPricingState {
  mtPricings: CustomerMtPricing[];
  selectedMtPricing: Partial<CustomerMtPricing>;
  mtPricingsLoading: boolean;
}

const initialState: MtPricingState = {
  mtPricings: [],
  selectedMtPricing: {},
  mtPricingsLoading: false,
};

export default function reducer(state: MtPricingState = initialState, action: AnyAction): MtPricingState {
  switch (action.type) {
    case 'SET_MT_PRICINGS_LOADING': {
      return {
        ...state,
        mtPricingsLoading: action.payload,
      };
    }
    case 'ADD_MT_PRICING': {
      return {
        ...state,
        mtPricings: [...state.mtPricings, { id: state.mtPricings.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_MT_PRICING': {
      return {
        ...state,
        mtPricings: state.mtPricings.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_MT_PRICING': {
      const itemToReplace = {
        destinationName: action.payload.destinationName,
        breakout: action.payload.breakout,
        rate: action.payload.rate,
        includedInPackage: action.payload.includedInPackage,
      };
      return {
        ...state,
        mtPricings: [
          // @ts-expect-error itemToReplace does not contain all CustomerMtPricing properties - to verify and fix!
          ...state.mtPricings.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerMtPricing properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MT_PRICING_FOR_FORM': {
      return {
        ...state,
        selectedMtPricing: action.payload,
      };
    }
    case 'SET_MT_PRICINGS': {
      return {
        ...state,
        mtPricings: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
