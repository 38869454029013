import { AnyAction } from 'redux';
import { CustomerPricelist } from '../../types/customerPricelist';

interface PricelistsState {
  pricelistsLoading: boolean;
  pricelists: CustomerPricelist[];
  pricelistForForm: Partial<CustomerPricelist>;
  pricelistFormLoading: boolean;
  editMode: boolean;
  customerPricelistId?: number;
}

const initialState: PricelistsState = {
  pricelistsLoading: false,
  pricelists: [],
  pricelistForForm: {},
  pricelistFormLoading: false,
  editMode: false,
  customerPricelistId: undefined,
};

export default function reducer(state: PricelistsState = initialState, action: AnyAction): PricelistsState {
  switch (action.type) {
    case 'SET_PRICELIST_FORM_LOADING': {
      return {
        ...state,
        pricelistFormLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_PRICELISTS_LOADING': {
      return {
        ...state,
        pricelistsLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_PRICELISTS': {
      return {
        ...state,
        pricelists: action.payload,
      };
    }
    case 'REMOVE_PRICELIST': {
      return {
        ...state,
        pricelists: state.pricelists.filter((item) => item.id !== action.payload),
      };
    }
    case 'SET_PRICELIST_FOR_FORM': {
      return {
        ...state,
        pricelistForForm: action.payload,
      };
    }
    case 'SET_EDIT_MODE': {
      return {
        ...state,
        editMode: action.payload,
      };
    }
    case 'UPDATE_PACKAGE_INFO': {
      const itemToReplace = {
        name: action.payload.name,
        smsInPackage: action.payload.smsInPackage,
        currency: action.payload.currency,
        monthlySubscriptionFee: action.payload.monthlySubscriptionFee,
        invoicePosition: action.payload.invoicePosition,
      };
      return {
        ...state,
        pricelists: [
          // @ts-expect-error itemToReplace does not contain all CustomerPricelist properties - to verify and fix!
          ...state.pricelists.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerPricelist properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_CUSTOMER_PRICELIST_ID': {
      return {
        ...state,
        customerPricelistId: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
