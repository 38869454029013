import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { confirmable } from 'react-confirm';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import theme from '../../theme';

const Transition = React.forwardRef(function Transition(props: $TSFixMe, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type Props = {
  readonly title: string;
  readonly okLabel: string;
  readonly cancelLabel: string;
  readonly show: boolean;
  readonly confirmation: $TSFixMe;
  readonly proceed: () => void;
  readonly dismiss: () => void;
  readonly cancel: () => void;
};

const Confirmation = ({
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
  title = 'Confirmation',
}: Props) => {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog
          open={show}
          TransitionComponent={Transition}
          onClose={dismiss}
          maxWidth={'xs'}
          fullWidth
        >
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {confirmation}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} color="primary">
              {cancelLabel}
            </Button>
            <Button onClick={proceed} color="secondary">
              {okLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default confirmable(Confirmation);
