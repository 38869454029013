import { ChatChannel } from '../interface';
import { ChatApi } from '../interface';
import { ChatConfig } from '../interface';
import { ChatListener } from '../interface';
import { ChatPublisher } from '../interface';
import whatsappChatConfig from './whatsappChatConfig';
import WhatsappChatApi from './whatsappChatApi';
import WhatsappChatListener from './whatsappChatListener';
import WhatsappChatPublisher from './whatsappChatPublisher';

export class WhatsappChatChannel implements ChatChannel {
  readonly api: ChatApi;
  readonly config: ChatConfig;
  readonly listener: ChatListener;
  readonly publisher: ChatPublisher;

  constructor() {
    this.config = whatsappChatConfig;
    this.api = new WhatsappChatApi();
    this.listener = new WhatsappChatListener();
    this.publisher = new WhatsappChatPublisher();
  }
}

export default new WhatsappChatChannel();
