import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { parseISO, format } from 'date-fns';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import green from '../../../node_modules/@mui/material/colors/green';
import { appConfig } from '../../config';
import BaseLoginView from '../common/logins/BaseLoginView';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AppState } from '../../reducers';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}));

type Props = {
  readonly countryCode: string;
  readonly match: { params: { hash: $TSFixMe }};
};

const OptOutForm = (props: Props) => {
  const [errors, setErrors] = useState<$TSFixMe>([]); // eslint-disable-line
  const [mobilePhone, setMobilePhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [activatedDate, setActivatedDate] = useState(undefined);

  useEffect(() => {
    setIsLoading(true);
    setDisabled(true);
    const { match } = props;
    axios
      .get(`${appConfig.URL}h/${match.params.hash}`)
      .then((res) => {
        setIsLoading(false);
        setDisabled(false);
        setMobilePhone(res.data.number);
        setActive(res.data.active);
        setActivatedDate(res.data.activatedDate && parseISO(res.data.activatedDate));
      })
      .catch(() => {
        setIsLoading(false);
        setDisabled(true);
        setShowError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMobilePhoneChange = () => '';

  const handleUnsubscribe = () => {
    setIsLoading(true);
    setDisabled(true);
    const { match } = props;
    axios.post(`${appConfig.URL}h/${match.params.hash}`).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        setMobilePhone('');
        setShowInfo(true);
      }
    });
  };

  const classes = useStyles();
  const { countryCode } = props;

  const content = (
    <Grid container spacing={3}>
      {showInfo && (
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage id="dialog.opt.out.info.success" />
          </Typography>
        </Grid>
      )}
      {active && activatedDate && (
          <Grid item xs={12}>
            <Typography>
              <FormattedMessage
                id="dialog.opt.out.info.success.date"
                values={{
                  date: format(activatedDate, 'yyyy-MM-dd HH:mm:ss'),
                  br: <br />,
                }}
              />
            </Typography>
          </Grid>
        )}
        {showError && (
          <Grid item xs={12}>
            <Typography color="error">
              <FormattedMessage id="dialog.opt.out.info.wrong.link" />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <MuiPhoneNumber
            error={Boolean(errors.mobilePhone)}
            fullWidth
            disabled
            name="mobileNumber"
            value={mobilePhone}
            label={<FormattedMessage id="common.label.mobile.phone" />}
            autoFormat={false}
            defaultCountry={countryCode.toLowerCase()}
            onChange={handleMobilePhoneChange}
            helperText={Boolean(errors.mobilePhone) && errors.mobilePhone[0]}
          />
        </Grid>
      <Grid item xs={12} style={{ alignItems: 'center', display: 'flex' }}>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            disabled={disabled || active}
            color="secondary"
            onClick={handleUnsubscribe}
          >
            <FormattedMessage id="dialog.opt.out.button.label" />
          </Button>
          {isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    </Grid>
  );

  return (
    <BaseLoginView
      title={<FormattedMessage id="dialog.opt.out.title" />}
      content={content}
    />
  );
};

// eslint-disable-next-line
export default connect((state: AppState) => ({
  countryCode: state.app.ipInfo.country,
}))(OptOutForm);
