import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';

type Props = {
  readonly id: $TSFixMe;
  readonly timezone: $TSFixMe;
  readonly showErrorsInline: $TSFixMe;
  readonly getFormatString: $TSFixMe;
  readonly dispatch: $TSFixMe;
  readonly myOnChange: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { name: $TSFixMe, value: string, onChange: (value: $TSFixMe) => void };
};

const RenderDateTimePickerField = ({
  timezone,
  showErrorsInline,
  input: { onChange, value, name },
  meta: { touched, error },
  dispatch,
  myOnChange,
  id,
  ...other
}: Props) => {
  // eslint-disable-next-line
  const handleChange = (value: $TSFixMe) => {
    if (myOnChange) myOnChange(value);
    onChange(value);
  };
  return (
    <DateTimePicker
      name={name}
      ampm={false}
      inputFormat="yyyy-MM-dd HH:mm"
      views={['year', 'month', 'day', 'hours', 'minutes']}
      mask="____-__-__ __:__"
      onChange={handleChange}
      value={value ? value : null}
      {...other}
      // @ts-expect-error TODO: no property autoOk
      autoOk
      renderInput={(params) => (
        <TextField {...params} id={id} variant="standard" fullWidth error={Boolean(error)} helperText={error && error[0]} />
      )}
    />
  );
};

export default RenderDateTimePickerField;
