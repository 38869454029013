export enum ResourceType {
  CONTACTS_KEY = 'contacts',
  CONTACTS_FILES_KEY = 'files',
  USERS_KEY = 'users',
  ROLES_KEY = 'roles',
  CUSTOMERS_KEY = 'customers',
  CONTACTS_TAGS_KEY = 'contact_tag',
  CAMPAIGNS_KEY = 'campaigns',
  CAMPAIGNS_TEMPLATES_KEY = 'campaigns/templates',
  CAMPAIGNS_SENDERS = 'sendersId',
  INVOICES = 'invoices',
  PRICING_STATIC = 'static-price-lists',
  PRICING_TRAFFIC = 'traffic-price-lists',
  CAMPAIGNS_SMSES = 'campaignSmsResources',
  CAMPAIGNS_STATS = 'campaigns/stats',
  SMS = 'sms',
  PRICELIST = 'pricelist',
  PRICELIST_ENTRY = 'pricelist_entry',
  PRICELIST_COUNTERS = 'pricelist_package_counter',
  PRICELIST_PACKAGES = 'pricingPackage',
  PRICELIST_CUSTOMERS_BALANCE = 'balanceOverviewMonthlyResources',
  SETTINGS = 'settings',
  VATRATE = 'vatRate',
  RESELLERS = 'resellers',
  BRANDING = 'branding',
  UNAUTHORIZED_MAIL = 'unauthorizedMailModelList',
  MESSAGE_LOGS = 'messageLogs',
}

export interface ResourceDetails {
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  },
  row: {
    resellerId?: $TSFixMe;
    senderId?: $TSFixMe;
    tags?: $TSFixMe;
    files: $TSFixMe[],
    invoiceNumber?: string,
    invoiceNotification?: $TSFixMe[],
    birthday?: $TSFixMe,
    _links?: $TSFixMe,
  },
  sort?: $TSFixMe,
  data: $TSFixMe[];
  links: $TSFixMe;
  loading: boolean;
  search: string;
  filter: string;
  loadingRow: boolean;
  files: $TSFixMe[];
  loadingExcel: boolean;
}

const defaultResourceDetails: ResourceDetails = {
  page: {
    size: 10,
    totalElements: 0,
    totalPages: 0,
    number: 0,
  },
  row: {
    tags: undefined,
    files: [],
    invoiceNumber: '',
    invoiceNotification: [],
    birthday: undefined,
    _links: {},
  },
  data: [],
  links: {},
  loading: false,
  search: '',
  filter: '',
  loadingRow: false,
  files: [],
  loadingExcel: false,
};

export type ResourcesState = {
  [key in ResourceType]: ResourceDetails;
}

const initialState: ResourcesState = Object.values(ResourceType)
  .reduce((acc, resourceType) => ({
    ...acc,
    [resourceType]: { ...defaultResourceDetails },
  }), {}) as ResourcesState;

export default initialState;
