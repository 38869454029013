import { v4 as uuidv4 } from 'uuid';
import {
  addSenderEntry,
  updateSenderEntry,
} from '../../../actions/email2sms/sender';
import { SenderFormSubmit } from './configuration.form';

const submitForm: SenderFormSubmit = (values, dispatch, props) => {
  dispatch(
    values.id
      ? updateSenderEntry(values)
      : addSenderEntry({ ...values, id: uuidv4() })
  );
}
export default submitForm;
