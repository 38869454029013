import Validator from 'validatorjs';
import { isValid, subDays, isAfter } from 'date-fns';
import { ExportValidationConfigFormValidator } from './ExportValidationConfig';

const validate: ExportValidationConfigFormValidator = (values, props) => {
  const rules = {
    createDateTimeAfter: 'required|validDate|notBeforeDate|notAfterDate',
    createDateTimeBefore: 'required|validDate|notAfterDate',
    lastStatusTimeAfter: 'validDate|notAfterDate',
    lastStatusTimeBefore: 'validDate|notAfterDate',
  };

  Validator.register(
    'validDate',
    (value) => isValid(value),
    props.intl.formatMessage({
      id: 'campaign.validation.invalid.date',
    })
  );

  Validator.register(
    'notBeforeDate',
    (value: $TSFixMe) => {
      const dateMinus35Days = subDays(values.createDateTimeBefore, 35);
      return isAfter(value, dateMinus35Days);
    },
    props.intl.formatMessage({
      id: 'email2sms.ubs.messageLogs.max.days.limit',
    })
  );

  Validator.register(
    'notAfterDate',
    (value: $TSFixMe) =>  !isAfter(value, new Date().setHours(23,59,59,999)),
    props.intl.formatMessage({
      id: 'form.validation.not.after.date',
    })
  );

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
  });
  validation.passes();
  return validation.errors.all();
};

export default validate;
