import axios from 'axios';
// @ts-expect-error @types/axios-cancel is outdated and use axios 0.15.2 with critical vulnerability
import axiosCancel from 'axios-cancel';
import React from 'react';
import { push } from 'react-router-redux';
import { FormattedMessage } from 'react-intl';
import { appConfig } from '../config';
import isArray from 'lodash-es/isArray';
import { AppThunkAction } from './index';
import { AnyAction } from 'redux';

axiosCancel(axios, {
  debug: false, // default
});

export const axiosWithCancel = axios;

export function setSort(resource: $TSFixMe, sort: { by: string, dir: string }): AnyAction {
  return {
    type: 'SET_SORT',
    sort,
    resource,
  };
}

export function setPageSize(resource: $TSFixMe, size: $TSFixMe): AnyAction {
  return {
    type: 'UPDATE_PAGE_SIZE',
    size,
    resource,
  };
}

export function setPage(resource: $TSFixMe, page: $TSFixMe): AnyAction {
  return {
    type: 'UPDATE_PAGE',
    page,
    resource,
  };
}

export function setSearch(resource: $TSFixMe, search: $TSFixMe): AnyAction {
  return {
    type: 'APPLY_SEARCH',
    search,
    resource,
  };
}

export function setFilter(resourceArrayName: $TSFixMe, filter: $TSFixMe): AnyAction {
  // const arr = filter.startsWith('&')
  //   ? filter.substring(1).split('&')
  //   : filter.split('&');
  // const filterString = arr
  //   .map(e => {
  //     const innerArr = e.split('=');
  //     return innerArr[0] && innerArr[1]
  //       ? `${innerArr[0]}=${innerArr[1]}`
  //       : undefined;
  //   })
  //   .join('&');
  return {
    type: 'APPLY_FILTER',
    filter: `&${filter}`,
    resource: resourceArrayName,
  };
}

export function get(
  resource: $TSFixMe,
  options: $TSFixMe = { link: '', cancelable: true, excel: false, full: false },
  reset: boolean = false,
): AppThunkAction<Promise<$TSFixMe>> {
  const {
    defaultSort,
    link,
    filterString,
    searchString,
    resourceArrayName,
    storeInRedux = true,
    cancelable = true,
    rawData = false,
    full = false,
  } = options;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  if (cancelable) {
    // @ts-expect-error TODO: config does not have requestId property - to verify!
    config.requestId = resource;
  }
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch, getState) =>
    new Promise((resolve) => {
      const filterByString = filterString || '';
      const searchByString = searchString || '';

      if (reset) {
        dispatch(setPageSize(resourceArray, 10));
        dispatch(setSort(resourceArray, { ...defaultSort }));
        dispatch(setSearch(resourceArray, ''));
        dispatch(setFilter(resourceArray, ''));
      }

      if (full) {
        dispatch({ type: 'LOADING_EXCEL', resource: resourceArray });
        // dispatch(setPageSize(resourceArray, 10000));
      } else dispatch({ type: 'LOADING', resource: resourceArray });

      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].sort) {
        dispatch(setSort(resourceArray, defaultSort));
      }
      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].filter === 'undefined') {
        dispatch(setFilter(resourceArray, filterByString));
      }
      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].search === 'undefined') {
        dispatch(setSearch(resourceArray, searchByString));
      }

      // @ts-expect-error TODO: Fix after providing Resource model
      const { page, sort, filter, search } = getState().resources[resourceArray];
      let url = '';

      if (page) {
        url = !link
          ? `${appConfig.URL_REST}${resource}` +
            `?page=${page.number}&size=${page.size}&sort=${sort.by},${sort.dir}${filter}${search}&full=${full}`
          : link && link.indexOf(filter.substring(1)) > 0
          ? link
          : link + filter;
      } else {
        url = !link
          ? `${appConfig.URL_REST}${resource}`
          : link && link.indexOf(filter.substring(1)) > 0
          ? link
          : link + filter;
      }
      axios
        .get(url, config)
        .then((response) => {
          // eslint-disable-next-line
          const items = response.data._embedded
            ? response.data._embedded[resourceArray].map((item: $TSFixMe) => {
                // eslint-disable-next-line
                return {
                  ...item,
                  // eslint-disable-next-line
                  id: item._links && item._links.self ? item._links.self.href : item.id ? item.id : '',
                };
              })
            : rawData
            ? response.data
            : [];

          if (storeInRedux) {
            dispatch({
              type: 'GET_SUCCESS',
              data: items,
              links: response.data._links,
              page: response.data.page || {
                size: response.data.size,
                totalElements: response.data.totalElements,
                totalPages: response.data.totalPages,
                number: response.data.number,
                first: response.data.first,
                last: response.data.last,
              },
              resource: resourceArray,
            });
            resolve(response);
          } else {
            dispatch({
              type: 'LOADING_FINISHED',
              resource: resourceArray,
            });
            resolve(response);
          }
        })
        .catch((error) => {
          if ((error.message && !error.message.includes('cancelRequest')) || !error.message) {
            if (error.response && error.response.status !== 404) {
              dispatch({
                type: 'GET_FAILED',
                data: [],
                links: [],
                response: error.response,
                resource: resourceArray,
              });
              dispatch({
                type: 'ADD_SNACKBAR',
                variant: 'error',
                msg: <FormattedMessage id="data.fetching.failed" />,
              });
            }
            if (error.message && error.message.includes('Network')) {
              dispatch({
                type: 'ADD_SNACKBAR',
                variant: 'error',
                msg: <FormattedMessage id="data.fetching.bad.request" />,
              });
            }
            dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
            if (error.response) {
              if (error.response.data.message) {
                if (error.response && error.response.data.message.includes('invalid JWT')) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('redux');
                  dispatch({ type: 'IS_NOT_AUTHENTICATED' });
                  dispatch(push('/login'));
                }
              }
              // if (error.response.data.status) {
              //   if (error.response && String(error.response.data.status).indexOf('invalid JWT') !== -1) {
              //     localStorage.removeItem('token');
              //     dispatch({ type: 'IS_NOT_AUTHENTICATED' });
              //     dispatch(push('/login'));
              //   }
              // }
            }
          } else {
            dispatch({
              type: 'LOADING_FINISHED',
              resource: resourceArray,
            });
          }
        });
    });
}

export function getSingleRow(
  resource: $TSFixMe,
  id: $TSFixMe,
  options: $TSFixMe = { storeInRedux: true },
): AppThunkAction<Promise<$TSFixMe>> {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  const { storeInRedux, cancelable = true } = options;
  if (cancelable) {
    // @ts-expect-error TODO: config does not have requestId property - to verify!
    config.requestId = id;
  }
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: 'LOADING_ROW', resource });
      const linkedProps = {};
      return axios
        .get(id, config)
        .then((response) => {
          if (response.data._links) {
            Object.entries(response.data._links).forEach(([key, values]) => {
              // @ts-expect-error TODO: Fix after providing LinkedProps type
              linkedProps[key] = values.href;
            });
          }
          if (storeInRedux) {
            dispatch({
              type: 'GET_ROW_SUCCESS',
              row: isArray(response.data) ? response.data : { ...linkedProps, ...response.data },
              resource,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          if ((error.message && !error.message.includes('cancelRequest')) || !error.message) {
            dispatch({
              type: 'GET_FAILED',
              data: [],
              links: [],
              response: error.response,
              resource,
            });
          }
        });
    });
}

export function setRow(resource: $TSFixMe, row: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_ROW',
      row,
      resource,
    });
  };
}

export function post(
  resource: $TSFixMe,
  data: $TSFixMe,
  options: $TSFixMe = { resourceArrayName: '', updateCollection: true },
): AppThunkAction {
  const { resourceArrayName, updateCollection } = options;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  const url = `${appConfig.URL_REST}${resource}`;
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: 'LOADING', resource: resourceArray });
      return axios
        .post(url, data, config)
        .then((response) => {
          if (updateCollection) {
            if (resourceArrayName) dispatch(get(resource, { resourceArrayName }));
            else dispatch(get(resource));
          }
          dispatch({
            type: 'ADD_SNACKBAR',
            msg: <FormattedMessage id="data.fetching.entrySuccess" />,
            variant: 'success',
          });
          dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
          resolve(response);
        })
        .catch((error) => {
          dispatch({ type: 'LOADING_FINISHED', resource: resourceArray });
          if (error.response.data.message.includes('Email has already been registered')) {
            dispatch({
              type: 'ADD_SNACKBAR',
              msg:
                <FormattedMessage
                  id="administration.customers.platform.users.email.exists"
                  values={{ email: error.response.data.message.slice(35) }}
                />,
              variant: 'error',
            });
          } else {
            const text = error.response ? error.response.data : '';
            dispatch({
              type: 'ADD_SNACKBAR',
              msg: <FormattedMessage id="data.fetching.entryFailed" values={{ text: text.error }} />,
              variant: 'error',
            });
          }
          dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
        });
    });
}

export function del(
  id: $TSFixMe,
  resource: $TSFixMe,
  options: $TSFixMe = { resourceArrayName: '' },
): AppThunkAction {
  const { resourceArrayName } = options;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  const url = id;
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch) => {
    dispatch({ type: 'LOADING', resource: resourceArray });
    return axios
      .delete(url, config)
      .then(() => {
        if (resourceArrayName) dispatch(get(resource, { resourceArrayName }));
        else dispatch(get(resource));
        dispatch({
          type: 'ADD_SNACKBAR',
          msg: <FormattedMessage id="data.fetching.dataDeleted" />,
          variant: 'success',
        });
        dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
      })
      .catch(() => {
        dispatch({ type: 'LOADING_FINISHED', resource: resourceArray });
        dispatch({
          type: 'ADD_SNACKBAR',
          msg: <FormattedMessage id="data.fetching.dataDelFailed" />,
          variant: 'error',
        });
        dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
      });
  };
}

export function put(
  id: $TSFixMe,
  resource: $TSFixMe,
  data: $TSFixMe,
  options: $TSFixMe = { resourceArrayName: '' },
): AppThunkAction {
  const { resourceArrayName } = options;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({ type: 'LOADING', resource: resourceArray });
      return axios
        .put(id, data, config)
        .then((response) => {
          if (resourceArrayName) dispatch(get(resource, { resourceArrayName }));
          else dispatch(get(resource));
          dispatch({
            type: 'ADD_SNACKBAR',
            msg: <FormattedMessage id="data.fetching.dataSaved" />,
            variant: 'success',
          });
          dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
          resolve(response);
        })
        .catch(() => {
          dispatch({ type: 'LOADING_FINISHED', resource: resourceArray });
          dispatch({
            type: 'ADD_SNACKBAR',
            msg: <FormattedMessage id="data.fetching.dataFailed" />,
            variant: 'error',
          });
          dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
        });
    });
}

export function changePageSize(
  resource: $TSFixMe,
  size: $TSFixMe,
  options: $TSFixMe = { resourceArrayName: '', rawData: false },
): AppThunkAction {
  const { resourceArrayName, rawData } = options;
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_PAGE_SIZE',
      size,
      resource: resourceArray,
    });
    if (resourceArrayName) dispatch(get(resource, { resourceArrayName, rawData }));
    else dispatch(get(resource, { rawData }));
  };
}

export function openConfirmationDelete(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_MODAL',
    });
  };
}

export function closeConfirmationDelete(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };
}

export function confirmDelete(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'CONFIRM_DELETE',
    });
  };
}

// export function setCancel(resource, cancel) {
//   return dispatch => {
//     dispatch({
//       type: 'SET_CANCEL',
//       cancel,
//       resource,
//     });
//   };
// }

export function resetResource(resource: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'RESET',
      resource,
    });
  };
}

export function getEmail2sms(
  resource: $TSFixMe,
  options: $TSFixMe = { link: '', cancelable: true, excel: false },
  reset: boolean = false,
): AppThunkAction {
  const {
    defaultSort,
    link,
    filterString,
    searchString,
    resourceArrayName,
    storeInRedux = true,
    cancelable = true,
    rawData = false,
  } = options;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  if (cancelable) {
    // @ts-expect-error TODO: config does not have requestId property - to verify!
    config.requestId = resource;
  }
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch, getState) =>
    new Promise((resolve) => {
      const filterByString = filterString || '';
      const searchByString = searchString || '';

      if (reset) {
        dispatch(setPageSize(resourceArray, 10));
        dispatch(setSort(resourceArray, { ...defaultSort }));
        dispatch(setSearch(resourceArray, ''));
        dispatch(setFilter(resourceArray, ''));
      }

      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].sort) {
        dispatch(setSort(resourceArray, defaultSort));
      }
      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].filter === 'undefined') {
        dispatch(setFilter(resourceArray, filterByString));
      }

      // @ts-expect-error TODO: Fix after providing Resource model
      if (!getState().resources[resourceArray].search === 'undefined') {
        dispatch(setSearch(resourceArray, searchByString));
      }

      // @ts-expect-error TODO: Fix after providing Resource model
      const { page, sort, filter, search } = getState().resources[resourceArray];
      let url = '';

      if (page) {
        url = !link
          ? `${appConfig.EMAIL2SMS_URL}${resource}` +
            `?page=${page.number}&size=${page.size}&sort=${sort.by},${sort.dir}${filter}${search}`
          : link && link.indexOf(filter.substring(1)) > 0
          ? link
          : link + filter;
      } else {
        url = !link
          ? `${appConfig.EMAIL2SMS_URL}${resource}`
          : link && link.indexOf(filter.substring(1)) > 0
          ? link
          : link + filter;
      }
      axios
        .get(url, config)
        .then((response) => {
          // eslint-disable-next-line
          const items = response.data._embedded
            ? response.data._embedded[resourceArray].map((item: $TSFixMe) => {
                // eslint-disable-next-line
                return {
                  ...item,
                  // eslint-disable-next-line
                  id: item._links && item._links.self ? item._links.self.href : item.id ? item.id : '',
                };
              })
            : rawData
            ? response.data
            : [];

          if (storeInRedux) {
            dispatch({
              type: 'GET_SUCCESS',
              data: items,
              links: response.data._links,
              page: response.data.page || {
                size: response.data.size,
                totalElements: response.data.totalElements,
                totalPages: response.data.totalPages,
                number: response.data.number,
              },
              resource: resourceArray,
            });
            resolve(response);
          } else {
            dispatch({
              type: 'LOADING_FINISHED',
              resource: resourceArray,
            });
            resolve(response);
          }
        })
        .catch((error) => {
          if ((error.message && !error.message.includes('cancelRequest')) || !error.message) {
            if (error.response && error.response.status !== 404) {
              dispatch({
                type: 'GET_FAILED',
                data: [],
                links: [],
                response: error.response,
                resource: resourceArray,
              });
              dispatch({
                type: 'ADD_SNACKBAR',
                variant: 'error',
                msg: <FormattedMessage id="data.fetching.failed" />,
              });
            }
            if (error.message && error.message.includes('Network')) {
              dispatch({
                type: 'ADD_SNACKBAR',
                variant: 'error',
                msg: <FormattedMessage id="data.fetching.bad.request" />,
              });
            }
            dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
            if (error.response) {
              if (error.response.data.message) {
                if (error.response && error.response.data.message.includes('invalid JWT')) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('redux');
                  dispatch({ type: 'IS_NOT_AUTHENTICATED' });
                  dispatch(push('/login'));
                }
              }
              // if (error.response.data.status) {
              //   if (error.response && String(error.response.data.status).indexOf('invalid JWT') !== -1) {
              //     localStorage.removeItem('token');
              //     dispatch({ type: 'IS_NOT_AUTHENTICATED' });
              //     dispatch(push('/login'));
              //   }
              // }
            }
          } else {
            dispatch({
              type: 'LOADING_FINISHED',
              resource: resourceArray,
            });
          }
        });
    });
}

export function changeEmail2smsPageSize(
  resource: $TSFixMe,
  size: $TSFixMe,
  options: $TSFixMe = { resourceArrayName: '', rawData: false },
): AppThunkAction {
  const { resourceArrayName, rawData } = options;
  const resourceArray = resourceArrayName || `${resource}`;
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_PAGE_SIZE',
      size,
      resource: resourceArray,
    });
    if (resourceArrayName) dispatch(getEmail2sms(resource, { resourceArrayName, rawData }));
    else dispatch(getEmail2sms(resource, { rawData }));
  };
}
