import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash-es/debounce';

type Props = {
  readonly hideSearchBar: () => void;
  readonly onSearch: (searchString: string) => void;
};

const SearchBar = (props: Props) => {
  const { hideSearchBar } = props;

  const [search, setSearch] = useState('');

  const onSearch = debounce(searchString => props.onSearch(searchString), 500);

  const handleChange = (name: string) => (event: $TSFixMe) => {
    const searchString = event.target.value;
    setSearch(searchString);
  };

  useEffect(() => {
    onSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <React.Fragment>
      <TextField
        value={search}
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={handleChange('search')}
      />
      <Tooltip title={<FormattedMessage id="datatable.button.close" />}>
        <IconButton onClick={hideSearchBar} size="large">
          <CloseIcon color="primary" />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

export default SearchBar;
