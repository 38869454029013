import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import { Typography } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { green } from '@mui/material/colors';
import TestAccountNumberConfirmationDialog from './TestAccountNumberConfirmationDialog';
import axios from 'axios';
import InfoBox from '../InfoBox';
import { CalendarToday, Message } from '@mui/icons-material';
import { appConfig } from '../../config';
import { isNumberInList, isNumberValid } from '../common/utils';
import { setNumbers } from '../../actions/testAccount';
import { AppState } from '../../reducers';

let timer: $TSFixMe;

type Props = {
  readonly numbers: $TSFixMe;
  readonly smsLimit: number;
  readonly accountValidTo: $TSFixMe;
  readonly selectedContact: $TSFixMe;
  readonly maxWhitelistsNumber: $TSFixMe;
  readonly whitelistedNumbers: $TSFixMe[];
  readonly smsCodeValidForMinutes: number;
  readonly setNumbers: (numbers: $TSFixMe) => void;
};

const TestAccountNumbersForm = (props: Props) => {
  const {
    smsCodeValidForMinutes,
    accountValidTo,
    smsLimit,
    maxWhitelistsNumber,
    whitelistedNumbers,
    selectedContact,
    numbers,
    setNumbers,
  } = props;

  const [numberToVerify, setNumberToVerify] = React.useState(undefined);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);
    const [error, setError] = React.useState(false);

  const timeLimit = 60 * smsCodeValidForMinutes;
  const [progress, setProgress] = React.useState(timeLimit);

  const disableNewNumbersAddButton =
    numbers.length + whitelistedNumbers.length >= maxWhitelistsNumber;

  const addNewNumber = () => {
    if (disableNewNumbersAddButton) return;
    setNumbers([
      ...numbers,
      {
        number: '',
        verified: false,
        new: true,
        name: `number${numbers.length + 1}`,
        valid: true,
        duplicated: false,
      },
    ]);
  };

  const removeNumber = (item: $TSFixMe) => {
    if (item.new) {
      setNumbers(numbers.filter((e: $TSFixMe) => item.name !== e.name));
    }
  };

  const removeNumberAfterConfirm = (number: $TSFixMe) => {
    if (number) {
      setNumbers(numbers.filter((e: $TSFixMe) => e.number !== number));
    }
  };

  const handleNewNumberChange = (event: $TSFixMe) => {
    const { value, name } = event.target;
    setNumbers(
      numbers.map((e: $TSFixMe) =>
        e.name === name
          ? {
              ...e,
              number: value,
              valid: isNumberValid(value),
              duplicated: isNumberInList(whitelistedNumbers, value),
            }
          : e
      )
    );
  };

  const handleVerifyNumberClick = (item: $TSFixMe) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    setNumberToVerify(item.number);
    axios
      .get(
        `${appConfig.URL_REST}number/registration/request/${item.number}`,
        config
      )
      .then((res) => {
        if (res.status === 200) {
          setConfirmationDialogOpen(true);
          timer = setInterval(() => {
            setProgress((prevProgress) => prevProgress - 1);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "Duplicate number") {
          setError(true);
        }
      });
  };

  const clearIntervalAndCloseDialog = () => {
    setConfirmationDialogOpen(false);
    clearInterval(timer);
    setProgress(timeLimit);
    removeNumberAfterConfirm(numberToVerify);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InfoBox
            variant="small"
            value={accountValidTo}
            label={
              <FormattedMessage id="common.label.valid.to" />
            }
            icon={<CalendarToday />}
            color="blue"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoBox
            variant="small"
            value={smsLimit}
            label={
              <FormattedMessage id="testAccount.numbers.infobox.label.remaining.messages" />
            }
            icon={<Message />}
            color="orange"
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell size="small">
                <Typography variant="body2">
                  <FormattedMessage id="testAccount.numbers.infobox.label.whitelisted.numbers" />
                </Typography>
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell padding="none" align="right">
                <Tooltip
                  title={
                    <FormattedMessage id="testAccount.number.confirmation.dialog.tooltip.add.number" />
                  }
                >
                  <span>
                    <IconButton
                      onClick={addNewNumber}
                      disabled={disableNewNumbersAddButton}
                      size="large"
                    >
                      <Add />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {whitelistedNumbers
              .map((e: $TSFixMe) => {
                return {
                  number: e,
                  verified: true,
                  new: false,
                  duplicated: false,
                  selected: true,
                };
              })
              .concat(numbers)
              .map((item: $TSFixMe, index: number) => (
                <TableRow key={index}>
                  <TableCell size="small" style={{ width: '250px' }}>
                    {!item.new && !item.selected && (
                      <Typography variant="body2">{item.number}</Typography>
                    )}
                    {item.new && (
                      <TextField
                        placeholder="+1234567890"
                        variant="standard"
                        helperText={
                          !item.valid ? (
                            <FormattedMessage id="testAccount.numbers.table.helper.text.number.is.not.valid" />
                          ) : item.duplicated ? (
                            <FormattedMessage id="testAccount.numbers.table.helper.text.number.already.confirmed" />
                          ) : error ? (
                            <FormattedMessage id="testAccount.numbers.table.helper.text.number.already.registered" />
                          ) : undefined
                        }
                        error={!item.valid || item.duplicated || error}
                        name={item.name}
                        value={item.number}
                        onChange={handleNewNumberChange}
                      />
                    )}
                    {!item.new && item.selected && (
                      <TextField
                        variant="standard"
                        name={item.name}
                        value={item.number}
                        onChange={handleNewNumberChange}
                        // @ts-expect-error TODO: no property selected
                        selected={selectedContact}
                      />
                    )}
                  </TableCell>
                  <TableCell align="justify">
                    {item.verified && (
                      <VerifiedUserIcon style={{ color: green[500] }} />
                    )}
                    {!item.verified && (
                      <Button
                        color="primary"
                        onClick={() => handleVerifyNumberClick(item)}
                        disabled={
                          !item.new
                            ? false
                            : item.number === '' ||
                              !item.valid ||
                              item.duplicated
                        }
                      >
                        <FormattedMessage id="testAccount.numbers.button.confirm" />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell padding="none" align="right">
                    <Tooltip
                      title={
                        <FormattedMessage id="common.button.delete" />
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => removeNumber(item)}
                          disabled={!item.new && !item.selected}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TestAccountNumberConfirmationDialog
        timeLimit={timeLimit}
        progress={progress}
        open={confirmationDialogOpen}
        handleClose={clearIntervalAndCloseDialog}
        handleConfirm={(pin) => {
          clearIntervalAndCloseDialog();
        }}
        number={numberToVerify}
      />
    </div>
  );
};

export default connect(
  (state: AppState) => {
    return {
      smsCodeValidForMinutes:
        state.testAccount.accountInfo.smsCodeValidForMinutes,
      accountValidTo: state.testAccount.accountInfo.validTo,
      smsLimit: state.testAccount.accountInfo.smsLimit,
      maxWhitelistsNumber: state.testAccount.accountInfo.maxWhitelistsNumber,
      whitelistedNumbers:
        state.testAccount.accountInfo.whitelistedNumbers || [],
      selectedContact: state.chat.selectedContact,
      numbers: state.testAccount.numbers || [],
    };
  },
  {
    change,
    setNumbers,
  }
)(TestAccountNumbersForm);
