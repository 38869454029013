import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 0,
  },
}));

type Props = {
  readonly label: string | object;
  readonly readOnly: $TSFixMe;
  readonly helperText: string;
  readonly loading: boolean;
  readonly maxLength: number;
  readonly onKeyUp: $TSFixMe;
  readonly onFocus: $TSFixMe;
  readonly endAdornment: $TSFixMe;
  readonly startAdornment: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe };
  readonly input: { value: string, onChange: (value: $TSFixMe) => void };
};

// eslint-disable-next-line
const RenderTextField = ({
  input,
  label,
  onKeyUp,
  loading,
  startAdornment,
  endAdornment,
  readOnly,
  onFocus,
  maxLength = Infinity,
  helperText = '',
  meta: { touched, error },
  ...custom
}: Props) => {
  const classes = useStyles();

  return (
    <TextField
      style={{ marginTop: 5 }}
      label={label}
      error={Boolean(touched && error)}
      helperText={(touched && error) || helperText}
      variant="standard"
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      inputProps={{ maxLength: maxLength }}
      InputProps={{
        startAdornment,
        readOnly: readOnly,
        endAdornment: endAdornment ? (
          endAdornment
        ) : loading ? (
          <InputAdornment position="start">
            <CircularProgress
              variant="indeterminate"
              className={classes.spinner}
              size={16}
              thickness={4}
            />
          </InputAdornment>
        ) : (
          undefined
        ),
      }}
      {...input}
      {...custom}
    />
  )
};

export default RenderTextField;
