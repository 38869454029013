import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const image = require('../../styles/servicechat.png');

const TestAccountDialogStep3 = () => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={7}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.3.sms.chat" />
        </Typography>
        <Typography variant={'body2'} style={{ marginTop: 15 }} align="justify">
          <FormattedMessage id="testAccount.dialog.step.3.choose.contact" />
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <img
          src={image}
          width={200}
          style={{ marginLeft: '30px' }}
          alt="chat"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.3.get.feedback" />
        </Typography>
        <Typography variant={'body2'} style={{ marginTop: 15 }} align="justify">
          <FormattedMessage id="testAccount.dialog.step.3.campaign.manager" />
        </Typography>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.3.sms2email" />
        </Typography>
        <Typography variant={'body2'} style={{ marginTop: 15 }} align="justify">
          <FormattedMessage id="testAccount.dialog.step.3.preference" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TestAccountDialogStep3;
