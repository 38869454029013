import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import RenderSelectField from '../../../common/reduxForm/RenderSelectField';
import RenderDateTimePickerField from '../../../common/reduxForm/RenderDateTimePickerField';
import RenderTextField from '../../../common/reduxForm/RenderTextField';
import { parseISO } from 'date-fns';
import { AppState } from '../../../../reducers';

type Props = {
  readonly filters?: $TSFixMe[];
  readonly smsStatusFilterOptions?: $TSFixMe[];
  readonly change: (field: $TSFixMe, value: $TSFixMe) => void;
};

const ExportMessageLogForm = (props: Props) => {
  const { change, smsStatusFilterOptions = [], filters = [] } = props;

  useEffect(() => {
    Object.keys(filters).forEach((key: $TSFixMe) => {
      const dates = ['createDateTimeAfter', 'createDateTimeBefore', 'lastStatusTimeAfter', 'lastStatusTimeBefore'];
      // @ts-expect-error TODO: type 'yyyy-mm-ddTHH:MM' is not assignable
      change(key, dates.includes(key) ? parseISO(filters[key], 'yyyy-mm-ddTHH:MM') : filters[key]);
    });
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ margin: '20px 10px' }}>
      <Grid container spacing={3} style={{ paddingRight: 25, paddingLeft: 25, display: 'flex' }}>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderDateTimePickerField}
            name="createDateTimeAfter"
            label={<FormattedMessage id="email2sms.ubs.messageLogs.sent.from" />}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderDateTimePickerField}
            name="createDateTimeBefore"
            label={<FormattedMessage id="email2sms.ubs.messageLogs.sent.to" />}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderTextField}
            name="emailMsgId"
            fullWidth
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.email.msg.id" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderTextField}
            name="notifyMail"
            fullWidth
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.email.sender" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderTextField}
            name="sender"
            fullWidth
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.sms.sender" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderTextField}
            name="receiver"
            fullWidth
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.receiver" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderTextField}
            name="numParts"
            fullWidth
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.parts" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderSelectField}
            fullWidth
            name="lastStatus"
            label={<FormattedMessage id="email2sms.ubs.messageLogs.table.last.status" />}
          >
            {smsStatusFilterOptions.map((s) => (
              <MenuItem key={s.name} value={s.value}>
                <FormattedMessage id={s.name} />
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderDateTimePickerField}
            name="lastStatusTimeAfter"
            clearable
            disablePast
            label={<FormattedMessage id="email2sms.ubs.messageLogs.status.time.from" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            component={RenderDateTimePickerField}
            name="lastStatusTimeBefore"
            clearable
            disablePast
            label={<FormattedMessage id="email2sms.ubs.messageLogs.status.time.to" />}
          />
        </Grid>
      </Grid>
    </div>
  );
};

// eslint-disable-next-line
export default connect(
  (store: AppState) => ({
    filters: store.messageLog.filtersForForm,
    smsStatusFilterOptions: store.app.smsStatusFilterOptions,
    uuid: store.email2smsConfig.customerUuid || store.login.userData.customerUuid || 0,
  }),
  {}
)(ExportMessageLogForm);
