import { ViberAccountConfig } from '../../types/viber/viberAccount';
import { HttpClient } from '../common/httpClient';

type ViberAccountConfigResponse = {
  readonly featureFlags: {
    readonly ACTIVE: boolean;
    readonly CAMPAIGN: boolean;
    readonly ONE_TO_ONE: boolean;
  }
};

export class ViberConfigApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToViberFeatureFlags(response: ViberAccountConfigResponse): ViberAccountConfig {
    return {
      featureFlags: {
        ACTIVE: response.featureFlags.ACTIVE,
        CAMPAIGN: response.featureFlags.CAMPAIGN,
        ONE_TO_ONE: response.featureFlags.ONE_TO_ONE,
      }
    };
  }

  getUserConfig(tenantId: string): Promise<ViberAccountConfig> {
    return this.httpClient.get<ViberAccountConfigResponse>(`tenants/${tenantId}/config`)
      .then(data => this.mapToViberFeatureFlags(data));
  }
}
