import { HttpClient } from '../common/httpClient';
import { PageResponse } from '../common/pagingModel';
import { Cdr } from '../../types/cdrs';

export type ViberCdrsResponse = {
  readonly id: number;
  readonly sender: string;
  readonly receiver: string;
  readonly destinationName: string;
  readonly account: string;
  readonly rate: number;
  readonly lastStatus: string;
  readonly createDateTime: string;
  readonly lastStatusTime: string;
  readonly statusDetails: string;
};

export class ViberCdrsApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToViberCdrs(response: ViberCdrsResponse): Partial<Cdr> {
    return {
      id: response.id,
      sender: response.sender,
      receiver: response.receiver,
      destinationName: response.destinationName,
      account: response.account,
      rate: response.rate,
      lastStatus: response.lastStatus,
      createDateTime: response.createDateTime,
      lastStatusTime: response.lastStatusTime,
      statusDetails: response.statusDetails,
    };
  }

  getAll(): Promise<Partial<Cdr>[]> {
    return this.httpClient.get<PageResponse<ViberCdrsResponse>>(``)
      .then(data => (
        data.content.map(it => this.mapToViberCdrs(it))
      ));
  }

  async delete(id: string): Promise<void> {
    await this.httpClient.delete<Cdr>(``);
  }
}
