import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';

type Props = {
  readonly value: number;
  readonly timeLimit: number;
};

const TestAccountProgress = ({ timeLimit, value }: Props) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 1,
      borderRadius: 1,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[200],
    },
    bar: {
      borderRadius: 1,
      backgroundColor: theme.palette.grey[500],
    },
  }))(LinearProgress);

  return (
    <Box display="flex" alignItems="center" component="span">
      <Box width="100%" mr={1} component="span">
        <BorderLinearProgress
          variant="determinate"
          value={Math.round((value * 100) / timeLimit)}
        />
      </Box>
      <Box minWidth={35} component="span">
        <Typography component="span" variant="body2" color="textSecondary">
          {[(value % 3600) / 60, value % 60]
            .map((e) => `0${Math.floor(e)}`.slice(-2))
            .join(':')}
        </Typography>
      </Box>
    </Box>
  );
};

export default TestAccountProgress;
