/* eslint max-len: 0 */

import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import HelpAdminCustomers from './HelpAdminCustomers';
import HelpAdminUsers from './HelpAdminUsers';
import HelpAdminFinance from './HelpAdminFinance';
import HelpAdminBalanceOverview from './HelpAdminBalanceOverview';
import HelpAdminTools from './HelpAdminTools';
import HelpAdminEmail2Sms from './HelpAdminEmail2Sms';
import HelpAdminMtApi from './HelpAdminMtApi';
import HelpAdminCdrs from './HelpAdminCdrs';
import HelpAdminInboundNumbers from './HelpAdminInboundNumbers';
import HelpAdminResellers from './HelpAdminResellers';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdministration = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography
        variant="subtitle2"
        style={{ marginTop: 15, marginBottom: 10 }}
      >
        <FormattedMessage id="help.admin.administration" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.1" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.2" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.3" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.4" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.5" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.6" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.7" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.8" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.9" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.10" />
        </Typography>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.admin.administration.11" />
        </Typography>
      </div>
      <HelpAdminCustomers fromDashboard="dash" />
      <HelpAdminUsers fromDashboard="dash" />
      <HelpAdminFinance fromDashboard="dash" />
      <HelpAdminBalanceOverview fromDashboard="dash" />
      <HelpAdminTools fromDashboard="dash" />
      <HelpAdminResellers fromDashboard="dash" />
      <HelpAdminEmail2Sms fromDashboard="dash" />
      <HelpAdminMtApi fromDashboard="dash" />
      <HelpAdminCdrs fromDashboard="dash" />
      <HelpAdminInboundNumbers fromDashboard="dash" />
    </div>
  );
};

export default HelpAdministration;
