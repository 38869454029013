import React from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { appConfig } from '../../config';
import Grow from '@mui/material/Grow';
import { Alert, AlertTitle } from '@mui/material';
import Ubs from './index';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';

const Email2SMS = (customerUuid: $TSFixMe) => {

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUuid.customerUuid}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        setConfig(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // @ts-expect-error TODO: Type 'false' is not assignable to type 'undefined'
        setConfig({ active: false });
        setIsLoading(false);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [config, setConfig] = React.useState({ active: undefined });
  const [isLoading, setIsLoading] = React.useState(false); // eslint-disable-line

  const Info = () => {
    return (
      <Grow in={config.active !== undefined}>
        <Alert severity="info">
          <AlertTitle>
            <FormattedMessage id="email2sms.alert.title" />
          </AlertTitle>
          <FormattedMessage id="email2sms.alert.text" />
          <strong>
            <FormattedMessage id="email2sms.alert.admin" />
          </strong>
        </Alert>
      </Grow>
    );
  };

  return config.active === true ? <Ubs /> : <Info />;
};

export default connect(
  (store: AppState) => {
    return {
      customerUuid: store.login.userData.customerUuid || 0,
    };
  },
  {}
)(Email2SMS);
