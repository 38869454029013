import React from "react";
import { useState, useEffect } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ScrollMode } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface PDFViewerProps {
  file: File | Blob | string | null | $TSFixMe;
  className?: string;
  scrollMode?: ScrollMode;
  readonly zoom?: SpecialZoomLevel | number;
}

export const PDFViewer = ({ file, className, scrollMode, zoom }: PDFViewerProps) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file === null) {
      setFileUrl(null);
      return;
    }

    if (file instanceof Blob || file instanceof File) {
      const objectURL = URL.createObjectURL(file);
      setFileUrl(objectURL);

      return () => URL.revokeObjectURL(objectURL); // Clean up object URL
    } else if (typeof file === 'string') {
      setFileUrl(file);
    }
  }, [file]);

  return (
    <div className={className}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        {fileUrl && (
          <Viewer fileUrl={fileUrl}
            defaultScale={zoom}
            scrollMode={scrollMode}
          />
        )}
      </Worker>
    </div>
  );
};