import Validator from "validatorjs";
import { CountryFormValidator } from "./country.form";

const validate: CountryFormValidator = (values, props) => {
  const rules = {
    country: 'required',
    override: 'required|mobileNumber_or_alphaNum',
  };

  Validator.register(
    'mobileNumber_or_alphaNum',
    (value: $TSFixMe) => {
      if (value.startsWith('+') || value.match(/^\d+$/)) {
        //check for mobileNumber
        return /^[+]{1}[0-9]{8,31}$/.test(value);
      } else {
        // check for alphaNumeric
        return /^([\p{L}0-9]{1,11})$/u.test(value);
      }
    },
    props.intl.formatMessage({
      id: 'form.validation.override.format',
    })
  );

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
  });

  validation.passes();
  return validation.errors.all();
};

export default validate;
