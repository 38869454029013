import { AnyAction } from 'redux';
import { MtApiIpWhitelist } from '../../types/mtApiIpWhitelist';

interface MtApiWhitelistState {
  ipWhitelist: MtApiIpWhitelist[];
  selectedIpWhitelistEntry: Partial<MtApiIpWhitelist>;
  ipWhitelistLoading: boolean;
}

const initialState: MtApiWhitelistState = {
  ipWhitelist: [],
  selectedIpWhitelistEntry: {},
  ipWhitelistLoading: false,
};

export default function ipWhitelistReducer(state: MtApiWhitelistState = initialState, action: AnyAction): MtApiWhitelistState {
  switch (action.type) {
    case 'SET_IP_WHITELIST_LOADING': {
      return {
        ...state,
        ipWhitelistLoading: action.payload,
      };
    }
    case 'SET_IP_WHITELIST_ENTRIES': {
      return {
        ...state,
        ipWhitelist: action.payload,
      };
    }
    case 'ADD_IP_WHITELIST_ENTRY': {
      return {
        ...state,
        ipWhitelist: [...state.ipWhitelist, action.payload],
      };
    }
    case 'REMOVE_IP_WHITELIST_ENTRY': {
      return {
        ...state,
        ipWhitelist: state.ipWhitelist.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_IP_WHITELIST_ENTRY': {
      const itemToReplace = {
        ip: action.payload.ip,
        endIp: action.payload.endIp,
      };

      return {
        ...state,
        ipWhitelist: [
          // @ts-expect-error itemToReplace does not contain all MtApiIpWhitelist properties - to verify and fix!
          ...state.ipWhitelist.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all MtApiIpWhitelist properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_IP_WHITELIST_ENTRY_FOR_FORM': {
      const entry = state.ipWhitelist.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedIpWhitelistEntry: entry ? entry[0] : {},
      };
    }
    default:
      return {
        ...state,
      };
  }
}
