import { AnyAction } from 'redux';
import { getLogo, setLogo } from './app';
import { fetchLogoByResellerId } from '../components/common/utils';
import { fetchAppearance, fetchAppearanceByResellerId } from '../theme/appearance.service';
import { AppThunkAction } from './index';
import { Appearance } from '../types/appearance';

const setAppearanceAction = (appearance: Appearance): AnyAction => ({
  type: 'SET_APPEARANCE',
  payload: appearance,
});

const setAppearancePreviewModeAction = (appearance: Appearance): AnyAction => ({
  type: 'SET_APPEARANCE_PREVIEW_MODE',
  payload: appearance,
});

export function getAppearance(): AppThunkAction {
  return (dispatch, getState) => {
    const { userData } = getState().login;
    const appearanceLink = userData._links && userData._links.appearance;

    if (appearanceLink) {
      fetchAppearance(appearanceLink.href)
        .then(appearance => {
          dispatch(setAppearanceAction(appearance));
        });
    }
  };
}

export function enterPreviewMode(resellerId: number): AppThunkAction {
  return (dispatch) => {
    Promise.all([
      fetchLogoByResellerId(resellerId),
      fetchAppearanceByResellerId(resellerId)
    ]).then(([logo, appearance]) => {
        dispatch(setLogo(logo));
        dispatch(setAppearancePreviewModeAction(appearance));
      });
  };
}

export function leavePreviewMode(): AppThunkAction {
  return (dispatch) => {
    dispatch(getLogo());
    dispatch(getAppearance());
  };
}
