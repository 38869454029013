import backendChatWsConnection from '../websocket/backendChatWsConnection';
import smsChatConfig from './smsChatConfig';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatListener, ChatListenerCallbacks } from '../interface';
import { ChatWsMessageType, ChatWsWrapper } from '../websocket/chatWsWrapper';
import { ChatMessagePayloadType, ChatMessageStatus } from '../../types/chatMessage';
import dateTimeService from '../../service/dateTimeService';

type SmsWsIncomingMessage = ChatWsWrapper<{
  readonly sender: string;
  readonly receiver: string;
  readonly msgId: string;
  readonly message: string;
  readonly status: string;
  readonly receivedDate: string;
}>;

type SmsWsOutgoingMessage = ChatWsWrapper<{
  readonly from: string;
  readonly to: string;
  readonly msgId: string;
  readonly message: string;
}>;

type SmsWsEvent = ChatWsWrapper<{
  readonly msgId: string;
  readonly status: string;
  readonly timestamp: number;
}>;

export default class SmsChatListener implements ChatListener {
  private registeredConnectionListener: string | undefined;
  private registeredMessageListeners: string[] = [];

  register(callbacks: ChatListenerCallbacks): void {
    this.registeredConnectionListener = backendChatWsConnection.addConnectionListener({
      onOpen: () => callbacks.onConnect?.(SupportedChatChannel.SMS),
      onClose: () => callbacks.onDisconnect?.(SupportedChatChannel.SMS),
    });

    this.registeredMessageListeners = [
      backendChatWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.EVENT,
        onWsMessage: wsMessage => this.onSmsEvent(wsMessage, callbacks),
      }),
      backendChatWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.INCOMING_MESSAGE,
        onWsMessage: wsMessage => this.onSmsIncomingMessage(wsMessage, callbacks),
      }),
      backendChatWsConnection.addMessageListener({
        channel: SupportedChatChannel.SMS,
        msgType: ChatWsMessageType.OUTGOING_MESSAGE,
        onWsMessage: wsMessage => this.onSmsOutgoingMessage(wsMessage, callbacks),
      }),
    ];
  }

  unregister(): void {
    this.registeredMessageListeners.forEach(listenerId => {
      backendChatWsConnection.removeMessageListener(listenerId);
    });

    if (this.registeredConnectionListener) {
      backendChatWsConnection.removeConnectionListener(this.registeredConnectionListener);
    }
  }

  private onSmsEvent(wsMessage: SmsWsEvent, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onEvent?.({
      channel: SupportedChatChannel.SMS,
      msgId: payload.msgId,
      status: payload.status,
      timestamp: payload.timestamp,
    });
  }

  private onSmsIncomingMessage(wsMessage: SmsWsIncomingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onIncomingMessage?.({
      channel: SupportedChatChannel.SMS,
      senderId: payload.receiver,
      clientId: payload.sender,
      msgId: payload.msgId,
      message: payload.message,
      date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
      outgoing: false,
      status: ChatMessageStatus.UNREAD,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      statusCode: 200,
      error: null,
    });
  }

  private onSmsOutgoingMessage(wsMessage: SmsWsOutgoingMessage, callbacks: ChatListenerCallbacks): void {
    const { payload } = wsMessage;

    callbacks.onOutgoingMessage?.({
      channel: SupportedChatChannel.SMS,
      senderId: payload.from,
      clientId: payload.to,
      msgId: payload.msgId,
      message: payload.message,
      date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
      outgoing: true,
      status: ChatMessageStatus.QUEUED,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      statusCode: 200,
      error: null,
    });
  }
}
