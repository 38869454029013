import smsChatChannel from './sms';
import whatsappChatChannel from './whatsapp';
import viberChatChannel from './viber';
import { ChatChannel } from './interface';
import { SupportedChatChannel } from './supportedChatChannel';

type ChatChannelMap = {
  [key in SupportedChatChannel]: ChatChannel;
};

class ChatChannelProvider {
  private readonly registeredChatChannels: ChatChannelMap = {
    [SupportedChatChannel.SMS]: smsChatChannel,
    [SupportedChatChannel.WHATSAPP]: whatsappChatChannel,
    [SupportedChatChannel.VIBER]: viberChatChannel,
  };

  get(channel: SupportedChatChannel): ChatChannel {
    return this.registeredChatChannels[channel];
  }

  all(): ChatChannel[] {
    return Object.values(this.registeredChatChannels);
  }
}

export default new ChatChannelProvider();
