import { AnyAction } from 'redux';
import { Customer } from '../types/customer';
import { ViberAccount } from '../types/viber/viberAccount';
import { GetViberPricing, ViberCountry } from '../types/viber/viberPricing';

export const tabs = { config: 0, pricing: 1, templates: 2 };

interface ViberState {
  tab: number;
  tabOpen: boolean;
  customers: Customer[];
  configs: ViberAccount[];
  pricings: GetViberPricing[];
  selectedConfig: Partial<ViberAccount>;
  selectedViberPricing: Partial<GetViberPricing>;
  configsLoading: boolean;
  selectedCustomerUuid: string;
  copyViberUrlsDialogOpen: boolean;
  viberId: string;
  pricingsLoading: boolean;
  viberMonthlyFee: { fee: number };
  countries: ViberCountry[];
  selectedViberConfig: { value: string, label: string };
}

const initialState: ViberState = {
  tab: tabs.config,
  tabOpen: false,
  customers: [],
  configs: [],
  pricings: [],
  selectedConfig: {},
  selectedViberPricing: {},
  configsLoading: false,
  selectedCustomerUuid: '',
  copyViberUrlsDialogOpen: false,
  viberId: '',
  pricingsLoading: false,
  viberMonthlyFee: { fee: 0 },
  countries: [],
  selectedViberConfig: { value: '', label: '' },
};

export default function reducer(state: ViberState = initialState, action: AnyAction): ViberState {
  switch (action.type) {
    case 'SET_VIBER_ADMINISTRATION_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'SET_VIBER_ADMINISTRATION_TAB_OPEN': {
      return {
        ...state,
        tabOpen: action.tabOpen,
      };
    }
    case 'SET_VIBER_ADMINISTRATION_CUSTOMERS': {
      return {
        ...state,
        customers: action.customers,
      };
    }
    case 'SET_VIBER_CONFIGS': {
      return {
        ...state,
        configs: action.configs,
      };
    }
    case 'SET_VIBER_ADMIN_PRICINGS': {
      return {
        ...state,
        pricings: action.pricings,
      };
    }
    case 'SET_VIBER_SELECTED_CONFIG': {
      return {
        ...state,
        selectedConfig: action.config,
      };
    }
    case 'SET_VIBER_CONFIGS_LOADING': {
      return {
        ...state,
        configsLoading: action.payload,
      };
    }
    case 'UPDATE_VIBER_CONFIG': {
      const itemToReplace = {
        id: action.payload.id,
        name: action.payload.name,
        appId: action.payload.appId,
        createdAt: action.payload.createdAt,
        serviceId: action.payload.serviceId,
        numberFilteringMode: action.payload.numberFilteringMode,
        disabled: action.payload.disabled,
        tenantId: action.payload.tenantId,
        updatedAt: action.payload.updatedAt,
        // categories: action.payload.categories,
        // ageRestriction: action.payload.ageRestriction,
        // description: action.payload.description,
        // website: action.payload.website,
        // addresses: action.payload.addresses,
        // mobileNumbers: action.payload.mobileNumbers,
      };
      return {
        ...state,
        configs: [
          ...state.configs.filter((item) => item.id !== action.payload.id),
          itemToReplace,
        ],
      };
    }
    case 'SET_SELECTED_CUSTOMER_UUID': {
      return {
        ...state,
        selectedCustomerUuid: action.payload,
      };
    }
    case 'SET_COPY_VIBER_URLS_DIALOG_OPEN': {
      return {
        ...state,
        copyViberUrlsDialogOpen: action.payload,
      };
    }
    case 'SET_VIBER_ID': {
      return {
        ...state,
        viberId: action.payload,
      };
    }
    case 'SET_VIBER_MONTHLY_FEE': {
      return {
        ...state,
        viberMonthlyFee: action.payload,
      };
    }
    case 'SET_VIBER_COUNTRIES': {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case 'SET_SELECTED_VIBER_PRICING': {
      return {
        ...state,
        selectedViberPricing: action.payload,
      };
    }
    case 'UPDATE_VIBER_PRICING': {
      const itemToReplace = {
        id: action.payload.id,
        tenantId: action.payload.tenantId,
        countryCode: action.payload.countryCode,
        countryName: action.payload.countryName,
        promotionalPrice: action.payload.promotionalPrice,
        transactionalPrice: action.payload.transactionalPrice,
      };
      return {
        ...state,
        pricings: [
          ...state.pricings.filter((item) => item.id !== action.payload.id),
          itemToReplace,
        ],
      };
    }
    case 'SET_SELECTED_VIBER_CONFIG': {
      return {
        ...state,
        selectedViberConfig: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}

