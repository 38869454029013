import React from 'react';
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AppBarForm from '../../../common/AppBarForm';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PerformanceChart from './performanceChart';
import { FormattedMessage } from 'react-intl';
import { subMonths, subQuarters, subWeeks, subYears, addMonths, addQuarters, addWeeks, addYears } from 'date-fns';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import subDays from 'date-fns/subDays';
import { Divider, Theme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigatePrevIcon from '@mui/icons-material/NavigateBefore';
import IconButton from '@mui/material/IconButton';
import isEqual from 'date-fns/isEqual';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 20,
    margin: theme.spacing(4),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: '48px',
    color: '#000000de',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#d7d7d7',
    },
  },
  buttons: {
    paddingBottom: '10px',
    paddingTop: '10px',
    backgroundColor: '#F5F5F5',
    width: '100%',
  },
}));

type Props = {
  readonly loading?: boolean;
  readonly toggleDrawer: (open: boolean) => void;
};

const Performance = (props: Props) => {
  const { loading, toggleDrawer } = props;

  const classes = useStyles();

  const [period, setPeriod] = React.useState('DAY');
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(startOfDay(new Date()));
  const [updateDate, setUpdateDate] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(endOfDay(new Date()));
  const [checked, setChecked] = React.useState(false);
  const [refreshInterval, setRefreshInterval] = React.useState(1000 * 60 * 5);

  const handleIntervalChange = React.useCallback((event) => {
    setRefreshInterval(event.target.value);
  }, []);

  const toggleChecked = React.useCallback(() => {
    setChecked((prev) => !prev);
  }, []);
  const handleDateFromChange = (value: $TSFixMe) => {
    if (!isNaN(Date.parse(value.toString()))) setDateFrom(value);
  };
  const handleDateToChange = (value: $TSFixMe) => {
    if (!isNaN(Date.parse(value.toString()))) setDateTo(value);
  };

  const disableNext = () => {
    switch (period) {
      case 'DAY':
        setNextDisabled(isEqual(startOfDay(dateFrom), startOfDay(new Date())));
        break;
      case 'WEEK':
        setNextDisabled(isEqual(startOfWeek(dateFrom), startOfWeek(new Date())));
        break;
      case 'MONTH':
        setNextDisabled(isEqual(startOfMonth(dateFrom), startOfMonth(new Date())));
        break;
      case 'QUARTER':
        setNextDisabled(isEqual(startOfQuarter(dateFrom), startOfQuarter(new Date())));
        break;
      case 'YEAR':
        setNextDisabled(isEqual(startOfYear(dateFrom), startOfYear(new Date())));
        break;
      default:
    }
  };

  const updateDates = () => {
    const date = new Date();
    switch (period) {
      case 'DAY':
        setDateFrom(startOfDay(date));
        setDateTo(endOfDay(date));
        break;
      case 'WEEK':
        setDateFrom(startOfWeek(date, { weekStartsOn: 1 }));
        setDateTo(endOfWeek(date));
        break;
      case 'MONTH':
        setDateFrom(startOfMonth(date));
        setDateTo(endOfMonth(date));
        break;
      case 'QUARTER':
        setDateFrom(startOfQuarter(date));
        setDateTo(endOfQuarter(date));
        break;
      case 'YEAR':
        setDateFrom(startOfYear(date));
        setDateTo(endOfYear(date));
        break;
      default:
    }
  };

  const handlePeriodType = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === null || newValue === period) return;
    setPeriod(newValue);
  };

  React.useEffect(() => {
    updateDates(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  React.useEffect(() => {
    disableNext(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  React.useEffect(() => {
    let interval: $TSFixMe;
    if (checked) {
      interval = setInterval(() => setUpdateDate(new Date()), refreshInterval);
      return () => clearInterval(interval);
    } else {
      clearInterval(interval);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, refreshInterval]);

  const handlePrevNext = (add: boolean) => {
    switch (period) {
      case 'DAY':
        setDateFrom(add ? addDays(dateFrom, 1) : subDays(dateFrom, 1));
        setDateTo(add ? addDays(dateTo, 1) : subDays(dateTo, 1));
        break;
      case 'WEEK':
        setDateFrom(add ? addWeeks(dateFrom, 1) : subWeeks(dateFrom, 1));
        setDateTo(add ? addWeeks(dateTo, 1) : subWeeks(dateTo, 1));
        break;
      case 'MONTH':
        setDateFrom(add ? addMonths(dateFrom, 1) : subMonths(dateFrom, 1));
        setDateTo(add ? addMonths(dateTo, 1) : subMonths(dateTo, 1));
        break;
      case 'QUARTER':
        setDateFrom(add ? addQuarters(dateFrom, 1) : subQuarters(dateFrom, 1));
        setDateTo(add ? addQuarters(dateTo, 1) : subQuarters(dateTo, 1));
        break;
      case 'YEAR':
        setDateFrom(add ? addYears(dateFrom, 1) : subYears(dateFrom, 1));
        setDateTo(add ? addYears(dateTo, 1) : subYears(dateTo, 1));
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <AppBarForm
            label={<FormattedMessage id="common.label.performance" />}
            toggleDrawer={() => toggleDrawer(false)}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-start" style={{ overflowY: 'auto', flex: 1 }}>
        <Grid item>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <ToggleButtonGroup size="small" value={period} exclusive onChange={handlePeriodType}>
                  <ToggleButton value="DAY">
                    <FormattedMessage id="email2sms.ubs.performance.day" />
                  </ToggleButton>
                  <ToggleButton value="WEEK">
                    <FormattedMessage id="email2sms.ubs.performance.week" />
                  </ToggleButton>
                  <ToggleButton value="MONTH">
                    <FormattedMessage id="email2sms.ubs.performance.month" />
                  </ToggleButton>
                  <ToggleButton value="QUARTER">
                    <FormattedMessage id="email2sms.ubs.performance.quarter" />
                  </ToggleButton>
                  <ToggleButton value="YEAR">
                    <FormattedMessage id="email2sms.ubs.performance.year" />
                  </ToggleButton>
                  <ToggleButton value="PERIOD">
                    <FormattedMessage id="email2sms.ubs.performance.period" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={3}>
                <DateTimePicker
                  // @ts-expect-error TODO: no property variant
                  variant="inline"
                  value={dateFrom}
                  disabled={period !== 'PERIOD'}
                  disableFuture
                  inputFormat="yyyy-MM-dd HH:mm"
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                  fullWidth
                  label={<FormattedMessage id="common.label.date.from" />}
                  name="dateFrom"
                  onChange={handleDateFromChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={3}>
                <DateTimePicker
                  disabled={period !== 'PERIOD'}
                  value={dateTo}
                  inputFormat="yyyy-MM-dd HH:mm"
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                  // @ts-expect-error TODO: no property fullWidth
                  fullWidth
                  label={<FormattedMessage id="common.label.date.to" />}
                  name="dateTo"
                  variant="inline"
                  onChange={handleDateToChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={1} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <IconButton
                    style={{ width: '60px', height: '60px' }}
                    color="secondary"
                    onClick={() => handlePrevNext(false)}
                    disabled={period === 'PERIOD'}
                    size="large"
                  >
                    <NavigatePrevIcon />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={10}>
                <PerformanceChart
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  updateDate={updateDate}
                  checked={checked}
                  toggleChecked={toggleChecked}
                  handleIntervalChange={handleIntervalChange}
                  refreshInterval={refreshInterval}
                  setDateTo={setDateTo}
                  setDateFrom={setDateFrom}
                />
              </Grid>
              <Grid item xs={1} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <IconButton
                    color="secondary"
                    style={{ width: '60px', height: '60px' }}
                    onClick={() => handlePrevNext(true)}
                    disabled={nextDisabled || period === 'PERIOD'}
                    size="large"
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => toggleDrawer(false)}
            >
              <FormattedMessage id="common.button.close" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Performance;
