import { AnyAction } from 'redux';

interface TestAccountState {
  showTestAccountDrawer: boolean;
  accountInfo: {
    smsLimit: number;
    validTo?: $TSFixMe;
    customerType: string;
    whitelistedNumbers: $TSFixMe[];
    maxWhitelistsNumber: number;
    smsCodeValidForMinutes?: $TSFixMe;
  };
  showTestAccountWelcomeScreen: boolean;
  hideTestAccountWelcomeScreenNextTime: boolean;
  hideTestAccountWelcomeScreenNextTimeLoading: boolean;
  showTestAccountExpiredScreen: boolean;
  showWhitelistedNumbersDialog: boolean;
  numbers: $TSFixMe[];
}

const initialState = {
  showTestAccountDrawer: false,
  accountInfo: {
    smsLimit: 0,
    validTo: undefined,
    customerType: 'BUSSINESS',
    whitelistedNumbers: [],
    maxWhitelistsNumber: 0,
    smsCodeValidForMinutes: undefined,
  },
  showTestAccountWelcomeScreen: false,
  hideTestAccountWelcomeScreenNextTime: false,
  hideTestAccountWelcomeScreenNextTimeLoading: false,
  showTestAccountExpiredScreen: false,
  showWhitelistedNumbersDialog: false,
  numbers: [],
};

export default function reducer(state: TestAccountState = initialState, action: AnyAction): TestAccountState {
  switch (action.type) {
    case 'SET_SHOW_TEST_ACCOUNT_DRAWER': {
      return {
        ...state,
        showTestAccountDrawer: action.payload,
      };
    }

    case 'SET_ACCOUNT_INFO': {
      return {
        ...state,
        accountInfo: action.payload,
      };
    }

    case 'SET_SHOW_TEST_ACCOUNT_WELCOME_SCREEN': {
      return {
        ...state,
        showTestAccountWelcomeScreen: action.payload,
      };
    }

    case 'SET_HIDE_SHOW_TEST_ACCOUNT_WELCOME_SCREEN_NEXT_TIME': {
      return {
        ...state,
        hideTestAccountWelcomeScreenNextTime: action.payload,
      };
    }

    case 'SET_HIDE_SHOW_TEST_ACCOUNT_WELCOME_SCREEN_NEXT_TIME_LOADING': {
      return {
        ...state,
        hideTestAccountWelcomeScreenNextTimeLoading: action.payload,
      };
    }

    case 'SET_SHOW_TEST_ACCOUNT_EXPIRED_SCREEN': {
      return {
        ...state,
        showTestAccountExpiredScreen: action.payload,
      };
    }

    case 'SET_SHOW_WHITELISTED_NUMBERS_DIALOG': {
      return {
        ...state,
        showWhitelistedNumbersDialog: action.payload,
      };
    }

    case 'SET_WHITELISTED_NUMBERS': {
      return {
        ...state,
        numbers: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
