/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpCampaign = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography
        variant="subtitle2"
        style={{ marginTop: 15, marginBottom: 10 }}
      >
        <FormattedMessage id="help.campaign.1" />
      </Typography>
      <Typography
        style={{ marginTop: 15, marginBottom: 15 }}
        variant="body1"
        component="div"
      >
        {props.fromDashboard ? (
          <Button
            component={Link}
            to="/smsCampaign/new"
            size="small"
            color="secondary"
          >
            <FormattedMessage id="help.campaign.2" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'}>
            <FormattedMessage id="help.campaign.2" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.campaign.3" />
        </Typography>
      </div>
      <Typography
        style={{ marginTop: 15, marginBottom: 15 }}
        variant="body1"
        component="div"
      >
        {props.fromDashboard ? (
          <Button
            component={Link}
            to="/smsCampaign/history"
            size="small"
            color="secondary"
          >
            <FormattedMessage id="help.campaign.4" />
          </Button>
        ) : (
          <Typography
            variant={'subtitle2'}
            style={{ marginBottom: 10 }}
            align="justify"
          >
            <FormattedMessage id="help.campaign.4" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Typography variant="body2" component="div" align="justify">
          <p>
            <FormattedMessage id="help.campaign.5" />
          </p>
        </Typography>
        {!props.fromDashboard && (
          <>
            <Typography variant={'subtitle2'} component="div" align="justify">
              <p style={{ marginBottom: 0 }}>
                <FormattedMessage id="help.campaign.6" />
              </p>
            </Typography>
            <Typography variant="body2" component="div" align="justify">
              <p style={{ margin: 0 }}>
                <FormattedMessage id="help.campaign.7" />
              </p>
            </Typography>
            <Typography variant={'subtitle2'} component="div" align="justify">
              <p style={{ marginBottom: 0 }}>
                <FormattedMessage id="help.campaign.8" />
              </p>
            </Typography>
            <Typography variant="body2" component="div" align="justify">
              <p style={{ margin: 0 }}>
                <FormattedMessage id="help.campaign.9" />
              </p>
            </Typography>
            <Typography variant={'subtitle2'} component="div" align="justify">
              <p style={{ marginBottom: 0 }}>
                <FormattedMessage id="help.campaign.10" />
              </p>
            </Typography>
            <Typography variant="body2" component="div" align="justify">
              <p style={{ margin: 0 }}>
                <FormattedMessage id="help.campaign.11" />
              </p>
            </Typography>
            <Typography variant={'subtitle2'} component="div" align="justify">
              <p style={{ marginBottom: 0 }}>
                <FormattedMessage id="help.campaign.12" />
              </p>
            </Typography>
            <Typography variant="body2" component="div" align="justify">
              <p style={{ margin: 0 }}>
                <FormattedMessage id="help.campaign.13" />
              </p>
            </Typography>
            <Typography variant={'subtitle2'} component="div" align="justify">
              <p style={{ marginBottom: 0 }}>
                <FormattedMessage id="help.campaign.14" />
              </p>
            </Typography>
            <Typography variant="body2" component="div" align="justify">
              <p style={{ margin: 0 }}>
                <FormattedMessage id="help.campaign.15" />
              </p>
            </Typography>
            <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <Typography variant={'subtitle2'} component="div" align="justify">
                <p style={{ marginBottom: 0 }}>
                  <FormattedMessage id="help.campaign.16" />
                </p>
              </Typography>
              <Typography variant="body2" component="div" align="justify">
                <p style={{ margin: 0 }}>
                  <FormattedMessage id="help.campaign.17" />
                </p>
              </Typography>
              <ul>
                <Typography variant="body2" component="li" align="justify">
                  <p>
                    <FormattedMessage id="help.campaign.18" />
                  </p>
                </Typography>
                <Typography variant="body2" component="li" align="justify">
                  <FormattedMessage id="help.campaign.19" />
                </Typography>
                <Typography variant="body2" component="li" align="justify">
                  <p>
                    <FormattedMessage id="help.campaign.19.1" />
                  </p>
                </Typography>
              </ul>
              <Typography variant="body2" component="p" align="justify">
                <p>
                  <FormattedMessage id="help.campaign.19.2" />
                </p>
              </Typography>
              <Typography variant={'subtitle2'} component="div" align="justify">
                <p style={{ marginBottom: 0 }}>
                  <FormattedMessage id="help.campaign.19.3" />
                </p>
              </Typography>
              <Typography variant="body2" component="div" align="justify">
                <p style={{ margin: 0 }}>
                  <FormattedMessage id="help.campaign.19.4" />
                </p>
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HelpCampaign;
