import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Fade from '@mui/material/Fade';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  doLogin,
  openPage,
  setSmsVerificationDialogShow,
} from '../../actions/login';
import BaseLoginView from '../common/logins/BaseLoginView';
import { goToResetPassword } from '../../actions/changePassword';
import SmsVerificationDialog from './smsVerification/SmsVerificationDialog';
import { green } from '@mui/material/colors';
import { useWithWidthUp } from '../common/useWithWidth';
import { AppState } from '../../reducers';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  messageError: {
    color: 'red',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}));

type Props = {
  readonly isLoading: boolean;
  readonly smsVerificationDialogShow: boolean;
  readonly loginData: $TSFixMe;
  readonly openPage: () => void;
  readonly goToResetPassword: () => void ;
  readonly doLogin: (login: string, password: string, code?: string, redirectUrl?: string) => void;
};

const Login = (props: Props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(undefined);

  const history: $TSFixMe = useHistory();
  const initUrl = history.location.state ? history.location.state.from : undefined;

  useEffect(() => {
    props.openPage();
    initUrl && setRedirectUrl(initUrl.pathname + initUrl.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pressEnter = (event: $TSFixMe) => {
    if (event.charCode === 13) handleSubmit();
  };

  const handleSubmit = () => {
    props.doLogin(login, password, undefined, redirectUrl);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const goToResetPage = () => {
    props.goToResetPassword();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { isLoading, loginData, smsVerificationDialogShow } = props;
  const classes = useStyles();
  const isWidthUpMd = useWithWidthUp('md');
  const spacing = isWidthUpMd ? 3 : 1;

  const content = (
    <form>
      <Grid container spacing={spacing}>
        <Fade in={loginData.failed}>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            {(loginData.userData.message === 'loginFailed' && (
              <Typography variant="caption" className={classes.messageError}>
                <FormattedMessage id="data.fetching.bad.request" />
              </Typography>
            )) ||
              // (loginData.userData.message === 'Authentication failed' &&
              (loginData.userData.message === 'authenticationFailed' && (
                <Typography
                  variant="caption"
                  className={classes.messageError}
                >
                  <FormattedMessage id="login.authFailed" />
                </Typography>
              )) ||
              (loginData.userData.message === 'EmptyPWD' && (
                <Typography
                  variant="caption"
                  className={classes.messageError}
                >
                  <FormattedMessage id="login.emptyPwLogin" />
                </Typography>
              )) || <Typography variant="caption">&nbsp;</Typography>}
          </Grid>
        </Fade>

        <Grid item xs={12}>
          <TextField
            id="login"
            label={<FormattedMessage id="login.login" />}
            fullWidth
            variant="standard"
            autoComplete="current-login"
            value={login}
            onChange={(event) => setLogin(event.target.value)}
            onKeyPress={pressEnter}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            id="password"
            label={<FormattedMessage id="login.password" />}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyPress={pressEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ alignItems: 'center', display: 'flex' }}>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              disabled={isLoading}
              color="secondary"
              onClick={handleSubmit}
            >
              <FormattedMessage id="login.login" />
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </Grid>
        <SmsVerificationDialog
          open={smsVerificationDialogShow}
          login={login}
          password={password}
          redirectUrl={redirectUrl}
        />
      </Grid>
      <div style={{ marginTop: 15 }}>
        <Button color="primary" size={'small'} onClick={goToResetPage}>
          <FormattedMessage id="login.forgot.password" /> ?
        </Button>
      </div>
    </form>
  );
  return (
    <BaseLoginView
      title={<FormattedMessage id="login.dialog.title" />}
      content={content}
    />
  );
}

function mapStateToProps(store: AppState) {
  return {
    isLoading: store.login.isLoading,
    loginData: store.login,
    smsVerificationDialogShow: store.login.smsVerificationDialogShow,
  };
};

export default connect(mapStateToProps, {
  doLogin,
  openPage,
  goToResetPassword,
  setSmsVerificationDialogShow,
})(Login);
