import Validator from "validatorjs";
import isValidHostname from "is-valid-hostname";
import isIP from "is-ip";
import { WhitelistFormValidator } from "./whitelist.form";

Validator.register(
  "ip",
  (value: $TSFixMe, requirement, attribute) => {
    return isIP(value);
  },
  "The ip is in wrong format"
);

Validator.register(
  "host",
  (value: $TSFixMe, requirement, attribute) => {
    return isValidHostname(value);
  },
  "The gateway is in wrong format"
);

const validate: WhitelistFormValidator = (values, props) => {
  const rules = {
    host: "host",
    ip: "required|ip",
    endIp: "ip",
  };

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
    ip: props.intl.formatMessage({ id: 'email2sms.ubs.whitelist.ip.wrong.format.form.validation' }),
    host: props.intl.formatMessage({ id: 'email2sms.ubs.whitelist.gateway.wrong.format.form.validation' })
  });

  validation.passes();
  return validation.errors.all();
};

export default validate;
