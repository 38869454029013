import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Spinner from './spinner';
import { isAuthenticated } from '../../actions/login';

type Props = {
  readonly isAuth: () => void;
};

const LoadingPage = (props: Props) => {

  useEffect(() => {
    const { isAuth } = props;
    isAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  return <Spinner />;
}

export default connect(null, { isAuth: isAuthenticated })(LoadingPage);
