import axios from 'axios';
import { appConfig } from '../config';
import { AnyAction } from 'redux';
import { AppThunkAction } from './index';

export function setExportMessageLogFormOpen(flag: $TSFixMe): AnyAction {
  return { type: 'SET_EXPORT_MESSAGE_LOG_FORM_OPEN', payload: flag };
}

export function setExportFiltersForForm(filters: $TSFixMe): AnyAction {
  return { type: 'SET_EXPORT_FILTERS_FOR_FORM', payload: filters };
}

export function getExportMessageLogFiles(): AppThunkAction {
  return (dispatch, getState) => {
    const uuid = getState().email2smsConfig.customerUuid || getState().login.userData.customerUuid || 0;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const url = `${appConfig.URL_REST}email2sms/message_logs/${uuid}`;
    dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_LOADING', payload: true });
    axios
      .get(url, config)
      .then((res) => {
        dispatch({
          type: 'SET_EXPORT_MESSAGE_LOGS',
          payload: (
            res.data &&
            res.data._embedded &&
            res.data._embedded.e2SMessageLogsExportHistoryResources
          ) || [],
        });
        dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_LOADING', payload: false });
      })
      .catch((err) => {
        dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_ERROR', payload: err.message });
        dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_LOADING', payload: false });
      });
  }
}

export function deleteExportMessageLogFile(messageLogs: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_LOADING', payload: true });
    axios
      .delete(messageLogs._links.delete.href, config)
      .then((res) => {
        dispatch(getExportMessageLogFiles());
      })
      .catch((err) => {
        dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_ERROR', payload: err.message });
        dispatch({ type: 'SET_EXPORT_MESSAGE_LOGS_LOADING', payload: false });
      });
  };
}