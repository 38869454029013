import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PDFViewer } from './components/common/PDFViewer';
//@ts-ignore
import TermsConditionsEN from './documents/TermsConditions_EN.pdf';
//@ts-ignore
import DataProcessingEN from './documents/DataProcessing_EN.pdf';
import { SupportedLang } from './messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  actions: {
    padding: theme.spacing(1, 3, 3),
  },
  dialog: {
    height: '640px',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfViewer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

type DialogType = 'termsConditions' | 'dataProcessing';

type Props = {
  readonly open: boolean;
  readonly dialogType: DialogType | null;
  readonly language: SupportedLang;
  readonly setDialogOpen: (flag: boolean) => void;
};

const TermsConditionsDialog = ({
  open,
  dialogType,
  //language,
  setDialogOpen,
}: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title" maxWidth={'md'} fullWidth={true}>
      <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center' }}>
        <FormattedMessage id={
          dialogType === 'termsConditions'
            ? 'dashboard.terms.conditions'
            : 'dashboard.data.processing'
        } />
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <div className={classes.dialog}>
            <PDFViewer file={dialogType === 'termsConditions' ? TermsConditionsEN : DataProcessingEN} className={classes.pdfViewer} zoom={1.28} />
          </div>
        </DialogContent>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
          id={`btn-${dialogType}-dialog-close`}
        >
          <FormattedMessage id="common.button.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsConditionsDialog;
