import { AnyAction } from 'redux';
import { MtApiRouting } from '../../types/mtApiRouting';

interface MtApiRoutingState {
  routings: MtApiRouting[];
  selectedRoutingEntry: Partial<MtApiRouting>;
  mtApiRoutingLoading: boolean;
}

const initialState: MtApiRoutingState = {
  routings: [],
  selectedRoutingEntry: {},
  mtApiRoutingLoading: false,
};

export default function mtApiReducer(state: MtApiRoutingState = initialState, action: AnyAction): MtApiRoutingState {
  switch (action.type) {
    case 'SET_MT_API_ROUTING_LOADING': {
      return {
        ...state,
        mtApiRoutingLoading: action.payload,
      };
    }
    case 'ADD_MT_API_ROUTING_ENTRY': {
      return {
        ...state,
        routings: [...state.routings, { id: state.routings.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_MT_API_ROUTING_ENTRY': {
      return {
        ...state,
        routings: state.routings.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_MT_API_ROUTING_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        prefix: action.payload.prefix,
        smscId: action.payload.smscId,
      };
      return {
        ...state,
        routings: [...state.routings.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_MT_API_ROUTING_ENTRY_FOR_FORM': {
      const entry = state.routings.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedRoutingEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_MT_API_ROUTING_ENTRIES': {
      return {
        ...state,
        routings: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
