import { AnyAction } from 'redux';
import { Appearance, NO_CUSTOM_APPEARANCE } from '../types/appearance';

interface AppearanceState {
  custom: Appearance;
  previewMode: boolean;
}

const initialState: AppearanceState = {
  custom: NO_CUSTOM_APPEARANCE,
  previewMode: false,
};

export default function reducer(state: AppearanceState = initialState, action: AnyAction): AppearanceState {
  switch (action.type) {
    case 'SET_APPEARANCE': {
      return {
        ...state,
        custom: action.payload,
        previewMode: false,
      };
    }

    case 'SET_APPEARANCE_PREVIEW_MODE': {
      return {
        ...state,
        custom: action.payload,
        previewMode: true,
      }
    }

    default:
      return state;
  }
}
