/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpEmail2Sms = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/email2sms" size="small" color="secondary">
            <FormattedMessage id="nav.email2sms" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.email2sms.1" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.email2sms.2" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.email2sms.3" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.email2sms.4" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.email2sms.5" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.email2sms.6" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.email2sms.7" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.email2sms.8" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.email2sms.9" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpEmail2Sms;
