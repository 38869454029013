import { AnyAction } from 'redux';
import { Email2SmsWhitelist } from '../../types/email2SmsWhitelist';

interface Email2SmsWhitelistState {
  whitelist: Email2SmsWhitelist[];
  whitelistFormOpen: boolean;
  selectedEntry: Partial<Email2SmsWhitelist>;
  whitelistLoading: boolean;
}

const initialState: Email2SmsWhitelistState = {
  whitelist: [],
  whitelistFormOpen: false,
  selectedEntry: {},
  whitelistLoading: false,
};

export default function reducer(state: Email2SmsWhitelistState = initialState, action: AnyAction): Email2SmsWhitelistState {
  switch (action.type) {
    case 'SET_WHITELIST_LOADING': {
      return {
        ...state,
        whitelistLoading: action.payload,
      };
    }
    case 'SET_WHITELIST_ENTRIES': {
      return {
        ...state,
        whitelist: action.payload,
      };
    }
    case 'SET_FORM_OPEN': {
      return {
        ...state,
        whitelistFormOpen: action.payload,
      };
    }
    case 'ADD_WHITELIST_ENTRY': {
      return {
        ...state,
        whitelist: [...state.whitelist, action.payload],
      };
    }
    case 'REMOVE_WHITELIST_ENTRY': {
      return {
        ...state,
        whitelist: state.whitelist.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_WHITELIST_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        host: action.payload.host,
        ip: action.payload.ip,
        endIp: action.payload.endIp,
      };

      return {
        ...state,
        whitelist: [...state.whitelist.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_ENTRY_FOR_FORM': {
      const entry = state.whitelist.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedEntry: entry ? entry[0] : {},
      };
    }
    default:
      return {
        ...state,
      };
  }
}
