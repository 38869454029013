import { ChatConfig } from '../interface';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatMessagePayloadType } from '../../types/chatMessage';

const smsChatConfig: ChatConfig = {
  id: SupportedChatChannel.SMS,
  dateFormat: 'yyyy-MM-dd HH:mm:ss',
  enableEmojis: false,
  supportedMessageTypes: [
    ChatMessagePayloadType.TEXT,
  ],
};

export default smsChatConfig;
