import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography, Paper } from '@mui/material';
import LocalizedMaterialTable from '../../../common/LocalizedMaterialTable';
import { AppState } from '../../../../reducers';
import { Components } from '@material-table/core';

const components: Components = {
  Container: ({ children }) => {
    return <Paper elevation={0}>{children}</Paper>;
  },
};

type Props = {
  readonly tags: $TSFixMe[];
};

const Tags = ({ tags }: Props) => {
  return (
    <>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="email2sms.ubs.tags.table.title.predefined.tags" />
          </Typography>
        }
        columns={[
          {
            title: <FormattedMessage id="email2sms.ubs.tags.table.tag" />,
            field: 'tag',
          },
          {
            title: <FormattedMessage id="email2sms.ubs.tags.table.action" />,
            field: 'action',
          },
        ]}
        data={tags}
        components={components}
        options={{
          padding: 'dense',
          pageSize: 10,
          searchFieldStyle: { margin: '40px' },
          rowStyle: { fontSize: '0.8125rem', padding: '0px' },
          // @ts-expect-error TODO: no property cellStyle
          cellStyle: {
            padding: '2px 16px 1px 16px',
            height: '36px',
            minHeight: '36px',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 500,
        }}
      />
    </>
  );
};

export default connect((store: AppState) => {
  return {
    tags: store.ubsTags.tags,
  };
}, {})(Tags);
