import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { chatSendersReducer, ChatSendersState, DEFAULT_CHAT_SENDERS_STATE } from './senders';
import { chatClientsReducer, ChatClientsState, DEFAULT_CHAT_CLIENTS_STATE } from './clients';
import { chatMessagesReducer, ChatMessagesState, DEFAULT_CHAT_MESSAGES_STATE } from './messages';
import { AppState } from '../index';
import { ChatChannelsAction } from '../../actions/chats';

interface ChatStatePerChannel {
  senders: ChatSendersState,
  clients: ChatClientsState,
  messages: ChatMessagesState,
}

const defaultChatStatePerChannel: ChatStatePerChannel = {
  senders: DEFAULT_CHAT_SENDERS_STATE,
  clients: DEFAULT_CHAT_CLIENTS_STATE,
  messages: DEFAULT_CHAT_MESSAGES_STATE,
};

type ChatChannelsState = {
  [key in SupportedChatChannel]: ChatStatePerChannel;
};

const initialState: ChatChannelsState = {
  [SupportedChatChannel.SMS]: defaultChatStatePerChannel,
  [SupportedChatChannel.WHATSAPP]: defaultChatStatePerChannel,
  [SupportedChatChannel.VIBER]: defaultChatStatePerChannel,
}

const updateChatStatePerChannel = (
  state: ChatChannelsState,
  channel: SupportedChatChannel,
  updateFunc: (state: ChatStatePerChannel) => ChatStatePerChannel,
): ChatChannelsState => {
  return {
    ...state,
    [channel]: updateFunc(state[channel]),
  };
};

export default function reducer(state: ChatChannelsState = initialState, action: ChatChannelsAction): ChatChannelsState {
  const { type, channel } = action;

  if (type.startsWith('chatChannels/senders')) {
    return updateChatStatePerChannel(state, channel, currentState => ({
      ...currentState,
      senders: chatSendersReducer(currentState.senders, action),
    }));
  } else if (type.startsWith('chatChannels/clients')) {
    return updateChatStatePerChannel(state, channel, currentState => ({
      ...currentState,
      clients: chatClientsReducer(currentState.clients, action),
    }));
  } else if (type.startsWith('chatChannels/messages')) {
    return updateChatStatePerChannel(state, channel, currentState => ({
      ...currentState,
      messages: chatMessagesReducer(currentState.messages, action),
    }));
  } else {
    return {
      ...state,
    };
  }
}

export const selectActiveChatChannel = (state: AppState): SupportedChatChannel => state.chat.channel;
