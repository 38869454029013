import { AnyAction } from 'redux';
import { CustomerAdditionalFeeEntry } from '../../types/customerAdditionalFee';

interface AdditionalFeeState {
  additionalFees: CustomerAdditionalFeeEntry[];
  selectedAdditionalFee: Partial<CustomerAdditionalFeeEntry>;
  additionalFeesLoading: boolean;
}

const initialState: AdditionalFeeState = {
  additionalFees: [],
  selectedAdditionalFee: {},
  additionalFeesLoading: false,
};

export default function reducer(state: AdditionalFeeState = initialState, action: AnyAction): AdditionalFeeState {
  switch (action.type) {
    case 'SET_ADDITIONAL_FEES_LOADING': {
      return {
        ...state,
        additionalFeesLoading: action.payload,
      };
    }
    case 'ADD_ADDITIONAL_FEE': {
      return {
        ...state,
        additionalFees: [...state.additionalFees, { id: state.additionalFees.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_ADDITIONAL_FEE': {
      return {
        ...state,
        additionalFees: state.additionalFees.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_ADDITIONAL_FEE': {
      const itemToReplace = {
        item: action.payload.item,
        amount: action.payload.amount,
        oneoff: action.payload.oneoff,
      };
      return {
        ...state,
        additionalFees: [
          // @ts-expect-error itemToReplace does not contain all CustomerAdditionalFeeEntry properties - to verify and fix!
          ...state.additionalFees.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerAdditionalFeeEntry properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_ADDITIONAL_FEE_FOR_FORM': {
      return {
        ...state,
        selectedAdditionalFee: action.payload,
      };
    }
    case 'SET_ADDITIONAL_FEES': {
      return {
        ...state,
        additionalFees: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
