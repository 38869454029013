import { AnyAction } from 'redux';

interface UbsSpamState {
  spam: $TSFixMe[];
  spamFormOpen: boolean;
  selectedEntry: $TSFixMe;
  spamLoading: boolean;
}

const initialState: UbsSpamState = {
  spam: [],
  spamFormOpen: false,
  selectedEntry: {},
  spamLoading: false,
};

export default function reducer(state: UbsSpamState = initialState, action: AnyAction): UbsSpamState {
  switch (action.type) {
    case 'SET_SPAM_LOADING': {
      return {
        ...state,
        spamLoading: action.payload,
      };
    }
    case 'SET_FORM_OPEN': {
      return {
        ...state,
        spamFormOpen: action.payload,
      };
    }
    case 'ADD_SPAM_ENTRY': {
      return {
        ...state,
        spam: [...state.spam, action.payload],
      };
    }
    case 'REMOVE_SPAM_ENTRY': {
      return {
        ...state,
        spam: state.spam.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_SPAM_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        sender: action.payload.sender,
      };

      return {
        ...state,
        spam: [...state.spam.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_ENTRY_FOR_FORM': {
      const entry = state.spam.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_WHITELISTED_SENDERS': {
      return {
        ...state,
        spam: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
