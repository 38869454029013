import { Timestamp } from '../datetime';

export enum MetaChannel {
  WHATSAPP = 'WHATSAPP',
  MESSENGER = 'MESSENGER',
  INSTAGRAM = 'INSTAGRAM',
}

export interface MetaAccount {
  readonly channel: MetaChannel;
  readonly name: string;
  readonly tenantId: string;
  readonly businessAccountId: string;
  readonly isDisabled: boolean;
  readonly accessToken?: string;
  readonly webhookVerifyToken?: string;
  readonly createdAt: Timestamp;
  readonly updatedAt: Timestamp;
  readonly appId: string;
  readonly metaAppId: string;
}

export interface WhatsappAccountConfig {
  readonly featureFlags: {
    readonly ACTIVE: boolean;
    readonly CAMPAIGN: boolean;
    readonly ONE_TO_ONE: boolean;
  }
}