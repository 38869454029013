import { ChatClient } from '../../types/chatClient';
import { AppState } from '../index';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { ChatChannelsAction } from '../../actions/chats';
import { ChatConversationId } from '../../types/chatMessage';
import { selectSelectedChatSenderId } from './senders';
import { PhoneNumberWithPlusSign } from '../../types/phoneNumber';

export interface ChatClientsState {
  selectedClientId?: string;
  entities: ChatClient[];
  loading: boolean;
  errorMessage?: string;
}

export const DEFAULT_CHAT_CLIENTS_STATE: ChatClientsState = {
  loading: false,
  entities: [],
};

export function chatClientsReducer(state: ChatClientsState, action: ChatChannelsAction): ChatClientsState {
  switch (action.type) {
    case 'chatChannels/clients/fetchPending': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'chatChannels/clients/fetchSuccess': {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: payload,
      };
    }
    case 'chatChannels/clients/fetchFailure': {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        errorMessage: payload,
      };
    }

    case 'chatChannels/clients/addOne': {
      const { client } = action.payload;

      return {
        ...state,
        entities: [
          ...state.entities,
          client,
        ],
      };
    }
    case 'chatChannels/clients/upsertOne': {
      const { client } = action.payload;

      const updatedClients = [...state.entities];
      const existingIndex = updatedClients.findIndex(oldClient => oldClient.id === client.id);
      if (existingIndex !== -1) {
        updatedClients[existingIndex] = client;
      } else {
        updatedClients.push(client);
      }

      return {
        ...state,
        entities: updatedClients,
      };
    }
    case 'chatChannels/clients/replace': {
      const { clientIdToReplace, client } = action.payload;

      const updatedClients = [...state.entities]
        .filter(client => client.id !== clientIdToReplace);

      updatedClients.push(client);

      return {
        ...state,
        entities: updatedClients,
        ...(state.selectedClientId === clientIdToReplace && {
          selectedClientId: client.id,
        }),
      };
    }

    case 'chatChannels/clients/set': {
      const { payload } = action;

      return {
        ...state,
        selectedClientId: payload.clientId,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

const getChatClientState = (state: AppState, channel: SupportedChatChannel) => state.chatChannels[channel].clients;

const getIsLoadingClients = (state: ChatClientsState) => state.loading;
const getSelectedClientId = (state: ChatClientsState) => state.selectedClientId;
const getClientById = (state: ChatClientsState, clientId: string) => (
  state.entities.find(client => client.id === clientId)
);
const getClientBySenderIdAndPhoneNumber = (state: ChatClientsState, senderId: string, phoneNumber: PhoneNumberWithPlusSign) => (
  state.entities.find(client => client.senderId === senderId && client.number === phoneNumber)
);
const getClientByIdAndSenderId = (state: ChatClientsState, clientId: string, senderId: string) => (
  state.entities.find(client => client.id === clientId && client.senderId === senderId)
);
const getAllClientsBySenderId = (state: ChatClientsState, senderId: string) => (
  state.entities.filter(client => client.senderId === senderId)
);

export const selectIsLoadingChatClients = (state: AppState, channel: SupportedChatChannel) => {
  return getIsLoadingClients(getChatClientState(state, channel));
};
export const selectChatClientById = (state: AppState, channel: SupportedChatChannel, clientId: string) => {
  return getClientById(getChatClientState(state, channel), clientId);
};
export const selectChatClientBySenderIdAndPhoneNumber = (state: AppState, params: {
  readonly channel: SupportedChatChannel;
  readonly senderId: string;
  readonly phoneNumber: PhoneNumberWithPlusSign;
}) => {
  const { channel, senderId, phoneNumber } = params;
  return getClientBySenderIdAndPhoneNumber(getChatClientState(state, channel), senderId, phoneNumber);
};
export const selectChatClientsForSelectedSenderId = (state: AppState, channel: SupportedChatChannel) => {
  const selectedSenderId = selectSelectedChatSenderId(state, channel);
  return selectedSenderId ? getAllClientsBySenderId(getChatClientState(state, channel), selectedSenderId) : [];
};
export const selectChatClientByConversationId = (state: AppState, conversationId: ChatConversationId) => {
  const { channel, clientId, senderId } = conversationId;
  return getClientByIdAndSenderId(getChatClientState(state, channel), clientId, senderId);
};
export const selectSelectedChatClientId = (state: AppState, channel: SupportedChatChannel) => {
  return getSelectedClientId(getChatClientState(state, channel));
};
export const selectSelectedChatClient = (state: AppState, channel: SupportedChatChannel) => {
  const chatClientState = getChatClientState(state, channel);
  const selectedClientId = getSelectedClientId(chatClientState);
  return selectedClientId ? getClientById(chatClientState, selectedClientId) : undefined;
};
