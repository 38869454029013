import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { setTestAccountWelcomeScreenShowFlag } from '../../actions/testAccount';

import TestAccountStep1 from './TestAccountDialogStep1';
import TestAccountStep2 from './TestAccountDialogStep2';
import TestAccountStep3 from './TestAccountDialogStep3';
import TestAccountStep4 from './TestAccountDialogStep4';
import { AppState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type DialogProps = {
  readonly children: React.ReactNode;
  readonly onClose: () => void;
};

const DialogTitle = (props: DialogProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    // @ts-expect-error TODO: no property disableTypography
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" color="primary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

type Props = {
  readonly showTestAccountWelcomeScreen: boolean;
  readonly setTestAccountWelcomeScreenShowFlag: (flag: boolean) => void;
};

const TestAccountWelcomeDialog = ({
  showTestAccountWelcomeScreen,
  setTestAccountWelcomeScreenShowFlag,
}: Props) => {
  const tutorialSteps = [
    {
      label: <FormattedMessage id="testAccount.welcome.dialog.label.thank.you" />,
      component: <TestAccountStep1 />,
    },
    {
      label: <FormattedMessage id="testAccount.welcome.dialog.label.sms.campaigns" />,
      component: <TestAccountStep2 />,
    },
    {
      label: <FormattedMessage id="testAccount.welcome.dialog.label.sms.chat" />,
      component: <TestAccountStep3 />,
    },
    {
      label: <FormattedMessage id="testAccount.welcome.dialog.label.whatsapp" />,
      component: <TestAccountStep4 />,
    },
  ];

  const handleClose = () => {
    setTestAccountWelcomeScreenShowFlag(false);
  };
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
    dialogContent: {
      padding: theme.spacing(2),
      minHeight: '550px',
    },
    dialogActions: {
      margin: 0,
      padding: theme.spacing(1),
    }
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    activeStep === maxSteps - 1
      ? handleClose()
      : setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={showTestAccountWelcomeScreen}>
        <DialogTitle onClose={handleClose}>
          <FormattedMessage id="testAccount.welcome.dialog.label.welcome" />
        </DialogTitle>
        
        <MuiDialogContent dividers className={classes.dialogContent}>
          <div className={classes.root}>
            <Typography variant={'h6'} style={{ marginBottom: 25 }}>
              {tutorialSteps[activeStep].label}
            </Typography>

            <SwipeableViews
              axis={'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {tutorialSteps.map((step: $TSFixMe, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? step.component : null}
                </div>
              ))}
            </SwipeableViews>
          </div>
        </MuiDialogContent>
        <MuiDialogActions className={classes.dialogActions}>
          <div className={classes.root}>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  style={{ width: '80px' }}
                >
                  {activeStep === maxSteps - 1 ? (
                    <FormattedMessage id="common.button.finish" />
                  ) : (
                    <>
                      <FormattedMessage id="common.button.next" />
                      <KeyboardArrowRight />
                    </>
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  style={{ width: '80px' }}
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  <FormattedMessage id="common.button.back" />
                </Button>
              }
            />
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};

export default connect(
  (state: AppState) => {
    return {
      showTestAccountWelcomeScreen:
        state.testAccount.showTestAccountWelcomeScreen,
    };
  },
  { setTestAccountWelcomeScreenShowFlag }
)(TestAccountWelcomeDialog);
