import React from 'react';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  readonly filters: $TSFixMe[];
  readonly handleClose?: () => void;
  readonly handleFilterType: (id: $TSFixMe, del: boolean) => void;
};

const RenderFilterChips = (props: Props) => {
  const { filters } = props;

  const classes = useStyles();

  const handleDelete = (key: $TSFixMe) => {
    props.handleFilterType({ name: [key], value: '' }, true);
  };

  const chips = filters.map((filter: $TSFixMe, index: number) => {
    const key = Object.keys(filter)[0];
    const label = (
      <div>
        {filters[index].labelForField}: {filters[index].labelForValue}
      </div>
    );
    return (
      <Chip
        key={key}
        label={label}
        onDelete={() => handleDelete(key)}
        className={classes.chip}
      />
    );
  });
  return <React.Fragment>{chips}</React.Fragment>;
}

export default RenderFilterChips;
