import { AnyAction } from 'redux';

interface MessageLogState {
  filtersForForm: $TSFixMe;
  exportMessageLogFormOpen: boolean;
  loading: boolean;
  exportMessageLogs: $TSFixMe[];
}

const initialState: MessageLogState = {
  filtersForForm: {},
  exportMessageLogFormOpen: false,
  loading: false,
  exportMessageLogs: [],
};

export default function reducer(state: MessageLogState = initialState, action: AnyAction): MessageLogState {
  switch (action.type) {
    case 'SET_EXPORT_FILTERS_FOR_FORM': {
      return {
        ...state,
        filtersForForm: action.payload,
      };
    }
    case 'SET_EXPORT_MESSAGE_LOG_FORM_OPEN': {
      return {
        ...state,
        exportMessageLogFormOpen: action.payload,
      };
    }
    case 'SET_EXPORT_MESSAGE_LOGS_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'SET_EXPORT_MESSAGE_LOGS': {
      return {
        ...state,
        exportMessageLogs: action.payload,
      };
    }
    case 'REMOVE_EXPORT_MESSAGE_LOGS': {
      return {
        ...state,
        exportMessageLogs: state.exportMessageLogs.filter((item) => item.id !== action.payload),
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}