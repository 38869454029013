import { ChatConfig } from '../interface';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatMessagePayloadType } from '../../types/chatMessage';

const whatsappChatConfig: ChatConfig = {
  id: SupportedChatChannel.WHATSAPP,
  dateFormat: 'yyyy-MM-dd HH:mm',
  enableEmojis: true,
  supportedMessageTypes: [
    ChatMessagePayloadType.TEXT,
    ChatMessagePayloadType.IMAGE,
    ChatMessagePayloadType.DOC,
    ChatMessagePayloadType.VIDEO,
  ],
  image: {
    maxSizeInBytes: 5242880,
    allowedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
  },
  video: {
    maxSizeInBytes: 16777216,
    allowedFileTypes: ['video/mp4'],
  },
  document: {
    maxSizeInBytes: 104857600,
    allowedFileTypes: ['application/pdf'],
  },
};

export default whatsappChatConfig;
