import React, { ChangeEvent } from 'react';
import { Line } from 'react-chartjs-2';
import { addMinutes, differenceInHours } from 'date-fns';
import { Chip, Avatar, Grid } from '@mui/material';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as ChartDatasourcePrometheusPlugin from 'chartjs-plugin-datasource-prometheus';
import 'chartjs-adapter-moment';
import { Chart, LinearScale, TimeScale, TimeSeriesScale } from 'chart.js';

Chart.register(TimeScale, LinearScale, TimeSeriesScale);

const promUlr = 'https://sunrise.smsportal.ch/';

const data = {
  labels: [],
  datasets: [],
};

const options = (dateFrom: $TSFixMe, dateTo: $TSFixMe) => {
  const hoursStepsMap = [
    { distanceInHours: 1, stepInSeconds: 15, stepRelative: '60s' },
    { distanceInHours: 8, stepInSeconds: 20, stepRelative: '60s' },
    { distanceInHours: 15, stepInSeconds: 30, stepRelative: '60s' },
    { distanceInHours: 3 * 24, stepInSeconds: 60, stepRelative: '75s' },
    { distanceInHours: 7 * 24, stepInSeconds: 5 * 60, stepRelative: '315s' },
    { distanceInHours: 12 * 24, stepInSeconds: 10 * 60, stepRelative: '615s' },
    { distanceInHours: 15 * 24, stepInSeconds: 15 * 60, stepRelative: '915s' },
    { distanceInHours: 25 * 24, stepInSeconds: 20 * 60, stepRelative: '1215s' },
    { distanceInHours: 30 * 24, stepInSeconds: 30 * 60, stepRelative: '1815s' },
    { distanceInHours: 90 * 24, stepInSeconds: 60 * 60, stepRelative: '3615s' },
    { distanceInHours: 180 * 24, stepInSeconds: 3 * 60 * 60, stepRelative: '10815s' },
    { distanceInHours: 366 * 24, stepInSeconds: 6 * 60 * 60, stepRelative: '21615s' },
  ];

  const getStepBasedOnDistance = () => {
    const hoursApart = differenceInHours(dateTo, dateFrom);
    const step = hoursStepsMap
      .sort((a, b) => a.distanceInHours - b.distanceInHours)
      .find((e) => hoursApart < e.distanceInHours);
    return step;
  };

  const setFormatBasedOnDistance = () => {
    const hoursApart = differenceInHours(dateTo, dateFrom);
    if (hoursApart <= 24) return 'hour';
    return 'day';
  };

  return {
    plugins: {
      'datasource-prometheus': {
        prometheus: {
          endpoint: promUlr,
          baseURL: '/metrics',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
        query: [
          `rate(bearerbox_smsc_sent_sms_total{smsc_id='smpp_ubs'}[${getStepBasedOnDistance()!.stepRelative}])`,
          `rate(bearerbox_smsc_received_sms_total{smsc_id='http_ubs_mo'}[${getStepBasedOnDistance()!.stepRelative}])`,
        ],

        dataSetHook: (ChartDataSet: $TSFixMe[]) => {
          if (ChartDataSet[0]) {
            ChartDataSet[0].label = 'out (sms/s)';
            ChartDataSet[0].borderWidth = 2;
            ChartDataSet[0].backgroundColor = 'rgba(255, 99, 132, 0.1)';
            ChartDataSet[0].fill = 'origin';
            ChartDataSet[0].pointRadius = 0;
            ChartDataSet[0].pointHoverRadius = 3;

            ChartDataSet[0].data = ChartDataSet[0].data.map((e: $TSFixMe) => ({ ...e, y: e.y.toFixed(2) }));
          }
          if (ChartDataSet[1]) {
            ChartDataSet[1].label = 'in (sms/s)';
            ChartDataSet[1].borderWidth = 2;
            ChartDataSet[1].data = ChartDataSet[1].data.map((e: $TSFixMe) => ({ ...e, y: e.y.toFixed(2) }));
            ChartDataSet[1].fill = 'origin';
            ChartDataSet[1].pointRadius = 0;
            ChartDataSet[1].pointHoverRadius = 3;
          }
          return ChartDataSet;
        },
        timeRange: {
          type: 'absolute',
          start: dateFrom,
          end: addMinutes(dateTo, 1),
          step: getStepBasedOnDistance()!.stepInSeconds,
        },
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      position: 'average',
    },
    interaction: {
      mode: 'index',
    },
    legend: {
      display: false,
    },
    fontFamily: 'Roboto',
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
        },
      },
      xAxes: {
        type: 'time',
        time: {
          parser: 'YYYY-MM-DD HH:mm:ss',
          unit: setFormatBasedOnDistance(),
          displayFormats: {
            second: 'h:MM:SS',
            minute: 'h:MM',
            hour: 'hA',
            day: 'MMM D',
            month: 'YYYY MMM',
            year: 'YYYY',
          },
        },
        gridLines: {
          display: false,
        },

        ticks: {
          fontFamily: 'Roboto',
        },
      },
    },
  };
};

type Props = {
  readonly checked: boolean;
  readonly dateFrom: $TSFixMe;
  readonly dateTo: $TSFixMe;
  readonly updateDate: $TSFixMe;
  readonly refreshInterval: number;
  readonly setDateFrom: (date: $TSFixMe) => void;
  readonly setDateTo: (date: $TSFixMe) => void;
  readonly handleIntervalChange: (event: $TSFixMe) => void;
  readonly toggleChecked: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const PerformanceChart = ({
  dateFrom,
  dateTo,
  checked,
  toggleChecked,
  refreshInterval,
  handleIntervalChange,
}: Props) => {
  return (
    <>
      <Grid container style={{ marginBottom: '10px' }}>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={3}>
          <Chip
            style={{ marginRight: '20px' }}
            avatar={
              <Avatar
                style={{
                  backgroundColor: 'rgba(255, 99, 132, 1)',
                  width: '50px',
                  height: '24px',
                  borderRadius: '12px',
                }}
              >
                {' '}
              </Avatar>
            }
            label="Sent sms per second"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <Chip
            avatar={
              <Avatar
                style={{
                  backgroundColor: 'rgba(54, 162, 235, 1)',
                  width: '50px',
                  height: '24px',
                  borderRadius: '12px',
                }}
              >
                {' '}
              </Avatar>
            }
            label="Received sms per second"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'right', alignSelf: 'end' }}>
          <FormControlLabel
            control={<Switch size="small" checked={checked} onChange={toggleChecked} />}
            label={
              <Typography variant="caption" component="span" style={{ fontSize: '0.8125rem' }}>
                Refresh every{' '}
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={refreshInterval}
                  onChange={handleIntervalChange}
                >
                  <MenuItem value={1000 * 60 * 5}>5</MenuItem>
                  <MenuItem value={1000 * 60 * 10}>10</MenuItem>
                  <MenuItem value={1000 * 60 * 15}>15</MenuItem>
                </Select>{' '}
                minutes
              </Typography>
            }
          />
        </Grid>
      </Grid>
      {/* @ts-expect-error string is not assignable */}
      <Line data={data} options={options(dateFrom, dateTo)} plugins={[ChartDatasourcePrometheusPlugin]} />
    </>
  );
};

export default React.memo(PerformanceChart);
