import { AnyAction } from 'redux';
import { User } from '../types/user';

interface UsersState {
  users: User[];
  selectedUser: $TSFixMe;
  usersFormLoading: boolean;
  usersLoading: boolean;
}

const initialState: UsersState = {
  users: [],
  selectedUser: {},
  usersFormLoading: false,
  usersLoading: false,
};

export default function reducer(state: UsersState = initialState, action: AnyAction): UsersState {
  switch (action.type) {
    case 'SET_USERS_LOADING': {
      return {
        ...state,
        usersLoading: action.payload,
      };
    }
    case 'SET_USERS': {
      return {
        ...state,
        users: action.payload,
      };
    }
    case 'SET_USER_FORM_LOADING': {
      return {
        ...state,
        usersFormLoading: action.payload,
      };
    }
    case 'ADD_USER': {
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    }
    case 'REMOVE_USER': {
      return {
        ...state,
        users: state.users.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_USER': {
      const itemToReplace = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        phoneNumber: action.payload.phoneNumber,
        tags: action.payload.tags,
        smsVerificationRequired: action.payload.smsVerificationRequired,
        chatAgent: action.payload.chatAgent,
      };
      return {
        ...state,
        users: [
          // @ts-expect-error itemToReplace does not contain all User properties - to verify and fix!
          ...state.users.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all User properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_USER_FOR_FORM': {
      return {
        ...state,
        selectedUser: action.payload,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}
