import { AnyAction } from 'redux';
import { Customer } from '../../types/customer';
import { UploadedFile } from '../../types/uploadedFile';
import { CustomerServiceEntry, CustomerServices, CustomerServiceType } from '../../types/customerService';

interface CustomersState {
  tab: number;
  services: CustomerServices;
  files: UploadedFile[];
  filesLoading: boolean;
  filesLink: string;
  customers: Customer[];
  customerId?: number;
  customerName?: string,
  selectedCustomer: $TSFixMe;
  customerLoading: boolean;
  customersLoading: boolean;
  customersFormLoading: boolean;
  error?: $TSFixMe;
  resellerPackages: $TSFixMe[];
  resellerPackagesLoading: boolean;
  notifyLoading: boolean;
}

const initialServiceData: CustomerServiceEntry = {
  items: [],
  status: true,
  uri: '',
  active: false,
  open: false,
  logo: undefined,
};

const initialState: CustomersState = {
  tab: 0,
  services: {
    smsChat: {
      ...initialServiceData,
    },
    // waChat: {
    //   ...initialServiceData,
    // },
    // fbChat: {
    //   ...initialServiceData,
    // },
    // vbChat: {
    //   ...initialServiceData,
    // },
  },
  files: [],
  filesLoading: false,
  filesLink: '',
  customers: [],
  customerName: '',
  customerId: undefined,
  selectedCustomer: {},
  customerLoading: false,
  customersLoading: false,
  customersFormLoading: false,
  resellerPackages: [],
  resellerPackagesLoading: false,
  notifyLoading: false,
};

export default function reducer(state: CustomersState = initialState, action: AnyAction): CustomersState {
  switch (action.type) {
    case 'SET_CUSTOMERS_LOADING': {
      return {
        ...state,
        customersLoading: action.payload,
      };
    }
    case 'SET_CUSTOMERS': {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case 'SET_CUSTOMER_FORM_LOADING': {
      return {
        ...state,
        customersFormLoading: action.payload,
      };
    }
    case 'ADD_CUSTOMER': {
      return {
        ...state,
        customers: [...state.customers, action.payload],
      };
    }
    case 'REMOVE_CUSTOMER': {
      return {
        ...state,
        customers: state.customers.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_CUSTOMER': {
      const itemToReplace = {
        active: action.payload.active,
        address: action.payload.address,
        apiSmsSuccess: action.payload.apiSmsSuccess,
        autoAcceptInvoice: action.payload.autoAcceptInvoice,
        billingCycle: action.payload.billingCycle,
        billingInterval: action.payload.billingInterval,
        birthday: action.payload.birthday,
        city: action.payload.city,
        cmt: action.payload.cmt,
        connectionType: action.payload.connectionType,
        correspondenceAddress: action.payload.correspondenceAddress,
        correspondenceCity: action.payload.correspondenceCity,
        correspondenceCountry: action.payload.correspondenceCountry,
        correspondencePostalCode: action.payload.correspondencePostalCode,
        correspondenceRegion: action.payload.correspondenceRegion,
        country: action.payload.country,
        creditLimit: action.payload.creditLimit,
        currency: action.payload.currency,
        currentPackage: action.payload.currentPackage,
        customerInfo: action.payload.customerInfo,
        customerType: action.payload.customerType,
        customerUuid: action.payload.customerUuid,
        email: action.payload.email,
        endDate: action.payload.endDate,
        firstMonth: action.payload.firstMonth,
        firstName: action.payload.firstName,
        id: action.payload.id,
        invoiceDeliveryAddressSame: action.payload.invoiceDeliveryAddressSame,
        invoiceEmailAddress: action.payload.invoiceEmailAddress,
        language: action.payload.language,
        lastName: action.payload.lastName,
        mo_sessionEsme: action.payload.mo_sessionEsme,
        mo_smscId: action.payload.mo_smscId,
        mobilePhone: action.payload.mobilePhone,
        mt_sessionEsme: action.payload.mt_sessionEsme,
        mt_smscId: action.payload.mt_smscId,
        name: action.payload.name,
        nextPackage: action.payload.nextPackage,
        paymentTerms: action.payload.paymentTerms,
        postalCode: action.payload.postalCode,
        protocol: action.payload.protocol,
        pricelist: action.payload.pricelist,
        pricelists: action.payload.pricelists,
        pricingPackage: action.payload.pricingPackage,
        region: action.payload.region,
        registrationNumber: action.payload.registrationNumber,
        resellerId: action.payload.resellerId,
        sendInvoiceAutomatically: action.payload.sendInvoiceAutomatically,
        shortName: action.payload.shortName,
        smpp: action.payload.smpp,
        smsVerificationRequired: action.payload.smsVerificationRequired,
        smscId: action.payload.smscId,
        startDate: action.payload.startDate,
        staticSenderId: action.payload.staticSenderId,
        staticSenderIdNumber: action.payload.staticSenderIdNumber,
        useCreditLimit: action.payload.useCreditLimit,
        useGlobalPaymentTerms: action.payload.useGlobalPaymentTerms,
        useStaticSenderId: action.payload.useStaticSenderId,
        vatNumber: action.payload.vatNumber,
        vatRate: action.payload.vatRate,
      };
      return {
        ...state,
        customers: [
          // @ts-expect-error itemToReplace does not contain all Customer properties - to verify and fix!
          ...state.customers.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all Customer properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_CUSTOMER_FOR_FORM': {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }
    case 'SET_CUSTOMERS_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'SET_OPEN': {
      const serviceName: CustomerServiceType = action.data.service;

      return {
        ...state,
        services: {
          ...state.services,
          [serviceName]: {
            ...state.services[serviceName],
            open: action.data.open,
          },
        },
      };
    }
    case 'SET_SERVICE_DATA': {
      const serviceName: CustomerServiceType = action.data.service;

      return {
        ...state,
        services: {
          ...state.services,
          [serviceName]: {
            ...state.services[serviceName],
            ...action.data,
          },
        },
      };
    }
    case 'SET_CUSTOMER_FILES': {
      return {
        ...state,
        files: [...action.payload],
      };
    }
    case 'RESET_SERVICE_DATA': {
      return {
        ...state,
        services: {...initialState.services},
      };
    }
    case 'ADD_CUSTOMER_FILE': {
      return {
        ...state,
        files: [...state.files, action.file],
      };
    }
    case 'SET_CUSTOMER_FILES_LOADING': {
      return {
        ...state,
        filesLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_FILES_LINK': {
      return {
        ...state,
        filesLink: action.link,
      };
    }
    case 'SET_CUSTOMER_FILES_ERROR': {
      return {
        ...state,
        error: action.message,
      };
    }
    case 'SET_CUSTOMER_ID': {
      return {
        ...state,
        customerId: action.payload,
      };
    }
    case 'SET_CUSTOMER_LOADING': {
      return {
        ...state,
        customerLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_NAME': {
      return {
        ...state,
        customerName: action.payload,
      };
    }
    case 'SET_RESELLER_PACKAGES_LOADING': {
      return {
        ...state,
        resellerPackagesLoading: action.payload,
      };
    }
    case 'SET_RESELLER_PACKAGES': {
      return {
        ...state,
        resellerPackages: action.payload,
      };
    }
    case 'SET_NOTIFY_LOADING': {
      return {
        ...state,
        notifyLoading: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
