import { AnyAction } from 'redux';
import { MobileCode } from '../../types/mobileCode';

interface MobileCountryCodesState {
  mobileCountryCodes: MobileCode[];
  mobileCountryCodesLoading: boolean;
  selectedMobileCountryCode: Partial<MobileCode>;
  mobileCountryCodesFormLoading: boolean;
}

const initialState: MobileCountryCodesState = {
  mobileCountryCodes: [],
  mobileCountryCodesLoading: false,
  selectedMobileCountryCode: {},
  mobileCountryCodesFormLoading: false,
};

export default function reducer(state: MobileCountryCodesState = initialState, action: AnyAction): MobileCountryCodesState {
  switch (action.type) {
    case 'SET_MOBILE_COUNTRY_CODES_LOADING': {
      return {
        ...state,
        mobileCountryCodesLoading: action.payload,
      };
    }
    case 'SET_MOBILE_COUNTRY_CODES': {
      return {
        ...state,
        mobileCountryCodes: action.payload,
      };
    }
    case 'SET_MOBILE_COUNTRY_CODES_FORM_LOADING': {
      return {
        ...state,
        mobileCountryCodesFormLoading: action.payload,
      };
    }
    case 'ADD_MOBILE_COUNTRY_CODE': {
      return {
        ...state,
        mobileCountryCodes: [...state.mobileCountryCodes, action.payload],
      };
    }
    case 'REMOVE_MOBILE_COUNTRY_CODE': {
      return {
        ...state,
        mobileCountryCodes: state.mobileCountryCodes.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_MOBILE_COUNTRY_CODE': {
      const itemToReplace = {
        countryCode: action.payload.countryCode,
        networkCode: action.payload.networkCode,
      };
      return {
        ...state,
        mobileCountryCodes: [
          // @ts-expect-error itemToReplace does not contain all MobileCode properties - to verify and fix!
          ...state.mobileCountryCodes.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all MobileCode properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MOBILE_COUNTRY_CODE_FOR_FORM': {
      return {
        ...state,
        selectedMobileCountryCode: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}