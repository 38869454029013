/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminMtApi = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/administration/mtApi" size="small" color="secondary">
            <FormattedMessage id="nav.administration.mtApi" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.mtApi" />
        </Typography>
        <p></p>
        <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.mtApi.1" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.mtApi.2" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.mtApi.3" />
          </Typography>
        </div>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.4" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.5" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.6" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.7" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.8" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.9" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.10" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.11" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="administration.mtApi.tab.label.routing" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.12" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="administration.mtApi.tab.label.country.override" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.email2sms.20" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.mtApi.13" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpAdminMtApi;
