import { ViberAccount } from '../../types/viber/viberAccount';
import { HttpClient } from '../common/httpClient';
import { PageResponse } from '../common/pagingModel';
import dateTimeService from '../../service/dateTimeService';

export type ViberAccountResponse = {
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly serviceId: number;
  readonly numberFilteringMode: string;
  readonly disabled: boolean;
  readonly appId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly tenantId: string;
  readonly customerId: string;
};

export class ViberAccountApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToViberAccount(response: ViberAccountResponse): ViberAccount {
    return {
      id: response.id,
      appId: response.appId,
      name: response.name,
      serviceId: response.serviceId,
      numberFilteringMode: response.numberFilteringMode,
      disabled: response.disabled,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
      tenantId: response.tenantId,
    };
  }

  getAllByTenantId(tenantId: string): Promise<ViberAccount[]> {
    return this.httpClient.get<PageResponse<ViberAccountResponse>>(`tenants/${tenantId}/accounts`)
      .then(data => (
        data.content.map(it => this.mapToViberAccount(it))
      ));
  }

  getAllForAdmin(): Promise<ViberAccount[]> {
    return this.httpClient.get<PageResponse<ViberAccountResponse>>(`accounts`)
      .then(data => (
        data.content.map(it => this.mapToViberAccount(it))
      ));
  }

  async add(tenantId: string, values: Partial<ViberAccount>): Promise<void> {
    await this.httpClient.post<ViberAccount>(`tenants/${tenantId}/accounts`, values);
  }

  getById(tenantId: string, id: string): Promise<ViberAccount> {
    return this.httpClient.get<ViberAccountResponse>(`tenants/${tenantId}/accounts/${id}`)
      .then(data => this.mapToViberAccount(data));
  }

  async update(tenantId: string,id: string, item: Partial<ViberAccount>): Promise<void> {
    await this.httpClient.put<ViberAccount>(`tenants/${tenantId}/accounts/${id}`, item);
  }

  async enable(tenantId: string,id: string): Promise<void> {
    await this.httpClient.put<ViberAccount>(`tenants/${tenantId}/accounts/${id}/enable`, {});
  }

  async disable(tenantId: string,id: string): Promise<void> {
    await this.httpClient.put<ViberAccount>(`tenants/${tenantId}/accounts/${id}/disable`, {});
  }
}
