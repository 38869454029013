import { AnyAction } from 'redux';

interface UbsCountryState {
  countries: $TSFixMe[];
  countryFormOpen: boolean;
  countryLoading: boolean;
  selectedEntry: $TSFixMe;
}

const initialState: UbsCountryState = {
  countries: [],
  countryFormOpen: false,
  countryLoading: false,
  selectedEntry: {},
};

export default function reducer(state: UbsCountryState = initialState, action: AnyAction): UbsCountryState {
  switch (action.type) {
    case 'SET_COUNTRY_LOADING': {
      return {
        ...state,
        countryLoading: action.payload,
      };
    }
    case 'SET_COUNTRY_FORM_OPEN': {
      return {
        ...state,
        countryFormOpen: action.payload,
      };
    }
    case 'ADD_COUNTRY_ENTRY': {
      return {
        ...state,
        countries: [...state.countries, action.payload],
      };
    }
    case 'REMOVE_COUNTRY_ENTRY': {
      return {
        ...state,
        countries: state.countries.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_COUNTRY_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        country: action.payload.country,
        override: action.payload.override,
      };
      return {
        ...state,
        countries: [...state.countries.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_COUNTRY_ENTRY_FOR_FORM': {
      const entry = state.countries.filter((item) => item.id === action.payload);

      return {
        ...state,
        selectedEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_COUNTRY_ENTRIES': {
      return {
        ...state,
        countries: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
