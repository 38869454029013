import { Page } from './page';
import { SupportedChatChannel } from '../chats/supportedChatChannel';

export enum ChatMessagePayloadType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOC = 'document',
  TEXT = 'text',
  TEXT_BUTTON = 'text_button',
  IMAGE_BUTTON = 'image_button',
  UNSUPPORTED = 'unsupported',
  BUTTON = 'button'
  // AUDIO = 'audio',
  // CONTACT = 'contact',
}

export enum ChatMessageStatus {
  SENDING = 'SENDING',
  QUEUED = 'QUEUED',
  SENT = 'SENT',
  UNREAD = 'UNREAD',
  READ = 'READ',
  DELIVERED = 'DELIVERED',
  // TODO: add remaining statuses that backend returns
}

export interface ChatConversationId {
  readonly channel: SupportedChatChannel;
  readonly senderId: string;
  readonly clientId: string;
}

export interface ChatAttachment {
  readonly link: string;
  readonly filename?: string;
  readonly caption?: string;
}

export interface ChatMessage {
  readonly channel: SupportedChatChannel;
  readonly senderId: string;
  readonly clientId: string;
  readonly msgId: string;
  readonly message?: string;
  readonly date: string;
  readonly outgoing: boolean;
  readonly origin?: string;
  readonly user?: string;
  readonly payloadType: ChatMessagePayloadType;
  readonly attachments: ChatAttachment[];
  readonly status: string;
  readonly statusCode: number;
  readonly statusDetails?: string;
  readonly error: string | null;
  readonly button?: {
    readonly action: string;
    readonly caption: string;
  }
}

export type ChatMessagesPage = Page<ChatMessage>;
