import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(() => ({
  popoverPaper: {
    width: 250,
    maxHeight: 600,
    flexGrow: 1,
  },
  appbar: {
    height: 48,
    minHeight: 48,
  },
  flex: {
    flex: 1,
  },
}));

type Props = {
  readonly anchorEl: $TSFixMe;
  readonly columns: $TSFixMe[];
  readonly handleClose: () => void;
  readonly handleColumnsSelection: (id: $TSFixMe) => void;
};

const ColumnChooser = (props: Props) => {
  const { columns, handleClose, handleColumnsSelection, anchorEl } = props;

  const classes = useStyles();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper className={classes.popoverPaper} elevation={0}>
        <AppBar position="sticky" color="default" className={classes.appbar} elevation={0}>
          <Toolbar className={classes.appbar}>
            <Typography className={classes.flex} variant="h6" style={{ fontSize: '1em' }}>
              <FormattedMessage id="datatable.button.columns" />
              :
            </Typography>
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        {columns.filter((column: $TSFixMe) => column.id !== 'actions').map(
          (column: $TSFixMe) => (
            <MenuItem dense key={column.id} value={column.label} onClick={() => handleColumnsSelection(column.id)}>
              <Checkbox checked={column.visible} color="secondary" />
              <ListItemText primary={column.label} />
            </MenuItem>
          ))}
      </Paper>
    </Popover>
  );
};

export default ColumnChooser;
