import axios from 'axios';
import { appConfig } from '../../config';
import smsChatConfig from './smsChatConfig';
import { ChatPublisher, SendChatMessageParams } from '../interface';
import { ChatMessage, ChatMessagePayloadType } from '../../types/chatMessage';
import { SupportedChatChannel } from '../supportedChatChannel';
import dateTimeService from '../../service/dateTimeService';

interface SmsMessageRequest {
  readonly from: string;
  readonly to: string;
  readonly message: string;
}

export default class SmsChatPublisher implements ChatPublisher {
  async sendChatMessage(params: SendChatMessageParams): Promise<ChatMessage> {
    const messageRequest: SmsMessageRequest = {
      from: params.senderId,
      to: params.clientPhoneNumber,
      message: params.text
    };

    return axios.post(`${appConfig.URL_REST}chat/reply/SMS`, messageRequest, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(response => {
      const { data } = response;

      return {
        channel: SupportedChatChannel.SMS,
        senderId: params.senderId,
        clientId: params.clientId,
        msgId: data.id,
        message: params.text,
        date: dateTimeService.formatCurrentTimestamp(smsChatConfig.dateFormat),
        outgoing: true,
        payloadType: ChatMessagePayloadType.TEXT,
        attachments: [],
        status: data.status,
        statusCode: response.status,
        error: null,
      };
    });
  }
}
