import Validator from 'validatorjs';
import { ChangePasswordFormValidator } from './ChangePassword';

const validate: ChangePasswordFormValidator = (values, props) => {
  const rules = {
    newPassword: 'required|hasSpecialCharacter|hasUppercaseLetter|hasDigit|regex:/^.{10,}$/',
  };

  Validator.register(
    'hasSpecialCharacter',
    (value: $TSFixMe) => /[!@#$%^&*(),.?":{}|<>_+=\-[\]]/.test(value),
    props.intl.formatMessage({
      id: 'form.validation.password',
    })
  );

  Validator.register(
    'hasUppercaseLetter',
    (value: $TSFixMe) => /[A-Z]/.test(value),
    props.intl.formatMessage({
      id: 'form.validation.password',
    })
  );

  Validator.register(
    'hasDigit',
    (value: $TSFixMe) => /\d/.test(value),
    props.intl.formatMessage({
      id: 'form.validation.password',
    })
  );

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
    'regex': props.intl.formatMessage({ id: 'form.validation.password' })
  });
  validation.passes();
  return validation.errors.all();
};

export default validate;
