import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

type Props = {
  readonly label: object;
  readonly id?: string;
  readonly selectedField: string;
  readonly fields: $TSFixMe[];
  readonly myStyle?: object;
  readonly handleChangeSelected: (value: $TSFixMe) => void;
};

const SelectTextField = (props: Props) => {
  const { label, myStyle, selectedField, handleChangeSelected, id, fields } = props;

  return (
    <TextField
      fullWidth
      select
      label={label}
      value={selectedField}
      onChange={handleChangeSelected}
      margin="dense"
      variant="standard"
      style={myStyle}
      id={id}
    >
      {fields.map((option: $TSFixMe) => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

SelectTextField.propTypes = {
  handleChangeSelected: PropTypes.func.isRequired,
  label: PropTypes.object.isRequired,
  selectedField: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  myStyle: PropTypes.object,
  id: PropTypes.string,
};

export default SelectTextField;
