import { ChatSender } from '../../types/chatSender';
import { AppState } from '../index';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { ChatChannelsAction } from '../../actions/chats';

export interface ChatSendersState {
  selectedSenderId?: string;
  entities: ChatSender[];
  loading: boolean;
  errorMessage?: string;
}

export const DEFAULT_CHAT_SENDERS_STATE: ChatSendersState = {
  entities: [],
  loading: false,
};

export function chatSendersReducer(state: ChatSendersState, action: ChatChannelsAction): ChatSendersState {
  switch (action.type) {
    case 'chatChannels/senders/fetchPending': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'chatChannels/senders/fetchSuccess': {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        entities: payload,
      };
    }
    case 'chatChannels/senders/fetchFailure': {
      const { payload } = action;

      return {
        ...state,
        loading: false,
        errorMessage: payload,
      };
    }

    case 'chatChannels/senders/set': {
      const { payload } = action;

      return {
        ...state,
        selectedSenderId: payload.senderId,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

const getChatSendersState = (state: AppState, channel: SupportedChatChannel) => state.chatChannels[channel].senders;
const getAllSenders = (state: ChatSendersState) => state.entities;
const getIsLoadingSenders = (state: ChatSendersState) => state.loading;
const getSelectedSenderId = (state: ChatSendersState) => state.selectedSenderId;

export const selectAllChatSenders = (state: AppState, channel: SupportedChatChannel) => (
  getAllSenders(getChatSendersState(state, channel))
);
export const selectIsLoadingChatSenders = (state: AppState, channel: SupportedChatChannel) => (
  getIsLoadingSenders(getChatSendersState(state, channel))
);
export const selectSelectedChatSenderId = (state: AppState, channel: SupportedChatChannel) => (
  getSelectedSenderId(getChatSendersState(state, channel))
);
