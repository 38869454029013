import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { getDefaultTheme, mergeAppearanceIntoTheme } from './appearance.service';
import { AppState } from '../reducers';
import { Appearance } from '../types/appearance';

type Props = {
  readonly appearance: Appearance;
  readonly children: React.ReactNode;
};

const AppearanceProvider = (props: Props) => {
  const { appearance, children } = props;

  const muiTheme = appearance
    ? mergeAppearanceIntoTheme(appearance)
    : getDefaultTheme();

  return (
    <ThemeProvider theme={muiTheme}>
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  appearance: state.appearance.custom,
});

export default connect(mapStateToProps)(AppearanceProvider);
