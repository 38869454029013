import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import FilterField from './FilterField';

const useStyles = makeStyles(() => ({
  popoverPaper: {
    width: 450,
    maxHeight: 600,
    flexGrow: 1,
  },
  appbar: {
    height: 48,
    minHeight: 48,
  },
  flex: {
    flex: 1,
  },
  marginRight15: {
    marginRight: '15px',
  },
}));

type Props = {
  readonly anchorEl: null;
  readonly columns: $TSFixMe[];
  readonly filters: $TSFixMe[];
  readonly onSearch: () => void;
  readonly handleClose: () => void;
  readonly clearFilters: () => void;
  readonly onKeyPress: (event: $TSFixMe) => void;
  readonly handleFilterSettings: (filter: $TSFixMe) => void;
  readonly onFilter: (filter: $TSFixMe, del: boolean) => void;
};

const FilterChooser = (props: Props) => {
  const { columns, handleClose, anchorEl, filters } = props;

  const classes = useStyles();

  const handleFilter = (filter: $TSFixMe) => {
    props.handleFilterSettings(filter);
  };
  // add default allowFilter flag set to true if absent on columns object
  const defaultColumns = columns.map((col) => ({
    ...col,
    allowFilter: col.allowFilter === undefined ? true : col.allowFilter,
  }));
  const cols = defaultColumns
    .filter((col) => col.visible)
    .filter((col) => col.allowFilter)
    .filter((col) => col.id !== 'actions')
    .map((col) => {
      if (col.dateFiltering) {
        const filterValueAfter = filters.find((e) => {
          const key = Object.keys(e)[0];
          return key === `${col.id}After`;
        });
        const valueAfter = filterValueAfter ? Object.values(filterValueAfter)[0] : '';
        const filterValueBefore = filters.find((e) => {
          const key = Object.keys(e)[0];
          return key === `${col.id}Before`;
        });
        const valueBefore = filterValueBefore ? Object.values(filterValueBefore)[0] : '';
        return (
          <React.Fragment key={`${col.id}dates`}>
            <Grid item xs={6} key={`${col.id}After`}>
              <FilterField
                key={`${col.id}After`}
                type="After"
                column={col}
                value={valueAfter}
                handleFilter={handleFilter}
              />
            </Grid>
            <Grid item xs={6} key={`${col.id}Before`}>
              <FilterField
                key={`${col.id}Before`}
                type="Before"
                column={col}
                value={valueBefore}
                handleFilter={handleFilter}
              />
            </Grid>
          </React.Fragment>
        );
      } else {
        const filterValue = filters.find((e) => {
          const key = Object.keys(e)[0];
          return key === col.id;
        });
        const value = filterValue ? Object.values(filterValue)[0] : '';
        return (
          <Grid item xs={12} key={col.id}>
            <FilterField
              column={col}
              value={value}
              handleFilter={handleFilter}
              onKeyPress={props.onKeyPress}
            />
          </Grid>
        );
      }
    });
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper className={classes.popoverPaper} elevation={0}>
        <AppBar position="sticky" color="default" className={classes.appbar} elevation={0}>
          <Toolbar className={classes.appbar}>
            <Typography className={classes.flex}>
              <FormattedMessage id="datatable.label.filters" />:
            </Typography>
            <Button
              className={classes.marginRight15}
              color="secondary"
              variant="contained"
              onClick={props.onSearch}
            >
              <FormattedMessage id="datatable.button.search" />
            </Button>
            <Button color="secondary" variant="contained" onClick={props.clearFilters}>
              <FormattedMessage id="datatable.label.clear" />
            </Button>
            <IconButton onClick={handleClose} size="large">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Divider />
        <Grid container style={{ padding: 15 }} spacing={1}>
          {cols}
        </Grid>
      </Paper>
    </Popover>
  );
}

export default FilterChooser;
