import Validator from 'validatorjs';
import { SpamFormValidator } from './spam.form';

const validate: SpamFormValidator = (values, props) => {
  const rules = {
    sender: 'required|email',
  };

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
    email: props.intl.formatMessage({ id: 'form.validation.email.format' }),
  });

  validation.passes();
  return validation.errors.all();
};

export default validate;
