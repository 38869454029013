import React from 'react';
import axios from 'axios';
import { push } from 'react-router-redux';
import { CircularProgress, IconButton, Theme } from '@mui/material';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getSingleRow } from '../../actions/resources';
import BaseLoginView from '../common/logins/BaseLoginView';
import { setPassword } from '../../actions/changePassword';
import { appConfig } from '../../config';
import TestAccountNumberConfirmationDialog from '../../components/testAccount/TestAccountNumberConfirmationDialog';
import { green } from '@mui/material/colors';
import { useWithWidthUp } from '../common/useWithWidth';
import validate from './PasswordValidator';
import { Field, SubmissionError, formValueSelector, reduxForm } from 'redux-form';
import RenderTextField from '../common/reduxForm/RenderTextField';
import isEmpty from 'lodash-es/isEmpty';
import { IntlProps } from '../common/types/intlProps';
import { AppState } from '../../reducers';
import { AppReduxFormProps, AppReduxFormSubmit, AppReduxFormValidator } from '../common/types/appReduxForm';

const selector = formValueSelector('userVerificationForm');

const useStyles = makeStyles((theme: Theme) => ({
  messageError: {
    color: 'red',
    fontSize: '0.8em',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonMargin: {
    marginTop: '20px',
  },
  wrapper: {
    position: 'relative',
    marginTop: '15px',
  },
}));

type Props = {
  readonly newPassword: string;
  readonly match: { params: { hash: $TSFixMe }};
  readonly loginData: {failed: boolean, userData: { message?: string }};
  readonly redirect: (url: string) => void;
  readonly updatePassword: (password: string, newPassword: string, code?: $TSFixMe) => void;
} & IntlProps;

type FormValues = $TSFixMe;
type FormProps = AppReduxFormProps<FormValues, Props>;
export type ChangePasswordFormValidator = AppReduxFormValidator<FormValues, Props>;
export type UserVerificationFormSubmit = AppReduxFormSubmit<FormValues, Props>;

const UserVerificationForm = (props: FormProps) => {
  const { match, loginData, newPassword, redirect, handleSubmit } = props;

  const [userName, setUserName] = React.useState('');
  const [hidePassword, setHidePassword] = React.useState(true);
  const [isSmsVerificationRequired, setIsSmsVerificationRequired] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  
  const classes = useStyles();
  const isWidthUpMd = useWithWidthUp('md');
  const spacing = isWidthUpMd ? 3 : 1;

  React.useEffect(() => {
    axios.get(`${appConfig.URL_REST}users/new/${match.params.hash}`).then((res) => {
      if (res.data === '') {
        return redirect('/login');
      }
      setUserName(res.data.email);
      setIsSmsVerificationRequired(res.data.smsVerificationRequired);
      setPhoneNumber(res.data.phoneNumber);
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyNumber = (number: $TSFixMe) => {
    axios
      .get(`${appConfig.URL_REST}demo/registration/request/${match.params.hash}`)
      .then((res) => {
        if (res.status === 200) {
          setConfirmationDialogOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setConfirmationDialogOpen(false);
    setIsLoading(false);
  };

  const submit: UserVerificationFormSubmit = (values) => {
    const errors = validate(values, props);
    if (!isEmpty(errors)) return Promise.reject(new SubmissionError(errors));
    setIsLoading(true);
    const { updatePassword, match } = props;
    if (isSmsVerificationRequired) {
      verifyNumber(phoneNumber);
    } else {
      updatePassword(match.params.hash, newPassword);
      setIsLoading(false);
    }
  };

  const handleClickShowPassword = () => {
    setHidePassword((showPassword) => !showPassword);
  };

  const handleConfirm = (code: $TSFixMe) => {
    const { updatePassword, match } = props;
    setIsLoading(false);
    updatePassword(match.params.hash, newPassword, code);
  };

  const verifyNumberComplete = (code: $TSFixMe) => {
    const { updatePassword, match } = props;
    setIsLoading(false);
    updatePassword(match.params.hash, newPassword, code);
  };

  const content = (
    <>
      <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
        <Grid container spacing={spacing}>
          <Grid item xs={12}>
            <Fade in={loginData.failed}>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography variant="caption">&nbsp;</Typography>
              </Grid>
            </Fade>
          </Grid>

          <Grid item xs={12}>
            <Field
              component={RenderTextField}
              name="newPassword"
              label={<FormattedMessage id="dialog.change.set.password.label.new.pass" />}
              type={hidePassword ? 'password' : 'text'}
              autoComplete="current-password"
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} size="large">
                      {hidePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.wrapper}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading || !newPassword}
                color="secondary"
                fullWidth
              >
                <FormattedMessage id="dialog.set.password.button.set.new.password" />
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </Grid>
        </Grid>
      </form>
      {/* @ts-expect-error TODO: fix after migrating testAccount component */}
      <TestAccountNumberConfirmationDialog
        open={confirmationDialogOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        number={phoneNumber}
        customVerification
        verify={verifyNumberComplete}
      />
    </>
  );

  return (
    <BaseLoginView
      title={<FormattedMessage id="login.set.password.dialog.title" />}
      subheader={userName}
      content={content}
    />
  );
};

const UserVerificationReduxForm = injectIntl(
  reduxForm<FormValues, Props, string[]>({
    form: 'userVerificationForm',
    validate,
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch, props) => { },
  })(UserVerificationForm));

export default connect(
  (store: AppState) => ({
    loginData: store.login,
    newPassword: selector(store, 'newPassword') || '',
  }),
  {
    updatePassword: setPassword,
    getData: getSingleRow,
    redirect: push,
  }
)(UserVerificationReduxForm);
