import chatChannelProvider from '../../chats/chatChannelProvider';
import { AppThunkAction } from '../index';
import { ChatConversationId, ChatMessage, ChatMessagesPage } from '../../types/chatMessage';
import { ChatEvent } from '../../types/chatEvent';
import {
  selectChatMessageConversationById,
  selectCurrentChatMessageConversation,
  selectIsCurrentChatMessageConversation,
} from '../../reducers/chats/messages';
import { createChatChannelsAction } from './index';
import { selectChatClientByConversationId } from '../../reducers/chats/clients';
import { selectActiveChatChannel } from '../../reducers/chats';
import { recordChatClientActivity } from './clients';
import dateTimeService from '../../service/dateTimeService';
import { markMessageAsUnread } from './unreadMessages';

const DEBUG_CHAT: boolean = true;
const chatLog = (message: string): void => {
  if (DEBUG_CHAT) console.log(message);
}

const upsertOneChatMessageAction = createChatChannelsAction<{ message: ChatMessage }>('messages/upsertOne');
const updateOneChatMessageAction = createChatChannelsAction<{ message: ChatMessage }>('messages/updateOne');

const chatMessagesFetchPendingAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  refreshInBackground: boolean;
}>('messages/fetchPending');

const chatMessagesFetchSuccessAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  messagesPage: ChatMessagesPage;
}>('messages/fetchSuccess');

const chatMessagesFetchFailureAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  errorMessage: string;
}>('messages/fetchFailure');

type FetchChatMessagesParams = {
  readonly page: number;
  readonly size: number;
  readonly refreshInBackground?: boolean;
};

const fetchChatMessages = (conversationId: ChatConversationId, params: FetchChatMessagesParams): AppThunkAction => {
  return async (dispatch, getState) => {
    const { channel } = conversationId;
    const { page, size, refreshInBackground = false } = params;

    const client = selectChatClientByConversationId(getState(), conversationId);

    if (client) {
      dispatch(chatMessagesFetchPendingAction(channel, { conversationId, refreshInBackground }));
      try {
        const messagesPage: ChatMessagesPage = await chatChannelProvider.get(channel)
          .api.fetchMessages(client, { page, size });

        dispatch(chatMessagesFetchSuccessAction(channel, { conversationId, messagesPage }));
      } catch {
        dispatch(chatMessagesFetchFailureAction(channel, {
          conversationId,
          errorMessage: 'Failed to fetch message conversation',
        }));
      }
    }
  }
};

const MESSAGES_CHUNK_SIZE: number = 20;

export const fetchFirstChatConversationChunk = (conversationId: ChatConversationId): AppThunkAction => {
  return async (dispatch) => {
    dispatch(fetchChatMessages(conversationId, {
      page: 0,
      size: MESSAGES_CHUNK_SIZE,
    }));
  };
};

export const fetchNextChatConversationChunk = (conversationId: ChatConversationId): AppThunkAction => {
  return async (dispatch, getState) => {
    const conversation = selectChatMessageConversationById(getState(), conversationId);

    if (conversation && conversation.totalMessages > conversation.numberOfMessages) {
      dispatch(fetchChatMessages(conversationId, {
        page: 0,
        size: conversation.numberOfMessages + MESSAGES_CHUNK_SIZE,
      }));
    }
  };
};

export const refreshChatConversationInBackground = (conversationId: ChatConversationId): AppThunkAction => {
  return async (dispatch, getState) => {
    const conversation = selectChatMessageConversationById(getState(), conversationId);

    if (conversation) {
      dispatch(fetchChatMessages(conversationId, {
        page: 0,
        size: conversation.numberOfMessages,
        refreshInBackground: true,
      }));
    }
  };
};

export const addOutgoingChatMessage = (message: ChatMessage) => (
  upsertOneChatMessageAction(message.channel, { message })
);

export const handleChatEvent = (chatEvent: ChatEvent): AppThunkAction => {
  return (dispatch, getState) => {
    chatLog(`Handling chat event: ${JSON.stringify(chatEvent)}`);

    const state = getState();
    const activeChannel = selectActiveChatChannel(state);

    if (activeChannel === chatEvent.channel) {
      const conversation = selectCurrentChatMessageConversation(state, activeChannel);
      const existingChatMessage = conversation?.entities
        .find(msg => msg.msgId === chatEvent.msgId);

      if (conversation && existingChatMessage) {
        const updatedChatMessage: ChatMessage = {
          ...existingChatMessage,
          status: chatEvent.status,
          statusDetails: chatEvent.statusDetails,
        };

        chatLog(`Applied chat event: ${JSON.stringify(updatedChatMessage)}`);

        dispatch(updateOneChatMessageAction(conversation.channel, { message: updatedChatMessage }));
      }
    }
  };
}

export const handleChatIncomingMessage = (chatMessage: ChatMessage): AppThunkAction => {
  return (dispatch, getState) => {
    chatLog(`Handling chat incoming-message: ${JSON.stringify(chatMessage)}`);

    const { channel, senderId, clientId } = chatMessage;
    const conversationId: ChatConversationId = { channel, senderId, clientId };

    const isOpenConversationByUser = selectIsCurrentChatMessageConversation(getState(), conversationId);
    if (isOpenConversationByUser) {
      dispatch(refreshChatConversationInBackground(conversationId));
    } else {
      dispatch(markMessageAsUnread(chatMessage));
    }

    dispatch(recordChatClientActivity({
      channel,
      clientId,
      activityTime: dateTimeService.getCurrentTimestamp(),
    }));
  };
};

export const handleChatOutgoingMessage = (chatMessage: ChatMessage): AppThunkAction => {
  return (dispatch, getState) => {
    chatLog(`Handling chat outgoing-message: ${JSON.stringify(chatMessage)}`);

    const { channel, clientId, senderId } = chatMessage;
    const conversationId: ChatConversationId = { channel, senderId, clientId };

    const isOpenConversationByUser = selectIsCurrentChatMessageConversation(getState(), conversationId);
    if (isOpenConversationByUser) {
      dispatch(addOutgoingChatMessage(chatMessage));
    }

    dispatch(recordChatClientActivity({
      channel,
      clientId,
      activityTime: dateTimeService.getCurrentTimestamp(),
    }));
  };
};
