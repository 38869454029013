import React, { useState } from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash-es/debounce';
import Grid from '@mui/material/Grid';
import SearchBar from './SearchBar';
import ColumnChooser from './ColumnChooser';
import FilterChooser from './FilterChooser';
import ToolbarButtons from './ToolbarButtons';
import RenderFilterChips from './RenderFilterChips';
import { Theme } from '@mui/material';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 10,
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[200],
  },
  spacer: {
    flex: 1,
  },
  title: {
    flex: '0 0 auto',
  },
}));

type Props = {
  readonly title: string | object;
  readonly tooltip: string | object | boolean;
  readonly columns: $TSFixMe[];
  readonly numSelected: number;
  readonly selected: $TSFixMe[];
  readonly onSearch: () => void;
  readonly onClickNew: () => void;
  readonly extraButtons: () => void;
  readonly handleDelete: () => void;
  readonly clearIdsArray: () => void;
  readonly onSearchClick: () => void;
  readonly onFilter: (filter: string) => void;
  readonly handleColumnsSelection: (id: $TSFixMe) => void;
  readonly actions: (selected: $TSFixMe[], e: $TSFixMe) => React.ReactNode;
};

const DataTableToolbar = (props: Props) => {
  const {
    numSelected,
    title,
    tooltip,
    onClickNew,
    onSearch,
    selected,
    clearIdsArray,
    extraButtons,
    columns,
    handleColumnsSelection,
    onFilter,
    actions,
  } = props;

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [filtersForChips, setFiltersForChips] = useState([]); // eslint-disable-line
  const [showSearchField, setShowSearchField] = useState(false);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElColumns, setAnchorElColumns] = useState(null);

  const classes = useStyles();
  const ENTER_KEY = 13;

  const handleSearchClick = () => {
    setShowSearchField(true);
  };

  const hideSearchBar = () => {
    setShowSearchField(false);
  };

  const handleColumnVisibility = (event: $TSFixMe) => {
    setAnchorElColumns(event.currentTarget);
  };

  const handleFilterVisibility = (event: $TSFixMe) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElFilter(null);
    setAnchorElColumns(null);
  };

  const clearFilters = () => {
    setFilters([]);
    setFiltersForChips([]);
    props.onFilter('');
  };

  const onClickSearchFilter = (query: $TSFixMe) => {
    setFiltersForChips(filters);
    props.onFilter(query);
  };

  const handleFilterType = (filter: $TSFixMe, del = false) => {
    const { value, name, labelForField, labelForValue, numeric } = filter;
    const newFilters = [
      ...filters.filter((item) => !item[name]),
      ...(value !== '' ? [{ [name]: value, labelForField, labelForValue, numeric }] : []),
    ];
    // @ts-expect-error TODO: type is not assignable 
    setFilters(newFilters);
    let query = '';
    newFilters.forEach((item) => {
      const key: $TSFixMe = Object.keys(item)[0];
      const val = item[key];
      if (item.numeric) {
        if (!isNaN(val)) {
          query += `&${key}=${encodeURIComponent(item[key])}`;
        }
      } else {
        query += `&${key}=${encodeURIComponent(item[key])}`;
      }
    });
    if (del) removeFilter(query);
    setQuery(query);
  };

  const removeFilter = debounce((query) => {
    props.onFilter(query);
    setFiltersForChips(filters);
  }, 50);

  const pressEnter = (event: $TSFixMe) => {
    if (event.charCode === ENTER_KEY) {
      onClickSearchFilter(query);
    }
  };

  const showTitleOrInfo = () => {
    return numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} <FormattedMessage id="datatable.label.selected" />
      </Typography>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5" id="tableTitle">
          {title}
        </Typography>
        {tooltip &&
          <Tooltip
            title={tooltip!}
            placement="top-start"
          >
            <IconButton style={{ height: 32, width: 32, padding: 0 }} size="large">
              <Info color="primary" />
            </IconButton>
          </Tooltip>
        }
      </div>
    );
  };

  return (
    <React.Fragment>
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} style={{ paddingLeft: 20 }}>
            {!showSearchField ? (
              showTitleOrInfo()
            ) : (
              <SearchBar hideSearchBar={hideSearchBar} onSearch={onSearch} />
            )}
          </Grid>
          <Grid item xs={12} sm={8} style={{ paddingTop: 12 }}>
            {numSelected > 0 ? (
              <Grid container alignItems="center" className={classes.grid}>
                <Grid item>{actions(selected, clearIdsArray)}</Grid>
              </Grid>
            ) : (
              <ToolbarButtons
                handleSearchClick={handleSearchClick}
                handleColumnVisibility={handleColumnVisibility}
                handleFilterVisibility={handleFilterVisibility}
                extraButtons={extraButtons}
                onClickNew={onClickNew}
                onFilter={onFilter}
                onSearch={onSearch}
              />
            )}
          </Grid>
        </Grid>
      </Toolbar>
      {filters.length > 0 && (
        <Toolbar>
          <Grid container alignItems="center">
            <Grid>
              <RenderFilterChips filters={filters} handleFilterType={handleFilterType} />
            </Grid>
          </Grid>
        </Toolbar>
      )}

      <ColumnChooser
        columns={columns}
        handleClose={handleClose}
        handleColumnsSelection={handleColumnsSelection}
        anchorEl={anchorElColumns}
      />
      <FilterChooser
        columns={columns}
        handleClose={handleClose}
        handleFilterSettings={handleFilterType}
        anchorEl={anchorElFilter}
        onFilter={handleFilterType}
        filters={filters}
        clearFilters={clearFilters}
        onSearch={() => onClickSearchFilter(query)}
        onKeyPress={pressEnter}
      />
    </React.Fragment>
  );
};

export default DataTableToolbar;
