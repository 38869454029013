/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminPackages = (props: Props) => {
  return (
    <div style={{ padding: 20 }}>
      <Typography variant="body1">
        {props.fromDashboard ? (
          <Button
            component={Link}
            to="/administration/pricelistTemplates"
            size="small"
            color="secondary"
          >
            <FormattedMessage id="help.admin.packages.0" />
          </Button>
        ) : (
          <FormattedMessage id="help.admin.packages.0" />
        )}
      </Typography>
      <Typography variant="body2" style={{ marginTop: 15 }}>
        <FormattedMessage id="help.admin.packages.1" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.2" />
      </Typography>
      <Typography variant="body2" style={{ marginTop: 15 }}>
        <FormattedMessage id="help.admin.packages.3" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.4" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.5" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.6" />
      </Typography>
      <Typography variant="body2" style={{ marginTop: 15 }}>
        <FormattedMessage id="help.admin.packages.7" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.8" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.9" />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id="help.admin.packages.10" />
      </Typography>
    </div>
  );
};

export default HelpAdminPackages;
