import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';

const ReCaptchaTerms = () => {
  return (
    <Typography variant="caption" color="textSecondary" component="span">
      <FormattedMessage id="signup.reCaptcha.text.1" />
      <Link
        target="blank"
        underline="always"
        color="inherit"
        href="https://policies.google.com/privacy"
      >
        <FormattedMessage id="signup.reCaptcha.privacy.policy" />
      </Link>
      <FormattedMessage id="signup.reCaptcha.text.2" />
      <Link
        target="blank"
        underline="always"
        color="inherit"
        href="https://policies.google.com/terms"
      >
        <FormattedMessage id="signup.reCaptcha.terms.of.service" />
      </Link>
      <FormattedMessage id="signup.reCaptcha.text.3" />
    </Typography>
  );
};

export default ReCaptchaTerms;
