import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import {
  get,
  changePageSize,
  setPage,
  setSort,
  setFilter,
  setSearch,
  resetResource,
} from '../../../../actions/resources';
import DataTable from '../../../DataTable';
import columns from './message.logs.table.columns';
import { change } from 'redux-form';
import Button from '@mui/material/Button';
import { showSnackBarMsg } from '../../../../actions/snackbar';
import { setExportFiltersForForm } from '../../../../actions/messageLog';
import { destroy } from 'redux-form';
import { AppState } from '../../../../reducers';
//import { MessageLogData } from '../../../../types/messageLog';

const defaultSort = {
  by: 'createDateTime',
  dir: 'desc',
};

type Props = {
  readonly uuid: string | number;
  readonly page: $TSFixMe;
  readonly loading: boolean;
  readonly filters: $TSFixMe;
  readonly filterString: string;
  readonly data: $TSFixMe;
  readonly sort: { by: string, dir: string };
  readonly smsStatusFilterOptions: $TSFixMe[];
  readonly destroy: (form: string) => void;
  readonly reset: (resource: string) => void;
  readonly setExportFiltersForForm: (filters: $TSFixMe) => void;
  readonly setPage: (resource: string, options: object) => void;
  readonly showSnackBarMsg: (msg: $TSFixMe, variant: string) => void;
  readonly filter: (resourceArrayName: string, filter: string) => void;
  readonly change: (form: string, field: string, value: $TSFixMe) => void;
  readonly changePageSize: (resource: string, size: number, options?: object) => void;
  readonly getData: (resource: string, options: object, reset?: boolean) => Promise<'$TSFixME'>;
};

const MessageLogs = (props: Props) => {

  const mapResponse = (response: $TSFixMe) => {
    return response.map((e: $TSFixMe) => {
      return {
        id: e.id,
        createDateTime: e.createDateTime,
        emailMsgId: e.emailMsgId,
        notifyMail: e.senderEmail,
        sender: e.smsSender,
        receiver: e.smsReceiver,
        numParts: e.smsNumParts,
        lastStatus: e.lastStatus,
        lastStatusTime: e.lastStatusTime,
      };
    });
  };

  useEffect(() => {
    const { reset } = props;
    getMessageLogs();
    return () => {
      reset('messageLogs');
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMessageLogs = () => {
    const { getData, uuid } = props;
    getData(
      `email2sms/${uuid}`,
      {
        defaultSort,
        resourceArrayName: 'messageLogs',
        rawData: true,
      },
      true
    );
  };

  const onChangeRowsPerPage = (event: $TSFixMe) => {
    const { changePageSize, uuid } = props;
    changePageSize(`email2sms/${uuid}`, event.target.value, {
      resourceArrayName: 'messageLogs',
      rawData: true,
    });
  };

  const onFilter = (filterString: string) => {
    const { filter, getData, uuid, page: pageObject, setPage, setExportFiltersForForm } = props;
    filter('messageLogs', filterString);
    setPage('messageLogs', { ...pageObject, number: 0 });
    getData(`email2sms/${uuid}`, {
      defaultSort,
      resourceArrayName: 'messageLogs',
      rawData: true,
    });
    let params = new URLSearchParams(filterString);
    let parsedFilters = Object.fromEntries(params.entries());
    setExportFiltersForForm(parsedFilters);
  };

  const handleChangePage = (event: React.SyntheticEvent, page: $TSFixMe) => {
    const { page: pageObject, setPage, getData, uuid, sort } = props;
    setPage('messageLogs', { ...pageObject, number: page });
    getData(`email2sms/${uuid}`, { defaultSort: sort, resourceArrayName: 'messageLogs', rawData: true }, false);
  };

  const handleSortChange = (property: $TSFixMe) => {
    const { sort, getData, uuid } = props;
    if (sort.by === property) {
      sort.dir = sort.dir === 'asc' ? 'desc' : 'asc';
    } else {
      sort.by = property;
    }
    getData(`email2sms/${uuid}`, {
      defaultSort: sort,
      resourceArrayName: 'messageLogs',
      rawData: true,
    });
  };

  const extraButtons = () => {
    return [
      {
        button: (
          <div>
            <Tooltip title={<FormattedMessage id="common.button.refresh" />}>
              <div>
                <Button onClick={() => getMessageLogs()} size="small" color="primary">
                  <FormattedMessage id="common.button.refresh" />
                </Button>
              </div>
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  const {
    page,
    data,
    loading,
    sort: injectedSort,
    smsStatusFilterOptions,
  } = props;
  const sort = injectedSort || defaultSort;
  const options = {
    onFilter: onFilter,
    onChangeRowsPerPage: onChangeRowsPerPage,
    onHandleChangePage: handleChangePage,
    onHandleSortChange: handleSortChange,
    sort,
    extraButtons,
    elevation: 0,
  };

  return (
    <DataTable
      columns={columns(smsStatusFilterOptions)}
      data={mapResponse(data.content || [])}
      page={page}
      title={<FormattedMessage id="email2sms.ubs.messageLogs.table.title.message.logs" />}
      tooltip={false}
      options={options}
      loading={loading}
    />
  );
};

export default connect(
  (store: AppState) => {
    return {
      data: store.resources.messageLogs.data,
      uuid: store.email2smsConfig.customerUuid || store.login.userData.customerUuid || 0,
      page: store.resources.messageLogs.page,
      links: store.resources.messageLogs.links,
      loading: store.resources.messageLogs.loading,
      sort: store.resources.messageLogs.sort,
      filterString: store.resources.messageLogs.filter,
      smsStatusFilterOptions: store.app.smsStatusFilterOptions,
      filters: store.messageLog.filtersForForm,
    };
  },
  {
    getData: get,
    changePageSize,
    setSort,
    filter: setFilter,
    search: setSearch,
    reset: resetResource,
    change,
    setPage,
    destroy,
    setExportFiltersForForm,
    showSnackBarMsg,
  }
)(MessageLogs);
