import { AnyAction } from 'redux';
import { Email2SmsSender } from '../../types/email2SmsSender';

interface Email2SmsConfigState {
  senders: Email2SmsSender[];
  senderFormOpen: boolean;
  senderSelectedEntry: Partial<Email2SmsSender>;
  customers: $TSFixMe[];
  customerModelList: $TSFixMe[];
  customer?: $TSFixMe;
  parametersForForm: $TSFixMe;
  customersModelListLoading: boolean;
  parametersFormLoading: boolean;
  sendersLoading: boolean;
  serviceDisabled: boolean;
  customerUuid: string;
  smscId: string[];
}

const initialState: Email2SmsConfigState = {
  senders: [],
  senderFormOpen: false,
  senderSelectedEntry: {},
  customers: [],
  customerModelList: [],
  customer: {},
  parametersForForm: {},
  customersModelListLoading: false,
  parametersFormLoading: false,
  sendersLoading: false,
  serviceDisabled: false,
  customerUuid: '',
  smscId: [],
};

export default function reducer(state: Email2SmsConfigState = initialState, action: AnyAction): Email2SmsConfigState {
  switch (action.type) {
    case 'SET_SERVICE_DISABLED': {
      return {
        ...state,
        serviceDisabled: action.payload,
      };
    }
    case 'SET_SENDERS_LOADING': {
      return {
        ...state,
        sendersLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_MODEL_LIST_LOADING': {
      return {
        ...state,
        customersModelListLoading: action.payload,
      };
    }
    case 'SET_PARAMETERS_FORM_LOADING': {
      return {
        ...state,
        parametersFormLoading: action.payload,
      };
    }
    case 'SET_ALLOWED_SENDERS': {
      return {
        ...state,
        senders: action.payload,
      };
    }
    case 'SET_SENDER_FORM_OPEN': {
      return {
        ...state,
        senderFormOpen: action.payload,
      };
    }
    case 'ADD_SENDER_ENTRY': {
      return {
        ...state,
        senders: [...state.senders, action.payload],
      };
    }
    case 'REMOVE_SENDER_ENTRY': {
      return {
        ...state,
        senders: state.senders.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_SENDER_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        email: action.payload.email,
        senderId: action.payload.senderId,
        smscId: action.payload.smscId,
        notifyFinalStatusToSender: action.payload.notifyFinalStatusToSender,
      };
      return {
        ...state,
        senders: [...state.senders.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_SENDER_ENTRY_FOR_FORM': {
      const entry = state.senders.filter((item) => item.id === action.payload);
      return {
        ...state,
        senderSelectedEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_CUSTOMER_MODEL_LIST': {
      return {
        ...state,
        customerModelList: action.payload,
      };
    }
    case 'SET_CUSTOMERS': {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case 'SET_CUSTOMER': {
      return {
        ...state,
        customer: action.payload,
      };
    }
    case 'SET_PARAMETERS_FOR_FORM': {
      return {
        ...state,
        parametersForForm: action.payload,
      };
    }
    case 'SET_CUSTOMER_UUID': {
      return {
        ...state,
        customerUuid: action.payload,
      };
    }
    case 'SET_SMSC_ID': {
      return {
        ...state,
        smscId: action.payload,
      }
    }
    default:
      return {
        ...state,
      };
  }
}
