import { AnyAction } from 'redux';
import { CustomerMoPricing } from '../../types/customerMoPricing';

interface PlMoPricingState {
  plMoPricings: CustomerMoPricing[];
  selectedPlMoPricing: Partial<CustomerMoPricing>;
  plMoPricingsLoading: boolean;
}

const initialState: PlMoPricingState = {
  plMoPricings: [],
  selectedPlMoPricing: {},
  plMoPricingsLoading: false,
};

export default function reducer(state: PlMoPricingState = initialState, action: AnyAction): PlMoPricingState {
  switch (action.type) {
    case 'SET_PL_MO_PRICINGS_LOADING': {
      return {
        ...state,
        plMoPricingsLoading: action.payload,
      };
    }
    case 'ADD_PL_MO_PRICING': {
      return {
        ...state,
        plMoPricings: [...state.plMoPricings, { id: state.plMoPricings.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_PL_MO_PRICING': {
      return {
        ...state,
        plMoPricings: state.plMoPricings.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_PL_MO_PRICING': {
      const itemToReplace = {
        destinationName: action.payload.destinationName,
        breakout: action.payload.breakout,
        rate: action.payload.rate,
      };
      return {
        ...state,
        plMoPricings: [
          // @ts-expect-error itemToReplace does not contain all CustomerMoPricing properties - to verify and fix!
          ...state.plMoPricings.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerMoPricing properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_PL_MO_PRICING_FOR_FORM': {
      return {
        ...state,
        selectedPlMoPricing: action.payload,
      };
    }
    case 'SET_PL_MO_PRICINGS': {
      return {
        ...state,
        plMoPricings: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
