import { ChatConversationId, ChatMessage } from '../../types/chatMessage';
import { AppState } from '../index';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { selectSelectedChatClient } from './clients';
import { ChatChannelsAction } from '../../actions/chats';
import { ChatClient } from '../../types/chatClient';

interface ChatMessageConversation {
  channel: SupportedChatChannel;
  senderId: string;
  clientId: string;
  entities: ChatMessage[];
  numberOfMessages: number;
  totalMessages: number;
  loading: boolean;
  refreshingInBackground: boolean;
  errorMessage?: string;
  unreadMessagesIds: string[];
}

export type ChatMessagesState = ChatMessageConversation[];
export const DEFAULT_CHAT_MESSAGES_STATE = [];

const isChatMessageConversationWithId = (conversation: ChatMessageConversation, id: ChatConversationId) => {
  return conversation.channel === id.channel
    && conversation.senderId === id.senderId
    && conversation.clientId === id.clientId;
};

const upsertMessageConversation = (
  state: ChatMessagesState,
  conversationId: ChatConversationId,
  updateFunc: (conversation: ChatMessageConversation) => ChatMessageConversation,
): ChatMessagesState => {
  let newMessages = [ ...state ];
  const existingIndex = newMessages
    .findIndex(msg => isChatMessageConversationWithId(msg, conversationId));

  if (existingIndex !== -1) {
    newMessages[existingIndex] = updateFunc(newMessages[existingIndex]);
  } else {
    const initialMessageConversation = {
      channel: conversationId.channel,
      senderId: conversationId.senderId,
      clientId: conversationId.clientId,
      loading: false,
      refreshingInBackground: false,
      entities: [],
      numberOfMessages: 0,
      totalMessages: 0,
      unreadMessagesIds: [],
    };

    newMessages.push(updateFunc(initialMessageConversation));
  }

  return newMessages;
}

export function chatMessagesReducer(state: ChatMessagesState, action: ChatChannelsAction): ChatMessagesState {
  switch (action.type) {
    case 'chatChannels/messages/fetchPending': {
      const { conversationId, refreshInBackground } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => ({
        ...conversationState,
        loading: !refreshInBackground,
        refreshingInBackground: refreshInBackground,
      }));
    }
    case 'chatChannels/messages/fetchSuccess': {
      const { conversationId, messagesPage } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => ({
        ...conversationState,
        loading: false,
        refreshingInBackground: false,
        entities: messagesPage.content,
        numberOfMessages: messagesPage.pageSize,
        totalMessages: messagesPage.totalElements,
      }));
    }
    case 'chatChannels/messages/fetchFailure': {
      const { conversationId, errorMessage } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => ({
        ...conversationState,
        loading: false,
        refreshingInBackground: false,
        errorMessage,
      }));
    }

    case 'chatChannels/messages/upsertOne': {
      const { message } = action.payload;

      const conversationId: ChatConversationId = {
        channel: message.channel,
        senderId: message.senderId,
        clientId: message.clientId,
      };

      return upsertMessageConversation(state, conversationId, conversationState => {
        const updatedMessages = [ ...conversationState.entities ];
        const existingIndex = updatedMessages
          .findIndex(oldMessage => oldMessage.msgId === message.msgId);

        if (existingIndex !== -1) {
          updatedMessages[existingIndex] = message;
        } else {
          updatedMessages.push(message);
        }

        return {
          ...conversationState,
          entities: updatedMessages,
        };
      });
    }
    case 'chatChannels/messages/updateOne': {
      const { message } = action.payload;

      const conversationId: ChatConversationId = {
        channel: message.channel,
        senderId: message.senderId,
        clientId: message.clientId,
      };

      return upsertMessageConversation(state, conversationId, conversationState => {
        const updatedMessages = conversationState.entities.map(oldMessage => (
          oldMessage.msgId === message.msgId ? message : oldMessage
        ));

        return {
          ...conversationState,
          entities: updatedMessages,
        };
      });
    }

    case 'chatChannels/messages/setUnreadMessagesIds': {
      const { conversationId, unreadMessagesIds } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => {
        return {
          ...conversationState,
          unreadMessagesIds,
        };
      });
    }
    case 'chatChannels/messages/addUnreadMessageId': {
      const { conversationId, msgId } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => {
        const updatedUnreadMessagesIds = conversationState.unreadMessagesIds
          .filter(unreadMessageId => unreadMessageId !== msgId);

        updatedUnreadMessagesIds.push(msgId);

        return {
          ...conversationState,
          unreadMessagesIds: updatedUnreadMessagesIds,
        };
      });
    }
    case 'chatChannels/messages/removeUnreadMessageId': {
      const { conversationId, msgId } = action.payload;

      return upsertMessageConversation(state, conversationId, conversationState => {
        const updatedUnreadMessagesIds = conversationState.unreadMessagesIds
          .filter(unreadMessageId => unreadMessageId !== msgId);

        return {
          ...conversationState,
          unreadMessagesIds: updatedUnreadMessagesIds,
        };
      });
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

const getChatMessagesState = (state: AppState, channel: SupportedChatChannel) => state.chatChannels[channel].messages;
const getChatMessageConversationById = (state: ChatMessagesState, id: ChatConversationId) => (
  state.find(it => isChatMessageConversationWithId(it, id))
);
const getUnreadMessagesIdsByConversationId = (state: ChatMessagesState, id: ChatConversationId) => (
  state.find(it => isChatMessageConversationWithId(it, id))?.unreadMessagesIds
);

export const selectChatMessageConversationById = (state: AppState, conversationId: ChatConversationId) => {
  return getChatMessageConversationById(getChatMessagesState(state, conversationId.channel), conversationId);
};
export const selectCurrentChatMessageConversation = (state: AppState, channel: SupportedChatChannel) => {
  const selectedChatClient = selectSelectedChatClient(state, channel);

  return !selectedChatClient
    ? undefined
    : getChatMessageConversationById(getChatMessagesState(state, channel), {
      channel,
      clientId: selectedChatClient.id,
      senderId: selectedChatClient.senderId,
    });
};
export const selectIsCurrentChatMessageConversation = (state: AppState, conversationId: ChatConversationId) => {
  const currentConversation = selectCurrentChatMessageConversation(state, conversationId.channel);
  return currentConversation ? isChatMessageConversationWithId(currentConversation, conversationId) : false;
};
export const selectUnreadMessagesCountByClient = (state: AppState, client: ChatClient) => {
  const { channel, id, senderId } = client;
  const unreadMessagesIds = getUnreadMessagesIdsByConversationId(getChatMessagesState(state, channel), {
    channel,
    senderId,
    clientId: id,
  });
  return unreadMessagesIds ? unreadMessagesIds.length : 0;
};
export const selectUnreadMessagesCountByChannel = (state: AppState, channel: SupportedChatChannel) => {
  return getChatMessagesState(state, channel)
    .reduce((acc, { unreadMessagesIds }) => {
      return acc + unreadMessagesIds.length;
    }, 0);
};
export const selectIsUnreadMessageId = (state: AppState, conversationId: ChatConversationId, msgId: string) => {
  const { channel } = conversationId;
  const unreadMessagesIds = getUnreadMessagesIdsByConversationId(getChatMessagesState(state, channel), conversationId);
  return unreadMessagesIds ? unreadMessagesIds.some(unreadMsgId => unreadMsgId === msgId) : false;
};
