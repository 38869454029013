/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminCdrs = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/administration/cdrs" size="small" color="secondary">
            <FormattedMessage id="nav.administration.cdrs" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.cdrs.1" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.cdrs.2" />
        </Typography>
        <p></p>
        <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.3" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.4" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.5" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.6" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.7" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.8" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.9" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.10" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.11" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.12" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.13" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.14" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.15" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.cdrs.16" />
          </Typography>
        </div>
        <p></p>
        {!props.fromDashboard && (
          <>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.cdrs.17" />
            </Typography>
            <p></p>
            <Typography variant="subtitle2" align="justify">
              <FormattedMessage id="help.administration.cdrs.18" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.cdrs.19" />
            </Typography>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.cdrs.20" />
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default HelpAdminCdrs;
