import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { FormattedMessage } from 'react-intl';
import { startOfDay } from 'date-fns';

type Props = {
  readonly label: string | object;
  readonly selectedDate: string;
  readonly handleChangeDate: (name: $TSFixMe, type: $TSFixMe) => void;
};

const DatePic = (props: Props) => {
  const { label, selectedDate, handleChangeDate } = props;

  return (
    <DateTimePicker
      ampm={false}
      label={label}
      inputFormat="yyyy-MM-dd HH:mm"
      mask="____-__-__ __:__"
      value={selectedDate}
      onChange={handleChangeDate}
      // @ts-expect-error TODO: no property autoOk
      autoOk
      views={['year', 'month', 'day', 'hours', 'minutes']}
      initialFocusedDate={startOfDay(new Date())}
      clearLabel={<FormattedMessage id="date.picker.clear.label" />}
      cancelLabel={<FormattedMessage id="common.button.cancel" />}
      okLabel={<FormattedMessage id="date.picker.ok.label" />}
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
  );
};

export default DatePic;
