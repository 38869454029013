import { appConfig } from '../../config';
import { ChatClient, ChatClientType } from '../../types/chatClient';
import { ChatConversationId, ChatMessage, ChatMessagePayloadType, ChatMessagesPage } from '../../types/chatMessage';
import { ChatSender } from '../../types/chatSender';
import { ChatApi, FetchChatMessagesRequest } from '../interface';
import { SupportedChatChannel } from '../supportedChatChannel';
import phoneNumberService from '../../service/phoneNumberService';
import dateTimeService from '../../service/dateTimeService';
import smsChatConfig from './smsChatConfig';
import { HttpClient } from '../../api/common/httpClient';
import { AxiosHttpClient } from '../../api/common/axiosHttpClient';

interface SmsClientResponse {
  readonly number: string;
  readonly name: string;
  readonly countUnread: number;
  readonly agentId?: number;
  readonly foundInContacts: boolean;
  readonly lastMsgTime?: string;
}

interface SmsMessageResponse {
  readonly sender: string;
  readonly receiver: string;
  readonly msgId: string;
  readonly message: string;
  readonly receivedDate: string;
  readonly status: string;
  readonly outgoing: boolean;
  readonly user: string;
  readonly statusCode: number;
  readonly error?: string;
}

interface PageSmsMessageResponse {
  readonly page: {
    readonly number: number;
    readonly size: number;
    readonly totalElements: number;
    readonly totalPages: number;
  };
  readonly _embedded: {
    readonly chatResources: SmsMessageResponse[];
  };
}

interface SmsUnreadMessagesResponse {
  readonly number: string;
  readonly clientNumber: string;
  readonly messages: string[];
}

interface SmsConversationIdResponse {
  readonly number: string;
  readonly clientNumber: string;
}

export default class SmsChatApi implements ChatApi {
  private readonly httpClient: HttpClient;

  constructor() {
    this.httpClient = new AxiosHttpClient({
      baseUrl: appConfig.URL_REST,
      accessToken: () => localStorage.getItem('token')!,
    });
  }

  isEnabled(): Promise<boolean> {
    return Promise.resolve(true);
  }

  async fetchSenders(): Promise<ChatSender[]> {
    const data = await this.httpClient.get<string[]>('/chat/numbers/SMS');

    return data.map(number => ({
      id: number,
      name: number,
    }));
  }

  async fetchClients(senderId: string): Promise<ChatClient[]> {
    const data = await this.httpClient.get<SmsClientResponse[]>(`/chat/clients/${senderId}/SMS`);
    const content = data || [];

    return content.map(it => (
      phoneNumberService.isValidPhoneNumber(it.number)
        ? this.mapToChatClientWithNumericId(it, senderId)
        : this.mapToChatClientWithAlphanumericId(it, senderId)
    ));
  }

  private mapToChatClientWithNumericId(smsClient: SmsClientResponse, senderId: string): ChatClient {
    return {
      channel: SupportedChatChannel.SMS,
      id: smsClient.number,
      type: ChatClientType.NUMERIC,
      name: smsClient.name,
      number: phoneNumberService.toPhoneNumberWithPlusSign(smsClient.number),
      senderId,
      foundInContacts: smsClient.foundInContacts,
      agentId: smsClient.agentId,
      ...(smsClient.lastMsgTime && ({
        lastActivityTime: dateTimeService.convertDateStringToTimestamp(smsClient.lastMsgTime),
      })),
    };
  }

  private mapToChatClientWithAlphanumericId(smsClient: SmsClientResponse, senderId: string): ChatClient {
    return {
      channel: SupportedChatChannel.SMS,
      id: smsClient.name,
      type: ChatClientType.ALPHANUMERIC,
      name: smsClient.name,
      senderId,
      foundInContacts: smsClient.foundInContacts,
      agentId: smsClient.agentId,
      ...(smsClient.lastMsgTime && ({
        lastActivityTime: dateTimeService.convertDateStringToTimestamp(smsClient.lastMsgTime),
      })),
    };
  }

  async fetchMessages(client: ChatClient, params: FetchChatMessagesRequest): Promise<ChatMessagesPage> {
    const { id, senderId } = client;
    const queryParams = [
      `page=${params.page}`,
      `size=${params.size}`,
    ].join('&');

    const data = await this.httpClient.get<PageSmsMessageResponse>(`/chat/${senderId}/${id}/SMS?${queryParams}`);
    const page = data.page;
    const content: ChatMessage[] = data._embedded.chatResources
      .map((it: SmsMessageResponse) => this.mapSmsMessageToChatMessage(it));

    return {
      content,
      pageNumber: page.number,
      pageSize: page.size,
      totalElements: page.totalElements,
      totalPages: page.totalPages,
    };
  }
  
  async fetchUnreadConversationsIds(): Promise<ChatConversationId[]> {
    const data = await this.httpClient.get<SmsConversationIdResponse[]>('/chat/unread_conversations');

    return data.map(it => ({
      channel: SupportedChatChannel.SMS,
      senderId: it.number,
      clientId: it.clientNumber,
    }));
  }

  async fetchUnreadMessagesIds(conversationId: ChatConversationId): Promise<string[]> {
    const { senderId: number, clientId: clientNumber } = conversationId;

    const data = await this.httpClient
      .get<SmsUnreadMessagesResponse>(`/chat/unread_messages_count/${number}/${clientNumber}`);

    return data.messages;
  }

  markMessageAsRead(message: ChatMessage): Promise<void> {
    return this.httpClient.post<void>('/chat/mark_read/SMS', [message.msgId]);
  }

  private mapSmsMessageToChatMessage(msg: SmsMessageResponse): ChatMessage {
    const senderId = msg.outgoing ? msg.sender : msg.receiver;
    const clientId = msg.outgoing ? msg.receiver : msg.sender;
    const receivedAt = dateTimeService.convertDateStringToTimestamp(msg.receivedDate);

    return {
      channel: SupportedChatChannel.SMS,
      senderId,
      clientId,
      msgId: msg.msgId,
      message: msg.message,
      date: dateTimeService.formatTimestamp(receivedAt, smsChatConfig.dateFormat),
      outgoing: msg.outgoing,
      origin: msg.user,
      user: msg.user,
      payloadType: ChatMessagePayloadType.TEXT,
      attachments: [],
      status: msg.status,
      statusCode: msg.statusCode,
      error: msg.error && JSON.parse(msg.error).error,
    };
  }
}
