import { AnyAction } from 'redux';
import { ResellerBankAccount } from '../../types/resellerBankAccount';
import { Reseller } from '../../types/reseller';

interface ResellersBankAccountsState {
  bankAccounts: ResellerBankAccount[];
  bankAccountsLoading: boolean;
  selectedBankAccount: Partial<ResellerBankAccount>;
  bankAccountsFormLoading: boolean;
  bankAccountDialogOpen: boolean;
  reseller?: Reseller;
}

const initialState: ResellersBankAccountsState = {
  bankAccounts: [],
  bankAccountsLoading: false,
  selectedBankAccount: {},
  bankAccountsFormLoading: false,
  bankAccountDialogOpen: false,
};

export default function reducer(state: ResellersBankAccountsState = initialState, action: AnyAction): ResellersBankAccountsState {
  switch (action.type) {
    case 'SET_RESELLER_BANK_ACCOUNTS_LOADING': {
      return {
        ...state,
        bankAccountsLoading: action.payload,
      };
    }
    case 'SET_RESELLER_BANK_ACCOUNTS': {
      return {
        ...state,
        bankAccounts: action.payload,
      };
    }
    case 'SET_RESELLER_BANK_ACCOUNT_FORM_LOADING': {
      return {
        ...state,
        bankAccountsFormLoading: action.payload,
      };
    }
    case 'ADD_RESELLER_BANK_ACCOUNT': {
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload],
      };
    }
    case 'REMOVE_RESELLER_BANK_ACCOUNT': {
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_RESELLER_BANK_ACCOUNT': {
      const itemToReplace = {
        description: action.payload.description,
        lastbeneficiaryName: action.payload.beneficiary,
        bankName: action.payload.bankName,
        address: action.payload.address,
        clearingNO: action.payload.clearingNO,
        iban: action.payload.iban,
        swift: action.payload.swift,
        mainAccount: action.payload.mainAccount,
      };
      return {
        ...state,
        bankAccounts: [
          // @ts-expect-error itemToReplace does not contain all bankAccount properties - to verify and fix!
          ...state.bankAccounts.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all bankAccount properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_RESELLER_BANK_ACCOUNT_FOR_FORM': {
      return {
        ...state,
        selectedBankAccount: action.payload,
      };
    }
    case 'SET_RESELLER': {
      return {
        ...state,
        reseller: action.payload,
      };
    }
    case 'SET_ADD_BANK_ACCOUNT_DIALOG_OPEN': {
      return {
        ...state,
        bankAccountDialogOpen: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
