import { AnyAction } from 'redux';

interface CdrsState {
  exportFormOpen: boolean;
  filtersForForm: $TSFixMe;
  customerId?: number;
  selectedCustomer: $TSFixMe;
  exportHistoryLoading: boolean;
  exportCdrs: $TSFixMe[];
}

const initialState: CdrsState = {
  exportFormOpen: false,
  filtersForForm: {},
  customerId: undefined,
  selectedCustomer: undefined,
  exportHistoryLoading: false,
  exportCdrs: [],
};

export default function reducer(state: CdrsState = initialState, action: AnyAction): CdrsState {
  switch (action.type) {
    case 'SET_EXPORT_FORM_OPEN': {
      return {
        ...state,
        exportFormOpen: action.payload,
      };
    }
    case 'SET_FILTERS_FOR_FORM': {
      return {
        ...state,
        filtersForForm: action.payload,
      };
    }
    case 'SET_CUSTOMER_ID': {
      return {
        ...state,
        customerId: action.payload,
      };
    }
    case 'SET_SELECTED_CUSTOMER': {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }
    case 'SET_EXPORT_CDRS': {
      return {
        ...state,
        exportCdrs: action.payload,
      };
    }
    case 'REMOVE_EXPORT_CDRS': {
      return {
        ...state,
        exportCdrs: state.exportCdrs.filter((item) => item.id !== action.payload),
      };
    }
    case 'SET_EXPORT_CDRS_LOADING': {
      return {
        ...state,
        exportHistoryLoading: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}