import { AnyAction } from 'redux';

interface InvoiceState {
  showDeleted: boolean;
  sendInvoiceDialogOpen: boolean;
  invoiceId?: $TSFixMe;
}

const initialState: InvoiceState = {
  showDeleted: false,
  sendInvoiceDialogOpen: false,
  invoiceId: undefined,
};

export default function reducer(state: InvoiceState = initialState, action: AnyAction): InvoiceState {
  switch (action.type) {
    case 'SET_SHOW_DELETED': {
      return {
        ...state,
        showDeleted: action.checked,
      };
    }
    case 'SET_SEND_INVOICE_DIALOG_OPEN': {
      return {
        ...state,
        sendInvoiceDialogOpen: action.payload,
      };
    }
    case 'SET_INVOICE_ID': {
      return {
        ...state,
        invoiceId: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
