import { AnyAction } from 'redux';
import { CustomerMtPricing } from '../../types/customerMtPricing';

interface PlMtPricingState {
  plMtPricings: CustomerMtPricing[];
  selectedPlMtPricing: Partial<CustomerMtPricing>;
  plMtPricingsLoading: boolean;
}

const initialState: PlMtPricingState = {
  plMtPricings: [],
  selectedPlMtPricing: {},
  plMtPricingsLoading: false,
};

export default function reducer(state: PlMtPricingState = initialState, action: AnyAction): PlMtPricingState {
  switch (action.type) {
    case 'SET_PL_MT_PRICINGS_LOADING': {
      return {
        ...state,
        plMtPricingsLoading: action.payload,
      };
    }
    case 'ADD_PL_MT_PRICING': {
      return {
        ...state,
        plMtPricings: [...state.plMtPricings, { id: state.plMtPricings.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_PL_MT_PRICING': {
      return {
        ...state,
        plMtPricings: state.plMtPricings.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_PL_MT_PRICING': {
      const itemToReplace = {
        destinationName: action.payload.destinationName,
        breakout: action.payload.breakout,
        rate: action.payload.rate,
        includedInPackage: action.payload.includedInPackage,
      };
      return {
        ...state,
        plMtPricings: [
          // @ts-expect-error itemToReplace does not contain all CustomerMtPricing properties - to verify and fix!
          ...state.plMtPricings.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerMtPricing properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_PL_MT_PRICING_FOR_FORM': {
      return {
        ...state,
        selectedPlMtPricing: action.payload,
      };
    }
    case 'SET_PL_MT_PRICINGS': {
      return {
        ...state,
        plMtPricings: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
