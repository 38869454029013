import axios from 'axios';
import theme from './index';
import { appConfig } from '../config';
import { Appearance, CustomAppearance, NO_CUSTOM_APPEARANCE } from '../types/appearance';
import { Theme } from '@mui/material';

/*
  FORMAT_VERSION is used for versioning appearance format
  It allows the possibility to ensure backward compatibility with major changes

  When we add more properties we don't need to bump the version,
  it's necessary when we make incompatible changes in the format.
*/
const FIRST_FORMAT_VERSION = 1;

type AppearanceProperties = {
  readonly version: number;
  readonly properties: {
    readonly appBarColor: string;
    readonly primaryMainColor: string;
    readonly primaryLightColor: string;
    readonly primaryDarkColor: string;
    readonly secondaryMainColor: string;
    readonly secondaryLightColor: string;
    readonly secondaryDarkColor: string;
  };
};

const mapResponseToAppearance = (response: AppearanceProperties): CustomAppearance => {
  const { version, properties } = response;

  if (version === FIRST_FORMAT_VERSION) {
    return {
      appBarColor: properties.appBarColor,
      primaryMainColor: properties.primaryMainColor,
      primaryLightColor: properties.primaryLightColor,
      primaryDarkColor: properties.primaryDarkColor,
      secondaryMainColor: properties.secondaryMainColor,
      secondaryLightColor: properties.secondaryLightColor,
      secondaryDarkColor: properties.secondaryDarkColor,
    };
  }

  throw Error(`Unsupported appearance format version: ${version}`);
};

const mapAppearanceToRequest = (appearance: CustomAppearance): AppearanceProperties => ({
  version: FIRST_FORMAT_VERSION,
  properties: {
    appBarColor: appearance.appBarColor,
    primaryMainColor: appearance.primaryMainColor,
    primaryLightColor: appearance.primaryLightColor,
    primaryDarkColor: appearance.primaryDarkColor,
    secondaryMainColor: appearance.secondaryMainColor,
    secondaryLightColor: appearance.secondaryLightColor,
    secondaryDarkColor: appearance.secondaryDarkColor,
  },
});

const getAuthHeader = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

const fetchAppearance = async (appearanceLink: string): Promise<Appearance> => {
  try {
    const response = await axios.get(appearanceLink, getAuthHeader());

    return mapResponseToAppearance(response.data);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response && err.response.status === 404) {
      return NO_CUSTOM_APPEARANCE;
    }

    throw err;
  }
};

const fetchAppearanceByResellerId = (resellerId: number): Promise<Appearance> => (
  fetchAppearance(`${appConfig.URL_REST}resellers/${resellerId}/branding/appearance`)
);

const updateAppearance = async (appearanceLink: string, appearance: CustomAppearance): Promise<Appearance> => {
  const request = mapAppearanceToRequest(appearance);

  const response = await axios.put(appearanceLink, request, getAuthHeader());

  return mapResponseToAppearance(response.data);
};

const resetAppearance = (appearanceLink: string): Promise<void> => (
  axios.delete(appearanceLink, getAuthHeader())
);

const getDefaultTheme = (): Theme => theme;

const getDefaultAppearance = (): CustomAppearance => {
  const { palette } = getDefaultTheme();

  return {
    appBarColor: palette.custom.appBarColor,
    primaryMainColor: palette.primary.main,
    primaryLightColor: palette.primary.light,
    primaryDarkColor: palette.primary.dark,
    secondaryMainColor: palette.secondary.main,
    secondaryLightColor: palette.secondary.light,
    secondaryDarkColor: palette.secondary.dark,
  };
};

const mergeAppearanceIntoTheme = (appearance: CustomAppearance): Theme => {
  const defaultTheme = getDefaultTheme();

  return {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        ...defaultTheme.palette.primary,
        main: appearance.primaryMainColor,
        light: appearance.primaryLightColor,
        dark: appearance.primaryDarkColor,
      },
      secondary: {
        ...defaultTheme.palette.secondary,
        main: appearance.secondaryMainColor,
        light: appearance.secondaryLightColor,
        dark: appearance.secondaryDarkColor,
      },
      custom: {
        ...defaultTheme.palette.custom,
        appBarColor: appearance.appBarColor,
      },
    },
  };
};

export {
  fetchAppearance,
  fetchAppearanceByResellerId,
  updateAppearance,
  resetAppearance,
  getDefaultTheme,
  getDefaultAppearance,
  mergeAppearanceIntoTheme,
};
