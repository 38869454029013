import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';
import { doLogout } from '../../actions/login';
import { Theme } from '@mui/material';
import { AppState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  }
}));

type DialogProps = {
  readonly children: React.ReactNode;
  readonly onClose: () => void;
};

const DialogTitle = (props: DialogProps) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    // @ts-expect-error TODO: no property disableTypography
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" color="primary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

type Props = {
  readonly showTestAccountExpiredScreen: boolean;
  readonly doLogout: () => void;
};

const TestAccountExpiredDialog = ({
  showTestAccountExpiredScreen,
  doLogout,
}: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    doLogout();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={showTestAccountExpiredScreen}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle onClose={handleClose}>
        <FormattedMessage id="testAccount.expired.dialog.account.expired.title" />
      </DialogTitle>
      <MuiDialogContent dividers className={classes.dialogContent}>
        <Typography variant={'body1'} align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.expired.dialog.account.expired" />
        </Typography>

        <Typography variant={'body1'} align="justify" style={{ marginTop: 25 }}>
          <FormattedMessage id="testAccount.expired.dialog.extend.trial" />
          <br />
          <Link href="mailto: help@telxira.com">
            <FormattedMessage id="testAccount.expired.dialog.contact.email" />
          </Link>
          <FormattedMessage id="testAccount.expired.dialog.contact.or" />
          <FormattedMessage id="testAccount.expired.dialog.contact.mobile.number" />
        </Typography>
      </MuiDialogContent>
      <MuiDialogActions className={classes.dialogActions}>
        <Button size="small" onClick={handleClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

export default connect(
  (state: AppState) => {
    return {
      showTestAccountExpiredScreen:
        state.testAccount.showTestAccountExpiredScreen,
    };
  },
  { doLogout }
)(TestAccountExpiredDialog);
