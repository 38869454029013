import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { FormattedMessage } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux';
import HelpDrawer from './help/HelpDrawer';
import Hidden from '@mui/material/Hidden';
import { doLogout, getAccountInfo } from '../actions/login';
import { goToChangePassword } from '../actions/changePassword';
import goToProfile from '../actions/profile';
import theme from '../theme';
import { grey } from '@mui/material/colors';
import { useWithWidthDown } from './common/useWithWidth';
import { AgentStatusBadge } from './common/AgentStatusBadge';
import Popover from '@mui/material/Popover';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getAgents, setAgentStatus } from '../actions/chat';
import { AppState } from '../reducers';

type Props = {
  readonly agent?: boolean;
  readonly status?: string;
  readonly userName: string;
  readonly logout: () => void;
  readonly getAgents: () => void;
  readonly profilePage: () => void;
  readonly changePassword: () => void;
  readonly getAccountInfo: () => void;
  readonly setAgentStatus: (status: string) => void;
};

const UserInfo = ({
  logout,
  changePassword,
  userName,
  profilePage,
  agent,
  status,
  getAgents,
  setAgentStatus,
  getAccountInfo,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const handleMenu = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetStatus = (status: string) => (event: $TSFixMe) => {
    setAgentStatus(status);
    setStatusAnchorEl(null);
    getAccountInfo();
    setTimeout(
      () =>
        getAgents(),
      100
    );
  }

  const handleStatusOpen = (event: $TSFixMe) => {
    setStatusAnchorEl(event.currentTarget);
  }

  const isWidthDownSm = useWithWidthDown('sm');

  const statusColor = () => {
    if (status === 'online') {
      return 'online';
    } else if (status === 'away') {
      return 'away';
    } else if (status === 'offline') {
      return 'offline';
    } else {
      return 'unknown';
    }
  };

  return (
    <React.Fragment>
      <HelpDrawer />
      <ButtonBase
        onClick={handleMenu}
        style={{ color: grey[200], padding: 5 }}
        id="btn-user-menu"
      >
        <AgentStatusBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          style={{ marginRight: 10 }}
          variant="dot"
          invisible={!agent}
          // @ts-expect-error TODO: unassignable type of color
          color={statusColor()}
        >
          <Avatar
            style={{
              width: 28,
              height: 28,
              backgroundColor: theme.palette.custom.accentColor,
            }}
          ></Avatar>
        </AgentStatusBadge>
        <Hidden smDown>
          <Typography variant={'button'}>{userName.toUpperCase()}</Typography>
        </Hidden>
      </ButtonBase>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {isWidthDownSm && <MenuItem disabled>{userName}</MenuItem>}
        {agent &&
          <MenuItem onClick={handleStatusOpen} id="menu-user-set-status">
            <FormattedMessage id="dialog.user.info.menu.set.status" />
            <KeyboardArrowRightIcon style={{ marginLeft: 40 }} />
          </MenuItem>
        }
        <MenuItem onClick={profilePage} id="menu-user-go-to-profile">
          <FormattedMessage id="nav.account.profile" />
        </MenuItem>
        <MenuItem onClick={changePassword} id="menu-user-change-password">
          <FormattedMessage id="dialog.user.info.menu.button.change.password" />
        </MenuItem>
        <MenuItem onClick={logout} id="menu-user-logout">
          <FormattedMessage id="dialog.user.info.menu.button.logout" />
        </MenuItem>
      </Menu>
      <Popover
        open={Boolean(statusAnchorEl)}
        anchorEl={statusAnchorEl}
        onClose={() => setStatusAnchorEl(null)}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleSetStatus('online')} id="user-agent-status-online">
          <FormattedMessage id="dialog.user.info.menu.set.status.online" />
        </MenuItem>
        <MenuItem onClick={handleSetStatus('away')} id="user-agent-status-away">
          <FormattedMessage id="dialog.user.info.menu.set.status.away" />
        </MenuItem>
        <MenuItem onClick={handleSetStatus('offline')} id="user-agent-status-offline">
          <FormattedMessage id="dialog.user.info.menu.set.status.offline" />
        </MenuItem>
      </Popover>
    </React.Fragment>
  );
}

export default connect(
  (store: AppState) => ({
    userName: store.login.userData.userName,
    agent: store.login.userData.chatAgent,
    status: store.login.userData.chatAgentStatus,
  }),
  {
    logout: doLogout,
    changePassword: goToChangePassword,
    profilePage: goToProfile,
    getAgents,
    setAgentStatus,
    getAccountInfo,
  }
)(UserInfo);
