import { appConfig } from '../../config';
import { HttpClient } from '../common/httpClient';
import { AxiosHttpClient } from '../common/axiosHttpClient';
import { ContactApi } from './contactApi';

class BackendApi {
  readonly contacts: ContactApi;

  constructor() {
    const httpClient = this.initHttpClient();

    this.contacts = new ContactApi(httpClient);
  }

  private initHttpClient(): HttpClient {
    return new AxiosHttpClient({
      baseUrl: appConfig.URL_REST,
      accessToken: () => localStorage.getItem('token')!,
    });
  }
}

export default new BackendApi();
