/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminCustomers = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/administration/customers" size="small" color="secondary">
            <FormattedMessage id="nav.administration.customers" />
          </Button>
        ) : (
          <Typography variant={'subtitle2'}>
            <FormattedMessage id="nav.administration.customers" />
          </Typography>
        )}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.1" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.2" />
        </Typography>
        <p></p>
        {!props.fromDashboard && (
          <>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.3" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.4" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.5" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.6" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.7" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.8" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.9" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.10" />
            </Typography>
          </>
        )}
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.11" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.12" />
        </Typography>
        <p></p>
        <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.13" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.14" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.15" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.16" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.17" />
          </Typography>
        </div>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.18" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.19" />
        </Typography>
        <p></p>
        {!props.fromDashboard && (
          <>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.20" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.21" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.22" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.23" />
            </Typography>
            <p></p>
            <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.customers.24" />
              </Typography>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.customers.25" />
              </Typography>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.customers.26" />
              </Typography>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.customers.27" />
              </Typography>
            </div>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.28" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.29" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.30" />
            </Typography>
          </>
        )}
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.31" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.32" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.33" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.34" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.35" />
        </Typography>
        <p></p>
        <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.36" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.37" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.38" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.customers.39" />
          </Typography>
        </div>
        <p></p>
        {!props.fromDashboard && (
          <>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.40" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.41" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.42" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.43" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.44" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.45" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.46" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.47" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.48" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.53" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.49" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.50" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.51" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.52" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.54" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.customers.55" />
            </Typography>
          </>
        )}
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.56" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.57" />
        </Typography>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.customers.58" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.59" />
        </Typography>
        <p></p>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.administration.customers.60" />
        </Typography>
      </div>
    </div>
  );
};

export default HelpAdminCustomers;
