import { HttpClient } from '../common/httpClient';
import { ViberBalanceOverview, ViberMessagesStats, ViberMonthlyBalanceOverview } from '../../types/viber/viberStatistics';

export type ViberBalanceOverviewResponse = {
  readonly allMessagesCount: number;
  readonly balance: number;
  readonly dailyStats: { [key: string]: number };
  readonly promotionalMessagesCount: number;
  readonly revenue: number;
  readonly totalUsage: number;
  readonly transactionalMessagesCount: number;
};

type ViberMessagesStatsResponse = {
  readonly count: number;
};

export type ViberMonthlyBalanceOverviewResponse = {
  readonly monthlyCount: { [key: number]: number };
};

export class ViberStatisticsApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToViberBalanceOverview(response: ViberBalanceOverviewResponse): ViberBalanceOverview {
    return {
      allMessagesCount: response.allMessagesCount,
      balance: response.balance,
      dailyStats: response.dailyStats,
      promotionalMessagesCount: response.promotionalMessagesCount,
      revenue: response.revenue,
      totalUsage: response.totalUsage,
      transactionalMessagesCount: response.transactionalMessagesCount,
    };
  }

  private mapToViberMessagesStats(response: ViberMessagesStatsResponse): ViberMessagesStats {
    return {
      count: response.count,
    };
  }

  private mapToViberMonthlyBalanceOverview(response: ViberMonthlyBalanceOverviewResponse): ViberMonthlyBalanceOverview {
    return {
      monthlyCount: response.monthlyCount,
    };
  }

  getUserBalanceOverview(tenantId: string, from: string, to: string): Promise<ViberBalanceOverview> {
    return this.httpClient.get<ViberBalanceOverviewResponse>(`${tenantId}/statistics/user-balance-overview?from=${from}&to=${to}`)
      .then(data => this.mapToViberBalanceOverview(data));
  }

  getUserMonthlyStats(tenantId: string, year: number): Promise<ViberMonthlyBalanceOverview> {
    return this.httpClient.get<ViberMonthlyBalanceOverviewResponse>(`${tenantId}/statistics/user-monthly-count?year=${year}`)
      .then(data => this.mapToViberMonthlyBalanceOverview(data));
  }

  getUserMessagesStats(tenantId: string): Promise<ViberMessagesStats> {
    return this.httpClient.get<ViberMessagesStatsResponse>(`tenants/${tenantId}/statistics/messages`)
      .then(data => this.mapToViberMessagesStats(data));
  }

  getAdminBalanceOverview(from: string, to: string, appId: string): Promise<ViberBalanceOverview> {
    return this.httpClient.get<ViberBalanceOverviewResponse>(`statistics/user-balance-overview?from=${from}&to=${to}&appId=${appId}`)
      .then(data => this.mapToViberBalanceOverview(data));
  }

  getAdminMonthlyStats(year: number, appId: string): Promise<ViberMonthlyBalanceOverview> {
    return this.httpClient.get<ViberMonthlyBalanceOverviewResponse>(`statistics/user-monthly-count?year=${year}&appId=${appId}`)
      .then(data => this.mapToViberMonthlyBalanceOverview(data));
  }

}
