import { HttpClient } from '../common/httpClient';
import { MetaFileId } from '../../types/meta/metaFile';

type UploadMediaResponse = {
  readonly fileId: string;
};

export class MetaFileApi {
  constructor(private readonly httpClient: HttpClient) {}

  upload(accountId: string, file: File): Promise<MetaFileId> {
    const jsonFile = new FormData();
    jsonFile.append('files', file);

    return this.httpClient.post<UploadMediaResponse>(`${accountId}/upload/media`, jsonFile)
      .then(data => data.fileId);
  }
}
