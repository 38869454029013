import React from 'react';
import axios from 'axios';
import { push } from 'react-router-redux';
import { FormattedMessage } from 'react-intl';
import { appConfig } from '../config';
import { getMenu } from './app';
import { AppThunkAction } from './index';

export function setPassword(hashCode: $TSFixMe, password: $TSFixMe, code: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_START' });
    const url = `${appConfig.URL_REST}users/new`;
    const payload = {
      newPassword: password,
      emailCode: hashCode,
      smsCode: code ? code : undefined,
    };
    axios
      .post(url, payload)
      .then(() => {
        // eslint-disable-line
        dispatch({ type: 'LOGIN_STOP' });
        dispatch({
          type: 'ADD_SNACKBAR',
          variant: 'success',
          msg: <FormattedMessage id="login.password.set.success" />,
        });
        dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
        dispatch(push('/login'));
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'LOGIN_STOP' });
        dispatch({
          type: 'ADD_SNACKBAR',
          variant: 'error',
          msg: <FormattedMessage id="common.label.error" />,
        });
        dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
      });
  };
}

export function changePassword(password: $TSFixMe, newPassword: $TSFixMe): AppThunkAction {
  const data = {
    newPassword,
    oldPassword: password,
  };

  if (newPassword && password) {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
      dispatch({ type: 'LOGIN_START' });
      const url = `${appConfig.URL_REST}user/change-password`;
      axios
        .post(url, data, config)
        .then((response) => {
          // eslint-disable-line
          if (!response.data) {
            dispatch({ type: 'LOGIN_STOP' });
            return dispatch({
              type: 'CHANGE_PW_FAILED',
              payload: { message: 'wrongPW' },
            });
          }
          dispatch({
            type: 'ADD_SNACKBAR',
            variant: 'success',
            msg: <FormattedMessage id="login.password.changed.success" />,
          });
          dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
          dispatch({ type: 'LOGIN_STOP' });
          dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
          dispatch(getMenu());
          dispatch(push('/dashboard'));
        })
        .catch((err) => {
          if (err.response === 'undefined') {
            dispatch({
              type: 'LOGIN_FAILED',
              payload: {
                message: 'undefinedResponse',
              },
            });
          } else if (!err.response) {
            dispatch({
              type: 'LOGIN_FAILED',
              payload: { ...data, message: 'is false' },
            });
          } else {
            dispatch({
              type: 'LOGIN_FAILED',
              payload: { message: err.response.data.message },
            });
          }
        });
    };
  }

  return {
    // @ts-expect-error TODO: this action is not dispatched - to verify!
    type: 'CHANGE_PW_FAILED',
    payload: {
      message: 'EmptyPW',
    },
  };
}

export function goToChangePassword(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'CHANGE_PW_FAILED', payload: { message: '' } });
    dispatch(push('/account/changePassword'));
  };
}

export function goToDashboard(): AppThunkAction {
  return (dispatch) => {
    dispatch(push('/dashboard'));
  };
}

export function goToResetPassword(): AppThunkAction {
  return (dispatch) => {
    dispatch(push('/account/resetPassword'));
  };
}

export function goToLogin(): AppThunkAction {
  return (dispatch) => {
    dispatch(push('/login'));
  };
}
