import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const HelpContacts = () => {
  return (
    <div style={{ padding: 5, marginBottom: 15 }}>
      <Typography style={{ marginBottom: 15 }} variant="body2" component="div">
        <FormattedMessage id="help.contact.1" />
      </Typography>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.contact.2" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="body2" align="justify">
          <FormattedMessage id="help.contact.2.1" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.contact.2.2" />
          </b>
          <FormattedMessage id="help.contact.2.3" />
          <br />
          <b>
            <FormattedMessage id="help.contact.2.4" />
          </b>
          <FormattedMessage id="help.contact.2.5" />
          <br />
          <b>
            <FormattedMessage id="help.contact.2.6" />
          </b>
          <FormattedMessage id="help.contact.2.7" />
          <br />
          <b>
            <FormattedMessage id="help.contact.2.8" />
          </b>
          <FormattedMessage id="help.contact.2.9" />
          <br />
        </Typography>
      </div>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.contact.3" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.3.1" />
        </Typography>
      </div>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.contact.4" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <b>
            <FormattedMessage id="help.contact.4.1" />
          </b>
          <FormattedMessage id="help.contact.4.2" />
          <br />
          <br />
          <b>
            <FormattedMessage id="help.contact.4.3" />
          </b>
          <FormattedMessage id="help.contact.4.4" />
        </Typography>
      </div>
      <Typography variant="subtitle2" style={{ marginTop: 20, fontWeight: 700 }}>
        <FormattedMessage id="help.contact.5" />
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
        <Typography variant="body2">
          <FormattedMessage id="help.contact.5.1" />
        </Typography>
      </div>

    </div>
  );
};

export default HelpContacts;
