import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppBarForm from '../common/AppBarForm';
import TestAccountNumbersFormRows from './TestAccountNumbersFormRows';

type Props = {
  readonly onClose: () => void;
};

const TestAccountDrawer = ({ onClose }: Props) => {
  return (
    <React.Fragment>
      <AppBarForm
        label={<FormattedMessage id="testAccount.drawer.form.label.test.account" />}
        toggleDrawer={onClose}
        position="sticky"
      />
      <div style={{ padding: '20px' }}>
        <TestAccountNumbersFormRows />
      </div>
    </React.Fragment>
  );
};
export default TestAccountDrawer;
