import React from 'react';
import { FormattedMessage } from 'react-intl';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { MessageLog } from '../../../../types/messageLog';

// eslint-disable-next-line
const columns = (smsStatusFilterOptions: $TSFixMe[]) => {
  return [
    {
      id: 'createDateTime',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.sent" />,
      allowSort: true,
      visible: true,
      allowFilter: true,
      dateFiltering: true,
      timeFiltering: true,
      noWrap: true,
    },
    {
      id: 'emailMsgId',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.email.msg.id" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'notifyMail',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.email.sender" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'sender',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.sms.sender" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'receiver',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.receiver" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'numParts',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.parts" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
    },
    {
      id: 'lastStatus',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.last.status" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
      filterOptions: smsStatusFilterOptions,
      render: (row: MessageLog) => (
        <Tooltip title={row['lastStatus']} placement="top-start">
          <Chip label={row['lastStatus']} variant="outlined" style={{ height: 20 }} />
        </Tooltip>
      ),
    },
    {
      id: 'lastStatusTime',
      numeric: false,
      disablePadding: false,
      label: <FormattedMessage id="email2sms.ubs.messageLogs.table.status.time" />,
      allowSort: false,
      visible: true,
      allowFilter: true,
      dateFiltering: true,
      timeFiltering: true,
      noWrap: true,
    },
  ];
};

export default columns;
