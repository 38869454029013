import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, PreloadedState } from 'redux';
import { routerMiddleware } from 'connected-react-router';
// @ts-expect-error Problem with incorrect types - open issue: https://github.com/elgerlambert/redux-localstorage/issues/78
import persistState from 'redux-localstorage';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { AppState } from './reducers';

export const history = createBrowserHistory();

const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  const { createLogger } = require('redux-logger'); // eslint-disable-line
  middleware.push(
    createLogger({
      collapsed: true,
      diff: true,
    })
  );
}

const enhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  persistState(['locale', 'routing', 'app', 'login', 'testAccount', 'appearance'])
);

export default function configureStore(preloadedState?: PreloadedState<AppState>) {
  const store = createStore(
    rootReducer(history), // root reducer with router state
    preloadedState,
    enhancer
  );

  return store;
}
