import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import green from '../../../node_modules/@mui/material/colors/green';
import Visibility from '../../../node_modules/@mui/icons-material/Visibility';
import VisibilityOff from '../../../node_modules/@mui/icons-material/VisibilityOff';
import { changePassword, goToDashboard } from '../../actions/changePassword';
import BaseLoginView from '../common/logins/BaseLoginView';
import { isAuthenticated } from '../../actions/login';
import { useWithWidthUp } from '../common/useWithWidth';
import { AppState } from '../../reducers';
import validate from './PasswordValidator';
import { Field, SubmissionError, formValueSelector, reduxForm } from 'redux-form';
import { AppReduxFormProps, AppReduxFormSubmit, AppReduxFormValidator } from '../common/types/appReduxForm';
import { IntlProps } from '../common/types/intlProps';
import RenderTextField from '../common/reduxForm/RenderTextField';
import { isEmpty } from 'lodash-es';

const selector = formValueSelector('changePasswordForm');

const useStyles = makeStyles((theme: Theme) => ({
  messageError: {
    color: 'red',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}));

type Props = {
  readonly userName?: string;
  readonly isLoading?: boolean;
  readonly password?: string;
  readonly newPassword: string;
  readonly loginData: { failed: boolean, userData: { message?: string } };
  readonly goToDashboard?: () => void;
  readonly changePassword?: (password: string, newPassword: string) => void;
} & IntlProps;

type FormValues = $TSFixMe;
type FormProps = AppReduxFormProps<FormValues, Props>;
export type ChangePasswordFormValidator = AppReduxFormValidator<FormValues, Props>;
export type ChangePasswordFormSubmit = AppReduxFormSubmit<FormValues, Props>;

const ChangePasswordForm = (props: FormProps) => {
  const [showPassword, setShowPassword] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(true);

  useEffect(() => {
    isAuthenticated();
  }, []);

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const pressEnter = (event: $TSFixMe) => {
    if (event.charCode === 13) {
      // @ts-expect-error Expected 3 arguments, but got 1
      submitPassword(newPassword);
    }
  };

  const submitPassword: ChangePasswordFormSubmit = (values) => {
    const { changePassword } = props;
    const errors = validate(values, props);
    if (!isEmpty(errors)) return Promise.reject(new SubmissionError(errors));
    changePassword?.(password!, newPassword);
  };

  const cancel = () => {
    const { goToDashboard } = props;
    goToDashboard?.();
  };

  const { isLoading, loginData, userName, password, newPassword, handleSubmit } = props;

  const classes = useStyles();
  const isWidthUpMd = useWithWidthUp('md');
  const spacing = isWidthUpMd ? 3 : 1;

  const content = (
    <form onSubmit={handleSubmit(submitPassword)} autoComplete="off" noValidate>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Fade in={loginData.failed}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
              {(loginData.userData.message === 'wrongPW' && (
                <Typography className={classes.messageError}>
                  <FormattedMessage id="dialog.change.password.info.wrong.password" />
                </Typography>
              )) ||
                (loginData.userData.message === 'EmptyPW' && (
                  <Typography className={classes.messageError}>
                    <FormattedMessage id="dialog.change.password.info.empty.password" />
                  </Typography>
                )) ||
                (loginData.userData.message === 'undefinedResponse' && (
                  <Typography className={classes.messageError}>
                    <FormattedMessage id="data.fetching.bad.request" />
                  </Typography>
                )) || <Typography variant="caption">&nbsp;</Typography>}
            </Grid>
          </Fade>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={RenderTextField}
            name="password"
            label={
              <FormattedMessage id="dialog.change.password.label.current.pass" />
            }
            type={showOldPassword ? 'password' : 'text'}
            autoComplete="current-password"
            fullWidth
            required
            variant="standard"
            onKeyPress={pressEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={RenderTextField}
            name="newPassword"
            label={
              <FormattedMessage id="dialog.change.set.password.label.new.pass" />
            }
            type={showPassword ? 'password' : 'text'}
            autoComplete="current-password"
            fullWidth
            required
            variant="standard"
            onKeyPress={pressEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ alignItems: 'center', display: 'flex', marginTop: 20 }}>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading || !password || !newPassword}
              color="secondary"
            >
              <FormattedMessage id="dialog.change.password.button.submit.change" />
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={cancel}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage id="common.button.cancel" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <BaseLoginView
      title={
        <FormattedMessage id="dialog.change.password.button.change.password" />
      }
      subheader={userName}
      content={content}
    />
  );
}

const ChangePasswordReduxForm = injectIntl(
  reduxForm<FormValues, Props, string[]>({
    form: 'changePasswordForm',
    destroyOnUnmount: true,
    onSubmitSuccess: (result, dispatch, props) => {
    },
  })(ChangePasswordForm));

export default connect(
  (store: AppState) => ({
    isLoading: store.login.isLoading,
    loginData: store.login,
    userName: store.login.userData.userName,
    password: selector(store, 'password') || '',
    newPassword: selector(store, 'newPassword') || '',
  }),
  {
    changePassword,
    goToDashboard,
  }
)(ChangePasswordReduxForm);
