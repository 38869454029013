import { AnyAction } from 'redux';
import { Reseller } from '../../types/reseller';

interface ResellersState {
  resellers: Reseller[];
  selectedReseller: Partial<Reseller>;
  resellersFormLoading: boolean;
  resellersLoading: boolean;
  tab: number;
  logo: $TSFixMe;
  channelPartnerIds: number[];
}

const initialState: ResellersState = {
  resellers: [],
  selectedReseller: {},
  resellersFormLoading: false,
  resellersLoading: false,
  tab: 0,
  logo: {},
  channelPartnerIds: [],
};

export default function reducer(state: ResellersState = initialState, action: AnyAction): ResellersState {
  switch (action.type) {
    case 'SET_RESELLERS_LOADING': {
      return {
        ...state,
        resellersLoading: action.payload,
      };
    }
    case 'SET_RESELLERS': {
      return {
        ...state,
        resellers: action.payload,
      };
    }
    case 'SET_RESELLER_FORM_LOADING': {
      return {
        ...state,
        resellersFormLoading: action.payload,
      };
    }
    case 'ADD_RESELLER': {
      return {
        ...state,
        resellers: [...state.resellers, action.payload],
      };
    }
    case 'REMOVE_RESELLER': {
      return {
        ...state,
        resellers: state.resellers.filter((item) => item.id !== action.payload),
      };
    }
    case 'SET_RESELLER_FOR_FORM': {
      return {
        ...state,
        selectedReseller: action.payload,
      };
    }
    case 'SET_RESELLERS_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'SET_CHANNEL_PARTNER_IDS': {
      return {
        ...state,
        channelPartnerIds: action.payload,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}
