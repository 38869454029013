import { WhatsappAccountConfig } from '../../types/meta/metaAccount';
import { HttpClient } from '../common/httpClient';

type WhatsappAccountConfigResponse = {
  readonly featureFlags: {
    readonly ACTIVE: boolean;
    readonly CAMPAIGN: boolean;
    readonly ONE_TO_ONE: boolean;
  }
};

export class WhatsappConfigApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToWhatsappFeatureFlags(response: WhatsappAccountConfigResponse): WhatsappAccountConfig {
    return {
      featureFlags: {
        ACTIVE: response.featureFlags.ACTIVE,
        CAMPAIGN: response.featureFlags.CAMPAIGN,
        ONE_TO_ONE: response.featureFlags.ONE_TO_ONE,
      }
    };
  }

  getUserConfig(tenantId: string): Promise<WhatsappAccountConfig> {
    return this.httpClient.get<WhatsappAccountConfigResponse>(`tenants/${tenantId}/config`)
      .then(data => this.mapToWhatsappFeatureFlags(data));
  }
}
