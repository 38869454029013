/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpChat = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/smschat" size="small" color="secondary">
            <FormattedMessage id="nav.smschat" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify" style={{ fontWeight: 700 }}>
          <FormattedMessage id="help.smschat.2" />
        </Typography>
        <Typography variant="subtitle2" align="justify" style={{ marginTop: 15, fontWeight: 700 }}>
          <FormattedMessage id="help.smschat.3" />
        </Typography>
        <Typography variant="body2" align="justify">
          <React.Fragment>
            <div style={{ margin: '0 12px' }}>
              <br />
              <b>
                <FormattedMessage id="help.smschat.3.1" />
              </b>
              <FormattedMessage id="help.smschat.3.2" />
              <br />
              <b>
                <FormattedMessage id="help.smschat.3.3" />
              </b>
              <FormattedMessage id="help.smschat.3.4" />
              <br />
              <b>
                <FormattedMessage id="help.smschat.3.5" />
              </b>
              <FormattedMessage id="help.smschat.3.6" />
              <br />
              <b>
                <FormattedMessage id="help.smschat.3.7" />
              </b>
              <FormattedMessage id="help.smschat.3.8" />
            </div>
            <br />
            <b>
              <FormattedMessage id="help.smschat.4" />
            </b>
            <br />
            <FormattedMessage id="help.smschat.4.1" />
            <div style={{ margin: '0 12px' }}>
              <br />
              <b>
                <FormattedMessage id="help.smschat.4.2" />
              </b>
              <FormattedMessage id="help.smschat.4.3" />
              <br />
              <br />
              <b>
                <FormattedMessage id="help.smschat.4.4" />
              </b>
              <FormattedMessage id="help.smschat.4.5" />
            </div>
            <br />
            <b>
              <FormattedMessage id="help.smschat.5" />
            </b>
            <br />
            <FormattedMessage id="help.smschat.5.1" />
            <br />
            <br />
            <b>
              <FormattedMessage id="help.smschat.6" />
            </b>
            <br />
            <br />
            <div style={{ margin: '0 12px' }}>
              <b>
                <FormattedMessage id="help.smschat.6.1" />
              </b>
              <br />
              <FormattedMessage id="help.smschat.6.2" />
              <br />
              <FormattedMessage id="help.smschat.6.3" />
              <br />
              <FormattedMessage id="help.smschat.6.4" />
              <br />
              <br />
              <b>
                <FormattedMessage id="help.smschat.6.5" />
              </b>
              <br />
              <FormattedMessage id="help.smschat.6.6" />
              <br />
              <br />
              <b>
                <FormattedMessage id="help.smschat.6.7" />
              </b>
              <br />
              <FormattedMessage id="help.smschat.6.8" />
              <br />
              <FormattedMessage id="help.smschat.6.9" />
              <br />
              <FormattedMessage id="help.smschat.6.10" />
              <br />
              <FormattedMessage id="help.smschat.6.11" />
              <br />
            </div>
            <br />
            <b>
              <FormattedMessage id="help.smschat.7" />
            </b>
            <br />
            <FormattedMessage id="help.smschat.7.1" />
            <br />
            <FormattedMessage id="help.smschat.7.2" />
            <br />
            <FormattedMessage id="help.smschat.7.3" />
            <br />
            <FormattedMessage id="help.smschat.7.4" />
            <br />
            <br />
            <b>
              <FormattedMessage id="help.smschat.8" />
            </b>
            <br />
            <FormattedMessage id="help.smschat.8.1" />
            <br />
            <br />
            {/* <b>
              <FormattedMessage id="help.smschat.9" />
            </b>
            <br />
            <div style={{ margin: '0 12px' }}>
              <FormattedMessage id="help.smschat.9.1" />
              <br />
              <FormattedMessage id="help.smschat.9.2" />
              <br />
              <FormattedMessage id="help.smschat.9.3" />
              <br />
              <FormattedMessage id="help.smschat.9.4" />
              <br />
              <FormattedMessage id="help.smschat.9.5" />
              <br />
            </div> */}
            <br />
            {/* <b>
              <FormattedMessage id="help.smschat.10" />
            </b>
            <div style={{ margin: '0 12px' }}>
              <b>
                <FormattedMessage id="help.smschat.10.1" />
              </b>
              <ul>
                <li><FormattedMessage id="help.smschat.10.2" /></li>
                <li><FormattedMessage id="help.smschat.10.3" /></li>
              </ul>
              <b>
                <FormattedMessage id="help.smschat.10.4" />
              </b>
              <ul>
                <li><FormattedMessage id="help.smschat.10.5" /></li>
                <li><FormattedMessage id="help.smschat.10.6" /></li>
              </ul>
              <b>
                <FormattedMessage id="help.smschat.10.7" />
              </b>
              <div style={{ margin: '0 12px' }}>
                <FormattedMessage id="help.smschat.10.8" />
                <ul>
                  <li><FormattedMessage id="help.smschat.10.9" /></li>
                  <li><FormattedMessage id="help.smschat.10.10" /></li>
                </ul>
              </div>
            </div> */}
          </React.Fragment>
        </Typography>
      </div>
    </div>
  );
};

export default HelpChat;
