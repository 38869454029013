import { AnyAction } from 'redux';

interface UbsSettings {
  readonly defaultSender: string;
  readonly subject: string;
  readonly spamPeriod: number;
  readonly spamBlockedPeriod: number;
  readonly maxMsg: number;
  readonly maxSameMsg: number;
  readonly defaultEmail: string;
}

interface UbsSettingsState {
  settings: UbsSettings;
}

const initialState: UbsSettingsState = {
  settings: {
    defaultSender: "1",
    subject: "Email2Sms message confirmation",
    spamPeriod: 1,
    spamBlockedPeriod: 10,
    maxMsg: 6,
    maxSameMsg: 2,
    defaultEmail: "sh-test-wmsb-2010@ubs.com",
  },
};

export default function reducer(state: UbsSettingsState = initialState, action: AnyAction): UbsSettingsState {
  switch (action.type) {
    case "UPDATE_SETTINGS_ENTRY": {
      return {
        ...state,
        settings: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
