import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import { appConfig } from '../../config';
import axios from 'axios';
import { extractIdFromHref, getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';

export function setOptOuts(items: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_OPTOUT_ENTRIES', payload: items });
  };
}

export function setOptOutFormOpen(open: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: "SET_FORM_OPEN", payload: open });
  };
}

export function setOptOutEntryForForm(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: "SET_OPTOUT_ENTRY_FOR_FORM", payload: id });
  };
}

export function fetchOptOuts(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_OPTOUT_LOADING', payload: true });
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/opt_out`, config)
      .then((res) => {
        const data = res.data.map((e: $TSFixMe) => {
          return {
            id: extractIdFromHref(e.links.find((link: $TSFixMe) => link.rel === 'self').href),
            number: e.sender,
            info: e.info,
          };
        });
        if (res.status === 200) dispatch(setOptOuts(data));
        dispatch({ type: 'SET_OPTOUT_LOADING', payload: false });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'SET_OPTOUT_LOADING', payload: false });
      });
  };
}

export function addOptOutEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/opt_out`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: 'ADD_OPTOUT_ENTRY',
            payload: {
              id: extractIdFromHref(res.data._links.self.href),
              number: res.data.number,
              info: res.data.info,
            },
          });
          dispatch(fetchOptOuts());
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function removeOptOutEntry(id: number): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .delete(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/opt_out/${id}`, config)
      .then((res) => {
        if (res.status === 200) dispatch({ type: 'REMOVE_OPTOUT_ENTRY', payload: id });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateOptOutEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/opt_out/${item.id}`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'UPDATE_OPTOUT_ENTRY', payload: item });
          dispatch(fetchOptOuts());
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}
