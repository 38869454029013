import { AnyAction } from 'redux';
import { CustomerPlatformUser } from '../../types/customerPlatformUser';

interface PlatformUsersState {
  platformUsers: CustomerPlatformUser[];
  selectedPlatformUser: Partial<CustomerPlatformUser>;
  customerPlatformUsersLoading: boolean;
  platformUsersFormLoading: boolean;
  createUserDialogOpen: boolean;
  customerUsersList: $TSFixMe;
  platformUsersLoading: boolean;
}

const initialState: PlatformUsersState = {
  platformUsers: [],
  customerPlatformUsersLoading: false,
  selectedPlatformUser: {},
  platformUsersFormLoading: false,
  createUserDialogOpen: false,
  customerUsersList: {},
  platformUsersLoading: false,
};

export default function reducer(state: PlatformUsersState = initialState, action: AnyAction): PlatformUsersState {
  switch (action.type) {
    case 'SET_CUSTOMER_USERS_LOADING': {
      return {
        ...state,
        platformUsersLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_USERS': {
      return {
        ...state,
        platformUsers: action.payload,
      };
    }
    case 'SET_CUSTOMER_USER_FORM_LOADING': {
      return {
        ...state,
        platformUsersFormLoading: action.payload,
      };
    }
    case 'ADD_CUSTOMER_USER': {
      return {
        ...state,
        platformUsers: [...state.platformUsers, action.payload],
      };
    }
    case 'REMOVE_CUSTOMER_USER': {
      return {
        ...state,
        platformUsers: state.platformUsers.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_CUSTOMER_USER': {
      const itemToReplace = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        tags: action.payload.tags,
        activePlatformUser: action.payload.activePlatformUser,
        role: action.payload.role,
      };
      return {
        ...state,
        platformUsers: [
          // @ts-expect-error itemToReplace does not contain all CustomerPlatformUser properties - to verify and fix!
          ...state.platformUsers.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerPlatformUser properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_CUSTOMER_USER_FOR_FORM': {
      return {
        ...state,
        selectedPlatformUser: action.payload,
      };
    }
    case 'SET_CREATE_ACTIVE_USER_DIALOG_OPEN': {
      return {
        ...state,
        createUserDialogOpen: action.payload,
      };
    }
    case 'SET_CUSTOMER_USERS_LIST': {
      return {
        ...state,
        customerUsersList: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
