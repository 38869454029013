import { AnyAction } from 'redux';
import { SupportedLang } from '../messages';

interface LocaleState {
  lang: SupportedLang;
}

const initialState: LocaleState = { lang: SupportedLang.EN };

export default function reducer(state: LocaleState = initialState, action: AnyAction): LocaleState {
  switch (action.type) {
    case 'SET_LOCALE': {
      return {
        ...state,
        lang: action.locale,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
