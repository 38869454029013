import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import axios from 'axios';

import { appConfig } from '../../config';
import { parseISO } from 'date-fns';

import { getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';
import { Email2SmsConfig } from '../../types/email2SmsConfig';

export function getEmail2SmsData(): AppThunkAction {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  };
  return (dispatch, getState) => {
    const role = getState().login.userData.authorizations;
    if (!role['SUPER_ADMIN']) return;
    dispatch({ type: 'SET_CUSTOMER_MODEL_LIST_LOADING', payload: true });
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/?size=10000&page=0`, config)
      .then((res) => {
        if (res.data && res.data._embedded) {
          dispatch(
            setCustomerModelList(
              res.data._embedded.customerModelList.map((model: Email2SmsConfig) => {
                const _customer = getState().email2smsConfig.customers.find((c) => c.uuid === model.uuid);
                return {
                  uuid: model.uuid,
                  customer: _customer ? _customer.name : '',
                  sessionEsme: model.sessionEsme,
                  smscId: model.smscId,
                  active: model.active,
                  links: model._links,
                  startDate: model.activeStartDate,
                  endDate: model.activeEndDate,
                  maxNumberOfSms: model.maxNumberOfSms,
                  defaultValidity: model.defaultValidity,
                  activeNow: model.activeNow,
                  rejectTooLongMessage: model.rejectTooLongMessage,
                };
              })
            )
          );
          dispatch({ type: 'SET_CUSTOMER_MODEL_LIST_LOADING', payload: false });
        }
        dispatch({ type: 'SET_CUSTOMER_MODEL_LIST_LOADING', payload: false });
      })
      .catch((error) => {
        dispatch({ type: 'SET_CUSTOMER_MODEL_LIST_LOADING', payload: false });
        console.error(error);
      });
  };
}

function setCustomerModelList(customerModelList: Email2SmsConfig): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_CUSTOMER_MODEL_LIST', payload: customerModelList });
  };
}

export function getCustomersFromBackend(): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}customers?size=10000&page=0&sort=name,asc`, config)
      .then((res) => {
        if (res.status === 200) {
          res.data && res.data._embedded && dispatch({ type: 'SET_CUSTOMERS', payload: res.data._embedded.customers });
          dispatch(getEmail2SmsData());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCustomerFromBackend(url: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(url, config)
      .then((res) => {
        res.data && dispatch({ type: 'SET_CUSTOMER', payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addEmail2SmsConfig(values: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: true });
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.EMAIL2SMS_URL}/customers`, values, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCustomersFromBackend());
          dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message.includes('UUID')) {
          dispatch(
            showSnackBarMsg(<FormattedMessage id="administration.email2sms.config.already.exists" />, 'error')
          );
        };
        if (error.response && error.response.data.message.includes('email address')) {
          dispatch(
            showSnackBarMsg(
              <FormattedMessage
                id="email2sms.ubs.sender.email.config.already.exists"
                values={{ email: error.response.data.message.slice(70) }}
              />,
              'error',
            )
          );
        };
        if (error.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
        dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
        console.log(error);
      });
  };
}

export function editEmail2SmsConfig(values: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: true });
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}`, values, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
          dispatch(getCustomersFromBackend());
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function getParametersForForm(uuid: string): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: true });
    dispatch({ type: 'SET_PARAMETERS_FOR_FORM', payload: {} }); //this is to reset prev data
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${uuid}`, config)
      .then((res) => {
        res.data &&
          dispatch({
            type: 'SET_PARAMETERS_FOR_FORM',
            payload: {
              startDate: parseISO(res.data.activeStartDate),
              endDate: res.data.activeEndDate ? parseISO(res.data.activeEndDate) : null,
              maxNumberOfSms: res.data.maxNumberOfSms,
              defaultValidity: res.data.defaultValidity,
              ...res.data,
            },
          });
        dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_PARAMETERS_FORM_LOADING', payload: false });
      });
  };
}

export function setCustomerUuid(customerUuid: string): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_CUSTOMER_UUID', payload: customerUuid });
  };
}

export function getSmscId(): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/smsc_id`, config)
      .then((res) => {
        res.data && dispatch({ type: 'SET_SMSC_ID', payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function removeEmail2SmsConfig(uuid: string): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .delete(`${appConfig.EMAIL2SMS_URL}/customers/${uuid}`, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'REMOVE_EMAIL2SMS_CONFIG', payload: false });
        }
        dispatch(getEmail2SmsData());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
