import Validator from 'validatorjs';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { SignUpFormValidator } from './SignUpForm';

const validateForm: SignUpFormValidator = (values, props) => {
  const rules = {
    firstName: 'required',
    lastName: 'required',
    email: 'required|email',
    mobilePhone: 'required|isValid|between:8,31',
    tcAcceptance: 'accepted',
  };

  const phoneUtil = PhoneNumberUtil.getInstance();

  Validator.register(
    'isValid',
    // @ts-expect-error TODO: fix after providing type
    (value: $TSFixMe) => {
      if (value.length < 8) return true;
      try {
        const number = phoneUtil.parseAndKeepRawInput(value);
        if (
          phoneUtil.isValidNumber(number) &&
          phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE
        ) {
          return true;
        }
        if (phoneUtil.getNumberType(number) !== PhoneNumberType.MOBILE) return false;
      } catch (error: $TSFixMe) {
        console.log(error.message);
      }
    },
    props.intl.formatMessage({
      id: 'form.validation.mobilePhone.format',
    })
  );

  const validator = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
    required_if: props.intl.formatMessage({ id: 'form.validation.required' }),
    email: props.intl.formatMessage({ id: 'form.validation.email.format' }),
    'between.mobilePhone': props.intl.formatMessage({
      id: 'signup.form.validation.mobile.phone.length',
    }),
    'accepted.tcAcceptance': props.intl.formatMessage({
      id: 'signup.form.validation.terms.accepted',
    }),
  });
  validator.passes();
  return validator.errors.all();
};

export default validateForm;
