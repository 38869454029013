import { AnyAction } from 'redux';
import { SettingsResellerCost } from '../../types/resellerCost';

interface SettingsResellerCostState {
  resellerCostEntries: SettingsResellerCost[];
  selectedResellerCostEntry: $TSFixMe;
  resellerCostEntriesLoading: boolean;
}

const initialState: SettingsResellerCostState = {
  resellerCostEntries: [],
  selectedResellerCostEntry: {},
  resellerCostEntriesLoading: false,
};

export default function reducer(state: SettingsResellerCostState = initialState, action: AnyAction): SettingsResellerCostState {
  switch (action.type) {
    case 'SET_RESELLER_COST_ENTRIES_LOADING': {
      return {
        ...state,
        resellerCostEntriesLoading: action.payload,
      };
    }
    case 'ADD_RESELLER_COST_ENTRY': {
      return {
        ...state,
        resellerCostEntries: [...state.resellerCostEntries, { id: state.resellerCostEntries.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_RESELLER_COST_ENTRY': {
      return {
        ...state,
        resellerCostEntries: state.resellerCostEntries.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_RESELLER_COST_ENTRY': {
      const itemToReplace = {
        feeName: action.payload.feeName,
        cost: action.payload.cost,
        curency: action.payload.currency,
        service: action.payload.service,
        mo: action.payload.mo,
      };
      return {
        ...state,
        resellerCostEntries: [
          // @ts-expect-error itemToReplace does not contain all DestinationDictionary properties - to verify and fix!
          ...state.resellerCostEntries.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all DestinationDictionary properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_RESELLER_COST_ENTRY_FOR_FORM': {
      return {
        ...state,
        selectedResellerCostEntry: action.payload,
      };
    }
    case 'SET_RESELLER_COST_ENTRIES': {
      return {
        ...state,
        resellerCostEntries: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
