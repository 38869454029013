import { HttpClient } from './httpClient';
import axios, { AxiosResponse, Method } from 'axios';

const normalizeBaseUrl = (baseUrl?: string) => (
  baseUrl && baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl
);

const appendBaseUrlToPath = (baseUrl: string, path: string) => (
  path.startsWith('/') ? `${baseUrl}${path}` : `${baseUrl}/${path}`
);

type GetAccessTokenFunc = () => string;
type Headers = { [name: string]: string };

interface Configuration {
  readonly baseUrl?: string;
  readonly headers?: Headers;
  readonly accessToken?: GetAccessTokenFunc;
}

export class AxiosHttpClient implements HttpClient {
  private readonly baseUrl?: string;
  private readonly headers?: Headers;
  private readonly accessToken?: GetAccessTokenFunc;

  constructor(params: Configuration) {
    this.baseUrl = normalizeBaseUrl(params.baseUrl);
    this.headers = params.headers;
    this.accessToken = params.accessToken;
  }

  get<T>(url: string): Promise<T> {
    return this.sendRequest('GET', url);
  }

  post<T>(url: string, body: {}): Promise<T> {
    return this.sendRequest('POST', url, body);
  }

  put<T>(url: string, body: {}): Promise<T> {
    return this.sendRequest('PUT', url, body);
  }

  delete<T>(url: string): Promise<T> {
    return this.sendRequest('DELETE', url);
  }

  private async sendRequest<T>(method: Method, url: string, body?: {}): Promise<T> {
    const requestUrl = this.createRequestUrl(url);
    const requestHeaders = this.createRequestHeaders();

    const response: AxiosResponse<T> = await axios.request({
      method,
      url: requestUrl,
      headers: requestHeaders,
      data: body,
      responseType: 'json',
    });

    return response.data;
  }

  private createRequestUrl(url: string): string {
    return this.baseUrl ? appendBaseUrlToPath(this.baseUrl, url) : url;
  }

  private createRequestHeaders(): {} {
    let requestHeaders = this.headers ? { ...this.headers } : {};

    if (this.accessToken) {
      requestHeaders = {
        ...requestHeaders,
        Authorization: `Bearer ${this.accessToken()}`
      };
    }

    return requestHeaders;
  }
}
