import { HttpClient } from '../common/httpClient';
import {
  ViberConversationId,
  ViberMessage,
  ViberMessageStatus,
  ViberMessageType,
} from '../../types/viber/viberMessage';
import { Page } from '../../types/page';
import { PageResponse } from '../common/pagingModel';
import dateTimeService from '../../service/dateTimeService';
import phoneNumberService from '../../service/phoneNumberService';

type ViberMessageResponse = {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly txt: string;
  readonly phoneNumber: string;
  readonly trackingData: string;
  readonly mediaURL: string;
  readonly thumbnail: string;
  readonly action: string;
  readonly fileName: string;
  readonly messageType: string;
  readonly messageStatus: string;
  readonly statusDetails: string;
  readonly messageDirection: 'outgoing' | 'incoming';
  readonly button?: {
    readonly action?: string;
    readonly caption?: string;
  }
};

type ViberUnreadConversationResponse = {
  readonly targetId: string;
  readonly accountId: string;
};

type ViberUnreadMessagesResponse = {
  readonly targetId: string;
  readonly accountId: string;
  readonly messages: string[];
};

type GetPageByPhoneNumberParams = {
  readonly accountId: string;
  readonly targetId: string;
  readonly page: number;
  readonly size: number;
  readonly sort: 'createdAt';
  readonly direction: 'asc' | 'desc';
};

export class ViberMessageApi {
  constructor(private readonly httpClient: HttpClient) {}

  getPageByTarget(params: GetPageByPhoneNumberParams): Promise<Page<ViberMessage>> {
    const { accountId, targetId, page, size, sort, direction } = params;

    const queryParams = [
      `accountUuid=${accountId}`,
      `page=${page}`,
      `size=${size}`,
      `sort=${sort},${direction}`,
    ].join('&');

    return this.httpClient.get<PageResponse<ViberMessageResponse>>(`/accounts/${accountId}/targets/${targetId}/messages?${queryParams}`)
      .then(data => {
        const { number, size, totalElements, totalPages } = data;
        const content = data.content.map(it => this.mapToViberMessage(it));

        return {
          content,
          pageNumber: number,
          pageSize: size,
          totalElements,
          totalPages,
        };
      });
  }

  getUnreadConversationsIds(tenantId: string): Promise<ViberConversationId[]> {
    return this.httpClient.get<ViberUnreadConversationResponse[]>(`/tenants/${tenantId}/conversations/unread`)
      .then(data => data.map(it => ({
        accountId: it.accountId,
        targetId: it.targetId,
      })));
  }

  getUnreadMessagesIds(accountId: string, targetId: string): Promise<string[]> {
    return this.httpClient.get<ViberUnreadMessagesResponse>(`/accounts/${accountId}/targets/${targetId}/count/unread`)
      .then(data => data.messages);
  }

  private mapToViberMessage(response: ViberMessageResponse): ViberMessage {
    const { button } = response;

    return {
      id: response.id,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
      direction: response.messageDirection,
      type: response.messageType as ViberMessageType,
      status: response.messageStatus as ViberMessageStatus,
      phoneNumber: phoneNumberService.toPhoneNumberWithPlusSign(response.phoneNumber),
      text: response.txt,
      mediaURL: response.mediaURL,
      fileName: response.fileName,
      thumbnail: response.thumbnail,
      statusDetails: response.statusDetails,
      ...((button && button.action && button.caption) && {
        button: {
          action: button.action,
          caption: button.caption,
        },
      }),
    };
  }
}
