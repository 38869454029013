import { AnyAction } from 'redux';
import { Reseller } from '../../types/reseller';
import { ResellerVatRate } from '../../types/resellerVatRate';

interface ResellersVatRatesState {
  vatRates: ResellerVatRate[];
  vatRatesLoading: boolean;
  selectedVatRate: Partial<ResellerVatRate>;
  vatRatesFormLoading: boolean;
  reseller?: Reseller;
}

const initialState: ResellersVatRatesState = {
  vatRates: [],
  vatRatesLoading: false,
  selectedVatRate: {},
  vatRatesFormLoading: false,
};

export default function reducer(state: ResellersVatRatesState = initialState, action: AnyAction): ResellersVatRatesState {
  switch (action.type) {
    case 'SET_RESELLER_VAT_RATES_LOADING': {
      return {
        ...state,
        vatRatesLoading: action.payload,
      };
    }
    case 'SET_RESELLER_VAT_RATES': {
      return {
        ...state,
        vatRates: action.payload,
      };
    }
    case 'SET_RESELLER_VAT_RATE_FORM_LOADING': {
      return {
        ...state,
        vatRatesFormLoading: action.payload,
      };
    }
    case 'ADD_RESELLER_VAT_RATE': {
      return {
        ...state,
        vatRates: [...state.vatRates, action.payload],
      };
    }
    case 'REMOVE_RESELLER_VAT_RATE': {
      return {
        ...state,
        vatRates: state.vatRates.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_RESELLER_VAT_RATE': {
      const itemToReplace = {
        rate: action.payload.rate,
      };
      return {
        ...state,
        vatRates: [
          // @ts-expect-error itemToReplace does not contain all vatRate properties - to verify and fix!
          ...state.vatRates.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all vatRate properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_RESELLER_VAT_RATE_FOR_FORM': {
      return {
        ...state,
        selectedVatRate: action.payload,
      };
    }
    case 'SET_RESELLER': {
      return {
        ...state,
        reseller: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
