import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import axios from 'axios';
import { appConfig } from '../../config';
import { getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';

export function setCountryFormOpen(open: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_COUNTRY_FORM_OPEN', payload: open });
  };
}

export function addCountryEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'ADD_COUNTRY_ENTRY', payload: item });
    dispatch(updateCountryOverride());
  };
}

export function removeCountryEntry(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'REMOVE_COUNTRY_ENTRY', payload: id });
    dispatch(updateCountryOverride());
  };
}

export function updateCountryEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_COUNTRY_ENTRY', payload: item });
    dispatch(updateCountryOverride());
  };
}

export function setCountryEntryForForm(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_COUNTRY_ENTRY_FOR_FORM', payload: id });
  };
}

export function setCountryEntries(items: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_COUNTRY_ENTRIES', payload: items });
  };
}

export function fetchCountryEntries(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_COUNTRY_LOADING', payload: true });
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/country_override`, config)
      .then((res) => {
        if (res.status === 200) {
          const data = Object.keys(res.data).map((e, index) => {
            return {
              id: index,
              country: +e,
              override: res.data[e],
            };
          });
          dispatch(setCountryEntries(data));
          dispatch({ type: 'SET_COUNTRY_LOADING', payload: false });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_COUNTRY_LOADING', payload: false });
      });
  };
}

export function updateCountryOverride(): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };

    const data = getState().ubsCountry.countries.reduce((obj, c) => {
      return {
        ...obj,
        [c.country]: c.override,
      };
    }, {});
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/country_override`, data, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchCountryEntries());
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}
