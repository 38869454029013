import React from 'react';
import { makeStyles } from '@mui/styles';
import LoginFooter from '../common/logins/LoginFooter';
import SignUpForm from './SignUpForm';
import stylesConfig, { appConfig } from '../../config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Theme } from '@mui/material';

const bgImage = stylesConfig.BACKGROUND_URL;
const background = require(`../../styles/${bgImage}`);

const getBackgroundImage = () => {
  switch (stylesConfig.THEME) {
    case 'sunrise':
      return `linear-gradient(
        rgba(255,255,255,.2), 
        rgba(255,255,255,.2)), url(${background}) no-repeat center center fixed`;
    case 'telxira':
      return `linear-gradient(
        rgba(255,255,255,.2), 
        rgba(255,255,255,.2)), url(${background}) no-repeat bottom center fixed`;

    default: return `linear-gradient(
      rgba(255,255,255,.2), 
      rgba(255,255,255,.2)), url(${background}) no-repeat bottom center fixed`;
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { height: '100vh' },
  loginFlexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: getBackgroundImage(),
    backgroundColor: 'rgba(0,0,0,0.1)',
    webkitBackgroundSize: 'cover',
    backgroundSize: 'cover',
  },
  loginMainContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SignUp = () => {
  const classes = useStyles();

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={appConfig.RECAPTCHA_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      <div className={classes.root}>
        <div className={classes.loginFlexContainer}>
          <div className={classes.loginMainContent}>
            <SignUpForm />
          </div>
          <LoginFooter />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  )
};

export default SignUp;
