import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import loadable from '@loadable/component';
import BaseView from '../BaseView';
import AuthenticatedRoute from './AuthenticatedRoute';
import UserVerificationForm from '../components/changePassword/UserVerification';
import ChangePassword from '../components/changePassword/ChangePassword';
import ResetPassword from '../components/resetPassword/ResetPassword';
import OptOutForm from '../components/optOut/OptOutForm';
import Login from '../components/login';
import Signup from '../components/signup';
//import Email2SMS2 from '../components/email2sms/index';
import Email2SMS from '../components/email2sms/Email2SMS';
import { appConfig } from '../config';

const Contacts = loadable(() => import('../components/contacts'), {
  fallback: LoadingComponent,
});
const ContactsNew = loadable(() => import('../components/contacts/ContactsNew'), {
  fallback: LoadingComponent,
});
const ContactsImport = loadable(() => import('../components/contacts/ContactsImport'), {
  fallback: LoadingComponent,
});
const ContactsImportHistory = loadable(() => import('../components/contacts/ImportHistory'), {
  fallback: LoadingComponent,
});

const Users = loadable(() => import('../components/administration/users'), {
  fallback: LoadingComponent,
});
const Customers = loadable(() => import('../components/administration/customers'), {
  fallback: LoadingComponent,
});
const Finance = loadable(() => import('../components/administration/finance'), {
  fallback: LoadingComponent,
});
const Invoices = loadable(() => import('../components/account/invoices'), {
  fallback: LoadingComponent,
});
const Pricing = loadable(() => import('../components/account/pricing'), {
  fallback: LoadingComponent,
});
const Balance2 = loadable(() => import('../components/account/balance/balance2'), {
  fallback: LoadingComponent,
});
const PricelistTemplates = loadable(() => import('../components/administration/pricelistTemplates'), {
  fallback: LoadingComponent,
});
const BalanceOverview = loadable(() => import('../components/administration/balanceOverview'), {
  fallback: LoadingComponent,
});
const Tools = loadable(() => import('../components/administration/tools'), {
  fallback: LoadingComponent,
});
const History = loadable(() => import('../components/campaign/history'), {
  fallback: LoadingComponent,
});
const CampaignNew = loadable(() => import('../components/campaign/new'), {
  fallback: LoadingComponent,
});
const NotFound = loadable(() => import('../components/NotFound'), {
  fallback: LoadingComponent,
});
const Profile = loadable(() => import('../components/account/profile'), {
  fallback: LoadingComponent,
});
const Dashboard = loadable(() => import('../components/dashboard'), {
  fallback: LoadingComponent,
});
const Reseller = loadable(() => import('../components/administration/reseller'), {
  fallback: LoadingComponent,
});
const Whatsapp = loadable(() => import('../components/administration/whatsapp'), {
  fallback: LoadingComponent,
});
const SmsChat = loadable(() => import('../components/smschat'), {
  fallback: LoadingComponent,
});
const DemoRequests = loadable(() => import('../components/administration/demoRequests'), {
  fallback: LoadingComponent,
});
const Tokens = loadable(() => import('../components/account/tokens'), {
  fallback: LoadingComponent,
});
const AdminEmail2SMS = loadable(() => import('../components/administration/email2sms'), {
  fallback: LoadingComponent,
});
const MtApi = loadable(() => import('../components/administration/mtApi'), {
  fallback: LoadingComponent,
});
const QuickCdrs = loadable(() => import('../components/cdrs'), {
  fallback: LoadingComponent,
});
const InboundNumbers = loadable(() => import('../components/administration/inboundNumbers'), {
  fallback: LoadingComponent,
});
const DailyReports = loadable(() => import('../components/reports/dailyReports'), {
  fallback: LoadingComponent,
});
const TrafficReports = loadable(() => import('../components/reports/trafficReports'), {
  fallback: LoadingComponent,
});
const QuickTestSms = loadable(() => import('../components/quickTestSms'), {
  fallback: LoadingComponent,
});
const MobileNetworkNames = loadable(() => import('../components/mnp/mobileNetworkNames'), {
  fallback: LoadingComponent,
});
const MobileCountryCodes = loadable(() => import('../components/mnp/mobileCountryCodes'), {
  fallback: LoadingComponent,
});
const LookupHistory = loadable(() => import('../components/mnp/lookupHistory'), {
  fallback: LoadingComponent,
});
const LookupNumber = loadable(() => import('../components/mnp/lookupNumber'), {
  fallback: LoadingComponent,
});
const ResellerCosts = loadable(() => import('../components/settings/resellerCosts'), {
  fallback: LoadingComponent,
});
const MasterDestinationList = loadable(() => import('../components/settings/masterDestination'), {
  fallback: LoadingComponent,
});
const Tests = loadable(() => import('../components/tests/index'), {
  fallback: LoadingComponent,
});
const WhatsAppTemplates = loadable(() => import('../components/templates/whatsapp/index'), {
  fallback: LoadingComponent,
});
const ViberTemplates = loadable(() => import('../components/templates/viber/index'), {
    fallback: LoadingComponent,
});
const UserFiles = loadable(() => import('../components/account/files'), {
  fallback: LoadingComponent,
});
const Viber = loadable(() => import('../components/administration/viber'), {
  fallback: LoadingComponent,
});

const basename = '';

export default function configRoutes(customerId?: number) {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={appConfig.ALLOW_SIGNUP === 'true' ? Signup : Login} />
      <Route exact path="/account/resetPassword" component={ResetPassword} />
      <AuthenticatedRoute exact path="/" component={BaseView} subComponent={Dashboard} />
      <AuthenticatedRoute exact path="/dashboard" component={BaseView} subComponent={Dashboard} />
      <Route exact path="/user/verify/:hash" component={UserVerificationForm} />
      <Route exact path="/u/:hash" component={OptOutForm} />
      <AuthenticatedRoute exact path={`${basename}/smsCampaign/new`} component={BaseView} subComponent={CampaignNew} />
      <AuthenticatedRoute exact path={`${basename}/smsCampaign/history`} component={BaseView} subComponent={History} />
      <AuthenticatedRoute exact path={`${basename}/contacts/new`} component={BaseView} subComponent={ContactsNew} />
      {/* @ts-expect-error TODO: incompatible types */}
      <AuthenticatedRoute exact path={`${basename}/contacts/manage`} component={BaseView} subComponent={Contacts} />
      <AuthenticatedRoute
        exact
        path={`${basename}/contacts/import`}
        component={BaseView}
        subComponent={ContactsImport}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/contacts/importHistory`}
        component={BaseView}
        //@ts-expect-error TODO: incompatible types
        subComponent={ContactsImportHistory}
      />
      <AuthenticatedRoute exact path={`${basename}/account/profile`} component={BaseView} subComponent={Profile} />
      <AuthenticatedRoute exact path={`${basename}/account/changePassword`} component={ChangePassword} />
      <AuthenticatedRoute exact path={`${basename}/finance/invoices`} component={BaseView} subComponent={Invoices} />
      <AuthenticatedRoute exact path={`${basename}/finance/pricing`} component={BaseView} subComponent={Pricing} />
      <AuthenticatedRoute exact path={`${basename}/finance/balance`} component={BaseView} subComponent={Balance2} />
      {/* @ts-expect-error TODO: incompatible types */}
      <AuthenticatedRoute exact path={`${basename}/account/tokens`} component={BaseView} subComponent={Tokens} />
      <AuthenticatedRoute exact path={`${basename}/administration/users`} component={BaseView} subComponent={Users} />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/customers`}
        component={BaseView}
        subComponent={Customers}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/invoices`}
        component={BaseView}
        subComponent={Finance}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/pricelistTemplates`}
        component={BaseView}
        subComponent={PricelistTemplates}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/balance`}
        component={BaseView}
        subComponent={BalanceOverview}
      />
      <AuthenticatedRoute exact path={`${basename}/administration/tools`} component={BaseView} subComponent={Tools} />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/reseller`}
        component={BaseView}
        subComponent={Reseller}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/admin/channels/whatsapp`}
        component={BaseView}
        subComponent={Whatsapp}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/demoRequests`}
        component={BaseView}
        subComponent={DemoRequests}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/admin/channels/email2sms`}
        component={BaseView}
        subComponent={AdminEmail2SMS}
      />
      <AuthenticatedRoute exact path={`${basename}/admin/channels/mtApi`} component={BaseView} subComponent={MtApi} />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/cdrs`}
        component={BaseView}
        subComponent={QuickCdrs}
      />
      <AuthenticatedRoute exact path={`${basename}/finance/cdrs`} component={BaseView} subComponent={QuickCdrs} />
      <AuthenticatedRoute
        exact
        path={`${basename}/administration/inboundNumbers`}
        component={BaseView}
        subComponent={InboundNumbers}
      />
      <AuthenticatedRoute exact path={`${basename}/smschat`} component={BaseView} subComponent={SmsChat} />
      <AuthenticatedRoute
        exact
        path={`${basename}/reports/dailyReports`}
        component={BaseView}
        subComponent={DailyReports}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/reports/trafficReports`}
        component={BaseView}
        subComponent={TrafficReports}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/quickTestSms`}
        component={BaseView}
        subComponent={QuickTestSms}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/mnp/mobileNetworkNames`}
        component={BaseView}
        subComponent={MobileNetworkNames}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/mnp/mobileCountryCodes`}
        component={BaseView}
        subComponent={MobileCountryCodes}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/mnp/lookupHistory`}
        component={BaseView}
        subComponent={LookupHistory}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/mnp/lookupNumber`}
        component={BaseView}
        subComponent={LookupNumber}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/settings/resellerCosts`}
        component={BaseView}
        subComponent={ResellerCosts}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/settings/masterDestination`}
        component={BaseView}
        subComponent={MasterDestinationList}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/channels/email2sms`}
        component={BaseView}
        subComponent={Email2SMS}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/tests`}
        component={BaseView}
        subComponent={Tests}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/channels/whatsapp`}
        component={BaseView}
        subComponent={WhatsAppTemplates}
      />
      <AuthenticatedRoute
        path={`${basename}/channels/viber`}
        component={BaseView}
        subComponent={ViberTemplates}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/account/files`}
        component={BaseView}
        subComponent={UserFiles}
      />
      <AuthenticatedRoute
        exact
        path={`${basename}/admin/channels/viber`}
        component={BaseView}
        subComponent={Viber}
      />
      {/* {process.env.NODE_ENV === 'development' && (
        <AuthenticatedRoute exact path={`${basename}/email2sms2`} component={BaseView} subComponent={Email2SMS2} />
      )} */}
      <AuthenticatedRoute path="*" component={BaseView} subComponent={NotFound} />
    </Switch>
  );
}
