import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';

type Props = {
  readonly label: string;
  readonly readOnly: $TSFixMe;
  readonly helperText: string;
  readonly loading: boolean;
  readonly endAdornment: $TSFixMe;
  readonly startAdornment: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { value: string, onChange: (value: $TSFixMe) => void };
};

// eslint-disable-next-line
const RenderMobilePhoneField2 = ({
  input,
  label,
  loading,
  startAdornment,
  endAdornment,
  readOnly,
  helperText = '',
  meta: { touched, error },
  ...custom
}: Props) => (
  <MuiPhoneNumber
    autoFormat={false}
    style={{ marginTop: 5 }}
    label={label}
    error={Boolean(touched && error)}
    helperText={(touched && error) || helperText}
    sx={{
      svg: {
        height: "20px",
      },
    }}
    {...input}
    {...custom}
  />
);

export default RenderMobilePhoneField2;
