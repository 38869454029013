import React from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography, Drawer, Paper, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import {
  setCountryFormOpen,
  setCountryEntryForForm,
  removeCountryEntry,
  fetchCountryEntries,
} from '../../../../actions/ubs/ubs.country';
import CountryForm from './country.form';
import drawerStyle from '../../../../styles/drawers';
import confirm from '../../../common/confirm';

import { allCountries } from './countries';
import Loading from '../../../common/Loading';
import LocalizedMaterialTable from '../../../common/LocalizedMaterialTable';
import { AppState } from '../../../../reducers';
import { Authorizations } from '../../../../types/login';
import { Components } from '@material-table/core';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: $TSFixMe) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char: $TSFixMe) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

type Props = {
  readonly loading: boolean;
  readonly role: Authorizations;
  readonly countries: $TSFixMe[];
  readonly countryFormOpen: boolean;
  readonly fetchCountryEntries: () => void;
  readonly removeCountryEntry: (id: $TSFixMe) => void;
  readonly setCountryFormOpen: (open: boolean) => void;
  readonly setCountryEntryForForm: (id?: $TSFixMe) => void;
};

const CountryOverride = ({
  countries,
  setCountryFormOpen,
  countryFormOpen,
  setCountryEntryForForm,
  fetchCountryEntries,
  removeCountryEntry,
  role,
  loading,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  React.useEffect(() => {
    fetchCountryEntries();
  }, [fetchCountryEntries]);

  const countryForm = <CountryForm toggleDrawer={setCountryFormOpen} />;

  const handleEdit = (id: $TSFixMe) => {
    setCountryEntryForForm(id);
    setCountryFormOpen(true);
  };
  const handleDelete = (id: $TSFixMe) => {
    confirm(intl.formatMessage({ id: 'data.fetching.confirmation' }), {
      okLabel: intl.formatMessage({ id: 'data.fetching.confirmation.yes' }),
      cancelLabel: intl.formatMessage({ id: 'data.fetching.confirmation.no' }),
      title: intl.formatMessage({ id: 'data.fetching.confirmation.title' }),
    }).then(
      () => removeCountryEntry(id),
      () => {}
    );
  };

  const prepareComponents = (role: Authorizations) => {
    let components: Components = {
      Container: ({ children }) => {
        return (
          <Paper elevation={0} style={{ marginBottom: '70px' }}>
            <Loading loading={loading}>
              <>{children}</>
            </Loading>
          </Paper>
        );
      },
    };
    if (role.SUPER_ADMIN)
      Object.assign(components, {
        Action: (props: any) => {
          if (props.action.icon === 'add new') {
            return (
              <Button onClick={(event) => props.action.onClick(event, props.data)} color="primary" size="small">
                {props.action.icon}
              </Button>
            );
          }
          return (
            <IconButton size="small">
              <EditIcon color="primary" />
            </IconButton>
          );
        },
      });
    return components;
  };

  const prepareActions = (role: Authorizations) => {
    let actions = [];
    if (role.SUPER_ADMIN)
      actions.push({
        icon: 'add new',
        tooltip: intl.formatMessage({ id: 'common.button.add.new' }),
        isFreeAction: true,
        onClick: () => {
          setCountryFormOpen(true);
          setCountryEntryForForm();
        },
      });
    return actions;
  };

  const prepareColumns = (role: Authorizations) => {
    let columns = [
      {
        title: <FormattedMessage id="common.label.country" />,
        field: 'country',
        render: (row: { country: string }) => {
          const country = allCountries.find((c) => c.phone === row.country + '');
          return country ? (
            <React.Fragment>
              <span style={{ marginRight: '10px' }}>{countryToFlag(country.code)}</span>
              {country.label} ({country.code}) +{country.phone}
            </React.Fragment>
          ) : (
            row.country
          );
        },
      },
      {
        title: <FormattedMessage id="common.label.override" />,
        field: 'override',
      },
    ];
    if (role.SUPER_ADMIN)
      columns.push({
        title: <FormattedMessage id="common.label.actions" />,
        // @ts-expect-error TODO: argument is not assignable
        width: 80,
        render: (props: $TSFixMe) => {
          return <>
            <IconButton
              onClick={() => handleEdit(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </>;
        },
      });
    return columns;
  };

  return (
    <>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="email2sms.ubs.countryOverride.table.country.overrides" />
          </Typography>
        }
        columns={prepareColumns(role)}
        data={countries}
        components={prepareComponents(role)}
        options={{
          padding: 'dense',
          pageSize: 10,
          searchFieldStyle: { margin: '40px' },
          rowStyle: { fontSize: '0.8125rem', padding: '0px' },
          // @ts-expect-error TODO: no property cellStyle
          cellStyle: {
            padding: role.SUPER_ADMIN ? '2px 16px 1px 16px' : '4px 16px',
            maxHeight: '36px',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 500,
        }}
        actions={prepareActions(role)}
      />
      <Drawer
        anchor="right"
        open={countryFormOpen}
        onClose={() => setCountryFormOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {countryForm}
      </Drawer>
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      countries: store.ubsCountry.countries,
      countryFormOpen: store.ubsCountry.countryFormOpen,
      loading: store.ubsCountry.countryLoading,
      role: store.login.userData.authorizations,
    };
  },
  { setCountryFormOpen, setCountryEntryForForm, removeCountryEntry, fetchCountryEntries }
)(CountryOverride);
