import { AnyAction } from 'redux';
import initialState, { ResourcesState, ResourceType } from './resourcesInitialState';

export default function reducer(state: ResourcesState = initialState, action: AnyAction): ResourcesState {
  switch (action.type) {
    case 'LOADING': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          loading: true,
        },
      };
    }
    case 'LOADING_EXCEL': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          loadingExcel: true,
        }
      };
    }
    case 'LOADING_FINISHED': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          loading: false,
          loadingExcel: false,
        }
      };
    }
    case 'LOADING_ROW': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          loadingRow: true,
        },
      };
    }
    case 'GET_SUCCESS': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          data: action.data,
          links: action.links,
          page: action.page,
          loading: false,
          loadingExcel: false,
        },
      };
    }
    case 'GET_ROW_SUCCESS': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          row: action.row,
          loadingRow: false,
        },
      };
    }
    case 'SET_ROW': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          row: action.row,
          loadingRow: false,
        },
      };
    }
    case 'GET_FAILED': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          data: [],
          links: [],
          response: action.response,
          loading: false,
          loadingRow: false,
        },
      };
    }
    case 'UPDATE_PAGE': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          page: action.page,
        },
      };
    }
    case 'UPDATE_PAGE_SIZE': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          page: {
            ...state[resourceType].page,
            size: action.size,
            number: 0,
          },
        },
      };
    }
    case 'SET_SORT': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          sort: action.sort,
        },
      };
    }
    case 'APPLY_SEARCH': {
      const resourceType: ResourceType = action.resource;

      return { ...state,
        [resourceType]: {
          ...state[resourceType],
          search: action.search,
        },
      };
    }
    case 'APPLY_FILTER': {
      const resourceType: ResourceType = action.resource;

      return { ...state,
        [resourceType]: {
          ...state[resourceType],
          filter: action.filter,
        },
      };
    }
    case 'RESET': {
      const resourceType: ResourceType = action.resource;

      return {
        ...state,
        [resourceType]: {
          ...initialState[resourceType]
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
}
