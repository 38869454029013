import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import setLocale from '../../actions/locale';

import stylesConfig from '../../config';
import { AppState } from '../../reducers';

const languages = stylesConfig.LANG?.split(',');

// @ts-expect-error TODO: Types of property 'direction' are incompatible.
const useStyles = makeStyles(() => ({
  languageSelection: {
    alignSelf: 'flex-end',
    justify: 'flex-start',
    direction: 'row-reverse',
    marginLeft: 'auto',
    width: 150,
    paddingRight: 20,
    paddingBottom: 10,
  },
  label: {
    left: '-14px',
    top: '6px',
  }
}));

type Props = {
  readonly lang: string;
  readonly setLang: (lang: string) => void;
};

const SelectLanguage = (props: Props) => {
  const { lang } = props;

  const classes = useStyles();

  const handleChange = (event: $TSFixMe) => {
    const { setLang } = props;
    setLang(event.target.value);
  };

  const menuLanguageItems = languages?.map((lang) => {
    const symbol = lang.trim().split('_')[0];
    const name = lang.trim().split('_')[1];
    return (
      <MenuItem value={symbol} key={symbol}>
        <FormattedMessage id={`login.${name}`} />
      </MenuItem>
    );
  });

  return (
    <FormControl className={classes.languageSelection}>
      <InputLabel htmlFor="languageSelect" className={classes.label}>
        <FormattedMessage id="common.label.language" />
      </InputLabel>
      <Select
        value={lang}
        variant="standard"
        onChange={handleChange}
        inputProps={{
          name: 'language',
          id: 'languageSelect',
        }}
      >
        {menuLanguageItems}
      </Select>
    </FormControl>
  );
}

function mapStateToProps(store: AppState) {
  return {
    lang: store.locale.lang,
  };
}

export default connect(mapStateToProps, { setLang: setLocale })(SelectLanguage);
