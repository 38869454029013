import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography, Drawer, Paper, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import {
  setOptOutFormOpen,
  setOptOutEntryForForm,
  removeOptOutEntry,
  fetchOptOuts,
} from '../../../../actions/ubs/ubs.optout';
import OptOutForm from './optput.form';
import drawerStyle from '../../../../styles/drawers';
import confirm from '../../../common/confirm';
import LocalizedMaterialTable from '../../../common/LocalizedMaterialTable';
import { AppState } from '../../../../reducers';
import { Authorizations } from '../../../../types/login';
import { Components } from '@material-table/core';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

type Props = {
  readonly role: Authorizations;
  readonly optOuts: $TSFixMe[];
  readonly optOutFormOpen: boolean;
  readonly fetchOptOuts: () => void;
  readonly removeOptOutEntry: (id: $TSFixMe) => void;
  readonly setOptOutFormOpen: (open: boolean) => void;
  readonly setOptOutEntryForForm: (id?: $TSFixMe) => void;
};

const OptOut = ({
  optOuts,
  setOptOutFormOpen,
  optOutFormOpen,
  setOptOutEntryForForm,
  removeOptOutEntry,
  fetchOptOuts,
  role,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  React.useEffect(() => {
    fetchOptOuts();
  }, [fetchOptOuts]);
  
  const optOutForm = <OptOutForm toggleDrawer={setOptOutFormOpen} />;

  const handleEdit = (id: number) => {
    setOptOutEntryForForm(id);
    setOptOutFormOpen(true);
  };
  const handleDelete = (id: number) => {
    confirm(intl.formatMessage({ id: 'data.fetching.confirmation' }), {
      okLabel: intl.formatMessage({ id: 'data.fetching.confirmation.yes' }),
      cancelLabel: intl.formatMessage({ id: 'data.fetching.confirmation.no' }),
      title: intl.formatMessage({ id: 'data.fetching.confirmation.title' }),
    }).then(
      () => removeOptOutEntry(id),
      () => { }
    );
  };

  const prepareComponents = (role: Authorizations) => {
    let components: Components = {
      Container: ({ children }) => {
        return <Paper elevation={0} style={{ marginBottom: '70px' }}>{children}</Paper>;
      }
    }
    if (role.SUPER_ADMIN)
      Object.assign(components, {
        Action: (props: any) => {
          if (props.action.icon === 'add new') {
            return (
              <Button
                onClick={(event) => props.action.onClick(event, props.data)}
                color="primary"
                size="small"
              >
                {props.action.icon}
              </Button>
            );
          }
          return (
            <IconButton size="small">
              <EditIcon color="primary" />
            </IconButton>
          );
        },
      })
    return components;
  };

  const prepareActions = (role: Authorizations) => {
    let actions = [];
    if (role.SUPER_ADMIN)
      actions.push(
        {
          icon: 'add new',
          tooltip: intl.formatMessage({
            id: 'common.button.add.new',
          }),
          isFreeAction: true,
          onClick: () => {
            setOptOutFormOpen(true);
            setOptOutEntryForForm();
          },
        })
    return actions;
  };

  const prepareColumns = (role: Authorizations) => {
    let columns = [
      {
        title: <FormattedMessage id="email2sms.ubs.optout.table.number" />,
        field: 'number',
      },
      {
        title: (
          <FormattedMessage id="email2sms.ubs.optout.table.optional.info" />
        ),
        field: 'info',
      }]
    if (role.SUPER_ADMIN)
      columns.push(
        {
          title: <FormattedMessage id="common.label.actions" />,
          // @ts-expect-error TODO: argument is not assignable
          width: 80,
          render: (props: $TSFixMe) => {
            return <>
              <IconButton
                onClick={() => handleEdit(props.id)}
                style={{
                  height: '32px',
                  width: '32px',
                  padding: 0,
                }}
                size="large"
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(props.id)}
                style={{
                  height: '32px',
                  width: '32px',
                  padding: 0,
                }}
                size="large"
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </>;
          },
        },
      )
    return columns;
  };

  return (
    <>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="email2sms.ubs.optout.table.title.optout.list" />
          </Typography>
        }
        columns={prepareColumns(role)}
        data={optOuts}
        components={prepareComponents(role)}
        options={{
          padding: 'dense',
          pageSize: 10,
          searchFieldStyle: { margin: '40px' },
          rowStyle: { fontSize: '0.8125rem', padding: '0px' },
          // @ts-expect-error TODO: no property cellStyle
          cellStyle: {
            padding: role.SUPER_ADMIN ? '2px 16px 1px 16px' : '4px 16px',
            maxHeight: '36px',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 500,
        }}
        actions={prepareActions(role)}
      />
      <Drawer
        anchor="right"
        open={optOutFormOpen}
        onClose={() => setOptOutFormOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {optOutForm}
      </Drawer>
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      optOuts: store.ubsOptOut.optOuts,
      optOutFormOpen: store.ubsOptOut.optOutFormOpen,
      role: store.login.userData.authorizations,
    };
  },
  { setOptOutFormOpen, setOptOutEntryForForm, removeOptOutEntry, fetchOptOuts }
)(OptOut);
