import React from 'react';
import Drawer from '@mui/material/Drawer';
import TestAccountDrawer from './TestAccountDrawer';
import makeStyles from '@mui/styles/makeStyles';
import drawerStyle from '../../styles/drawers';
import { connect } from 'react-redux';
import { 
  setTestAccountDrawerShowFlag,
  setNumbers,
} from '../../actions/testAccount';
import { Theme } from '@mui/material';
import { AppState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

type Props = {
  readonly showTestAccountDrawer: boolean;
  readonly setNumbers: (numbers: []) => void;
  readonly setTestAccountDrawerShowFlag: (show: boolean) => void;
};

const TestAccountDrawerWrapper = ({
  showTestAccountDrawer,
  setTestAccountDrawerShowFlag,
  setNumbers,
}: Props) => {
  const classes = useStyles();

  const handleCloseClick = () => {
    setTestAccountDrawerShowFlag(false);
    setNumbers([]);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showTestAccountDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={handleCloseClick}
    >
      <TestAccountDrawer onClose={handleCloseClick} />
    </Drawer>
  );
};

export default connect(
  (state: AppState) => {
    return { 
      showTestAccountDrawer: state.testAccount.showTestAccountDrawer,       
    };
  },
  { 
    setTestAccountDrawerShowFlag, 
    setNumbers,
  }
)(TestAccountDrawerWrapper);
