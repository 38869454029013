import axios from 'axios';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { searchResultsType } from '../reducers/chat';
import { appConfig } from '../config';
import { debounce } from 'lodash-es';
import { extractIdFromHref } from '../components/common/utils';
import { showSnackBarMsg } from './snackbar';
import { AppThunkAction } from './index';
import { ChatAgent } from '../types/chatAgent';
import { SupportedChatChannel } from '../chats/supportedChatChannel';
import chatChannelProvider from '../chats/chatChannelProvider';
import { ChatSender } from '../types/chatSender';
import { selectSelectedChatClient } from '../reducers/chats/clients';
import { ChatMessage } from '../types/chatMessage';
import { addOutgoingChatMessage } from './chats/messages';
import { SendChatMessageParams } from '../chats/interface';
import { selectActiveChatChannel } from '../reducers/chats';
import backendApi from '../api/backend/backendApi';
import { ChatContact, Contact } from '../types/contact';
import { Page } from '../types/page';

export const setChannel = (channel: SupportedChatChannel): AppThunkAction => {
  return (dispatch) => {
    // Can it be removed? We want to fetch configuration from messaging services directly
    // dispatch(getUserChannelConfig(channel));

    chatChannelProvider.get(channel).api.isEnabled()
      .then(isEnabled => {
        console.info(`Channel ${channel} is enabled: ${isEnabled}`);

        dispatch({ type: 'SET_CHANNEL', payload: channel });
        dispatch({ type: 'SET_SERVICE_DISABLED', payload: !isEnabled });
      })
      .catch(err => {
        console.error(`Caught error while checking the ${channel} channel is enabled. Error: ${err}`);

        dispatch({ type: 'SET_CHANNEL', payload: channel });
        dispatch({ type: 'SET_SERVICE_DISABLED', payload: true });
      });
  };
};

export const getChatSenders = (): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel } = getState().chat;
    const { customerUuid } = getState().login.userData;
    dispatch({ type: 'SET_SENDERS', payload: [] });
    dispatch({ type: 'SET_SENDER', payload: null });

    chatChannelProvider.get(channel).api.fetchSenders(customerUuid)
      .then((chatSenders: ChatSender[]) => {
        dispatch({
          type: 'FETCH_SENDERS_SUCCESS',
          payload: chatSenders,
        });
        if (chatSenders.length > 0) {
          dispatch({
            type: 'SET_SENDER',
            payload: chatSenders[0].id,
          });
        } else {
          dispatch({ type: 'SET_SERVICE_DISABLED', payload: true });
          dispatch({ type: 'SET_CHANNEL', payload: 'SMS' });
        }
      })
      .catch((err) => {
        dispatch({
          type: 'FETCH_SENDERS_FAILURE',
          payload: err.message,
        });
      });
  };
};

export const setServiceDisabled = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_SERVICE_DISABLED', payload: flag });
  };
};

export const setMobileMessageView = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_MOBILE_MESSAGE_VIEW', payload: flag });
  };
};

// TODO: consider to save newMessage per channel to be able to create a drafts
export const sendMessage = (): AppThunkAction => {
  return (dispatch, getState) => {
    const state = getState();

    const {
      text,
      payloadType: payload,
      attachment: { link, data },
      buttonAction,
      buttonCaption,
    } = state.chat;

    const channel = selectActiveChatChannel(state);
    const client = selectSelectedChatClient(state, channel);
    if (client) {
      const sendNewChatMessageParams: SendChatMessageParams = {
        senderId: client.senderId,
        clientId: client.id,
        clientPhoneNumber: client.number,
        text,
        payloadType: payload,
        ...(data && {
          attachment: {
            link,
            filename: data.name,
            size: data.size,
          },
        }),
        ...((buttonAction && buttonCaption) && {
          button: {
            action: buttonAction,
            caption: buttonCaption,
          },
        })
      };

      dispatch({ type: 'SET_SENDING', payload: true });
      chatChannelProvider.get(channel)
        .publisher.sendChatMessage(sendNewChatMessageParams)
        .then((chatMessage: ChatMessage) => {
          dispatch(addOutgoingChatMessage(chatMessage));

          dispatch({ type: 'SET_SENDING', payload: false });
          dispatch(setText(''));
          dispatch(clearAttachment());
        })
        .catch((error) => {
          console.log(error);
          dispatch(clearAttachment());
          dispatch(setChatSendingError(true));
          dispatch({ type: 'SET_SENDING', payload: false });
        });
    }
  };
};

export const setText = (text: string): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_TEXT', payload: text });
  };
};

export const setTo = (recipient: string): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_TO', payload: recipient });
  };
};

export const setSelectedContact = (contact: $TSFixMe, update = true): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'RESET_MESSAGES_PAGE_SIZE', payload: 20 });
    if (update) {
      const { chats } = getState().chat;
      const newChats = chats.map((c) => {
        if (c.number === contact.number) return { ...c, unread: 0 };
        else return c;
      });
      dispatch({ type: 'SET_CHATS', payload: [...newChats] });
      dispatch({ type: 'SET_SELECTED_CONTACT', payload: contact });
    }
    dispatch({ type: 'SET_TO', payload: contact.number });
    dispatch({ type: 'SET_SCROLL_TO_BOTTOM', payload: true });
  };
};

const mapContactToChatContact = (contact: Contact): ChatContact => {
  const firstName = contact.firstName || '';
  const lastName = contact.lastName || '';

  return {
    name: (firstName + ' ' + lastName).trim(),
    number: contact.mobilePhone,
  };
};

// TODO: Thunk action should have the getState function as 2nd arg but here it's state - to verify
const callApiForContacts: $TSFixMeFunction = (dispatch, state) => {
  const { contactsPageSize, filter, channel } = state;

  dispatch({ type: 'SET_CONTACTS', payload: [] });
  dispatch(setChatContactsLoading(true));
  dispatch({ type: 'SET_CHATS_LOADING', payload: true });

  backendApi.contacts.searchByChannel({
    channel,
    search: filter,
    page: 0,
    size: contactsPageSize,
  }).then((pageContact: Page<Contact>) => {
    if (pageContact.content.length) {
      const contacts: ChatContact[] = pageContact.content.map(c => mapContactToChatContact(c));

      dispatch({ type: 'SET_CONTACTS', payload: contacts });
      dispatch({ type: 'SET_CONTACTS_ALL_ELEMENTS', payload: pageContact.totalElements });
    } else {
      dispatch({ type: 'SET_CONTACTS', payload: [] });
      dispatch({ type: 'SET_CONTACTS_ALL_ELEMENTS', payload: 0 });
    }
  }).catch((error) => {
    console.error(error);
  }).finally(() => {
    dispatch(setChatContactsLoading(false));
    dispatch({ type: 'SET_CHATS_LOADING', payload: false });
  });
};

const callApiForContactsDebounced = debounce((_dispatch, state) => callApiForContacts(_dispatch, state), 500);

export const findContacts = (): AppThunkAction => {
  return (dispatch, getState) => {
    const state = getState().chat;
    callApiForContactsDebounced(dispatch, state);
  };
};

export const setFile = (file: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_FILE_CHAT', payload: file });
    const { validSize } = getState().chat.attachment;
    //upload the file if size is allowed
    if (validSize) {
      dispatch({ type: 'SET_FILE_UPLOADING_CHAT' });
      const url = `${appConfig.URL_REST}files/upload`;
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const uploadedFileId = res.data.filename;
            const uploadedFileLink = `${appConfig.URL_REST}files/download/${res.data.filename}`;

            dispatch({ type: 'SET_FILE_UPLOADED', payload: {
              id: uploadedFileId,
              link: uploadedFileLink,
            }});
          }
        })
        .catch((err) => {
          const error =
            err.response &&
            err.response.data &&
            err.response.message &&
            JSON.parse(err.response.data.message.replaceAll('"{', '{').replaceAll('}"', '}').replaceAll('\\"', '"'))
              .message.error;
          dispatch(setAttachmentUploadFailed(error));
        });
    }
  };
};

export const setAttachDialOpen = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_ATTACH_DIAL_OPEN', payload: flag });
  };
};

export const setPayloadType = (type: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_PAYLOAD_TYPE', payload: type });
  };
};

export const clearAttachment = (): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_ATTACHMENT' });
  };
};

export function setAttachmentUploadFailed(err: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_ATTACHMENT_UPLOAD_FAILED', payload: err });
  };
}

export const setNotificationIgnore = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_NOTIFICATION_IGNORE', payload: flag });
  };
};

export const getChatCounter = (): AppThunkAction => {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}chat/all_unread`, config)
      .then((res) => {
        dispatch({ type: 'SET_TOTAL_UNREAD', payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setFilter = (type: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CHAT_CONTACTS_FILTER', payload: type });
  };
};

export const setChatContactsLoading = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_CHAT_LOADING_CONTACTS', payload: flag });
  };
};

export const setAddNewContactDialogOpen = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ADD_NEW_CONTACT_DIALOG_OPEN', payload: flag });
  };
};

export const setAddNewUserFullFormOpen = (flag: boolean): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ADD_NEW_USER_FULL_FORM_OPEN', payload: flag });
  };
};

export const setSearchResults = (type: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setChatContactsLoading(true));
    const { filter } = getState().chat;
    dispatch({ type: 'SET_SEARCH_RESULTS_TYPE', payload: type });
    const contactsPageSize = type === searchResultsType.ALL ? 5 : 10;
    dispatch({
      type: 'SET_CHAT_CONTACTS_PAGE_SIZE',
      payload: contactsPageSize,
    });
    const chatsPageSize = type === searchResultsType.ALL ? 5 : 10000;
    dispatch({
      type: 'SET_CHAT_CHATS_PAGE_SIZE',
      payload: chatsPageSize,
    });
    // @ts-expect-error TODO: clearAttachment function does not have any arguments - to verify!
    dispatch(findContacts(filter));
  };
};

export const getMoreContacts = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setChatContactsLoading(true));
    const { filter, contactsPageSize } = getState().chat;

    dispatch({
      type: 'SET_CHAT_CONTACTS_PAGE_SIZE',
      payload: contactsPageSize + 10,
    });
    // @ts-expect-error TODO: clearAttachment function does not have any arguments - to verify!
    dispatch(findContacts(filter));
  };
};

export const setValidationError = (flag: $TSFixMe, message: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_VALIDATION_ERROR',
      payload: { flag, message },
    });
  };
};

export const setChatHeight = (height: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_HEIGHT',
      payload: height,
    });
  };
};

export const setChatSendingError = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_SENDING_ERROR',
      payload: flag,
    });
  };
};

export function getAgents(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_AGENTS_LOADING', payload: true });
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}users/chat-agents`, config)
      .then((res) => {
        const data = res.data.map((e: $TSFixMe) => {
          return {
            id: parseInt(extractIdFromHref(e.links.find((link: $TSFixMe) => link.rel === 'self').href)),
            name: (e.firstName + ' ' + e.lastName).trim(),
            number: e.phoneNumber,
            status: e.chatAgentStatus,
          };
        });
        dispatch(setAgents(data));
        dispatch({ type: 'SET_AGENTS_LOADING', payload: false });
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: 'SET_AGENTS_LOADING', payload: false });
      });
  };
}

export function setAgents(agents: ChatAgent[]): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_AGENTS', payload: agents });
  };
}

export function assignChatToAgent(agent: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const phoneNumber = getState().chat.sender;
    const clientNumber = getState().chat.clientNumber;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/${phoneNumber}/${clientNumber}/SMS/agent?agentId=${agent}`, agent, config)
      .then((res) => {
        dispatch(getChatSenders());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((err) => {
        console.error(err);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function removeAgent(): AppThunkAction {
  return (dispatch, getState) => {
    const phoneNumber = getState().chat.sender;
    const clientNumber = getState().chat.clientNumber;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/${phoneNumber}/${clientNumber}/SMS/agent`, null, config)
      .then((res) => {
        dispatch(getChatSenders());
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const setChatNumber = (clientNumber: $TSFixMe): AppThunkAction => {
  return (dispatch) => {
    dispatch({
      type: 'SET_CHAT_NUMBER',
      payload: clientNumber,
    });
  };
};

export const setShowAgents = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_SHOW_AGENTS', payload: flag });
  };
};

export function setAgentStatus(status: string): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.URL_REST}chat/agent/status`, { status: status }, config)
      .then((res) => {
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((err) => {
        console.error(err);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export const setShowButtonFields = (flag: boolean): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_SHOW_BUTTON_FIELDS', payload: flag });
  };
};

export const setButtonAction = (buttonAction: string): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_BUTTON_ACTION', payload: buttonAction });
  };
};

export const setButtonCaption = (buttonCaption: string): AppThunkAction => {
  return (dispatch) => {
    dispatch({ type: 'SET_BUTTON_CAPTION', payload: buttonCaption });
  };
};

export const findTemplates = (templatesFilter: string): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel, templatesPageSize } = getState().chat;
    const encodedFilter = encodeURIComponent(templatesFilter);
    const filterUrl =
      `page=0&size=${templatesPageSize}&sort=name,asc&channel=${encodedFilter}`;
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.URL_REST}chat/${channel}/templates/or?${filterUrl}`, config)
      .then((res) => {
        dispatch({ type: 'SET_MESSAGE_TEMPLATES', payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getMoreTemplates = (): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch(setTemplatesLoading(true));
    const { templatesFilter, templatesPageSize } = getState().chat;

    dispatch({
      type: 'SET_MESSAGE_TEMPLATES_PAGE_SIZE',
      payload: templatesPageSize + 10,
    });
    dispatch(findTemplates(templatesFilter));
  };
};

export const setTemplatesLoading = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_MESSAGE_TEMPLATES_LOADING', payload: flag });
  };
};

export const setTemplatesFilter = (filter: string): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_MESSAGE_TEMPLATES_FILTER', payload: filter });
  };
};

export const getAllSelectedChannelContacts = (): $TSFixMeFunction => {
  return (dispatch, getState) => {
    const { channel, filterUrl } = getState().chat;
  dispatch({ type: 'SET_ALL_CHANNEL_CONTACTS', payload: [] });
  dispatch({ type: 'SET_CHATS_LOADING', payload: true });

  const handleUrl = () => {
    switch (channel) {
      case 'VB':
        return `${appConfig.URL_REST}contacts/map/VIBER?page=0&size=10000`;
      case 'WB':
        return `${appConfig.URL_REST}contacts/map/WHATSAPP?page=0&size=10000`;

      default: return `${appConfig.URL_REST}contacts/or?${filterUrl}`;
    }
  };

  axios
    .get(handleUrl(), {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    .then((response) => {
      if (
        response.data._embedded &&
        response.data._embedded.contacts &&
        response.data._embedded.contacts.length > 0
      ) {
        const contacts = response.data._embedded.contacts.map((c: $TSFixMe) => {
          const firstName = c.firstName || '';
          const lastName = c.lastName || '';
          return {
            name: (firstName + ' ' + lastName).trim(),
            number: c.mobilePhone,
          };
        });
        dispatch({ type: 'SET_ALL_CHANNEL_CONTACTS', payload: contacts });
      } else {
        dispatch({ type: 'SET_ALL_CHANNEL_CONTACTS', payload: [] });
      }
      dispatch({ type: 'SET_CHATS_LOADING', payload: false });
    })
    .catch((error) => {
      console.error(error);
      dispatch({ type: 'SET_CHATS_LOADING', payload: false });
    });
  };
};
