import { AnyAction } from 'redux';
import { SettingsMasterDestination } from '../../types/masterDestination';

interface SettingsMasterDestinationState {
  masterDestinationEntries: SettingsMasterDestination[];
  selectedMasterDestinationEntry: Partial<SettingsMasterDestination>;
  masterDestinationEntriesLoading: boolean;
}

const initialState: SettingsMasterDestinationState = {
  masterDestinationEntries: [],
  selectedMasterDestinationEntry: {},
  masterDestinationEntriesLoading: false,
};

export default function reducer(state: SettingsMasterDestinationState = initialState, action: AnyAction): SettingsMasterDestinationState {
  switch (action.type) {
    case 'SET_MASTER_DESTINATION_ENTRIES_LOADING': {
      return {
        ...state,
        masterDestinationEntriesLoading: action.payload,
      };
    }
    case 'ADD_MASTER_DESTINATION_ENTRY': {
      return {
        ...state,
        masterDestinationEntries: [...state.masterDestinationEntries, { id: state.masterDestinationEntries.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_MASTER_DESTINATION_ENTRY': {
      return {
        ...state,
        masterDestinationEntries: state.masterDestinationEntries.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_MASTER_DESTINATION_ENTRY': {
      const itemToReplace = {
        destinationName: action.payload.destinationName,
        //breakout: action.payload.breakout,
      };
      return {
        ...state,
        masterDestinationEntries: [
          // @ts-expect-error itemToReplace does not contain all MasterDestination properties - to verify and fix!
          ...state.masterDestinationEntries.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all MasterDestination properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MASTER_DESTINATION_ENTRY_FOR_FORM': {
      return {
        ...state,
        selectedMasterDestinationEntry: action.payload,
      };
    }
    case 'SET_MASTER_DESTINATION_ENTRIES': {
      return {
        ...state,
        masterDestinationEntries: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
