import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  parent: {
    position: 'relative',
    zIndex: 0,
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 1,
  },
});

type Props = {
  readonly spinner?: boolean;
  readonly loading: boolean;
  readonly children: React.ReactNode;
};

const Loading = ({ loading, children, spinner }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.parent}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress sx={{ color: (theme) => theme.palette.secondary.light }} size={60} thickness={2} />
      </Backdrop>
      {children}
    </div>
  )
};

export default Loading;
