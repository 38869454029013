import { ChatConfig } from '../interface';
import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatMessagePayloadType } from '../../types/chatMessage';

const viberChatConfig: ChatConfig = {
  id: SupportedChatChannel.VIBER,
  dateFormat: 'yyyy-MM-dd HH:mm',
  enableEmojis: true,
  supportedMessageTypes: [
    ChatMessagePayloadType.TEXT,
    ChatMessagePayloadType.IMAGE,
    ChatMessagePayloadType.DOC,
    ChatMessagePayloadType.VIDEO,
    ChatMessagePayloadType.TEXT_BUTTON,
    ChatMessagePayloadType.IMAGE_BUTTON,
  ],
};

export default viberChatConfig;
