import React from 'react';
import { makeStyles } from '@mui/styles';

import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography, Drawer, Paper, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import drawerStyle from '../../../styles/drawers';
import confirm from '../../common/confirm';
import SenderForm from './configuration.form';
import {
  setSenderFormOpen,
  setSenderEntryForForm,
  removeSenderEntry,
  fetchAllowedSenders,
} from '../../../actions/email2sms/sender';
import { destroy } from 'redux-form';
import Loading from '../../common/Loading';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import LocalizedMaterialTable from '../../common/LocalizedMaterialTable';
import { AppState } from '../../../reducers';
import { Authorizations } from '../../../types/login';
import { Components } from '@material-table/core';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

type Props = {
  readonly loading: boolean;
  readonly role: Authorizations;
  readonly senders: $TSFixMe[];
  readonly senderFormOpen: boolean;
  readonly fetchAllowedSenders: () => void;
  readonly destroy: (form: string) => void;
  readonly removeSenderEntry: (id: $TSFixMe) => void;
  readonly setSenderFormOpen: (open: boolean) => void;
  readonly setSenderEntryForForm: (id?: $TSFixMe) => void;
};

const Configuration = ({
  role,
  loading,
  senders,
  destroy,
  senderFormOpen,
  removeSenderEntry,
  fetchAllowedSenders,
  setSenderFormOpen,
  setSenderEntryForForm,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  React.useEffect(() => {
    fetchAllowedSenders();
  }, [fetchAllowedSenders]);

  const handleEdit = (id: number) => {
    destroy('sender');
    setSenderEntryForForm(id);
    setSenderFormOpen(true);
  };
  const handleDelete = (id: number) => {
    confirm(intl.formatMessage({ id: 'data.fetching.confirmation' }), {
      okLabel: intl.formatMessage({ id: 'data.fetching.confirmation.yes' }),
      cancelLabel: intl.formatMessage({ id: 'data.fetching.confirmation.no' }),
      title: intl.formatMessage({ id: 'data.fetching.confirmation.title' }),
    }).then(
      () => removeSenderEntry(id),
      () => {}
    );
  };

  const prepareComponents = (role: Authorizations) => {
    let components: Components = {
      Container: ({ children }) => {
        return (
          <Paper elevation={0} style={{ marginBottom: '70px' }}>
            <Loading loading={loading}>
              <>{children}</>
            </Loading>
          </Paper>
        );
      },
    };
    if (role.SUPER_ADMIN)
      Object.assign(components, {
        Action: (props: any) => {
          if (props.action.icon === 'add new') {
            return (
              <Button onClick={(event) => props.action.onClick(event, props.data)} color="primary" size="small">
                {props.action.icon}
              </Button>
            );
          }
          return (
            <IconButton size="small">
              <EditIcon color="primary" />
            </IconButton>
          );
        },
      });
    return components;
  };

  const prepareActions = (role: Authorizations) => {
    let actions = [];
    if (role.SUPER_ADMIN)
      actions.push({
        icon: 'add new',
        tooltip: intl.formatMessage({ id: 'common.button.add.new' }),
        isFreeAction: true,
        onClick: () => {
          setSenderFormOpen(true);
          setSenderEntryForForm();
          destroy('sender');
        },
      });
    return actions;
  };

  const prepareColumns = (role: Authorizations) => {
    let columns = [
      {
        title: <FormattedMessage id="email2sms.ubs.sender.table.email" />,
        field: 'email',
      },
      {
        title: <FormattedMessage id="common.label.sender.id" />,
        field: 'senderId',
      },
      {
        title: <FormattedMessage id="email2sms.ubs.sender.table.require.confirmation" />,
        field: 'notifyFinalStatusToSender',
        render: (row: { notifyFinalStatusToSender: boolean }) => {
          return row.notifyFinalStatusToSender ? (
            <CheckIcon color="primary" style={{ height: 24, width: 24 }} />
          ) : (
            <RemoveIcon style={{ color: 'rgb(0 0 0 / 14%)', height: 24, width: 24 }} />
          );
        },
      },
    ];
    if (role.SUPER_ADMIN)
      columns.push({
        title: <FormattedMessage id="common.label.actions" />,
        // @ts-expect-error TODO: argument is not assignable
        width: 80,
        render: (props: $TSFixMe) => {
          return <>
            <IconButton
              onClick={() => handleEdit(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </>;
        },
      });
    return columns;
  };

  const senderForm = <SenderForm toggleDrawer={setSenderFormOpen} />;

  return (
    <>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="administration.email2sms.parameters.tab.label.allowed.emails" />
          </Typography>
        }
        columns={prepareColumns(role)}
        data={senders}
        components={prepareComponents(role)}
        options={{
          padding: 'dense',
          pageSize: 10,
          searchFieldStyle: { margin: '40px' },
          rowStyle: { fontSize: '0.8125rem', padding: '0px' },
          // @ts-expect-error TODO: no property cellStyle
          cellStyle: {
            padding: role.SUPER_ADMIN ? '2px 16px 1px 16px' : '4px 16px',
            maxHeight: '36px',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 500,
        }}
        actions={prepareActions(role)}
      />
      <Drawer
        anchor="right"
        open={senderFormOpen}
        onClose={() => setSenderFormOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {senderForm}
      </Drawer>
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      senders: store.email2smsConfig.senders,
      senderFormOpen: store.email2smsConfig.senderFormOpen,
      loading: store.email2smsConfig.sendersLoading,
      role: store.login.userData.authorizations,
    };
  },
  { setSenderFormOpen, setSenderEntryForForm, removeSenderEntry, fetchAllowedSenders, destroy }
)(Configuration);
