import { AnyAction } from 'redux';
import { MobileNetwork } from '../../types/mobileNetwork';

interface MobileNetworkNamesState {
  mobileNetworkNames: MobileNetwork[];
  mobileNetworkNamesLoading: boolean;
  selectedMobileNetworkName: Partial<MobileNetwork>;
  mobileNetworkNamesFormLoading: boolean;
}

const initialState: MobileNetworkNamesState = {
  mobileNetworkNames: [],
  mobileNetworkNamesLoading: false,
  selectedMobileNetworkName: {},
  mobileNetworkNamesFormLoading: false,
};

export default function reducer(state: MobileNetworkNamesState = initialState, action: AnyAction): MobileNetworkNamesState {
  switch (action.type) {
    case 'SET_MOBILE_NETWORK_NAMES_LOADING': {
      return {
        ...state,
        mobileNetworkNamesLoading: action.payload,
      };
    }
    case 'SET_MOBILE_NETWORK_NAMES': {
      return {
        ...state,
        mobileNetworkNames: action.payload,
      };
    }
    case 'SET_MOBILE_NETWORK_NAMES_FORM_LOADING': {
      return {
        ...state,
        mobileNetworkNamesFormLoading: action.payload,
      };
    }
    case 'ADD_MOBILE_NETWORK_NAME': {
      return {
        ...state,
        mobileNetworkNames: [...state.mobileNetworkNames, action.payload],
      };
    }
    case 'REMOVE_MOBILE_NETWORK_NAME': {
      return {
        ...state,
        mobileNetworkNames: state.mobileNetworkNames.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_MOBILE_NETWORK_NAME': {
      const itemToReplace = {
        name: action.payload.name,
        expirationDays: action.payload.expirationDays,
      };
      return {
        ...state,
        mobileNetworkNames: [
          // @ts-expect-error itemToReplace does not contain all MobileNetwork properties - to verify and fix!
          ...state.mobileNetworkNames.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all MobileNetwork properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MOBILE_NETWORK_NAME_FOR_FORM': {
      return {
        ...state,
        selectedMobileNetworkName: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}