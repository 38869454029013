import { AnyAction } from 'redux';

interface StepperState {
  step: number;
}

const initialState: StepperState = { step: 0 };

export default function reducer(state: StepperState = initialState, action: AnyAction): StepperState {
  switch (action.type) {
    case 'CURRENT_STEP': {
      return {
        ...state,
        step: action.step,
      };
    }
    case 'PLUS_STEP': {
      return {
        ...state,
        step: action.step,
      };
    }
    case 'MINUS_STEP': {
      return {
        ...state,
        step: action.step,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
