import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TestAccountProgress from './TestAccountProgress';
import axios from 'axios';
import { appConfig } from '../../config';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getAccountInfo } from '../../actions/login';
import { AppState } from '../../reducers';

type Props = {
  readonly open: boolean;
  readonly number: $TSFixMe;
  readonly progress: number;
  readonly timeLimit: $TSFixMe;
  readonly accountInfoUrl: $TSFixMe;
  readonly customVerification?: boolean;
  readonly handleClose: () => void;
  readonly verify?: (pin: $TSFixMe) => void;
  readonly handleNotConfirm?: () => void;
  readonly handleConfirm: (pin: $TSFixMe) => void;
  readonly getAccountInfo: (url: $TSFixMe) => void;
};

const TestAccountNumberConfirmationDialog = ({
  timeLimit,
  progress,
  open,
  handleClose,
  number,
  handleConfirm,
  getAccountInfo,
  accountInfoUrl,
  handleNotConfirm,
  customVerification = false,
  verify,
}: Props) => {
  React.useEffect(() => {
    if (progress === 0) {
      handleClose();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const verifyNumber = () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(
        `${appConfig.URL_REST}number/registration/confirm/${number}/${pin}`,
        config
      )
      .then((res) => {
        if (res.data === 'APPROVED') {
          setPin('');
          setError(false);
          handleConfirm(pin);
          getAccountInfo(accountInfoUrl.href);
        }
        if (res.data === 'NOT_MATCHED') {
          setError(true);
          handleNotConfirm?.();
          getAccountInfo(accountInfoUrl.href);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const [pin, setPin] = React.useState('');
  const [error, setError] = React.useState(false);

  const handleChange = (event: $TSFixMe) => {
    const { value } = event.target;
    setPin(value);
  };

  const handleCancel = () => {
    setError(false);
    handleClose();
    setPin('');
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id="testAccount.number.confirmation.dialog.title.confirm.number" />
      </DialogTitle>
      <DialogContent style={{ marginTop: '10px' }}>
        <DialogContentText component="span">
          <FormattedMessage 
            id="testAccount.number.confirmation.dialog.text.confirm.number"
            values={{ number: number }} 
          />
          {timeLimit && progress && (
            <TestAccountProgress timeLimit={timeLimit} value={progress} />
          )}
        </DialogContentText>
        <TextField
          helperText={error && <FormattedMessage id="testAccount.number.confirmation.dialog.helper.text.code.mismatch" />}
          error={error}
          style={{ marginTop: '40px' }}
          autoFocus
          variant="outlined"
          margin="dense"
          label={<FormattedMessage id="testAccount.number.confirmation.dialog.label.verification.code" />}
          fullWidth
          value={pin}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={customVerification ? () => verify?.(pin) : verifyNumber}
          color="primary"
          disabled={pin.length !== 6}
        >
          <FormattedMessage id="testAccount.number.confirmation.dialog.button.confirm" />
        </Button>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="common.button.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  (state: AppState) => {
    return {
      accountInfoUrl:
        state.login.userData &&
        state.login.userData._links &&
        state.login.userData._links.account_info,
    };
  },
  { getAccountInfo }
)(TestAccountNumberConfirmationDialog);
