import { AnyAction } from 'redux';

interface UbsOptOutState {
  optOuts: $TSFixMe[];
  optOutFormOpen: boolean;
  selectedEntry: $TSFixMe;
}

const initialState: UbsOptOutState = {
  optOuts: [],
  optOutFormOpen: false,
  selectedEntry: {},
};

export default function reducer(state: UbsOptOutState = initialState, action: AnyAction): UbsOptOutState {
  switch (action.type) {
    case 'SET_FORM_OPEN': {
      return {
        ...state,
        optOutFormOpen: action.payload,
      };
    }
    case 'ADD_OPTOUT_ENTRY': {
      return {
        ...state,
        optOuts: [...state.optOuts, action.payload],
      };
    }
    case 'REMOVE_OPTOUT_ENTRY': {
      return {
        ...state,
        optOuts: state.optOuts.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_OPTOUT_ENTRY': {
      const itemToReplace = {
        id: action.payload.id,
        number: action.payload.number,
        info: action.payload.info,
      };

      return {
        ...state,
        optOuts: [...state.optOuts.filter((item) => item.id !== action.payload.id), itemToReplace],
      };
    }
    case 'SET_OPTOUT_ENTRY_FOR_FORM': {
      const entry = state.optOuts.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedEntry: entry ? entry[0] : {},
      };
    }
    case 'SET_OPTOUT_ENTRIES': {
      return {
        ...state,
        optOuts: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
