import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const smsCampaignImage = require('../../styles/smscampaign.png');

const TestAccountDialogStep2 = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.2.create.campaigns" />
        </Typography>
        <Typography variant={'body2'} style={{ marginTop: 15 }} align="justify">
          <FormattedMessage id="testAccount.dialog.step.2.rely.on.management" />
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.2.design.interactions" />
        </Typography>
        <Typography variant={'body2'} align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.dialog.step.2.send.messages" />
        </Typography>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.2.create.workflows" />
        </Typography>
        <Typography variant={'body2'} align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.dialog.step.2.use.tool" />
        </Typography>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.2.track.results" />
        </Typography>
        <Typography variant={'body2'} style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.dialog.step.2.get.statistics" />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <img
          src={smsCampaignImage}
          width={150}
          style={{ marginLeft: '30px' }}
          alt="cmt"
        />
      </Grid>
    </Grid>
  );
};

export default TestAccountDialogStep2;
