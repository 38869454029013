import { AnyAction } from 'redux';

interface ApiKeyState {
  copyApiKeyDialogOpen: boolean;
  apiKey: string;
}

const initialState: ApiKeyState = {
  copyApiKeyDialogOpen: false,
  apiKey: '',
};

export default function reducer(state: ApiKeyState = initialState, action: AnyAction): ApiKeyState {
  switch (action.type) {
    case 'SET_COPY_API_KEY_DIALOG_OPEN': {
      return {
        ...state,
        copyApiKeyDialogOpen: action.payload,
      };
    }
    case 'SET_API_KEY': {
      return {
        ...state,
        apiKey: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
