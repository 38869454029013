import Validator from 'validatorjs';
import { SenderFormValidator } from './configuration.form';

const validate: SenderFormValidator = (values, props) => {
  const emailRule = values.id ? 'email_or_domain|required' : 'email_or_domain|uniq_sender|required';
  const rules = {
    email: emailRule,
    senderId: 'required',
  };

  Validator.register(
    'uniq_sender',
    (value: $TSFixMe) => {
      const { senders } = props;
      return !Boolean(senders.find((sender: $TSFixMe) => sender.email.toLowerCase() === value.toLowerCase()));
    },
    props.intl.formatMessage({
      id: 'form.validation.email.domain.duplicated',
    })
  );

  Validator.register(
    'email_or_domain',
    (value: $TSFixMe) => {
      if (value.startsWith('@')) {
        //check for domain
        return /(^@\w+([.-]?\w+)*(\.\w{2,5})+$)/.test(value);
      } else {
        // check for email
        return /(^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$)/.test(value);
      }
    },
    props.intl.formatMessage({
      id: 'form.validation.email.format',
    })
  );

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
  });

  validation.passes();
  return validation.errors.all();
};

export default validate;
