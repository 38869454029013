import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { doLogin, setSmsVerificationDialogShow } from '../../../actions/login';
import { FormattedMessage } from 'react-intl';
import { Theme } from '@mui/material';
import { AppState } from '../../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(1, 3, 3),
  },
}));

type Props = {
  readonly open: boolean;
  readonly error: boolean;
  readonly login: string;
  readonly password: string;
  readonly redirectUrl?: string;
  readonly setSmsVerificationDialogShow: (flag: boolean) => void ;
  readonly doLogin: (login: string, password: string, code?: string, redirectUrl?: string) => void;
};

const SmsVerificationDialog = ({
  open,
  login,
  password,
  doLogin,
  setSmsVerificationDialogShow,
  error,
  redirectUrl,
}: Props) => {
  const [code, setCode] = useState('');

  const classes = useStyles();

  const pressEnter = (event: $TSFixMe) => {
    if (event.charCode === 13) {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    doLogin(login, password, code, redirectUrl);
  };

  const handleCloseClick = () => {
    setCode('');
    setSmsVerificationDialogShow(false);
  };

  const handleChange = (event: $TSFixMe) => {
    setCode(event.target.value);
  };

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage id="login.verification.dialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          <FormattedMessage id="login.verification.dialog.text" />
        </DialogContentText>
        <>
          <Grid item xs={12}>
            <TextField
              helperText={
                error && <FormattedMessage id="login.verification.error.text" />
              }
              error={error}
              autoFocus
              margin="dense"
              id="verification"
              variant="outlined"
              label={<FormattedMessage id="login.verification.dialog.label" />}
              fullWidth
              required
              value={code}
              onChange={handleChange}
              onKeyPress={pressEnter}
            />
          </Grid>
        </>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={code.length !== 6 && !code.match('/^[0-9]{6}$/')}
        >
          <FormattedMessage id="common.button.confirm" />
        </Button>
        <Button variant="contained" onClick={handleCloseClick}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  (store: AppState) => ({
    error: store.login.failed,
  }), {
  doLogin,
  setSmsVerificationDialogShow,
})(SmsVerificationDialog);
