import { AnyAction } from 'redux';

interface AccountTokensState {
  copyTokenDialogOpen: boolean;
  token: string;
}

const initialState: AccountTokensState = {
  copyTokenDialogOpen: false,
  token: '',
};

export default function reducer(state: AccountTokensState = initialState, action: AnyAction): AccountTokensState {
  switch (action.type) {
    case 'SET_COPY_TOKEN_DIALOG_OPEN': {
      return {
        ...state,
        copyTokenDialogOpen: action.payload,
      };
    }
    case 'SET_TOKEN': {
      return {
        ...state,
        token: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
