import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import { appConfig } from '../../config';
import axios from 'axios';
import { extractIdFromHref, getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';

export function setWhitelistedSenders(items: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_WHITELISTED_SENDERS', payload: items });
  };
}

export function fetchSpamWhitelist(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_SPAM_LOADING', payload: true });
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/spam_whitelist`, config)
      .then((res) => {
        const data = res.data.map((e: $TSFixMe) => {
          return {
            id: extractIdFromHref(e.links.find((link: $TSFixMe) => link.rel === 'self').href),
            sender: e.sender,
          };
        });
        if (res.status === 200) dispatch(setWhitelistedSenders(data));
        dispatch({ type: 'SET_SPAM_LOADING', payload: false });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'SET_SPAM_LOADING', payload: false });
      });
  };
}

export function setSpamFormOpen(open: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_FORM_OPEN', payload: open });
  };
}

export function addSpamEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/spam_whitelist`, item, config)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          dispatch({
            type: 'ADD_SPAM_ENTRY',
            payload: {
              id: extractIdFromHref(res.data._links.self.href),
              sender: res.data.sender,
            },
          });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function removeSpamEntry(id: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .delete(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/spam_whitelist/${id}`, config)
      .then((res) => {
        if (res.status === 200) dispatch({ type: 'REMOVE_SPAM_ENTRY', payload: id });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateSpamEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/spam_whitelist/${item.id}`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'UPDATE_SPAM_ENTRY', payload: item });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function setSpamEntryForForm(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_ENTRY_FOR_FORM', payload: id });
  };
}
