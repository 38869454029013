import React from 'react';
import Badge from '@mui/material/Badge';

type Props = {
  readonly badgeContent?: React.ReactNode;
  readonly children?: React.ReactNode;
};

function NewMessageBadge({ children, badgeContent }: Props) {
  return (
    <Badge color="secondary" badgeContent={badgeContent} style={{ zIndex: 0 }}>
      {children}
    </Badge>
  );
}

export default NewMessageBadge;
