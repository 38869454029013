import { SupportedChatChannel } from '../supportedChatChannel';
import { ChatWsMessageType } from './chatWsWrapper';
import { ChatProxyInboundWsMessage } from './chatProxyWsMessage';
import { StompWsClient } from './stompWsClient';
import { v4 as uuidv4 } from 'uuid';

type ConnectionListener = {
  readonly onOpen: () => void;
  readonly onClose: () => void;
};

type MessageListener = {
  readonly channel: SupportedChatChannel,
  readonly msgType: ChatWsMessageType,
  readonly onWsMessage: (wsMessage: ChatProxyInboundWsMessage) => void,
};

type ListenersMap<T> = {
  [listenerId: string]: T;
};

type CustomerId = string | number;

type SubscriptionDetails = {
  readonly customerId: CustomerId;
  readonly subscriptionId: string;
};

export abstract class AbstractChatWsConnection {
  protected subscriptionDetails?: SubscriptionDetails;
  private connectionListenersMap: ListenersMap<ConnectionListener> = {};
  private messageListenersMap: ListenersMap<MessageListener> = {};

  protected constructor(protected readonly wsClient: StompWsClient) {}

  abstract subscribe(customerId: CustomerId): string;

  isOpen(): boolean {
    return this.wsClient.isConnected() && this.subscriptionDetails !== undefined;
  }

  open(customerId: CustomerId): void {
    if (this.isOpen()) {
      throw new Error('Chat proxy websocket connection is already open!');
    }

    this.wsClient.onConnect(() => {
      this.doSubscribe(customerId);
      this.notifyOpenConnectionListeners();
    });

    this.wsClient.onClose(() => {
      this.notifyCloseConnectionListeners();
    });

    this.wsClient.connect();
  }

  private doSubscribe(customerId: CustomerId): void {
    const subscriptionId = this.subscribe(customerId);

    this.subscriptionDetails = {
      customerId,
      subscriptionId,
    };
  }

  close(): void {
    if (this.isOpen()) {
      this.wsClient.unsubscribe(this.subscriptionDetails!.subscriptionId);
      this.subscriptionDetails = undefined;
    }

    this.wsClient.disconnect();
  }

  addConnectionListener(listener: ConnectionListener): string {
    const listenerId = `connectionListener-${uuidv4()}`;
    this.connectionListenersMap[listenerId] = listener;
    return listenerId;
  }

  removeConnectionListener(listenerId: string): void {
    delete this.connectionListenersMap[listenerId];
  }

  private notifyOpenConnectionListeners(): void {
    Object.values(this.connectionListenersMap).forEach(listener => listener.onOpen());
  }

  private notifyCloseConnectionListeners(): void {
    Object.values(this.connectionListenersMap).forEach(listener => listener.onClose());
  }

  addMessageListener(listener: MessageListener): string {
    const { channel, msgType } = listener;
    const listenerId = `${channel}-${msgType}-${uuidv4()}`;

    this.messageListenersMap[listenerId] = listener;

    return listenerId;
  }

  removeMessageListener(listenerId: string): void {
    delete this.messageListenersMap[listenerId];
  }

  protected notifyMessageListeners(chatWsMessage: ChatProxyInboundWsMessage): void {
    const { channel, type } = chatWsMessage;

    Object.values(this.messageListenersMap)
      .filter(listener => listener.channel === channel && listener.msgType === type)
      .forEach(listener => listener.onWsMessage(chatWsMessage));
  }
}
