import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { format, isValid } from 'date-fns';
import SelectTextField from '../common/SelectTextField';
import DatePicker from '../common/DatePicker';
import DateTimePicker from '../common/DateTimePicker';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { Option } from '../../reducers/app';

type Props = {
  readonly lang: string;
  readonly type?: $TSFixMe;
  readonly value: $TSFixMe;
  readonly column: $TSFixMe;
  readonly onKeyPress?: (event: $TSFixMe) => void;
  readonly handleFilter: (filter: $TSFixMe) => void;
};

const FilterField = (props: Props) => {
  const { column, lang, type, value } = props;

  const [filter, setFilter] = useState({}); // eslint-disable-line
  const [localizedFilterOptions, setLocalizedFilterOptions] = useState<Option[]>([]);

  const setInitialState = () => {
    if (column.dateFiltering) {
      const propName = type ? `${column.id}${type}` : column.id;
      return { [propName]: value || null };
    }
    return { [column.id]: value || '' };
  };

  const intl = useIntl();
  const state: $TSFixMe = setInitialState();

  const getLocalizedFilterOptions = () => {
    if (column.filterOptions && !Array.isArray(column.filterOptions)) {
      return Object.keys(column.filterOptions).map((key) => ({
        value: key,
        name: intl.formatMessage({ id: column.filterOptions[key] }),
      }));
    }
  
    return column.filterOptions || [];
  };

  useEffect(() => {
    const updatedOptions = getLocalizedFilterOptions();
    setLocalizedFilterOptions(updatedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleChange =
    (name: $TSFixMe, type = null) =>
      (event: $TSFixMe) => {
        if ((column.dateFiltering || column.timeFiltering) && !isValid(event))
          return;
        const value =
          event.constructor.name === 'Date'
            ? (column.timeFiltering)
              ? format(event, "yyyy-MM-dd'T'HH:mm")
              : format(event, 'yyyy-MM-dd')
            : event.target.value;
        const state = { [name]: value };
        const label =
          type !== null
            ? intl.formatMessage({
              id: props.column.label.props.id,
            }) +
            ' ' +
            intl.formatMessage({
              // @ts-expect-error TODO: no property toString
              id: `datatable.label.filters.${type.toString().toLowerCase()}`,
            })
            : props.column.label;
        props.handleFilter({
          name,
          value,
          numeric: props.column.numeric,
          labelForField: type !== null ? label : props.column.label,
          labelForValue: localizedFilterOptions
            ? localizedFilterOptions.filter(
              (item: $TSFixMe) => item.value === value
            )[0].name
            : props.column.timeFiltering
              ? value.replace('T', ' ')
              : value,
        });
        setFilter(state);
      };

  if (column.dateFiltering) {
    const label = intl.formatMessage({
      id: column.label.props.id,
    });
    const typeLabel = intl.formatMessage({
      id: `datatable.label.filters.${type.toString().toLowerCase()}`,
    });
    return column.timeFiltering ? (
      <DateTimePicker
        handleChangeDate={handleChange(`${column.id}${type}`, type)}
        selectedDate={state[`${column.id}${type}`]}
        label={`${label}\u00A0${typeLabel}`.replace(' ', `\u00A0`)}
      />
    ) : (
      <DatePicker
        handleChangeDate={handleChange(`${column.id}${type}`, type)}
        selectedDate={state[`${column.id}${type}`]}
        label={`${label}\u00A0${typeLabel}`.replace(' ', `\u00A0`)}
      />
    );
  }
  if (column.filterOptions) {
    return (
      <SelectTextField
        fields={localizedFilterOptions}
        label={column.label}
        selectedField={state[column.id]}
        handleChangeSelected={handleChange(column.id)}
      />
    );
  }

  return (
    <TextField
      fullWidth
      label={column.label}
      value={state[column.id]}
      onChange={handleChange(column.id)}
      variant="standard"
      onKeyPress={props.onKeyPress}
    />
  );
};

export default connect((state: AppState) => {
  return {
    lang: state.locale.lang,
  }
}, {})(FilterField);