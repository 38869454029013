import { AnyAction } from 'redux';

type Error = {
  readonly error_user_title?: string;
  readonly error_user_msg?: string;
};

interface WhatsAppTemplatesState {
  whatsAppTemplates: $TSFixMe[];
  selectedWhatsAppTemplate: $TSFixMe;
  whatsAppTemplatesLoading: boolean;
  whatsAppTemplatesFormLoading: boolean;
  whatsAppIdOptions: $TSFixMe[];
  whatsAppIdsLoading: boolean;
  selectedWhatsAppId: string;
  error: Error,
  validationErrorDialogOpen: boolean;
  selectedWhatsAppTemplateInLanguage: $TSFixMe;
}

const initialState: WhatsAppTemplatesState = {
  whatsAppTemplates: [],
  whatsAppTemplatesLoading: false,
  selectedWhatsAppTemplate: {},
  whatsAppTemplatesFormLoading: false,
  whatsAppIdOptions: [],
  whatsAppIdsLoading: false,
  selectedWhatsAppId: '',
  error: {},
  validationErrorDialogOpen: false,
  selectedWhatsAppTemplateInLanguage: {},
};

export default function reducer(state: WhatsAppTemplatesState = initialState, action: AnyAction): WhatsAppTemplatesState {
  switch (action.type) {
    case 'SET_WHATSAPP_TEMPLATES_LOADING': {
      return {
        ...state,
        whatsAppTemplatesLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_TEMPLATES': {
      return {
        ...state,
        whatsAppTemplates: action.payload,
      };
    }
    case 'SET_WHATSAPP_TEMPLATE_FORM_LOADING': {
      return {
        ...state,
        whatsAppTemplatesFormLoading: action.payload,
      };
    }
    case 'ADD_WHATSAPP_TEMPLATE': {
      return {
        ...state,
        whatsAppTemplates: [...state.whatsAppTemplates, action.payload],
      };
    }
    case 'REMOVE_WHATSAPP_TEMPLATE': {
      return {
        ...state,
        whatsAppTemplates: state.whatsAppTemplates.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_WHATSAPP_TEMPLATE': {
      const itemToReplace = {
        name: action.payload.name,
        language: action.payload.language,
        category: action.payload.category,
      };
      return {
        ...state,
        whatsAppTemplates: [
          ...state.whatsAppTemplates.filter((item) => item.id !== action.payload.id),
          itemToReplace,
        ],
      };
    }
    case 'SET_WHATSAPP_TEMPLATE_FOR_FORM': {
      return {
        ...state,
        selectedWhatsAppTemplate: action.payload,
      };
    }
    case 'SET_WHATSAPP_IDS_LOADING': {
      return {
        ...state,
        whatsAppIdsLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_IDS': {
      return {
        ...state,
        whatsAppIdOptions: action.payload,
      };
    }
    case 'SET_SELECTED_WHATSAPP_ID': {
      return {
        ...state,
        selectedWhatsAppId: action.payload,
      };
    }
    case 'SET_VALIDATION_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'SET_VALIDATION_ERROR_DIALOG_OPEN': {
      return {
        ...state,
        validationErrorDialogOpen: action.payload,
      };
    }
    case 'SET_WHATSAPP_TEMPLATE_IN_LANGUAGE_FOR_FORM': {
      return {
        ...state,
        selectedWhatsAppTemplateInLanguage: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
