import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { connect } from 'react-redux';
import {
  setSignUpFormDialogShowFlag,
  setSignUpFormSubmitResult,
} from '../../actions/signup';
import { AppState } from '../../reducers';

type Props = {
  readonly open: boolean;
  readonly signUpFormSubmitResult: { result: $TSFixMe };
  readonly reset: () => void;
  readonly setSignUpFormDialogShowFlag: (show: boolean) => void;
};

const ConfirmationDialog = ({
  open,
  setSignUpFormDialogShowFlag,
  signUpFormSubmitResult,
  reset,
}: Props) => {

  const handleCloseClick = () => {
    setSignUpFormDialogShowFlag(false);
    if (signUpFormSubmitResult && signUpFormSubmitResult.result) reset();
  };

  return (
    <Dialog
      open={open}
      style={{ textAlign: 'center' }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage id="signup.confirmation.dialog.title" />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CheckCircleOutlineIcon
            color={'secondary'}
            style={{ fontSize: 120 }}
          />
        </Grid>
        <DialogContentText component="span">
          <FormattedMessage id="signup.confirmation.success.message" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCloseClick}
          style={{ margin: '0 auto 16px' }}
        >
          <FormattedMessage id="common.button.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(
  (state: AppState) => {
    return {
      signUpFormDialogShow: state.signup.signUpFormDialogShow,
      signUpFormSubmitResult: state.signup.signUpFormSubmitResult,
    };
  },
  { setSignUpFormDialogShowFlag, setSignUpFormSubmitResult }
)(ConfirmationDialog);
