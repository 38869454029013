import smsChatConfig from './smsChatConfig';
import { ChatApi, ChatChannel, ChatConfig, ChatListener, ChatPublisher } from '../interface';
import SmsChatApi from './smsChatApi';
import SmsChatPublisher from './smsChatPublisher';
import SmsChatListener from './smsChatListener';

class SmsChatChannel implements ChatChannel {
  readonly api: ChatApi;
  readonly config: ChatConfig;
  readonly listener: ChatListener;
  readonly publisher: ChatPublisher;

  constructor() {
    this.config = smsChatConfig;
    this.api = new SmsChatApi();
    this.listener = new SmsChatListener();
    this.publisher = new SmsChatPublisher();
  }
}

export default new SmsChatChannel();
