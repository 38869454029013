import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import { BeatLoader } from 'react-spinners';
import theme from '../../theme';
import { ButtonBase, Theme } from '@mui/material';
import stylesConfig from '../../config';

type Props = {
  readonly info?: $TSFixMe;
  readonly icon: $TSFixMe;
  readonly label: $TSFixMe;
  readonly value: $TSFixMe;
  readonly color: $TSFixMe;
  readonly button?: boolean;
  readonly fullwidth?: boolean;
  readonly variant?: string;
  readonly style?: object;
  readonly onClick?: () => void;
  readonly handleMore?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
  },
  content: {
    flex: '1 0 auto',
    padding: '0px 15px 0px',
    justifyContent: 'center',
    '&:last-child': {
      padding: '0px 15px 0px',
    },
  },
  icon: {
    width: 80,
    height: 80,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  moreIcon: {
    color: grey[100],
  },
  infoIcon: {
    color: grey[500],
    height: 28,
    width: 28,
  },
  infoButton: {
    padding: 0,
  },
  value: {
    color: grey[700],
    fontWeight: 500,
  },
  divider: {
    backgroundColor: grey[200],
  },
  label: {
    color: grey[600],
    marginBottom: 4,
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const InfoBox = (props: Props) => {
  const {
    value,
    label,
    color,
    handleMore,
    icon,
    variant = 'normal',
    info = '',
    fullwidth = false,
    button = false,
    onClick,
  } = props;

  const small = variant === 'small';
  const mini = variant === 'mini';
  const classes = useStyles();

  const Icon = () =>
    React.cloneElement(icon, {
      style: {
        width: mini ? 24 : small ? 32 : 48,
        height: mini ? 254 : small ? 32 : 48,
      },
    });

  const getBackgroundColor = () => {
    switch (stylesConfig.THEME) {
      case 'sunrise':
        return theme.palette.infobox.color;
      case 'telxira':
        // @ts-expect-error TODO: fix after providing type
        return theme.palette.infobox.color[color];
      // @ts-expect-error TODO: fix after providing type
      default: return theme.palette.infobox.color[color];
    }
  };

  const card = (
    <Card
      className={classes.card}
      style={{ width: fullwidth ? '100%' : undefined }}
    >
      <div
        style={{
          width: mini ? 40 : small ? 48 : 80,
          height: mini ? 40 : small ? 48 : 80,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: getBackgroundColor(),
        }}
      >
        <Avatar
          style={{
            backgroundColor: getBackgroundColor(),
            color: grey[50],
            height: mini ? 24 : small ? 48 : 64,
            width: mini ? 24 : small ? 48 : 64,
          }}
        >
          <Icon />
        </Avatar>
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <div style={{ display: 'flex' }}>
            <Typography
              className={classes.label}
              variant={mini ? 'caption' : small ? 'caption' : 'button'}
              //style={{ marginBottom: mini ? '0px' : '4px' }}
              style={{ marginBottom: 0 }}
            >
              {label}
            </Typography>
            {info && (
              <Tooltip title={info} classes={{ tooltip: classes.lightTooltip }}>
                <IconButton onClick={handleMore} className={classes.infoButton} size="large">
                  <Info className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Divider
            className={classes.divider}
            style={{ backgroundColor: getBackgroundColor() }}
          />
          {value === undefined ? (
            <BeatLoader
            // @ts-expect-error TODO: no property sizeUnit
              sizeUnit={'px'}
              size={5}
              color={theme.palette.text.secondary}
            />
          ) : (
            <Typography
              className={classes.value}
              variant={mini ? 'caption' : small ? 'body2' : 'h4'}
              style={{
                fontWeight: mini ? 400 : 500,
              }}
            >
              {value}
            </Typography>
          )}
        </CardContent>
      </div>
      {handleMore && (
        <IconButton onClick={handleMore} size="large">
          <MoreVertIcon className={classes.moreIcon} />
        </IconButton>
      )}
    </Card>
  );
  return button ? <ButtonBase onClick={onClick}>{card}</ButtonBase> : card;
};

export default InfoBox;
