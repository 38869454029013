import { GetWhatsappPricing, PostWhatsappPricing, WhatsappCountry, WhatsappMonthlyFee } from '../../types/meta/whatsappPricing';
import { HttpClient } from '../common/httpClient';

type GetWhatsappPricingResponse = {
  readonly id: string;
  readonly countryName: string;
  readonly breakout: string;
  readonly marketing: number;
  readonly utility: number;
  readonly authentication: number;
  readonly service: number;
};

type WhatsappMonthlyFeeResponse = {
  readonly fee: number;
};

type WhatsappCountryResponse = {
  readonly dialCode: string;
  readonly name: string;
  readonly code: string;
};

export class WhatsappPricingApi {
  constructor(private readonly httpClient: HttpClient) { }

  private mapToGetWhatsappPricing(response: GetWhatsappPricingResponse):GetWhatsappPricing {
    return {
      id: response.id,
      countryName: response.countryName,
      breakout: response.breakout,
      marketing: response.marketing,
      utility: response.utility,
      authentication: response.authentication,
      service: response.service,
    };
  }

  private mapToWhatsappMonthlyFee(response: WhatsappMonthlyFeeResponse): WhatsappMonthlyFee {
    return {
      fee: response.fee,
    };
  }

  private mapToWhatsappCountry(response: WhatsappCountryResponse): WhatsappCountry {
    return {
      dialCode: response.dialCode,
      name: response.name,
      code: response.code,
    };
  }

  getAll(tenantId: string): Promise<GetWhatsappPricing[]> {
    return this.httpClient.get<GetWhatsappPricingResponse[]>(`price-list/${tenantId}`)
      .then(data => (
        data.map(it => this.mapToGetWhatsappPricing(it))
      ));
  }

  getMonthlyFee(tenantId: string): Promise<WhatsappMonthlyFee> {
    return this.httpClient.get<WhatsappMonthlyFeeResponse>(`price-list/${tenantId}/monthly-fee`)
    .then(data => this.mapToWhatsappMonthlyFee(data));
  }

  getCountries(): Promise<WhatsappCountry[]> {
    return this.httpClient.get<WhatsappCountry[]>(`price-list/countries`)
      .then(data => (
        data.map(it => this.mapToWhatsappCountry(it))
      ));
  }

  async updateMonthlyFee(tenantId: string, fee: number): Promise<void> {
    await this.httpClient.put<WhatsappMonthlyFee>(`price-list/${tenantId}/monthly-fee`, { fee });
  }

  async add(tenantId: string, values: PostWhatsappPricing): Promise<void> {
    await this.httpClient.post<PostWhatsappPricing>(`price-list/${tenantId}/entry`, values);
  }

  async update(tenantId: string, item: PostWhatsappPricing): Promise<void> {
    await this.httpClient.put<PostWhatsappPricing>(`price-list/${tenantId}/entry`, item);
  }

  async delete(tenantId: string, id: string): Promise<void> {
    await this.httpClient.delete<GetWhatsappPricing>(`price-list/${tenantId}/entry/${id}`);
  }
}
