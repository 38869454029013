import { AnyAction } from 'redux';

interface UbsPerformanceState {
  performanceOpen: boolean;
}

const initialState: UbsPerformanceState = {
  performanceOpen: false,
};

export default function reducer(state: UbsPerformanceState = initialState, action: AnyAction): UbsPerformanceState {
  switch (action.type) {
    case 'SET_PERFORMANCE_OPEN': {
      return {
        ...state,
        performanceOpen: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
