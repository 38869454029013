import { AnyAction } from 'redux';

interface SignupState {
  signUpFormSubmitResult?: $TSFixMe;
  signUpFormDialogShow: boolean;
}

const initialState: SignupState = {
  signUpFormSubmitResult: undefined,
  signUpFormDialogShow: false,
};

export default function reducer(state : SignupState = initialState, action: AnyAction): SignupState {
  switch (action.type) {
    case 'SET_SIGNUP_FORM_SUBMIT_RESULT': {
      return {
        ...state,
        signUpFormSubmitResult: action.payload,
      };
    }
    case 'SET_SIGNUP_FORM_DIALOG_SHOW_FLAG': {
      return {
        ...state,
        signUpFormDialogShow: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
