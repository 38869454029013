import { AppThunkAction } from './index';

export function showSnackBarMsg(msg: $TSFixMe, variant = 'success'): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'ADD_SNACKBAR', msg, variant });
    if (getState().snackbar.open === true) {
      dispatch({ type: 'CLOSE_SNACKBAR' });
    } else {
      dispatch({ type: 'PROCESS_SNACKBAR_QUEUE' });
    }
  };
}

export function hideSnackBarMsg(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'CLOSE_SNACKBAR',
    });
  };
}

export function processSnackbarQueue(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'PROCESS_SNACKBAR_QUEUE',
    });
  };
}
