import axios from 'axios';
import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import green from '../../../node_modules/@mui/material/colors/green';
import { appConfig } from '../../config';
import BaseLoginView from '../common/logins/BaseLoginView';
import { goToLogin } from '../../actions/changePassword';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Validator from 'validatorjs';
import { useWithWidthUp } from '../common/useWithWidth';

const useStyles = makeStyles(() => ({
  messageError: {
    color: 'red',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
}));

type Props = {
  readonly goToLogin: () => void;
};

const ResetPassword = (props: Props) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetDone, setResetDone] = useState(false);
  const [resetFailed, setResetFailed] = useState(false); // eslint-disable-line
  const [message, setMessage] = useState('');

  const pressEnter = (event: $TSFixMe) => {
    if (event.charCode === 13) handleSubmit();
  };

  const handleSubmit = () => {
    resetPassword(email);
  };

  const handleChange = (name: string) => (event: $TSFixMe) => {
    setEmail(event.target.value);
    setMessage('');
  };

  const cancel = () => {
    const { goToLogin } = props;
    goToLogin();
  };

  const resetPassword = (email: string) => {
    const data = { email };
    const rules = { email: 'required|email' };
    const validation = new Validator(data, rules);

    if (validation.fails()) {
      setMessage('empty');
      return;
    }
    setIsLoading(true);
    const url = `${appConfig.URL_REST}users/reset-password`;
    axios
      .post(url, email, {
        headers: { 'Content-Type': 'text/plain' },
      })
      .then((response) => {
        if (response && response.status === 200) {
          setIsLoading(false);
          setResetDone(true);
          setMessage('');
        }
        if (response && response.status === 404) {
          setIsLoading(false);
          setResetFailed(true);
          setMessage('notfound');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          setIsLoading(false);
          setMessage('notfound');
        } else {
          setIsLoading(false);
          setMessage('error');
        };
      });
  };

  const classes = useStyles();
  const isWidthUpMd = useWithWidthUp('md');
  const spacing = isWidthUpMd ? 3 : 1;

  const content = (
    <Grid
      container
      spacing={spacing}
      style={{ minHeight: 250 }}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant={'caption'} color={'error'}>
          {message === 'empty' && (
            <FormattedMessage id="form.validation.email.format" />
          )}
          {message === 'notfound' && (
            <FormattedMessage id="dialog.change.password.button.reset.password.not.found" />
          )}
          {message === 'error' && (
            <FormattedMessage id="data.fetching.bad.request" />
          )}
        </Typography>
      </Grid>

      {resetDone ? (
        <>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CheckCircleOutlineIcon
              color={'secondary'}
              style={{ fontSize: 120 }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'body2'}>
              <FormattedMessage id="components.reset.password.link.sent" />
              <br />
              <FormattedMessage id="components.reset.password.follow.instructions" />
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={cancel}
              style={{ marginLeft: '10px' }}
            >
              <FormattedMessage id="components.reset.password.button.ok" />
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            style={{ alignItems: 'center', alignContent: 'center' }}
          >
            <Typography variant={'body2'}>
              <FormattedMessage id="components.reset.password.email.accociated.with.account" />
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <TextField
              label={<FormattedMessage id="common.label.email" />}
              fullWidth
              variant="standard"
              value={email}
              onChange={handleChange('email')}
              onKeyPress={pressEnter}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ alignItems: 'center', display: 'flex' }}
          >
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                disabled={isLoading || !email}
                color="secondary"
                onClick={handleSubmit}
              >
                <FormattedMessage id="dialog.change.password.button.reset.password" />
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>

            <Button
              variant="contained"
              color="primary"
              onClick={cancel}
              style={{ marginLeft: '10px' }}
            >
              <FormattedMessage id="common.button.cancel" />
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <BaseLoginView
      title={
        <FormattedMessage id="dialog.change.password.button.reset.password" />
      }
      subheader=""
      content={content}
    />
  );
};

export default connect(null, { goToLogin })(ResetPassword);
