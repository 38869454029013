import { AnyAction } from 'redux';
import { CustomerAdditionalFeeEntry } from '../../types/customerAdditionalFee';

interface PlAdditionalFeeState {
  plAdditionalFees: CustomerAdditionalFeeEntry[];
  selectedPlAdditionalFee: Partial<CustomerAdditionalFeeEntry>;
  plAdditionalFeesLoading: boolean;
}

const initialState: PlAdditionalFeeState = {
  plAdditionalFees: [],
  selectedPlAdditionalFee: {},
  plAdditionalFeesLoading: false,
};

export default function reducer(state: PlAdditionalFeeState = initialState, action: AnyAction): PlAdditionalFeeState {
  switch (action.type) {
    case 'SET_PL_ADDITIONAL_FEES_LOADING': {
      return {
        ...state,
        plAdditionalFeesLoading: action.payload,
      };
    }
    case 'ADD_PL_ADDITIONAL_FEE': {
      return {
        ...state,
        plAdditionalFees: [...state.plAdditionalFees, { id: state.plAdditionalFees.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_PL_ADDITIONAL_FEE': {
      return {
        ...state,
        plAdditionalFees: state.plAdditionalFees.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_PL_ADDITIONAL_FEE': {
      const itemToReplace = {
        item: action.payload.item,
        amount: action.payload.amount,
        oneoff: action.payload.oneoff,
      };
      return {
        ...state,
        plAdditionalFees: [
          // @ts-expect-error itemToReplace does not contain all CustomerAdditionalFeeEntry properties - to verify and fix!
          ...state.plAdditionalFees.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerAdditionalFeeEntry properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_PL_ADDITIONAL_FEE_FOR_FORM': {
      return {
        ...state,
        selectedPlAdditionalFee: action.payload,
      };
    }
    case 'SET_PL_ADDITIONAL_FEES': {
      return {
        ...state,
        plAdditionalFees: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
