import chatProxyWsConnection from './chatProxyWsConnection';
import chatChannelProvider from '../chatChannelProvider';
import { ChatListener, ChatListenerCallbacks } from '../interface';
import backendChatWsConnection from './backendChatWsConnection';

type CustomerInfo = {
  readonly customerId: number;
  readonly customerUuid: string;
};

class ChatWebsocketManager {
  private readonly chatListeners: ChatListener[];

  constructor() {
    this.chatListeners = chatChannelProvider.all()
      .map(chatChannel => chatChannel.listener);
  }

  openConnection(customerInfo: CustomerInfo): void {
    console.log('Opening chat websocket connections...');
    const { customerId, customerUuid } = customerInfo;

    backendChatWsConnection.open(customerId);
    chatProxyWsConnection.open(customerUuid);
  }

  closeConnection(): void {
    console.log('Closing chat websocket connections...');

    chatProxyWsConnection.close();
    backendChatWsConnection.close();
  }

  registerListeners(callbacks: ChatListenerCallbacks): void {
    this.chatListeners.forEach(listener => listener.register(callbacks));

    console.log('Chat listeners are registered');
  }

  unregisterListeners(): void {
    this.chatListeners.forEach(listener => listener.unregister());

    console.log('Chat listeners are unregistered');
  }
}

export default new ChatWebsocketManager();
