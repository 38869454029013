/* eslint max-len: 0 */

import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

type Props = {
  readonly company?: string;
  readonly fromDashboard?: string;
};

const HelpAdminEmail2Sms = (props: Props) => {
  return (
    <div style={{ padding: 5 }}>
      <Typography variant="body1" style={{ marginTop: 15, marginBottom: 10 }}>
        {props.fromDashboard ? (
          <Button component={Link} to="/administration/email2sms" size="small" color="secondary">
            <FormattedMessage id="nav.email2sms" />
          </Button>
        ) : undefined}
      </Typography>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.administration.email2sms" />
        </Typography>
        <p></p>
        <div style={{ paddingLeft: 15, paddingRight: 15, textAlign: 'justify' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.email2sms.1" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.email2sms.2" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.email2sms.3" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.administration.email2sms.4" />
          </Typography>
        </div>
        <p></p>
        {!props.fromDashboard && (
          <>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.5" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.6" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.7" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.8" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.9" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.10" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.11" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.12" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.13" />
            </Typography>
            <p></p>
            <Typography variant="body2" align="justify">
              <FormattedMessage id="help.administration.email2sms.14" />
            </Typography>
            <p></p>
            <Typography variant="subtitle2" align="justify">
              <FormattedMessage id="help.administration.email2sms.15" />
            </Typography>
            <p></p>
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Typography variant="subtitle2" align="justify">
                <FormattedMessage id="help.administration.email2sms.16" />
              </Typography>
              <p></p>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.email2sms.17" />
              </Typography>
              <p></p>
              <Typography variant="subtitle2" align="justify">
                <FormattedMessage id="help.email2sms.2" />
              </Typography>
              <p></p>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.email2sms.18" />
              </Typography>
              <p></p>
              <Typography variant="subtitle2" align="justify">
                <FormattedMessage id="help.email2sms.4" />
              </Typography>
              <p></p>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.email2sms.19" />
              </Typography>
              <p></p>
              <Typography variant="subtitle2" align="justify">
                <FormattedMessage id="administration.mtApi.tab.label.country.override" />
              </Typography>
              <p></p>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.email2sms.20" />
              </Typography>
              <p></p>
              <Typography variant="subtitle2" align="justify">
                <FormattedMessage id="help.administration.email2sms.22" />
              </Typography>
              <p></p>
              <Typography variant="body2" align="justify">
                <FormattedMessage id="help.administration.email2sms.21" />
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HelpAdminEmail2Sms;
