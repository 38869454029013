import { v4 as uuidv4 } from 'uuid';
import {
  addOptOutEntry,
  updateOptOutEntry,
} from '../../../../actions/ubs/ubs.optout';
import { OptOutFormSubmit } from './optput.form';

const submitForm: OptOutFormSubmit = (values, dispatch, props) => {
  dispatch(
    values.id
      ? updateOptOutEntry(values)
      : addOptOutEntry({ ...values, id: uuidv4() })
  );
}
export default submitForm;
