import { Timestamp } from '../types/datetime';
import { format, fromUnixTime, getUnixTime, parseISO, parseJSON } from 'date-fns';

class DateTimeService {
  getCurrentTimestamp(): Timestamp {
    return getUnixTime(new Date());
  }

  convertIsoDateToTimestamp(isoDate: string): Timestamp {
    return getUnixTime(parseISO(isoDate));
  }

  convertDateStringToTimestamp(dateString: string): Timestamp {
    return getUnixTime(parseJSON(dateString));
  }

  formatCurrentTimestamp(pattern: string): string {
    return this.formatTimestamp(this.getCurrentTimestamp(), pattern);
  }

  formatTimestamp(timestamp: Timestamp, pattern: string): string {
    return format(fromUnixTime(timestamp), pattern);
  }
}

export default new DateTimeService();
