import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import SelectLanguage from '../SelectLanguage';

import stylesConfig from '../../../config';
import { Button, Theme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { setDialogOpen } from '../../../actions/app';
import TermsConditionsDialog from '../../../TermsConditionsDialog';
import { SupportedLang } from '../../../messages';

const getBackgroundColor = () => {
  switch (stylesConfig.THEME) {
    case 'gms':
      return 'rgba(255, 255, 255, 0.9)';

    default:
      return 'rgba(255, 255, 255, 0.7)';
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  copyrightCaption: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  loginFooter: {
    background: getBackgroundColor(),
    flexBasis: 64,
    display: 'flex',
    alignItems: 'center',
  },
  footerLink: {
    textDecoration: 'none',
    color: grey[900],
    '&:hover': {
      color: grey[700],
    },
  },
  tcLink: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: grey[700],
    },
    textTransform: 'none',
    margin: 0,
    padding: '0 10px',
    fontWeight: 400,
    fontSize: 'small',
  },
}));

type DialogType = 'termsConditions' | 'dataProcessing';

type Props = {
  readonly lang: SupportedLang;
  readonly dialogOpen: boolean;
  readonly setDialogOpen: (open: boolean) => void;
}

const LoginScreen = (props: Props) => {
  const {
    lang,
    dialogOpen,
    setDialogOpen,
  } = props;
  const classes = useStyles();
  const [dialogType, setDialogType] = React.useState<DialogType | null>(null);

  const handleDialogOpen = (type: DialogType) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  return (
    <div className={classes.loginFooter}>
      <Typography className={classes.copyrightCaption}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes.footerLink}
          href={`${stylesConfig.FOOTER_LINK}`}
        >
          {`© ${new Date().getFullYear()} | (v${stylesConfig.APP_VERSION})`}
        </a>
      </Typography>
      <Typography variant='caption' component='span'>
        <Button
          className={classes.tcLink}
          onClick={() => handleDialogOpen('termsConditions')}
        >
          <FormattedMessage id='dashboard.terms.conditions' />
        </Button>
      </Typography>
      <Typography variant='caption' component='span'>
        <Button
          className={classes.tcLink}
          onClick={() => handleDialogOpen('dataProcessing')}
        >
          <FormattedMessage id='dashboard.data.processing' />
        </Button>
      </Typography>
      <SelectLanguage />
      <TermsConditionsDialog
        open={dialogOpen}
        language={lang}
        dialogType={dialogType}
        setDialogOpen={setDialogOpen}
      />
    </div>
  );
};

export default connect(
  (state: AppState) => {
    return {
      lang: state.locale.lang,
      dialogOpen: state.app.dialogOpen,
    };
  },
  { setDialogOpen }
)(LoginScreen);
