import { AnyAction } from 'redux';

interface UbsMo {
  readonly id: number;
  readonly pattern: string;
  readonly email: string;
}

interface UbsMoState {
  mo: UbsMo[];
  moFormOpen: boolean;
  selectedEntry: $TSFixMe;
}

const initialState: UbsMoState = {
  mo: [
    { id: 1, pattern: "t1", email: "christof.strehler@ubs.com" },
    { id: 2, pattern: "cl0", email: "christof.strehler@ubs.com" },
    { id: 3, pattern: "z1", email: "christof.strehler@ubs.com" },
  ],
  moFormOpen: false,
  selectedEntry: {},
};

export default function reducer(state: UbsMoState = initialState, action: AnyAction): UbsMoState {
  switch (action.type) {
    case "SET_FORM_OPEN": {
      return {
        ...state,
        moFormOpen: action.payload,
      };
    }
    case "ADD_MO_ENTRY": {
      return {
        ...state,
        mo: [...state.mo, action.payload],
      };
    }
    case "REMOVE_MO_ENTRY": {
      return {
        ...state,
        mo: state.mo.filter((item) => item.id !== action.payload),
      };
    }

    case "UPDATE_MO_ENTRY": {
      const itemToReplace = {
        id: action.payload.id,
        pattern: action.payload.pattern,
        email: action.payload.email,
      };

      return {
        ...state,
        mo: [
          ...state.mo.filter((item) => item.id !== action.payload.id),
          itemToReplace,
        ],
      };
    }
    case "SET_MO_ENTRY_FOR_FORM": {
      const entry = state.mo.filter((item) => item.id === action.payload);
      return {
        ...state,
        selectedEntry: entry ? entry[0] : {},
      };
    }
    default:
      return {
        ...state,
      };
  }
}
