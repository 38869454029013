import { AnyAction } from 'redux';
import { CustomerMoPricing } from '../../types/customerMoPricing';

interface MoPricingState {
  moPricings: CustomerMoPricing[];
  selectedMoPricing: Partial<CustomerMoPricing>;
  moPricingsLoading: boolean;
}

const initialState: MoPricingState = {
  moPricings: [],
  selectedMoPricing: {},
  moPricingsLoading: false,
};

export default function reducer(state: MoPricingState = initialState, action: AnyAction): MoPricingState {
  switch (action.type) {
    case 'SET_MO_PRICINGS_LOADING': {
      return {
        ...state,
        moPricingsLoading: action.payload,
      };
    }
    case 'ADD_MO_PRICING': {
      return {
        ...state,
        moPricings: [...state.moPricings, { id: state.moPricings.length + 1, ...action.payload }],
      };
    }
    case 'REMOVE_MO_PRICING': {
      return {
        ...state,
        moPricings: state.moPricings.filter((item) => item.id !== action.payload),
      };
    }

    case 'UPDATE_MO_PRICING': {
      const itemToReplace = {
        destinationName: action.payload.destinationName,
        breakout: action.payload.breakout,
        rate: action.payload.rate,
      };
      return {
        ...state,
        moPricings: [
          // @ts-expect-error itemToReplace does not contain all CustomerMoPricing properties - to verify and fix!
          ...state.moPricings.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all CustomerMoPricing properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MO_PRICING_FOR_FORM': {
      return {
        ...state,
        selectedMoPricing: action.payload,
      };
    }
    case 'SET_MO_PRICINGS': {
      return {
        ...state,
        moPricings: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
