import { AnyAction } from 'redux';

interface QuickTestSmsState {
  contacts: $TSFixMe[];
  contactsPageSize: number;
  contactsAllElements: number;
  filter: string;
  loadingContacts: boolean;
}

const initialState: QuickTestSmsState = {
  contacts: [],
  contactsPageSize: 10,
  contactsAllElements: 0,
  filter: '',
  loadingContacts: false,
};

export default function reducer(state: QuickTestSmsState = initialState, action: AnyAction): QuickTestSmsState {
  switch (action.type) {
    case 'SET_CONTACTS_FOR_SMS': {
      return {
        ...state,
        contacts: action.payload,
      };
    }
    case 'SET_CONTACTS_ALL_ELEMENTS': {
      return {
        ...state,
        contactsAllElements: action.payload,
      };
    }
    case 'SET_CONTACTS_PAGE_SIZE': {
      return {
        ...state,
        contactsPageSize: action.payload,
      };
    }
    case 'SET_LOADING_CONTACTS_FOR_SMS': {
      return {
        ...state,
        loadingContacts: action.payload,
      };
    }
    case 'SET_CONTACTS_FOR_SMS_FILTER': {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case 'RESET_INITIAL_STATE':
      return {
        ...initialState,
      }; 

    default:
      return {
        ...state,
      };
  }
}
