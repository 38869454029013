import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    position: 'absolute',
    top: -10,
    right: 35,
  },
}));

type Props = {
  readonly order?: 'asc' | 'desc';
  readonly orderBy: string;
  readonly rowCount: number;
  readonly numSelected: number;
  readonly columnData: $TSFixMe[];
  readonly showCheckbox: boolean;
  readonly actionLoading: boolean;
  readonly onFilter: $TSFixMe;
  readonly onRequestSort: (property: $TSFixMe) => void;
  readonly onSelectAllClick: (event: $TSFixMe, checked: boolean) => void;
};

const DataTableHead = (props: Props) => {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    columnData,
    showCheckbox,
    actionLoading,
    onSelectAllClick,
  } = props;
  
  const classes = useStyles();

  const createSortHandler = (property: $TSFixMe) => (event: $TSFixMe) => {
    props.onRequestSort(property);
  };

  return (
    <TableHead style={{ display: 'table-header-group' }}>
      <TableRow>
        {showCheckbox && (
          <TableCell
            padding="checkbox"
            style={{
              borderBottom: !props.onFilter
                ? '1px solid rgba(224, 224, 224, 1)'
                : 0,
            }}
          >
            <Checkbox
              // style={{ height: 32, width: 32 }}
              color="secondary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columnData.map((column: $TSFixMe) => {
          if (column.visible) {
            return (
              <TableCell
                style={{
                  whiteSpace: 'nowrap',
                  borderBottom: !props.onFilter
                    ? '1px solid rgba(224, 224, 224, 1)'
                    : 0,
                }}
                key={column.id}
                align={column.align ? column.align : 'left'}
                {...(column.disablePadding
                  ? { size: 'small' }
                  : { padding: 'normal' })}
              // padding={column.disablePadding ? 'dense' : 'default'}
              >
                {column.allowSort ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  column.label
                )}
                {column.id === 'actions' && actionLoading && (
                  <CircularProgress
                    className={classes.spinner}
                    variant="indeterminate"
                    size={16}
                    thickness={4}
                  />
                )}
              </TableCell>
            );
          }
          return <React.Fragment key={column.id} />;
        })}
      </TableRow>
    </TableHead>
  );
}

export default DataTableHead;
