import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 0,
  },
  label: {
    left: '-14px',
    top: '6px',
  },
}));

type Props = {
  readonly label: string;
  readonly children: $TSFixMe[];
  readonly required: boolean;
  readonly loading: boolean;
  readonly multiple: boolean;
  readonly fullWidth: boolean;
  readonly myOnChange: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { value: string, onChange: (value: $TSFixMe) => void };
};

const RenderSelectField = ({
  input,
  required,
  label,
  meta: { touched, error },
  children,
  multiple,
  fullWidth,
  loading,
  myOnChange,
  ...custom
}: Props) => {
  const { onChange, ...rest } = input;
  const classes = useStyles();

  return (
    <FormControl
      required={required}
      style={{ width: fullWidth ? '100%' : 'auto', marginTop: 5 }}
    >
      <InputLabel
        error={touched && Boolean(error)}
        htmlFor={label}
        className={classes.label}
      >
        {label}
      </InputLabel>
      <Select
        variant="standard"
        IconComponent={
          loading
            ? () => (
              <CircularProgress
                variant="indeterminate"
                className={classes.spinner}
                size={16}
                thickness={4}
              />
            )
            : undefined
        }
        multiple={multiple}
        error={touched && Boolean(error)}
        disabled={loading}
        {...rest}
        onChange={(e) => {
          const { value } = e.target;
          if (myOnChange) myOnChange(value);
          onChange(value);
        }}
        {...custom}
      >
        {children}
      </Select>
      {touched && Boolean(error) && (
        <FormHelperText error sx={{ margin: '3px 0 0' }}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderSelectField;
