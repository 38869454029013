/* eslint max-len: 0 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Help from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage } from 'react-intl';
import helpIndex from '.';
import AppBarForm from '../common/AppBarForm';
import drawerStyle from '../../styles/drawers';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { AppState } from '../../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

type Props = {
  readonly location: string;
  readonly showHelp?: boolean;
};

const HelpDrawer = (props: Props) => {
  const { location, showHelp = false } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (name: string, state: boolean) => () => {
    setOpen(state);
  };

  const getHelperCaption = () => {
    const defaultCaption = {
      caption: <FormattedMessage id="help.untracked" />,
      component: undefined,
    };
    const helpCaption = helpIndex.find((item) => {
      return item.location === location;
    });
    return helpCaption || defaultCaption;
  };

  const helpObject = getHelperCaption();

  const drawerContent = (
    <div>
      <AppBarForm
        label={helpObject.caption}
        toggleDrawer={toggleDrawer('open', false)}
        position="sticky"
        showHelp={showHelp}
      />
      {location === '/dashboard' ? helpObject.component : <div style={{ padding: 15 }}>{helpObject.component}</div>}
      <Divider />
    </div>
  );
  return (
    <span>
      {helpObject.component && (
        <IconButton
          aria-haspopup="true"
          onClick={toggleDrawer('open', true)}
          style={{ color: grey[200], margin: 5 }}
          size="large"
        >
          <Help />
        </IconButton>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer('open', false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerContent}
      </Drawer>
    </span>
  );
};

function mapStateToProps(store: AppState) {
  return {
    location: store.router.location.pathname,
  };
}

export default connect(mapStateToProps, {})(HelpDrawer);
