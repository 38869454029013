import React, { MouseEventHandler } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import HelpDrawer from '../help/HelpDrawer';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flex: {
    flex: 1,
  },
  spinner: {
    color: theme.palette.primary.contrastText,
    animationDuration: '550ms',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    right: 0,
  },
}));

type Props = {
  readonly loading?: boolean;
  readonly showHelp?: boolean;
  readonly label: string | object;
  readonly position?: 'fixed' | 'static' | 'absolute' | 'sticky' | 'relative';
  readonly toggleDrawer: MouseEventHandler<HTMLButtonElement>;
};

const AppBarForm = (props: Props) => {
  const {
    label,
    toggleDrawer,
    position = 'static',
    loading = false,
    showHelp = false,
  } = props;

  const classes = useStyles();

  return (
    <AppBar position={position}>
      <Toolbar className={classes.buttons}>
        {!loading ? (
          <React.Fragment>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {label}
            </Typography>
            {showHelp && <HelpDrawer />}
            <IconButton color="inherit" onClick={toggleDrawer} size="large">
              <ClearIcon />
            </IconButton>
          </React.Fragment>
        ) : (
          undefined
        )}
      </Toolbar>
      {loading ? (
        <LinearProgress style={{ height: 2 }} />
      ) : (
        <LinearProgress
          variant="determinate"
          value={100}
          style={{ height: 2 }}
        />
      )}
    </AppBar>
  );
};

export default AppBarForm;
