import axios from 'axios';

import format from 'date-fns/format';

import { appConfig } from '../../config';
import jwt_decode from 'jwt-decode';

export function extractIdFromHref(href: $TSFixMe) {
  const splitted = href.split('/');
  return splitted[splitted.length - 1];
}

export function sendFileToBrowser(href: string) {
  return setTimeout(() => {
    const response = {
      file: href,
    };
    window.location.href = response.file;
  }, 100);
}

export const formatMoneyField = (value?: $TSFixMe, digits?: number) => {
  const res = parseFloat(value.toString().replace(/,/g, '.'));
  return Number.isNaN(res) ? '' : res.toFixed(digits);
};

export const fetchLogo = (href: string) => {
  return axios.get(href, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  }).then(res => `data:${res.data.type};base64,${res.data.data}`);
};

export const fetchLogoByResellerId = (resellerId: number) => (
  fetchLogo(`${appConfig.URL_REST}resellers/${resellerId}/branding/logo`)
);

export const isNumberValid = (number: $TSFixMe) => /^[+\d](\d*)?$/.test(number) && number.length > 6 && number.length < 15;

export const stripPlus = (number: $TSFixMe) => {
  return number.startsWith('+') ? number.substring(1) : number;
};

export const numberFormat = (
  value = 0,
  style = 'decimal',
  maximumFractionDigits = 2,
  currency = 'CHF',
  locale = appConfig.DEFAULT_LOCALE
) =>
  new Intl.NumberFormat(locale, {
    style: style,
    currency: currency ? currency : 'CHF',
    maximumFractionDigits: maximumFractionDigits,
  }).format(style === 'percent' ? value / 100 : value);

export const expirationDate = (values: $TSFixMe) => {
  const noExpiration = null;
  if (values.expirationDate === 'custom') {
    return (values.expirationDate = format(values.customDate, 'yyyy-MM-dd'));
  } else if (values.expirationDate === 'no expiration') {
    return (values.expirationDate = noExpiration);
  } else {
    return values.expirationDate;
  }
};

export const isNumberInList = (list: $TSFixMe, number: $TSFixMe) => {
  return list.some((n: $TSFixMe) => stripPlus(n) === stripPlus(number));
};

export const getCustomerUUID = (uuid: string) => {
  // @ts-expect-error TODO: Type 'null' is not assignable to type 'string'.
  return uuid ? uuid : jwt_decode(localStorage.getItem('token')).customerUUID;
};

export const getChannel = (channel: string) => {
  switch (channel) {
    case 'SMS':
      return 'SMS';
    case 'VB':
      return 'VIBER';
    case 'WB':
      return 'WHATSAPP';

    default:
      return channel;
  }
};

export const formatNumberWithCommas = (num: number) => {
  if (num === null || num === undefined) {
    return '0';
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const limitDecimalDigits = (value: string, digits: number) => {
  if (!value) return value;
  value = value.replace(/[^0-9.]/g, "");
  if (digits === 0) {
    return value.split(".")[0];
  }

  const regex = new RegExp(`^(\\d+)(\\.\\d{0,${digits}})?$`);
  const match = value.match(regex);
  return match ? match[0] : value.slice(0, -1);
};
