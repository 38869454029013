import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import app from './app';
import appearance from './appearance';
import contactsImport from './contactsImport';
import customers from './customers/customers';
import resellers from './resellers/resellers';
import invoice from './invoice';
import locale from './locale';
import login from './login';
import resources from './resources';
import snackbar from './snackbar';
import statistics from './statistics';
import stepper from './stepper';
import chat from './chat';
import chatChannels from './chats';
import signup from './signup';
import testAccount from './testAccount';
import whatsapp from './whatsapp';
import ubsPerformance from './ubs/ubs.performance';
import ubsWhitelist from './email2sms/whitelist';
import ubsSpam from './ubs/ubs.spam';
import email2smsConfig from './email2sms/config';
import ubsCountry from './ubs/ubs.country';
import ubsMo from './ubs/ubs.mo';
import ubsOptOut from './ubs/ubs.optout';
import ubsTags from './ubs/ubs.tags';
import ubsSettings from './ubs/ubs.settings';
import accountTokens from './accountTokens';
import mtApiConfig from './mtApi/config';
import mtApiRouting from './mtApi/routing';
import mtApiCountryOverride from './mtApi/countryOverride';
import customersMtPricing from './customers/mtPricing';
import customersAdditionalFees from './customers/additionalFees';
import customersPricelists from './customers/pricelists';
import cdrs from './cdrs';
import inboundNumbers from './inboundNumbers';
import customersPlatformUsers from './customers/platformUsers';
import users from './users';
import pricelistTemplates from './pricelistTemplates/pricelistTemplates';
import campaignHistory from './campaignHistory';
import resellerBankAccounts from './resellers/bankAccounts';
import messageLog from './messageLog';
import mobileNetworkNames from './mnp/mobileNetworkNames';
import mobileCountryCodes from './mnp/mobileCountryCodes';
import customersMoPricing from './customers/moPricing';
import quickTestSms from './quickTestSms';
import settingsResellerCost from './settings/resellerCosts';
import settingsMasterDestination from './settings/masterDestination';
import mtApiIpWhitelist from './mtApi/ipWhitelist';
import bulkRouting from './mtApi/bulkRouting';
import resellerVatRates from './resellers/vatRates';
import mtApiKey from './mtApi/apiKey';
import plMoPricing from './pricelistTemplates/plMoPricing';
import plMtPricing from './pricelistTemplates/plMtPricing';
import plAdditionalFees from './pricelistTemplates/plAdditionalFees';
import templatesWhatsapp from './templates/whatsapp';
import viber from './viber';
import viberTemplates from './templates/viber';
import meta from './meta';
import accountPricing from './accountPricing';

const appReducer = (history: History) => {
  return combineReducers({
    app,
    appearance,
    login,
    router: connectRouter(history),
    snackbar,
    locale,
    resources,
    resellers,
    customers,
    contactsImport,
    statistics,
    stepper,
    invoice,
    chat,
    chatChannels,
    signup,
    testAccount,
    whatsapp,
    ubsPerformance,
    ubsWhitelist,
    ubsSpam,
    email2smsConfig,
    ubsCountry,
    ubsMo,
    ubsOptOut,
    ubsTags,
    ubsSettings,
    accountTokens,
    mtApiConfig,
    mtApiRouting,
    mtApiCountryOverride,
    cdrs,
    customersMtPricing,
    customersAdditionalFees,
    customersPricelists,
    inboundNumbers,
    customersPlatformUsers,
    users,
    pricelistTemplates,
    campaignHistory,
    resellerBankAccounts,
    messageLog,
    mobileNetworkNames,
    mobileCountryCodes,
    customersMoPricing,
    quickTestSms,
    settingsResellerCost,
    settingsMasterDestination,
    mtApiIpWhitelist,
    bulkRouting,
    resellerVatRates,
    mtApiKey,
    plMoPricing,
    plMtPricing,
    plAdditionalFees,
    templatesWhatsapp,
    viber,
    viberTemplates,
    meta,
    accountPricing,
    form: formReducer,
  });
};

const rootReducer = (history: History): Reducer => {
  const reducer = appReducer(history);

  return (state, action) => {
    if (action.type === 'IS_NOT_AUTHENTICATED') {
      const { appearance, locale, router } = state;
      state = { appearance, locale, router };
    }
    return reducer(state, action);
  };
};

export type AppState = ReturnType<ReturnType<typeof appReducer>>;

export default rootReducer;
