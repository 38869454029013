import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

import { connect } from 'react-redux';
import AppBarForm from '../../../common/AppBarForm';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Loading from '../../../common/Loading';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import { Field, reduxForm } from 'redux-form';
import submit from './optout.form.submit';
import validate from './optout.form.validate';
import RenderTextField from '../../../common/reduxForm/RenderTextField';
import { IntlProps } from '../../../common/types/intlProps';
import { AppState } from '../../../../reducers';
import { AppReduxFormProps, AppReduxFormSubmit, AppReduxFormValidator } from '../../../common/types/appReduxForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 20,
    margin: theme.spacing(4),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: '48px',
  },
  buttonDefault: {
    color: '#000000de',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#d7d7d7',
    },
  },
  buttons: {
    paddingBottom: '10px',
    paddingTop: '10px',
    backgroundColor: '#F5F5F5',
    width: '100%',
  },
}));

export type Props = {
  readonly loading: boolean;
  readonly editMode: boolean;
  readonly toggleDrawer: (open: boolean) => void;
} & IntlProps;

type FormValues = $TSFixMe;
type FormProps = AppReduxFormProps<FormValues, Props>;
export type OptOutFormValidator = AppReduxFormValidator<FormValues, Props>;
export type OptOutFormSubmit = AppReduxFormSubmit<FormValues, Props>;

const  OptOutForm = (props: FormProps) => {
  const { loading, toggleDrawer, handleSubmit, pristine, submitting } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          <AppBarForm
            label={<FormattedMessage id="email2sms.ubs.optout.form.label.optout.list" />}
            toggleDrawer={() => toggleDrawer(false)}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        style={{ overflowY: 'auto', flex: 1 }}
      >
        <Grid item>
          <div className={classes.root}>
            <Loading loading={loading}>
              <form onSubmit={handleSubmit} autoComplete="off">
                <fieldset style={{ padding: 0, border: 0 }}>
                  <Grid container spacing={3}>
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingRight: 25, paddingLeft: 25 }}
                      >
                        <Typography variant="h5">
                          <FormattedMessage id="email2sms.ubs.optout.form.title.optout.details" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {/* <Collapse in={submitErrors.error}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={
                                  resetWaAdministrationPricingSubmitResult
                                }
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            <AlertTitle>{error}</AlertTitle>
                            {submitErrors && submitErrors.message}
                          </Alert>
                        </Collapse> */}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingRight: 25, paddingLeft: 25 }}
                      >
                        <Field
                          component={RenderTextField}
                          fullWidth
                          name="number"
                          label={<FormattedMessage id="common.label.phone.number" />}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ paddingRight: 25, paddingLeft: 25 }}
                      >
                        <Field
                          component={RenderTextField}
                          fullWidth
                          name="info"
                          label={<FormattedMessage id="email2sms.ubs.optout.form.label.optional.info" />}
                        />
                      </Grid>
                    </>
                  </Grid>
                </fieldset>
              </form>
            </Loading>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="column" justifyContent="flex-end">
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(submit)} autoComplete="off">
            <div className={classes.buttons}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={pristine || submitting}
              >
                <FormattedMessage id="common.button.save" />
              </Button>
              <Button
                variant="contained"
                onClick={() => toggleDrawer(false)}
                className={classes.buttonDefault}
              >
                <FormattedMessage id="common.button.cancel" />
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const OptOutReduxForm = injectIntl(
  reduxForm({
    form: 'optout',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmitSuccess: (result, dispatch, props) => {
      props.toggleDrawer(false);
    },
  })(OptOutForm));

// eslint-disable-next-line
export default connect(
  (state: AppState) => ({
    initialValues: {
      ...state.ubsOptOut.selectedEntry,
    },
  }),
  {}
)(OptOutReduxForm);
