import { HttpClient } from '../common/httpClient';
import { GetSelectedWhatsappTemplate, PostWhatsappTemplate, WhatsappTemplate } from '../../types/meta/whatsappTemplate';

export class WhatsappTemplateApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToWhatsappTemplate(response: WhatsappTemplate): WhatsappTemplate {
    return {
      name: response.name,
      status: response.status,
      category: response.category,
      whatsAppMessageTemplateId: response.whatsAppMessageTemplateId,
    };
  }

  private mapToSelectedWhatsappTemplate(response: GetSelectedWhatsappTemplate): GetSelectedWhatsappTemplate {
    return {
      name: response.name,
      status: response.status,
      category: response.category,
      language: response.language,
      components: response.components,
    }
  }

  getAllByBusinessId(businessAccountId: string): Promise<WhatsappTemplate[]> {
    return this.httpClient.get<WhatsappTemplate[]>(`whatsapp/accounts/${businessAccountId}/templates/synchronization`)
      .then(data => (
        data.map(it => this.mapToWhatsappTemplate(it))
      ));
  }

  add(item: PostWhatsappTemplate): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpClient.post<PostWhatsappTemplate>(`templates`, item);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  delete(templateId: string, name: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpClient.delete<PostWhatsappTemplate>(`templates/${templateId}/name/${name}`);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  getByName(whatsAppId: string, templateName: string): Promise<GetSelectedWhatsappTemplate[]> {
    return this.httpClient.get<GetSelectedWhatsappTemplate[]>(`whatsapp/accounts/${whatsAppId}/templates/${templateName}`)
      .then(data => (
        data.map(it => this.mapToSelectedWhatsappTemplate(it))
      ));
  }

  update(id: string, item: PostWhatsappTemplate): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpClient.put<PostWhatsappTemplate>(`templates/${id}`, item);
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }
}
