import IconButton from '@mui/material/IconButton';
import React, { ForwardedRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SnackbarContent from '@mui/material/SnackbarContent';
import { hideSnackBarMsg, processSnackbarQueue } from '../../actions/snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';

import { green, amber } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.secondary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    maxWidth: 400,
  },
  snackbar: {
    margin: theme.spacing(1),
  },
}));

type SnackbarMsgContentProps = {
  readonly className?: string;
  readonly message: React.ReactNode;
  readonly variant: 'success' | 'warning' | 'error' | 'info';
  readonly onClose: (reason?: $TSFixMe) => void;
};

const SnackbarMsgContent = React.forwardRef((props: SnackbarMsgContentProps, ref: ForwardedRef<null>) => {
  const { className, message, onClose, variant, ...other } = props;

  const classes = useStyles();

  return (
    <div ref={ref}>
      <SnackbarContent
        className={classNames(classes[variant], className, classes.snackbar)}
        aria-describedby="client-snackbar"
        message={message}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            //className={classes.close}
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        }
        {...other}
      />
    </div>
  );
});

const SnackbarContentWrapper = SnackbarMsgContent;

type Props = {
  readonly open: boolean;
  readonly msgInfo: { msg: string, variant: 'success' | 'warning' | 'error' | 'info', key: $TSFixMe };
  readonly hideSnackbar: () => void;
  readonly processSnackbar: () => void;
};

const SnackbarMsg = (props: Props) => {
  const handleClose = (reason: $TSFixMe) => {
    const { hideSnackbar } = props;
    if (reason === 'clickaway') {
      return;
    }
    hideSnackbar();
  };

  const handleExited = () => {
    const { processSnackbar } = props;
    processSnackbar();
  };

  const { open, msgInfo } = props;
  const { msg, variant, key } = msgInfo;
  return (
    <Snackbar
      key={key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited
      }}
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={msg}
      />
    </Snackbar>
  );
}

export default connect(null, {
  hideSnackbar: hideSnackBarMsg,
  processSnackbar: processSnackbarQueue,
})(SnackbarMsg);
