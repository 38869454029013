import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const HelpUserFinance = () => {
  return (
    <div style={{ padding: 5 }}>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.user.finance.1" />
        </Typography>
        <div style={{ margin: '0 10px' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.1.2" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.1.3" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.1.4" />
          </Typography>
        </div>
        <p></p>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.user.finance.2" />
        </Typography>
        <div style={{ margin: '0 10px' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.1" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.2" />
          </Typography>
          <p></p>
          <Typography variant="subtitle2" align="justify">
            <FormattedMessage id="help.user.finance.2.3" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.4" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.5" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.6" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.7" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.8" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.9" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.2.10" />
          </Typography>
          <p></p>
        </div>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.user.finance.3" />
        </Typography>
        <div style={{ margin: '0 10px' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.3.1" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.3.2" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.3.3" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.3.4" />
          </Typography>
          <p></p>
        </div>
        <Typography variant="subtitle2" align="justify">
          <FormattedMessage id="help.user.finance.4" />
        </Typography>
        <div style={{ margin: '0 10px' }}>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.4.1" />
          </Typography>
          <p></p>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.4.2" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.4.3" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.4.4" />
          </Typography>
          <Typography variant="body2" align="justify">
            <FormattedMessage id="help.user.finance.4.5" />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default HelpUserFinance;
