export interface CustomAppearance {
  readonly appBarColor: string;
  readonly primaryMainColor: string;
  readonly primaryLightColor: string;
  readonly primaryDarkColor: string;
  readonly secondaryMainColor: string;
  readonly secondaryLightColor: string;
  readonly secondaryDarkColor: string;
}

export const NO_CUSTOM_APPEARANCE = null;

export type Appearance = CustomAppearance | null;
