import { AnyAction } from 'redux';
import { InboundNumber } from '../types/inboundNumber';
import { Customer } from '../types/customer';

interface InboundNumbersState {
  customers: Customer[];
  customerId?: number;
  inboundNumbers: InboundNumber[];
  inboundNumbersLoading: boolean;
  selectedInboundNumber: $TSFixMe;
  inboundNumbersFormLoading: boolean;
  sessionEsme: string[];
  smscId: string[];
}

const initialState: InboundNumbersState = {
  customers: [],
  inboundNumbers: [],
  inboundNumbersLoading: false,
  selectedInboundNumber: {
    forwardingTypeDetails: {}, 
  },
  inboundNumbersFormLoading: false,
  sessionEsme: [],
  smscId: [],
};

export default function reducer(state: InboundNumbersState = initialState, action: AnyAction): InboundNumbersState {
  switch (action.type) {
    case 'SET_INBOUND_NUMBERS_LOADING': {
      return {
        ...state,
        inboundNumbersLoading: action.payload,
      };
    }
    case 'SET_CUSTOMER_ID': {
      return {
        ...state,
        customerId: action.payload,
      };
    }
    case 'SET_INBOUND_NUMBERS': {
      return {
        ...state,
        inboundNumbers: action.payload,
      };
    }
    case 'SET_INBOUND_NUMBER_FORM_LOADING': {
      return {
        ...state,
        inboundNumbersFormLoading: action.payload,
      };
    }
    case 'ADD_INBOUND_NUMBER': {
      return {
        ...state,
        inboundNumbers: [...state.inboundNumbers, action.payload],
      };
    }
    case 'REMOVE_INBOUND_NUMBER': {
      return {
        ...state,
        inboundNumbers: state.inboundNumbers.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_INBOUND_NUMBER': {
      const itemToReplace = {
        active: action.payload.active,
        customer: action.payload.customer,
        country: action.payload.country,
        numberFrom: action.payload.numberFrom,
        numberTo: action.payload.numberTo,
        forwardingType: action.payload.forwardingType,
        inboundProvider: action.payload.provider,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        forwardingTypeDetails: {
          // @ts-expect-error It looks like mistake because root state does not have forwardingTypeDetails props
          ...state.forwardingTypeDetails,
          [action.payload.prop]: action.payload.value,
        },
      };
      return {
        ...state,
        inboundNumbers: [
          // @ts-expect-error itemToReplace does not contain all InboundNumber properties - to verify and fix!
          ...state.inboundNumbers.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all InboundNumber properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_INBOUND_NUMBER_FOR_FORM': {
      return {
        ...state,
        selectedInboundNumber: action.payload,
      };
    }
    case 'SET_CUSTOMERS': {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case 'SET_CUSTOMER_SESSION_ESME': {
      return {
        ...state,
        sessionEsme: action.payload,
      };
    }
    case 'SET_CUSTOMER_SMSC_ID': {
      return {
        ...state,
        smscId: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
