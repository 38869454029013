import { AnyAction } from 'redux';
import { GetViberPricing } from '../types/viber/viberPricing';
import { GetWhatsappPricing } from '../types/meta/whatsappPricing';

interface ViberPricingState {
  viberPricing: GetViberPricing[];
  viberMonthlyFee: { fee: number };
  viberPricingLoading: boolean;
  channelPricing: string;
  packagePricing: string;
  whatsappPricing: GetWhatsappPricing[];
  whatsappMonthlyFee: { fee: number };
  whatsappPricingLoading: boolean;
}

const initialState: ViberPricingState = {
  viberPricing: [],
  viberPricingLoading: false,
  viberMonthlyFee: { fee: 0 },
  channelPricing: 'VB',
  packagePricing: 'current',
  whatsappPricing: [],
  whatsappMonthlyFee: { fee: 0 },
  whatsappPricingLoading: false,
};

export default function reducer(state: ViberPricingState = initialState, action: AnyAction): ViberPricingState {
  switch (action.type) {
    case 'SET_VIBER_PRICING_LOADING': {
      return {
        ...state,
        viberPricingLoading: action.payload,
      };
    }
    case 'SET_VIBER_PRICING': {
      return {
        ...state,
        viberPricing: action.payload,
      };
    }
    case 'SET_VIBER_MONTHLY_FEE': {
      return {
        ...state,
        viberMonthlyFee: action.payload,
      };
    }
    case 'SET_CHANNEL_PRICING': {
      return {
        ...state,
        channelPricing: action.payload,
      };
    }
    case 'SET_PACKAGE_PRICING': {
      return {
        ...state,
        packagePricing: action.payload,
      };
    }
    case 'SET_WHATSAPP_PRICING_LOADING': {
      return {
        ...state,
        whatsappPricingLoading: action.payload,
      };
    }
    case 'SET_WHATSAPP_PRICING': {
      return {
        ...state,
        whatsappPricing: action.payload,
      };
    }
    case 'SET_WHATSAPP_MONTHLY_FEE': {
      return {
        ...state,
        whatsappMonthlyFee: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
