import { createTheme } from '@mui/material/styles';

const gms = createTheme({
  palette: {
    primary: {
      light: '#def4ea',
      main: '#00C072',
      //dark: '#004e65',
      contrastText: '#ffffff',
    },
    secondary: {
      //light: '#894494',
      main: '#00473b',
      //dark: '#2e003b',
      contrastText: '#fff',
    },
    type: 'light',
    custom: {
      appBarColor: '#00C072',
    },
    online: {
      main: '#4caf50',
    },
    away: {
      main: '#ffd700',
    },
    offline: {
      main: '#d32f2f',
    },
    unknown: {
      main: '#a9a9a9',
    },
    infobox: {
      color: {
        orange: '#838FF7',
        red: '#C3E4FD',
        blue: '#48A1F8',
        green: '#00C072',
        grey: '#D1D2FB',
        viber: '#7360F2',
        whatsapp: '#25d366',
        sms: '#97bbcd',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#0000004d',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 16,
            border: '2px solid #fff',
            backgroundColor: '#0000004d',
          },
        },
      },
    },
  },
});

export default gms;
