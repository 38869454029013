import React from 'react';
import { FormattedMessage } from 'react-intl';

const getTranslatedStatus = (status: string) => {
  switch (status) {
    case 'ANALYSED':
      return <FormattedMessage id="status.analysed" />;
    case 'IMPORTED':
      return <FormattedMessage id="status.imported" />;
    case 'IN_PROGRESS':
      return <FormattedMessage id="status.in.progress" />;
    case 'ERROR':
      return <FormattedMessage id="status.error" />;
    case 'UPLOADED':
      return <FormattedMessage id="status.uploaded" />;
    case 'EXPIRED':
      return <FormattedMessage id="status.expired" />;
    case 'ACCEPTED':
      return <FormattedMessage id="status.accepted" />;
    case 'READY':
      return <FormattedMessage id="status.ready" />;

    default:
      return status;
  }
};

export default getTranslatedStatus;