import { HttpClient } from '../common/httpClient';
import { ViberTarget } from '../../types/viber/viberTarget';
import phoneNumberService from '../../service/phoneNumberService';
import dateTimeService from '../../service/dateTimeService';

type ViberTargetResponse = {
  readonly id: string;
  readonly name: string;
  readonly destination: string;
  readonly status: string;
  readonly lastActivity: string;
  readonly createdAt: string;
  readonly updatedAt: string;
};

export class ViberTargetApi {
  constructor(private readonly httpClient: HttpClient) {}

  getAllByAccountId(accountId: string): Promise<ViberTarget[]> {
    return this.httpClient.get<ViberTargetResponse[]>(`/accounts/${accountId}/targets/all`)
      .then(data => (
        data.filter(it => phoneNumberService.isValidPhoneNumber(it.destination))
          .map(it => this.mapToViberTarget(it))
      ));
  }

  private mapToViberTarget(response: ViberTargetResponse): ViberTarget {
    return {
      id: response.id,
      name: response.name,
      phoneNumber: phoneNumberService.toPhoneNumberWithPlusSign(response.destination),
      status: response.status,
      createdAt: dateTimeService.convertIsoDateToTimestamp(response.createdAt),
      updatedAt: dateTimeService.convertIsoDateToTimestamp(response.updatedAt),
      ...(response.lastActivity && {
        lastActivityAt: dateTimeService.convertIsoDateToTimestamp(response.lastActivity),
      }),
    };
  }
}