import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sessionExpired } from '../actions/login';

const UNAUTHORIZED: number = 401;

export const useLogoutInterceptor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutInterceptor = axios.interceptors.response.use(
      response => response,
      error=> {
        if (error.response?.status === UNAUTHORIZED) {
          dispatch(sessionExpired());
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(logoutInterceptor);
    };
  }, [dispatch]);
}
