import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";

export function useWithWidthUp(breakpoint: string) {
  const theme = useTheme();
  // @ts-expect-error TODO: Property 'breakpoints' does not exist on type 'DefaultTheme'
  return useMediaQuery(theme.breakpoints.up(breakpoint));
};

export function useWithWidthDown(breakpoint: string) {
  const theme = useTheme();
  // @ts-expect-error TODO: Property 'breakpoints' does not exist on type 'DefaultTheme'
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}