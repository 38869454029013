import React from 'react';
import { appConfig } from '../config';
import axios from 'axios';
import { AppThunkAction } from './index';
import { showSnackBarMsg } from './snackbar';
import { FormattedMessage } from 'react-intl';
import { Customer } from '../types/customer';
import metaApi from '../api/meta/metaApi';
import { MetaAccount } from '../types/meta/metaAccount';
import { WhatsappPhoneNumber } from '../types/meta/whatsappPhoneNumber';

export function setMetaAdministrationCustomers(customers: Customer[]): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_META_ADMIN_CUSTOMERS',
      customers: customers,
    });
  };
}

export function setMetaAdministrationConfigs(configs: MetaAccount[]): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_META_ADMIN_CONFIGS',
      configs: configs,
    });
  };
}

export function setMetaAdministrationConfig(config: MetaAccount): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_META_ADMIN_SELECTED_CONFIG',
      config: config,
    });
  };
}

export function resetMetaAdministrationConfig(): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_META_ADMIN_SELECTED_CONFIG',
      config: {},
    });
  };
}

export function getMetaConfigs(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: true });
    metaApi.accounts.getAll()
      .then((metaAccounts: MetaAccount[]) => {
        dispatch(setMetaAdministrationConfigs(metaAccounts));
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
      });
  };
}

export function addMetaConfig(values: MetaAccount): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: true });
    metaApi.accounts.add(values)
      .then(() => {
        dispatch(getMetaConfigs());
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function getOneMetaConfig(id: string): AppThunkAction {
  return (dispatch) => {
    metaApi.accounts.getById(id)
      .then((metaConfig: MetaAccount) => {
        dispatch(setMetaAdministrationConfig(metaConfig));
      })
      .catch((error) => console.log(error));
  };
}

export function updateMetaConfig(item: MetaAccount): AppThunkAction {
  return (dispatch, getState) => {
    const id = getState().meta.selectedConfig.businessAccountId!;
    metaApi.accounts.update(id, item)
      .then(() => {
        dispatch({ type: 'UPDATE_META_CONFIG', payload: item });
        dispatch(getMetaConfigs());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function handleSetMetaConfigEnabled(businessAccountId: string): AppThunkAction {
  return (dispatch) => {
    metaApi.accounts.enable(businessAccountId)
      .then(() => {
        dispatch(getMetaConfigs());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function handleSetMetaConfigDisabled(businessAccountId: string): AppThunkAction {
  return (dispatch) => {
    metaApi.accounts.disable(businessAccountId)
      .then(() => {
        dispatch(getMetaConfigs());
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function deleteOneMetaConfig(id: string): AppThunkAction {
  return (dispatch) => {
    metaApi.accounts.delete(id)
      .then(() => {
        dispatch(getMetaConfigs());
      })
      .catch((error) => console.log(error));
  };
}

export function getCustomersForForm(): AppThunkAction {
  return (dispatch) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const url = `${appConfig.URL_REST}customers?page=0&size=10000&sort=name,asc&full=false`;
    axios
      .get(url, config)
      .then((res) => {
        const customersDataForForm =
          res.data &&
          res.data._embedded &&
          res.data._embedded.customers.map((c: $TSFixMe) => {
            return {
              id: c.id,
              name: c.name,
              shortName: c.shortName,
              currency: c.defaultCurrency,
            };
          });
        dispatch(setMetaAdministrationCustomers(customersDataForForm));
      })
      .catch((error) => console.log(error));
  };
}

export function getMetaConfigsByTenantId(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: true });
    const tenantId = getState().login.userData.customerUuid!;
    metaApi.accounts.getAllByTenantId(tenantId)
      .then((metaAccounts: MetaAccount[]) => {
        dispatch(setMetaAdministrationConfigs(metaAccounts));
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_META_CONFIGS_LOADING', payload: false });
      });
  };
}

export function setDefaultMetaConfig(defaultAccount: MetaAccount): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_DEFAULT_META_CONFIG',
      defaultAccount: defaultAccount,
    });
  };
}

export function setWhatsappPhoneNumbers(phoneNumbers: WhatsappPhoneNumber[]): AppThunkAction {
  return (dispatch) => {
    dispatch({
      type: 'SET_WHATSAPP_PHONE_NUMBERS',
      phoneNumbers: phoneNumbers,
    });
  };
}

export function getDefaultMetaConfig(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_DEFAULT_META_CONFIG_LOADING', payload: true });
    metaApi.accounts.getDefault()
      .then((metaAccount: MetaAccount) => {
        dispatch(setDefaultMetaConfig(metaAccount));
        dispatch({ type: 'SET_DEFAULT_META_CONFIG_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_DEFAULT_META_CONFIG_LOADING', payload: false });
      });
  };
}

export function getPhoneNumbersByBusinessAccountId(businessAccountId: string): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_WHATSAPP_PHONE_NUMBERS_LOADING', payload: true });
    metaApi.whatsappPhoneNumber.getPhoneNumbersById(businessAccountId)
      .then((whatsappPhoneNumbers: WhatsappPhoneNumber[]) => {
        dispatch(setWhatsappPhoneNumbers(whatsappPhoneNumbers));
        dispatch({ type: 'SET_WHATSAPP_PHONE_NUMBERS_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_WHATSAPP_PHONE_NUMBERS_LOADING', payload: false });
      });
  };
}
