import { AnyAction } from 'redux';
import { PricelistTemplate } from '../../types/pricelistTemplate';

interface PricelistTemplatesState {
  pricelistTemplates: PricelistTemplate[];
  selectedPricelistTemplate: $TSFixMe;
  pricelistTemplatesFormLoading: boolean;
  pricelistTemplatesLoading: boolean;
}

const initialState: PricelistTemplatesState = {
  pricelistTemplates: [],
  selectedPricelistTemplate: {},
  pricelistTemplatesFormLoading: false,
  pricelistTemplatesLoading: false,
};

export default function reducer(state: PricelistTemplatesState = initialState, action: AnyAction): PricelistTemplatesState {
  switch (action.type) {
    case 'SET_PRICELIST_TEMPLATES_LOADING': {
      return {
        ...state,
        pricelistTemplatesLoading: action.payload,
      };
    }
    case 'SET_PRICELIST_TEMPLATES': {
      return {
        ...state,
        pricelistTemplates: action.payload,
      };
    }
    case 'SET_PRICELIST_TEMPLATE_FORM_LOADING': {
      return {
        ...state,
        pricelistTemplatesFormLoading: action.payload,
      };
    }
    case 'ADD_PRICELIST_TEMPLATE': {
      return {
        ...state,
        pricelistTemplates: [...state.pricelistTemplates, action.payload],
      };
    }
    case 'REMOVE_PRICELIST_TEMPLATE': {
      return {
        ...state,
        pricelistTemplates: state.pricelistTemplates.filter((item) => item.id !== action.payload),
      };
    }
    case 'SET_PRICELIST_TEMPLATE_FOR_FORM': {
      return {
        ...state,
        selectedPricelistTemplate: action.payload,
      };
    }
    
    default:
      return {
        ...state,
      };
  }
}
