import React from 'react';
import Typography from '@mui/material/Typography';
import TopBarTestAccountInfo from './TopBarTestAccountInfo';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { handleShowTestAccountWelcomeScreenNextTime } from '../../actions/testAccount';
import { AppState } from '../../reducers';
import { Theme } from '@mui/material';

const image = require('../../styles/2-1.jpg');

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: 5,
    left: -6,
    zIndex: 1,
  },
}));

type Props = {
  readonly numberOfMessages: number;
  readonly maxWhitelistsNumber: number;
  readonly hideTestAccountWelcomeScreenNextTime: boolean;
  readonly hideTestAccountWelcomeScreenNextTimeLoading: boolean;
  readonly handleShowTestAccountWelcomeScreenNextTime: (show: boolean) => void;
};

const TestAccountDialogStep1 = ({
  numberOfMessages,
  hideTestAccountWelcomeScreenNextTimeLoading,
  hideTestAccountWelcomeScreenNextTime,
  handleShowTestAccountWelcomeScreenNextTime,
  maxWhitelistsNumber,
}: Props) => {
  const handleChange = (event: $TSFixMe) => {
    handleShowTestAccountWelcomeScreenNextTime(event.target.checked);
  };
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.1.create.sms.campaign" />
        </Typography>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.1.communicate" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <img
          src={image}
          width={200}
          style={{ marginTop: '30px', marginLeft: '30px' }}
          alt="onboard"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.1.send.a.message" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 15 }} />
        <Typography variant={'body2'} align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.dialog.step.1.demo.limited" />
            {numberOfMessages}
          <FormattedMessage id="testAccount.dialog.step.1.limit.messages" />
        </Typography>
        <Typography variant={'body2'} align="justify" style={{ marginTop: 15 }}>
          <FormattedMessage id="testAccount.dialog.step.1.can.send" />
           {maxWhitelistsNumber} 
          <FormattedMessage id="testAccount.dialog.step.1.send.to.numbers" />
        </Typography>
        <div style={{ margin: '20px' }}>
          <TopBarTestAccountInfo />
        </div>
        <Typography variant={'body2'} align="justify" style={{ marginTop: 25 }}>
          <FormattedMessage id="testAccount.dialog.step.1.contact.us" />
        </Typography>

        <div style={{ marginTop: '25px' }} className={classes.wrapper}>
          <FormControlLabel
            disabled={hideTestAccountWelcomeScreenNextTimeLoading}
            control={
              <Checkbox
                checked={hideTestAccountWelcomeScreenNextTime}
                onChange={handleChange}
                name="showWelcome"
                color="primary"
              />
            }
            label={
              <Typography variant={'body2'}>
                <FormattedMessage id="testAccount.dialog.step.1.do.not.show" />
              </Typography>
            }
          />
          {hideTestAccountWelcomeScreenNextTimeLoading && (
            <CircularProgress size={32} className={classes.progress} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default connect(
  (state: AppState) => {
    return {
      hideTestAccountWelcomeScreenNextTimeLoading:
        state.testAccount.hideTestAccountWelcomeScreenNextTimeLoading,
      hideTestAccountWelcomeScreenNextTime:
        state.testAccount.hideTestAccountWelcomeScreenNextTime,
      numberOfMessages: state.testAccount.accountInfo.smsLimit,
      maxWhitelistsNumber: state.testAccount.accountInfo.maxWhitelistsNumber,
    };
  },
  { handleShowTestAccountWelcomeScreenNextTime }
)(TestAccountDialogStep1);
