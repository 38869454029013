import { Timestamp } from '../datetime';
import { PhoneNumberWithPlusSign } from '../phoneNumber';

export enum ViberMessageStatus {
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  EXPIRED = 'EXPIRED',
  AUTOREPLY = 'AUTOREPLY',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  FAILED = 'FAILED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export enum ViberMessageType {
  TEXT = 'text',
  TEXT_BUTTON = 'text-button',
  IMAGE = 'image',
  IMAGE_BUTTON = 'image-button',
  VIDEO = 'video',
  VIDEO_BUTTON = 'video-button',
  DOCUMENT = 'document',
  TEMPLATE = 'template',
}

export interface ViberMessage {
  readonly id: string;
  readonly createdAt: Timestamp;
  readonly updatedAt: Timestamp;
  readonly direction: 'outgoing' | 'incoming';
  readonly type: ViberMessageType;
  readonly status: ViberMessageStatus;
  readonly phoneNumber: PhoneNumberWithPlusSign;
  readonly text: string;
  readonly mediaURL?: string;
  readonly fileName?: string;
  readonly thumbnail?: string;
  readonly statusDetails?: string;
  readonly button?: {
    readonly action: string;
    readonly caption: string;
  };
}

export interface ViberConversationId {
  readonly accountId: string;
  readonly targetId: string;
}
