import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import axios from 'axios';
import { appConfig } from '../../config';
import { extractIdFromHref, getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';

export function setSenderFormOpen(open: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_SENDER_FORM_OPEN', payload: open });
  };
}

export function setAllowedSenders(items: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_ALLOWED_SENDERS', payload: items });
  };
}

export function addSenderEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    axios
      .post(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/users`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: 'ADD_SENDER_ENTRY',
            payload: {
              id: extractIdFromHref(res.data._links.self.href),
              email: res.data.email,
              senderId: res.data.senderId,
              notifyFinalStatusToSender: res.data.notifyFinalStatusToSender,
            },
          });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.message.includes('email address')) {
          dispatch(
            showSnackBarMsg(
              <FormattedMessage
                id="email2sms.ubs.sender.email.config.already.exists"
                values={{ email: error.response.data.message.slice(70) }}
              />,
              'error',
            )
          );
        } else {
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
        }
      });
  };
}

export function removeSenderEntry(id: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    axios
      .delete(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/users/${id}`, config)
      .then((res) => {
        if (res.status === 200) dispatch({ type: 'REMOVE_SENDER_ENTRY', payload: id });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateSenderEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/users/${item.id}`, item, config)
      .then((res) => {
        dispatch({ type: 'UPDATE_SENDER_ENTRY', payload: item });
        dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.data.message.includes('email address')) {
          dispatch(
            showSnackBarMsg(
              <FormattedMessage
                id="email2sms.ubs.sender.email.config.already.exists"
                values={{ email: error.response.data.message.slice(70) }}
              />,
              'error',
            )
          );
        } else {
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
        }
      });
  };
}

export function setSenderEntryForForm(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_SENDER_ENTRY_FOR_FORM', payload: id });
  };
}

function setServiceDisabled(): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_SERVICE_DISABLED', payload: true });
  };
}

export function fetchAllowedSenders(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch(setAllowedSenders([]));
    dispatch({ type: 'SET_SENDERS_LOADING', payload: true });
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/users`, config)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.map((e: $TSFixMe) => {
            const self = e.links.find((link: $TSFixMe) => link.rel === 'self').href;
            return { id: extractIdFromHref(self), ...e };
          });
          if (data.length > 0) dispatch(setAllowedSenders(data));
          else dispatch(setServiceDisabled());
          dispatch({ type: 'SET_SENDERS_LOADING', payload: false });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_SENDERS_LOADING', payload: false });
      });
  };
}
