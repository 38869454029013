import { HttpClient } from '../common/httpClient';
import { ViberTemplate } from '../../types/ViberTemplate';

type UpdateParams = {
  readonly tenantId: string;
  readonly accountId: string;
  readonly id: string;
  readonly item: Partial<ViberTemplate>;
};

type GetAllBySenderIdParams = {
  readonly tenantId: string;
  readonly accountId: string;
  readonly templatesPageSize: number;
  readonly encodedFilter: string;
};

export class ViberTemplateApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToViberTemplate(response: ViberTemplate): ViberTemplate {
    return {
      id: response.id,
      info: response.info,
      name: response.name,
      status: response.status,
      originalText: response.originalText,
      translateText: response.translateText,
      approvalAt: response.approvalAt,
      accountId: response.accountId,
      tenantId: response.tenantId,
    };
  }

  getAllByTenantId(tenantId: string): Promise<ViberTemplate[]> {
    return this.httpClient.get<ViberTemplate[]>(`tenants/${tenantId}/templates`)
      .then(data => (
        data.map(it => this.mapToViberTemplate(it))
      ));
  }

  async add(tenantId: string, accountId: string, item: ViberTemplate): Promise<void> {
    await this.httpClient.post<ViberTemplate>(`tenants/${tenantId}/accounts/${accountId}/templates`, item);
  }

  async delete(tenantId: string, accountId: string, id: string): Promise<void> {
    await this.httpClient.delete<ViberTemplate>(`tenants/${tenantId}/accounts/${accountId}/templates/${id}`);
  }

  async update(params: UpdateParams): Promise<void> {
    const { tenantId, accountId, id, item } = params;
    const itemNoStatus = { ...item, ...{ status: undefined } }
    delete itemNoStatus.status; //backend is not accepting status
    await this.httpClient.put<ViberTemplate>(`tenants/${tenantId}/accounts/${accountId}/templates/${id}`, itemNoStatus);
  }

  getById(tenantId: string, accountId: string, id: string): Promise<ViberTemplate> {
    return this.httpClient.get<ViberTemplate>(`tenants/${tenantId}/accounts/${accountId}/templates/${id}`)
      .then(data => this.mapToViberTemplate(data));
  }

  getAllBySenderId(params: GetAllBySenderIdParams): Promise<ViberTemplate[]> {
    const { tenantId, accountId, templatesPageSize, encodedFilter } = params;
    const url = encodedFilter
      ? `tenants/${tenantId}/accounts/${accountId}/templates?page=0&size=${templatesPageSize}&sort=name,asc&name=${encodedFilter}`
      : `tenants/${tenantId}/accounts/${accountId}/templates`;
    return this.httpClient.get<ViberTemplate[]>(url)
    .then(data => (
      data.map(it => this.mapToViberTemplate(it))
    ));
  }

  getAllForAdmin(): Promise<ViberTemplate[]> {
    return this.httpClient.get<ViberTemplate[]>(`templates`)
      .then(data => (
        data.map(it => this.mapToViberTemplate(it))
      ));
  }

  async updateStatus(id: string, status: string, item: ViberTemplate): Promise<void> {
    await this.httpClient.put<ViberTemplate>(`templates/${id}/${status}`, item);
  }

  async sendForApproval(id: string): Promise<void> {
    await this.httpClient.put<ViberTemplate>(`templates/${id}/send-to-approval`, {});
  }
}
