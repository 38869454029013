import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Search from '@mui/icons-material/Search';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { useWithWidthDown } from '../common/useWithWidth';

type Props = {
  readonly onFilter: $TSFixMe;
  readonly onSearch: $TSFixMe;
  //readonly extraButtons: $TSFixMe[];
  readonly onClickNew: () => void;
  readonly extraButtons: () => void;
  readonly handleSearchClick: () => void;
  readonly handleColumnVisibility: (event: $TSFixMe) => void;
  readonly handleFilterVisibility: (event: $TSFixMe) => void;
};

const ToolbarButtons = (props: Props) => {
  const {
    handleSearchClick,
    handleColumnVisibility,
    handleFilterVisibility,
    extraButtons,
    onClickNew,
    onFilter,
    onSearch,
  } = props;

  const isWidthDownSm = useWithWidthDown('sm');

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isWidthDownSm ? 'flex-start' : 'flex-end'}
    >
      {onSearch && (
        <Grid item>
          <Tooltip title={<FormattedMessage id="datatable.button.search" />}>
            <IconButton
              size="small"
              onClick={handleSearchClick}
              color="primary"
            >
              <Search />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {onFilter && (
        <Grid item>
          <Tooltip title={<FormattedMessage id="datatable.button.filter" />}>
            <Button
              size="small"
              onClick={handleFilterVisibility}
              color="primary"
              id="btn-data-table-filter"
            >
              <FormattedMessage id="datatable.button.filter" />
            </Button>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={<FormattedMessage id="datatable.button.show.columns" />}
        >
          <Button size="small" onClick={handleColumnVisibility} color="primary">
            <FormattedMessage id="datatable.button.show.columns" />
          </Button>
        </Tooltip>
      </Grid>
      {extraButtons &&
        // @ts-expect-error TODO: no property map on type void
        extraButtons().map((e: $TSFixMe, idx: number) => (
          <Grid key={idx} item>
            {e.button}
          </Grid>
        ))}
      {onClickNew && (
        <Grid item>
          <Tooltip title={<FormattedMessage id="datatable.button.add.new" />}>
            <Button
              size="small"
              color="primary" onClick={onClickNew}
              id="btn-data-table-add-new"
            >
              <FormattedMessage id="datatable.button.add.new" />
            </Button>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default ToolbarButtons;
