import React from 'react';
import { FormattedMessage } from 'react-intl';
import { showSnackBarMsg } from '../snackbar';
import axios from 'axios';
import { appConfig } from '../../config';
import { extractIdFromHref, getCustomerUUID } from '../../components/common/utils';
import { AppThunkAction } from '../index';

export function setWhitelistFormOpen(open: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_FORM_OPEN', payload: open });
  };
}

export function fetchWhitelisEntries(): AppThunkAction {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_WHITELIST_LOADING', payload: true });
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .get(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/allowed_servers`, config)
      .then((res) => {
        const data = res.data.map((e: $TSFixMe) => {
          const self = e.links.find((link: $TSFixMe) => link.rel === 'self').href;
          return { id: extractIdFromHref(self), ip: e.ip, endIp: e.endIp, host: e.host };
        });
        dispatch(setWhitelistEntries(data));
        dispatch({ type: 'SET_WHITELIST_LOADING', payload: false });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'SET_WHITELIST_LOADING', payload: false });
      });
  };
}

export function setWhitelistEntries(items: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_WHITELIST_ENTRIES', payload: items });
  };
}

export function addWhitelistEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .post(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/allowed_servers`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: 'ADD_WHITELIST_ENTRY',
            payload: {
              id: extractIdFromHref(res.data._links.self.href),
              ip: res.data.ip,
              endIp: res.data.endIp,
              host: res.data.host,
            },
          });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function removeWhitelistEntry(id: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    axios
      .delete(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/allowed_servers/${id}`, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'REMOVE_WHITELIST_ENTRY', payload: id });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateWhitelistEntry(item: $TSFixMe): AppThunkAction {
  return (dispatch, getState) => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    const customerUUID = getCustomerUUID(getState().email2smsConfig.parametersForForm.uuid);
    axios
      .put(`${appConfig.EMAIL2SMS_URL}/customers/${customerUUID}/allowed_servers/${item.id}`, item, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: 'UPDATE_WHITELIST_ENTRY', payload: item });
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataSaved" />, 'success'));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500)
          dispatch(showSnackBarMsg(<FormattedMessage id="data.fetching.dataFailed" />, 'error'));
      });
  };
}

export function setWhitelistEntryForForm(id: $TSFixMe): AppThunkAction {
  return (dispatch) => {
    dispatch({ type: 'SET_ENTRY_FOR_FORM', payload: id });
  };
}
