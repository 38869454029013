import Validator from "validatorjs";
import { OptOutFormValidator } from "./optput.form";

const validate: OptOutFormValidator = (values, props) => {
  const rules = {
    number: "required|regex:/^[+]?[0-9]{7,31}$/",
  };

  const validation = new Validator(values, rules, {
    required: props.intl.formatMessage({ id: 'form.validation.required' }),
    'regex.number': props.intl.formatMessage({ id: 'form.validation.mobilePhone.format' }),
  });

  validation.passes();
  return validation.errors.all();
};

export default validate;
