import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ExportMessageLogForm from './ExportMessageLogForm';
import validate from './exportMessageLogValidator';
import submit from './exportMessageLogSubmit';
import { Theme } from '@mui/material';
import { IntlProps } from '../../../common/types/intlProps';
import { AppState } from '../../../../reducers';
import { AppReduxFormProps, AppReduxFormSubmit, AppReduxFormValidator } from '../../../common/types/appReduxForm';

export type Props = {
  readonly open: boolean;
  readonly uuid: string | number;
  readonly handleClose: () => void;
} & IntlProps;

type FormValues = $TSFixMe;
type FormProps = AppReduxFormProps<FormValues, Props>;
export type ExportValidationConfigFormValidator = AppReduxFormValidator<FormValues, Props>;
export type ExportValidationConfigFormSubmit = AppReduxFormSubmit<FormValues, Props>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '50%',
    },
  },
  actions: {
    padding: theme.spacing(3),
  },
}));

const ExportValidationConfig = ({
  open,
  pristine,
  submitting,
  change,
  handleClose,
  handleSubmit,
}: FormProps) => {

  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth={true} className={classes.dialog} maxWidth={'md'}>
      <form onSubmit={handleSubmit(submit)} autoComplete="off" noValidate>
        <DialogTitle style={{ marginLeft: '10px' }}>
          <FormattedMessage id="email2sms.ubs.messageLogs.export.message.log" />
        </DialogTitle>
        <Divider />
        <DialogContent style={{ minHeight: 450, padding: 0 }}>
          <Grid item xs={12}>
            <ExportMessageLogForm change={change} />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          <Button color="secondary" variant="contained" disabled={submitting || pristine} type="submit">
            <FormattedMessage id="common.button.export" />
          </Button>
          <Button color="primary" variant="contained" onClick={handleClose}>
            <FormattedMessage id="common.button.close" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ExportValidationConfigReduxForm = injectIntl(
  reduxForm({
  form: 'exportValidationForm',
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  onSubmitSuccess: (result, dispatch, props) => {
    props.handleClose();
  },
})(ExportValidationConfig));

export default connect((state: AppState) => {
  return {
    filters: state.messageLog.filtersForForm,
    uuid: state.email2smsConfig.customerUuid || state.login.userData.customerUuid || 0,
  };
}, {}
)(ExportValidationConfigReduxForm);
