import { AnyAction } from 'redux';
import { ContactFile } from '../types/contactFile';

interface TagOption {
  readonly label: string;
  readonly value: string;
}

interface ContactsImportState {
  tags: TagOption[];
  tagOptions: TagOption[];
  skipLine: boolean;
  delimiter: string;
  quote: string;
  columns: Array<{
    index: number;
    col: string | null;
  }>;
  mappingError: string;
  file: Partial<ContactFile>;
  tab: number;
  error?: $TSFixMe;
}

const initialState: ContactsImportState = {
  tags: [],
  tagOptions: [],
  skipLine: true,
  delimiter: ',',
  quote: "'",
  columns: [],
  mappingError: '',
  file: {},
  tab: 0,
};

export default function reducer(state: ContactsImportState = initialState, action: AnyAction): ContactsImportState {
  switch (action.type) {
    case 'ADD_IMPORT_TAG': {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case 'REMOVE_IMPORT_TAG': {
      return {
        ...state,
        tags: action.tags,
      };
    }
    case 'SET_IMPORT_TAG_OPTIONS': {
      return {
        ...state,
        tagOptions: action.options,
      };
    }
    case 'ADD_IMPORT_TAG_OPTION': {
      return {
        ...state,
        tagOptions: action.options,
      };
    }
    case 'SET_IMPORT_FILE_DELIMITER': {
      return {
        ...state,
        delimiter: action.delimiter,
      };
    }
    case 'SET_IMPORT_FILE_QUOTE': {
      return {
        ...state,
        quote: action.quote,
      };
    }
    case 'SET_IMPORT_FILE_HEADER': {
      return {
        ...state,
        skipLine: action.header,
      };
    }
    case 'SET_IMPORT_FILE_COLUMNS': {
      return {
        ...state,
        columns: action.columns,
      };
    }
    case 'SET_IMPORT_FILE_COLUMN': {
      const newArray = [...state.columns];
      const duplicated = newArray.find(e => e.col === action.column);
      if (duplicated) {
        newArray[action.index].col = null;
        return {
          ...state,
          columns: newArray,
          error: 'contacts.error.sameCols',
        };
      }
      newArray[action.index].col = action.column;
      return {
        ...state,
        columns: newArray,
        error: '',
      };
    }
    case 'SET_MAPPING_ERROR': {
      return {
        ...state,
        mappingError: action.error,
      };
    }
    case 'SET_FILE': {
      return {
        ...state,
        file: action.file,
      };
    }
    case 'SET_TAB': {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case 'CLEAR_IMPORT_SETTINGS': {
      return { ...initialState };
    }
    default:
      return {
        ...state,
      };
  }
}
