const translatedAccessLogStatuses: Record<string, string> = {
  'OK': 'administration.email2sms.accessLog.status.ok',
  'SERVER_NOT_ON_LIST': 'administration.email2sms.accessLog.status.server.not.on.list',
  'SENDER_NOT_ON_LIST': 'administration.email2sms.accessLog.status.sender.not.on.list',
  'NOT_ACTIVE': 'administration.email2sms.accessLog.status.not.active',
  'MSG_TOO_LONG': 'administration.email2sms.accessLog.status.msg.too.long',
  'PHONE_NUMBER_IS_WRONG': 'administration.email2sms.accessLog.status.wrong.phone.number',
  'NO_RECIPIENT': 'administration.email2sms.accessLog.status.no.recipient',
  'RECIPIENT_DOMAIN_IS_NOT_ALLOWED': 'administration.email2sms.accessLog.status.domain.not.allowed',
};

export default translatedAccessLogStatuses;