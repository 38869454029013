import { AnyAction } from 'redux';
import { MtApiUser } from '../../types/mtApiUser';
import { Customer } from '../../types/customer';

interface MtApiConfigState {
  mtApiEntries: MtApiUser[];
  mtApiSelectedEntry: Partial<MtApiUser>;
  mtApiLoading: boolean;
  customers: Customer[];
  customer: $TSFixMe;
  mtApiFormLoading: boolean;
  editMode: boolean;
  smscId: string[];
}

const initialState: MtApiConfigState = {
  mtApiEntries: [],
  mtApiSelectedEntry: {},
  mtApiLoading: false,
  customers: [],
  customer: undefined,
  mtApiFormLoading: false,
  editMode: false,
  smscId: [],
};

export default function reducer(state: MtApiConfigState = initialState, action: AnyAction): MtApiConfigState {
  switch (action.type) {
    case 'SET_MT_API_LOADING': {
      return {
        ...state,
        mtApiLoading: action.payload,
      };
    }
    case 'SET_MT_API_FORM_LOADING': {
      return {
        ...state,
        mtApiFormLoading: action.payload,
      };
    }
    case 'SET_MT_API_ENTRIES': {
      return {
        ...state,
        mtApiEntries: action.payload,
      };
    }
    case 'ADD_MT_API_ENTRY': {
      return {
        ...state,
        mtApiEntries: [...state.mtApiEntries, action.payload],
      };
    }
    case 'REMOVE_MT_API_ENTRY': {
      return {
        ...state,
        mtApiEntries: state.mtApiEntries.filter((item) => item.username !== action.payload),
      };
    }

    case 'UPDATE_MT_API_ENTRY': {
      const itemToReplace = {
        customerUuid: action.payload.customerUuid,
        username: action.payload.username,
        callNumber: action.payload.callNumber,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        customLogic: action.payload.customLogic,
        defaultLanguage: action.payload.defaultLanguage,
        smscId: action.payload.smscId,
        active: action.payload.active,
        password: action.payload.password,
        whitelistActive: action.payload.whitelistActive,
      };

      return {
        ...state,
        mtApiEntries: [
          // @ts-expect-error itemToReplace does not contain all MtApiUser properties - to verify and fix!
          ...state.mtApiEntries.filter((item) => item.username !== action.payload.username),
          // @ts-expect-error itemToReplace does not contain all MtApiUser properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_MT_API_ENTRY_FOR_FORM': {
      return {
        ...state,
        mtApiSelectedEntry: action.payload,
      }
    }
    case 'SET_CUSTOMERS': {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case 'SET_CUSTOMER': {
      return {
        ...state,
        customer: action.payload,
      };
    }
    case 'SET_EDIT_MODE': {
      return {
        ...state,
        editMode: action.payload,
      };
    }
    case 'SET_SMSC_ID': {
      return {
        ...state,
        smscId: action.payload,
      }
    }
    default:
      return {
        ...state,
      };
  }
}
