import SockJS from 'sockjs-client';
import { Client, Message } from '@stomp/stompjs';

interface Configuration {
  readonly wsUrl: string;
  readonly debugMode: boolean;
  readonly accessToken: () => string;
  readonly reconnectDelayInMs: number;
}

export class StompWsClient {
  private readonly accessToken: () => string;

  private readonly client: Client;

  constructor(config: Configuration) {
    this.accessToken = config.accessToken;
    this.client = this.initWsStompClient(config);
  }

  private initWsStompClient(config: Configuration): Client {
    return new Client({
      webSocketFactory: () => {
        return new SockJS(config.wsUrl);
      },
      reconnectDelay: config.reconnectDelayInMs,
      ...(config.debugMode && {
        debug: (msg: string) => console.log(msg),
      }),
    });
  }

  onConnect(onConnect: () => void): void {
    this.client.onConnect = onConnect;
  }

  onClose(onClose: () => void): void {
    this.client.onWebSocketClose = onClose;
  }

  isConnected(): boolean {
    return this.client.connected;
  }

  connect(): void {
    this.client.connectHeaders = this.createHeaders();
    this.client.activate();
  }

  disconnect(): void {
    this.client.deactivate();
  }

  subscribe<T>(destination: string, onMessage: (message: T) => void): string {
    if (!this.isConnected()) {
      console.error('[STOMP WS CLIENT] Unable to subscribe due to disconnected websocket');
      throw new Error('Unable to subscribe due to disconnected websocket');
    }

    const subscription = this.client.subscribe(
      destination,
      (message: Message) => {
        onMessage(JSON.parse(message.body));
      },
      this.createHeaders(),
    );

    return subscription.id;
  }

  unsubscribe(subscriptionId: string): void {
    this.client.unsubscribe(subscriptionId, this.createHeaders());
  }

  send(destination: string, message: {}): void {
    if (!this.isConnected()) {
      console.error('[STOMP WS CLIENT] Unable to send message due to disconnected websocket');
      throw new Error('Unable to send message due to disconnected websocket');
    }

    this.client.publish({
      destination,
      body: JSON.stringify(message),
      headers: this.createHeaders(),
    });
  }

  private createHeaders(): {} {
    return { 'X-Authorization': `Bearer ${this.accessToken()}` };
  }
}