import React from 'react';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import Fab from '@mui/material/Fab';
import ShowChart from '@mui/icons-material/ShowChart';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Paper, Theme, Alert, AlertTitle, Drawer, Typography } from '@mui/material';

import Whitelist from './whitelist/whitelist';
import Tags from './ubs/tags/tags';
import Configuration from './configuration/configuration';
import CountryOverride from './ubs/country.override/country';
import Spam from './ubs/spam.whitelist/spam';
import MessageLogs from './ubs/message.logs/index';
import AccessLog from './../administration/email2sms/AccessLog';
import OptOut from './ubs/optput/optout';
import drawerStyle from '../../styles/drawers';
import Performance from './ubs/performance/performance';
import { setPerformanceOpen } from '../../actions/ubs/ubs.performance';
import { getEmail2SmsData } from '../../actions/email2sms/config';
import Grow from '@mui/material/Grow';
import stylesConfig, { appConfig } from '../../config';
import { Authorizations } from '../../types/login';
import { AppState } from '../../reducers';
import MessageLogsExportHistory from './ubs/message.logs/MessageLogsExportHistory';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  drawerPaper: drawerStyle(theme).drawerVeryBig,
}));

type TabPanelProps = {
  readonly children: React.ReactNode;
  readonly value: number;
  readonly index: number;
  readonly style?: React.CSSProperties;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

type Props = {
  readonly role: Authorizations;
  readonly uuid: string | number;
  readonly customerId?: number;
  readonly performanceOpen: boolean;
  readonly serviceDisabled: boolean;
  readonly setPerformanceOpen: (open: boolean) => void;
};

const Ubs = ({ 
  role,
  uuid,
  customerId,
  performanceOpen,
  serviceDisabled,
  setPerformanceOpen,
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isUbs = (stylesConfig.THEME === 'sunrise' && customerId === 30) || appConfig.BETA;

  const commonTabs = [
    { component: <Configuration />, label: 'administration.email2sms.parameters.tab.label.allowed.emails' },
    { component: <Whitelist />, label: 'email2sms.ubs.tab.label.whitelist' },
  ];
  const ubsTabs = [
    { component: <Spam />, label: 'email2sms.ubs.tab.label.spam.whitelist' },
    { component: <CountryOverride />, label: 'email2sms.ubs.tab.label.country.override' },
    { component: <OptOut />, label: 'email2sms.ubs.tab.label.optouts' },
    { component: <Tags />, label: 'email2sms.ubs.tab.label.tags' },
  ];
  const userTabs = [
    ...commonTabs,
    ...(isUbs ? ubsTabs : []),
    // @ts-expect-error TODO: no property uuid, but it's there?
    { component: <MessageLogs uuid={uuid} />, label: 'email2sms.ubs.tab.label.message.logs' },
    { component: <MessageLogsExportHistory />, label: 'email2sms.ubs.tab.label.message.logs.export.history' },
    { component: <AccessLog />, label: 'administration.email2sms.accessLog.table.title' },
  ];
  const adminTabs = [{ component: <Configuration />, label: 'email2sms.ubs.tab.label.configuration', ...commonTabs }];
  const tabs = role['SUPER_ADMIN'] ? adminTabs : userTabs;
  return serviceDisabled ? (
    <Grow in={true}>
      <Alert severity="info">
        <AlertTitle>
          <FormattedMessage id="email2sms.alert.title" />
        </AlertTitle>
        <FormattedMessage id="email2sms.alert.text" />
        <strong>
          <FormattedMessage id="email2sms.alert.admin" />
        </strong>
      </Alert>
    </Grow>
  ) : (
    <>
      <Grid container spacing={2} style={{ paddingTop: '20px' }}>
        <Grid item xs={12} style={{ paddingBottom: '60px' }}>
          <Paper>
            <Paper style={{ backgroundColor: '#F5F5F5', width: '100%' }}>
              <Tabs
                style={{ minHeight: '100%' }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab label={<FormattedMessage id={tab.label} />} />
                ))}
              </Tabs>
            </Paper>
            {tabs.map((tab, index) => (
              <TabPanel value={value} index={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Paper>
        </Grid>
      </Grid>
      {isUbs && (
        <Fab
          color="primary"
          variant="extended"
          style={{ position: 'fixed', bottom: '20px', right: '40px' }}
          onClick={() => setPerformanceOpen(true)}
        >
          <ShowChart />
          <FormattedMessage id="common.label.performance" />
        </Fab>
      )}
      <Drawer
        anchor="right"
        open={performanceOpen}
        onClose={() => setPerformanceOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {isUbs && <Performance toggleDrawer={setPerformanceOpen} />}
      </Drawer>
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      performanceOpen: store.ubsPerformance.performanceOpen,
      role: store.login.userData.authorizations,
      serviceDisabled: store.email2smsConfig.serviceDisabled,
      customerId: store.login.userData.customerId,
      uuid: store.login.userData.customerUuid || 0,
    };
  },
  { setPerformanceOpen, getEmail2SmsData }
)(Ubs);
