import chatChannelProvider from '../../chats/chatChannelProvider';
import { AppThunkAction } from '../index';
import { ChatConversationId, ChatMessage } from '../../types/chatMessage';
import { createChatChannelsAction } from './index';
import { selectIsUnreadMessageId } from '../../reducers/chats/messages';

const setUnreadMessagesIdsAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  unreadMessagesIds: string[];
}>('messages/setUnreadMessagesIds');

const addUnreadMessageIdAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  msgId: string;
}>('messages/addUnreadMessageId');

const removeUnreadMessageIdAction = createChatChannelsAction<{
  conversationId: ChatConversationId;
  msgId: string;
}>('messages/removeUnreadMessageId');

const fetchUnreadMessagesIdsByConversationId = (conversationId: ChatConversationId): AppThunkAction => {
  return async (dispatch) => {
    const { channel} = conversationId;

    const unreadMessagesIds: string[] = await chatChannelProvider.get(channel)
      .api.fetchUnreadMessagesIds(conversationId);

    dispatch(setUnreadMessagesIdsAction(channel, {
      conversationId,
      unreadMessagesIds,
    }));
  };
};

export const fetchUnreadMessagesForAllChannels = (): AppThunkAction => {
  return (dispatch, getState) => {
    const { customerUuid } = getState().login.userData;

    chatChannelProvider.all().forEach(async channel => {
      const unreadConversationIds = await channel.api.fetchUnreadConversationsIds(customerUuid!);

      unreadConversationIds.forEach(conversationId => {
        dispatch(fetchUnreadMessagesIdsByConversationId(conversationId));
      });
    });
  };
};

export const markMessageAsRead = (chatMessage: ChatMessage): AppThunkAction => {
  return async (dispatch, getState) => {
    const { channel, clientId, senderId, msgId } = chatMessage;
    const conversationId: ChatConversationId = { channel, senderId, clientId };

    const isUnreadMessage = selectIsUnreadMessageId(getState(), conversationId, msgId);
    if (isUnreadMessage) {
      try {
        await chatChannelProvider.get(channel).api.markMessageAsRead(chatMessage);
        dispatch(removeUnreadMessageIdAction(channel, { conversationId, msgId }));
      } catch {
        console.error(`Could not mark chat message as read with msgId=${msgId} on ${channel} channel`);
      }
    }
  };
};

export const markMessageAsUnread = (chatMessage: ChatMessage): AppThunkAction => {
  return (dispatch, getState) => {
    const { channel, clientId, senderId, msgId } = chatMessage;
    const conversationId: ChatConversationId = { channel, senderId, clientId };

    const isUnreadMessage = selectIsUnreadMessageId(getState(), conversationId, msgId);
    if (!isUnreadMessage) {
      dispatch(addUnreadMessageIdAction(channel, { conversationId, msgId }));
    }
  };
};
