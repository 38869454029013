import React from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography, Drawer, Paper, Theme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import {
  setSpamFormOpen,
  setSpamEntryForForm,
  removeSpamEntry,
  fetchSpamWhitelist,
} from '../../../../actions/ubs/ubs.spam';
import SpamForm from './spam.form';
import drawerStyle from '../../../../styles/drawers';
import confirm from '../../../common/confirm';
import Loading from '../../../common/Loading';
import LocalizedMaterialTable from '../../../common/LocalizedMaterialTable';
import { AppState } from '../../../../reducers';
import { Authorizations } from '../../../../types/login';
import { Components } from '@material-table/core';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: drawerStyle(theme).drawerSmall,
}));

type Props = {
  readonly loading: boolean;
  readonly role: Authorizations;
  readonly spam: $TSFixMe[];
  readonly spamFormOpen: boolean;
  readonly fetchSpamWhitelist: () => void;
  readonly removeSpamEntry: (id: $TSFixMe) => void;
  readonly setSpamFormOpen: (open: boolean) => void;
  readonly setSpamEntryForForm: (id?: $TSFixMe) => void;
};

const Spam = ({
  spam,
  setSpamFormOpen,
  spamFormOpen,
  setSpamEntryForForm,
  removeSpamEntry,
  role,
  fetchSpamWhitelist,
  loading,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  React.useEffect(() => {
    fetchSpamWhitelist();
  }, [fetchSpamWhitelist]);

  const spamForm = <SpamForm toggleDrawer={setSpamFormOpen} />;

  const handleEdit = (id: $TSFixMe) => {
    setSpamEntryForForm(id);
    setSpamFormOpen(true);
  };
  const handleDelete = (id: $TSFixMe) => {
    confirm(intl.formatMessage({ id: 'data.fetching.confirmation' }), {
      okLabel: intl.formatMessage({ id: 'data.fetching.confirmation.yes' }),
      cancelLabel: intl.formatMessage({ id: 'data.fetching.confirmation.no' }),
      title: intl.formatMessage({ id: 'data.fetching.confirmation.title' }),
    }).then(
      () => removeSpamEntry(id),
      () => {}
    );
  };

  const prepareComponents = (role: Authorizations) => {
    let components: Components = {
      Container: ({ children }) => {
        return (
          <Paper elevation={0} style={{ marginBottom: '70px' }}>
            <Loading loading={loading}>
              <>{children}</>
            </Loading>
          </Paper>
        );
      },
    };
    if (role.SUPER_ADMIN)
      Object.assign(components, {
        Action: (props: any) => {
          if (props.action.icon === 'add new') {
            return (
              <Button onClick={(event) => props.action.onClick(event, props.data)} color="primary" size="small">
                {props.action.icon}
              </Button>
            );
          }
          return (
            <IconButton size="small">
              <EditIcon color="primary" />
            </IconButton>
          );
        },
      });
    return components;
  };

  const prepareActions = (role: Authorizations) => {
    let actions = [];
    if (role.SUPER_ADMIN)
      actions.push({
        icon: 'add new',
        tooltip: intl.formatMessage({ id: 'common.button.add.new' }),
        isFreeAction: true,
        onClick: () => {
          setSpamFormOpen(true);
          setSpamEntryForForm();
        },
      });
    return actions;
  };

  const prepareColumns = (role: Authorizations) => {
    let columns = [
      {
        title: <FormattedMessage id="email2sms.ubs.spam.table.allowed.sender" />,
        field: 'sender',
      },
    ];
    if (role.SUPER_ADMIN)
      columns.push({
        title: <FormattedMessage id="common.label.actions" />,
        // @ts-expect-error TODO: argument is not assignable
        width: 80,
        render: (props: $TSFixMe) => {
          return <>
            <IconButton
              onClick={() => handleEdit(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(props.id)}
              style={{
                height: '32px',
                width: '32px',
                padding: 0,
              }}
              size="large"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </>;
        },
      });
    return columns;
  };

  return (
    <>
      <LocalizedMaterialTable
        title={
          <Typography variant="h5" component="span">
            <FormattedMessage id="email2sms.ubs.spam.table.title.text.allowed.senders" />
          </Typography>
        }
        columns={prepareColumns(role)}
        data={spam}
        components={prepareComponents(role)}
        options={{
          padding: 'dense',
          pageSize: 10,
          searchFieldStyle: { margin: '40px' },
          rowStyle: { fontSize: '0.8125rem', padding: '0px' },
          // @ts-expect-error TODO: no property cellStyle
          cellStyle: {
            padding: role.SUPER_ADMIN ? '2px 16px 1px 16px' : '4px 16px',
            maxHeight: '36px',
          },
          paginationPosition: 'top',
          headerStyle: { position: 'sticky', top: 0, zIndex: 100 },
          maxBodyHeight: 500,
        }}
        actions={prepareActions(role)}
      />
      <Drawer
        anchor="right"
        open={spamFormOpen}
        onClose={() => setSpamFormOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {spamForm}
      </Drawer>
    </>
  );
};

export default connect(
  (store: AppState) => {
    return {
      spam: store.ubsSpam.spam,
      spamFormOpen: store.ubsSpam.spamFormOpen,
      role: store.login.userData.authorizations,
      config: store.email2smsConfig.parametersForForm,
      loading: store.ubsSpam.spamLoading,
    };
  },
  { setSpamFormOpen, setSpamEntryForForm, removeSpamEntry, fetchSpamWhitelist }
)(Spam);
