import { ViberAccountApi } from './viberAccountApi';
import { ViberTargetApi } from './viberTargetApi';
import { ViberMessageApi } from './viberMessageApi';
import { HttpClient } from '../common/httpClient';
import { AxiosHttpClient } from '../common/axiosHttpClient';
import { appConfig } from '../../config';
import { ViberCdrsApi } from './viberCdrsApi';
import { ViberTemplateApi } from './viberTemplateApi';
import { ViberStatisticsApi } from './viberStatisticsApi';
import { ViberPricingApi } from './viberPricingApi';
import { ViberConfigApi } from './viberConfigApi';

class ViberApi {
  public readonly targets: ViberTargetApi;
  public readonly accounts: ViberAccountApi;
  public readonly messages: ViberMessageApi;
  public readonly cdrs: ViberCdrsApi;
  public readonly templates: ViberTemplateApi;
  public readonly statistics: ViberStatisticsApi;
  public readonly pricing: ViberPricingApi;
  public readonly config: ViberConfigApi;

  constructor() {
    const httpClient = this.initHttpClient();

    this.targets = new ViberTargetApi(httpClient);
    this.accounts = new ViberAccountApi(httpClient);
    this.messages = new ViberMessageApi(httpClient);
    this.cdrs = new ViberCdrsApi(httpClient);
    this.templates = new ViberTemplateApi(httpClient);
    this.statistics = new ViberStatisticsApi(httpClient);
    this.pricing = new ViberPricingApi(httpClient);
    this.config = new ViberConfigApi(httpClient);
  }

  private initHttpClient(): HttpClient {
    return new AxiosHttpClient({
      baseUrl: appConfig.VIBER_URL,
      accessToken: () => localStorage.getItem('chatToken')!,
    });
  }
}

export default new ViberApi();
