import { AnyAction } from 'redux';
import { ViberTemplate } from '../../types/ViberTemplate';

interface ViberTemplatesState {
  viberTemplates: ViberTemplate[];
  selectedViberTemplate: Partial<ViberTemplate>;
  viberTemplatesLoading: boolean;
  viberTemplatesFormLoading: boolean;
  updateSelectedTemplateDialogOpen: boolean;
  pendingForApprovalViberTemplates: ViberTemplate[];
  selectedViberId: string;
}

const initialState: ViberTemplatesState = {
  viberTemplates: [],
  viberTemplatesLoading: false,
  selectedViberTemplate: {},
  viberTemplatesFormLoading: false,
  updateSelectedTemplateDialogOpen: false,
  pendingForApprovalViberTemplates: [],
  selectedViberId: '',
};

export default function reducer(state: ViberTemplatesState = initialState, action: AnyAction): ViberTemplatesState {
  switch (action.type) {
    case 'SET_VIBER_TEMPLATES_LOADING': {
      return {
        ...state,
        viberTemplatesLoading: action.payload,
      };
    }
    case 'SET_VIBER_TEMPLATES': {
      return {
        ...state,
        viberTemplates: action.payload,
      };
    }
    case 'SET_VIBER_TEMPLATE_FORM_LOADING': {
      return {
        ...state,
        viberTemplatesFormLoading: action.payload,
      };
    }
    case 'ADD_VIBER_TEMPLATE': {
      return {
        ...state,
        viberTemplates: [...state.viberTemplates, action.payload],
      };
    }
    case 'REMOVE_VIBER_TEMPLATE': {
      return {
        ...state,
        viberTemplates: state.viberTemplates.filter((item) => item.id !== action.payload),
      };
    }
    case 'UPDATE_VIBER_TEMPLATE': {
      const itemToReplace = {
        name: action.payload.name,
        accountId: action.payload.accountId,
        originalText: action.payload.originalText,
        translateText: action.payload.translateText,
      };
      return {
        ...state,
        viberTemplates: [
          // @ts-expect-error itemToReplace does not contain all ViberTemplate properties - to verify and fix!
          ...state.viberTemplates.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all ViberTemplate properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_VIBER_TEMPLATE_FOR_FORM': {
      return {
        ...state,
        selectedViberTemplate: action.payload,
      };
    }
    case 'SET_UPDATE_SELECTED_TEMPLATE_DIALOG_OPEN': {
      return {
        ...state,
        updateSelectedTemplateDialogOpen: action.payload,
      };
    }
    case 'SET_PENDING_FOR_APPROVAL_VIBER_TEMPLATES': {
      return {
        ...state,
        pendingForApprovalViberTemplates: action.payload,
      };
    }
    case 'SET_SELECTED_VIBER_ID': {
      return {
        ...state,
        selectedViberId: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}
