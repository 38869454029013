import { DataTableColumn } from '../../types/dataTableColumn';

export const filterSerializableColumns = (columns: DataTableColumn[]) => {
  return columns.map(({ render, label, ...rest }) => rest);
};

export const restoreNonSerializableColumns = (columns: DataTableColumn[], columnsConfig: $TSFixMe) => {
  const config = columnsConfig;

  return columns.map(col => {
    const configCol = config.find((c: $TSFixMe) => c.id === col.id);
    return {
      ...col,
      render: configCol ? configCol.render : undefined,
      label: configCol.label,
    };
  });
};
