import React from 'react';
import { ClimbingBoxLoader } from 'react-spinners';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import theme from '../../theme';
import stylesConfig from '../../config';

const Spinner = () => (
  <div
    style={{
      height: '100vh',
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
      placeItems: 'center',
    }}
  >
    <div>
      <ClimbingBoxLoader size={9} color={theme.palette.primary.light} />
      <Typography>
        <FormattedMessage
          id="dashboard.app.name"
          values={{ appname: stylesConfig.APPNAME }}
        />
      </Typography>
    </div>
  </div>
);

export default Spinner;
