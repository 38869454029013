import React from 'react';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import Dashboard from '@mui/icons-material/Dashboard';
import ReportsIcon from '@mui/icons-material/InsertChart';
import Help from '@mui/icons-material/Help';
import Sms from '@mui/icons-material/Sms';
import Chat from '@mui/icons-material/Chat';
import Contacts from '@mui/icons-material/Contacts';
import Email from '@mui/icons-material/Email';
import Forum from '@mui/icons-material/Forum';
import Account from '@mui/icons-material/AccountBox';
import Admin from '@mui/icons-material/SupervisorAccount';
import PlaylistAddCheck from '@mui/icons-material/PlaylistAddCheck';
import Message from '@mui/icons-material/Message';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import AodIcon from '@mui/icons-material/Aod';
import SettingsIcon from '@mui/icons-material/Settings';
import RateReviewIcon from '@mui/icons-material/RateReview';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { FormattedMessage } from 'react-intl';
import MainMenuEntry from './MainMenuEntry';
import { MenuEntry } from '../types/menu';
import { AppState } from '../reducers';

type MenuIcons = {
  [key: string]: JSX.Element;
};

const icon: MenuIcons = {
  dashboard: <Dashboard />,
  sms: <Sms />,
  forum: <Forum />,
  contacts: <Contacts />,
  playlistAddCheck: <PlaylistAddCheck />,
  message: <Message />,
  reports: <ReportsIcon />,
  account: <Account />,
  admin: <Admin />,
  help: <Help />,
  chat: <Chat />,
  email: <Email />,
  quickSms: <SpeakerNotesIcon />,
  mnp: <AodIcon />,
  settings: <SettingsIcon />,
  tests: <RateReviewIcon />,
  channels: <NoteAddIcon />,
  finance: <RequestQuoteIcon />,
};

type Props = {
  readonly menuData: MenuEntry[];
  readonly menuOpen: string[];
  readonly menuSelected: string;
};

const MainMenu = (props: Props) => {
  const parseEntry = (entry: MenuEntry, parent?: string) => {
    const { menuOpen, menuSelected } = props;
    const parsedEntry = {
      label: <FormattedMessage id={entry.label} />,
      icon: entry.icon ? icon[entry.icon] : undefined,
      link: entry.link,
      header: entry.header ? <FormattedMessage id={entry.header} /> : undefined,
      selected: menuSelected === entry.label,
      id: entry.label,
      open: menuOpen.includes(entry.label),
      parent: parent,
    };
    return parsedEntry;
  };

  const { menuData } = props;

  const menuItems = menuData.map((entry) => {
    const subMenu = entry.subMenu.map((e) => {
      return { ...parseEntry(e, entry.label) };
    });
    return { ...parseEntry(entry), subMenu };
  });

  const entries = menuItems.map((entry, index) => {
    return (
      <MainMenuEntry
        key={index}
        menuData={entry}
      />
    );
  });

  return <List>{entries}</List>;
};

const mapStateToProps = (state: AppState) => ({
  menuData: state.app.menu,
  location: state.router.location,
  menuOpen: state.app.menuOpen,
  menuSelected: state.app.menuSelected,
});

export default connect(mapStateToProps)(MainMenu);
