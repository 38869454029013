import { v4 as uuidv4 } from 'uuid';
import {
  addCountryEntry,
  updateCountryEntry,
} from '../../../../actions/ubs/ubs.country';
import { CountryFormSubmit } from './country.form';

const submitForm: CountryFormSubmit = (values, dispatch, props) => {
  dispatch(
    values.id
      ? updateCountryEntry(values)
      : addCountryEntry({ ...values, id: uuidv4() })
  );
}
export default submitForm;
