import React, { Fragment } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { startOfDay } from 'date-fns';

type Props = {
  readonly label: string | object;
  readonly selectedDate: string;
  readonly handleChangeDate: (name: $TSFixMe, type: $TSFixMe) => void;
};

const DatePic = (props: Props) => {
  const { label, selectedDate, handleChangeDate } = props;

  return (
    <Fragment>
      <div className="picker">
        <DatePicker
          label={label}
          inputFormat="yyyy-MM-dd"
          mask="____-__-__"
          value={selectedDate}
          onChange={handleChangeDate}
          // @ts-expect-error TODO: no property initialFocusedDate
          initialFocusedDate={startOfDay(new Date())}
          autoOk
          views={['year', 'month', 'day']}
          clearLabel={<FormattedMessage id="date.picker.clear.label" />}
          cancelLabel={<FormattedMessage id="common.button.cancel" />}
          okLabel={<FormattedMessage id="date.picker.ok.label" />}
          renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
        />
      </div>
    </Fragment>
  );
};

export default DatePic;
