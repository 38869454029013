import { SupportedLang } from './supportedLang';
import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';

type DateLocales = {
  [key in SupportedLang]: Locale;
};

export const dateLocales: DateLocales = {
  en: enLocale,
  de: deLocale,
  // pl: plLocale,
};
