import { SupportedChatChannel } from '../supportedChatChannel';

export enum ChatWsMessageType {
  OUTGOING_MESSAGE = 'OUTGOING_MESSAGE',
  INCOMING_MESSAGE = 'INCOMING_MESSAGE',
  EVENT = 'EVENT',
  TARGET_CREATED = 'TARGET_CREATED',
}

export interface ChatWsWrapper<P = any> {
  readonly type: ChatWsMessageType;
  readonly channel: SupportedChatChannel;
  readonly payload: P;
}
