import { createTheme } from '@mui/material/styles';

const sunrise = createTheme({
  palette: {
    primary: {
      light: '#e6003c',
      //main: '#aa1937',
      main: '#d93227',
      dark: '#e6003c',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#64b9e6',
      main: '#64b9e6',
      dark: '#64b9e6',
      contrastText: '#fff',
    },
    type: 'light',
    custom: {
      //appBarColor: '#aa1937',
      appBarColor: '#d93227',
      accentColor: '#ffc805',
    },
    online: {
      main: '#4caf50',
    },
    away: {
      main: '#ffd700',
    },
    offline: {
      main: '#d32f2f',
    },
    unknown: {
      main: '#a9a9a9',
    },
    infobox: {
      color: '#d93227',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#0000004d",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#fff",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 16,
            border: '2px solid #fff',
            backgroundColor: "#0000004d",
          },
        },
      },
    },
  },
});

export default sunrise;
