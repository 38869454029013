import { CustomFormats } from 'react-intl';

export const formats: CustomFormats = {
  number: {
    USD: {
      style: 'currency',
      currency: 'USD',
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
    },
    CHF: {
      style: 'currency',
      currency: 'CHF',
    },
    GBP: {
      style: 'currency',
      currency: 'GBP',
    },
  },
};
