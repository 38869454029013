import { AnyAction } from 'redux';
import { MtApiBulkRouting } from '../../types/mtApiRouting';

interface MtApiBulkRoutingState {
  bulkRouting: MtApiBulkRouting[];
  selectedBulkRoutingEntry: Partial<MtApiBulkRouting>;
  bulkRoutingLoading: boolean;
}

const initialState: MtApiBulkRoutingState = {
  bulkRouting: [],
  selectedBulkRoutingEntry: {},
  bulkRoutingLoading: false,
};

export default function reducer(state: MtApiBulkRoutingState = initialState, action: AnyAction): MtApiBulkRoutingState {
  switch (action.type) {
    case 'SET_BULK_ROUTING_LOADING': {
      return {
        ...state,
        bulkRoutingLoading: action.payload,
      };
    }
    case 'UPDATE_BULK_ROUTING_ENTRY': {
      const itemToReplace = {
        smscId: action.payload.smscId,
      };
      return {
        ...state,
        bulkRouting: [
          // @ts-expect-error itemToReplace does not contain all MtApiBulkRouting properties - to verify and fix!
          ...state.bulkRouting.filter((item) => item.id !== action.payload.id),
          // @ts-expect-error itemToReplace does not contain all MtApiBulkRouting properties - to verify and fix!
          itemToReplace,
        ],
      };
    }
    case 'SET_BULK_ROUTING_ENTRY_FOR_FORM': {
      return {
        ...state,
        selectedBulkRoutingEntry: action.payload,
      };
    }
    case 'SET_BULK_ROUTING_ENTRIES': {
      return {
        ...state,
        bulkRouting: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
