import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const image = require('../../styles/whatsup.png');

const TestAccountDialogStep4 = () => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.4.communicate.whatsapp" />
        </Typography>

        <Typography variant={'body2'} style={{ marginTop: 15 }} align="justify">
          <FormattedMessage id="testAccount.dialog.step.4.raise.customer.satisfaction" />
        </Typography>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.4.why.use" />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.4.most.used" />
        </Typography>
        <Typography variant={'body2'}>
          <FormattedMessage id="testAccount.dialog.step.4.billions.of.users" />
        </Typography>

        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.4.timely.communication" />
        </Typography>
        <Typography variant={'body2'}>
          <FormattedMessage id="testAccount.dialog.step.4.communicate.without.delay" />
        </Typography>

        <Typography
          variant={'body1'}
          component={'p'}
          color="secondary"
          style={{ marginTop: 15, fontWeight: 'bold' }}
        >
          <FormattedMessage id="testAccount.dialog.step.4.security" />
        </Typography>
        <Typography variant={'body2'}>
          <FormattedMessage id="testAccount.dialog.step.4.encryption" />
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <img src={image} width={200} style={{ marginLeft: '30px' }} alt="wa" />
      </Grid>
    </Grid>
  );
};

export default TestAccountDialogStep4;
