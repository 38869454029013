import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { doLogout } from './actions/login';
import { appConfig } from './config';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const DEFAULT_ACTIVITY_TIMEOUT_LOGOUT = 1200000;
const DEFAULT_PROMPT_BEFORE_IDLE = 60000;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(1, 3, 3),
  },
}));

type Props = {
  readonly authenticated: boolean;
  readonly doLogout: (redirect?: boolean) => void;
};

const LogoutWarningDialog = ({
  doLogout,
  authenticated,
}: Props) => {
  const timeout = appConfig.USER_ACTIVITY_TIMEOUT_LOGOUT
    ? parseInt(appConfig.USER_ACTIVITY_TIMEOUT_LOGOUT)
    : DEFAULT_ACTIVITY_TIMEOUT_LOGOUT;
  const promptBeforeIdle = DEFAULT_PROMPT_BEFORE_IDLE;

  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const onIdle = () => {
    if (authenticated) {
      setOpen(false);
      doLogout();
    }
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    authenticated && setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime()/1000));
    }, 500);

    return () => {
      clearInterval(interval);
    }
  });

  const handleActive = () => {
    activate();
    setOpen(false);
  };

  return (
    <Dialog open={open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage id="dialog.idle.user.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="span">
          <FormattedMessage
            id="dialog.idle.user.countodwn.till.logout"
            values={{ remaining: remaining }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          onClick={handleActive}
        >
          <FormattedMessage id="dialog.idle.user.continue.session" />
        </Button>
        <Button variant="contained" color="secondary" onClick={onIdle}>
          <FormattedMessage id="dialog.user.info.menu.button.logout" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, { doLogout })(LogoutWarningDialog);
