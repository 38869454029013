import { Action } from 'redux';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';

export interface ChatChannelsAction extends Action {
  channel: SupportedChatChannel;
  payload?: any;
}

export const createChatChannelsAction = <P = void>(subtype: string) => {
  return (channel: SupportedChatChannel, payload: P): ChatChannelsAction => ({
    type: `chatChannels/${subtype}`,
    channel,
    payload,
  });
};
