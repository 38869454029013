import { Theme } from '@mui/material';

const styles = (theme: Theme) => ({
  nested: {
    paddingLeft: 80,
    textDecoration: 'none',
    // color: theme.palette.primary.main,
    borderStyle: 'solid',
    borderLeft: '4px',
    borderColor: theme.palette.grey[100],

    '&:hover': {
      borderStyle: 'solid',
      color: theme.palette.primary.main,
      borderLeft: '4px',
      borderColor: theme.palette.primary.main,
    },
  },
  nestedOpen: {
    textDecoration: 'none',
    borderStyle: 'solid',
    borderLeft: '4px',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey[100],

    '&:hover': {
      borderStyle: 'solid',
      // color: theme.palette.primary.main,
      borderLeft: '4px',
      borderColor: theme.palette.secondary.main,
      // backgroundColor: lighten(theme.palette.primary.dark, 0.8),
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  link: {
    textDecoration: 'none',
    // color: theme.palette.primary.main,
    borderStyle: 'solid',
    borderLeft: '4px',
    // borderRight: '4px',
    borderColor: theme.palette.grey[50],

    '&:hover': {
      borderStyle: 'solid',
      color: theme.palette.primary.main,

      borderLeft: '4px',
      // borderRight: '4px',
      borderColor: theme.palette.secondary.main,
      // backgroundColor: lighten(theme.palette.primary.dark, 0.8),
    },
  },
  arrowColor: {
    color: theme.palette.primary.main,
    margin: 0,
  },
  selected: {
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.grey[300],
  },
  open: {
    borderStyle: 'solid',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    borderLeft: '4px',
    // borderRight: '4px',
    borderColor: theme.palette.secondary.main,
  },
  badgeMargin: {
    margin: 5,
  },
});

export default styles;
