import { HttpClient } from '../common/httpClient';
import { SupportedChatChannel } from '../../chats/supportedChatChannel';
import { Contact } from '../../types/contact';
import { Page } from '../../types/page';
import { ResourceLink } from '../../types/resourceLink';

type ContactResponse = {
  readonly id: number;
  readonly mobilePhone: string;

  readonly email?: string;
  readonly firstName?: string;
  readonly middleName?: string;
  readonly lastName?: string;
  readonly nickname?: string;
  readonly salutation?: string;
  readonly gender?: string;
  readonly title?: string;
  readonly birthday?: string;
  readonly country?: string;
  readonly language?: string;
  readonly homeAddress?: string;
  readonly postalCode?: string;
  readonly city?: string;
  readonly region?: string;
  readonly companyName?: string;
  readonly companyPhoneNumber?: string;
  readonly companyEmail?: string;
  readonly companyCountry?: string;
  readonly companyRegion?: string;
  readonly companyCity?: string;
  readonly companyAddress?: string;
  readonly companyPostalCode?: string;
  readonly jobTitle?: string;
  readonly customData?: string;
  readonly contactTags?: Array<{ name: string }>;
  readonly channelsInfo?: {
    readonly fbMessengerId?: number;
    readonly instagramId?: number;
    readonly isViber?: boolean;
    readonly isWhatsapp?: boolean;
  };
  readonly _links: {
    readonly self: ResourceLink;
    readonly owner: ResourceLink;
    readonly tags: ResourceLink;
  };
};

type PageContactResponse = {
  readonly _embedded: {
    readonly contacts: Array<ContactResponse>;
  };
  readonly page: {
    readonly number: number;
    readonly size: number;
    readonly totalElements: number;
    readonly totalPages: number;
  };
};

type SearchByChannelParams = {
  readonly channel: SupportedChatChannel;
  readonly search: string;
  readonly page: number;
  readonly size: number;
};

export class ContactApi {
  constructor(private readonly httpClient: HttpClient) {}

  private mapToChannelType(channel: SupportedChatChannel): string {
    return {
      [SupportedChatChannel.SMS]: 'SMS',
      [SupportedChatChannel.VIBER]: 'VIBER',
      [SupportedChatChannel.WHATSAPP]: 'WHATSAPP',
    }[channel];
  }

  searchByChannel(params: SearchByChannelParams): Promise<Page<Contact>> {
    const { channel, search, page, size } = params;

    const channelType = this.mapToChannelType(channel);
    const queryParams = [
      `search=${search}`,
      `page=${page}`,
      `size=${size}`,
    ].join('&');

    return this.httpClient.get<PageContactResponse>(`/contacts/search/${channelType}?${queryParams}`)
      .then(data => {
        const { number, size, totalElements, totalPages } = data.page;
        const content = (data._embedded?.contacts || [])
          .map(it => this.mapToContact(it));

        return {
          content,
          pageNumber: number,
          pageSize: size,
          totalElements,
          totalPages,
        };
      });
  }

  private mapToContact(response: ContactResponse): Contact {
    const { channelsInfo } = response;

    return {
      id: response.id,
      mobilePhone: response.mobilePhone,
      email: response.email,
      firstName: response.firstName,
      middleName: response.middleName,
      lastName: response.lastName,
      nickname: response.nickname,
      salutation: response.salutation,
      gender: response.gender,
      title: response.title,
      birthday: response.birthday,
      country: response.country,
      language: response.language,
      homeAddress: response.homeAddress,
      postalCode: response.postalCode,
      city: response.city,
      region: response.region,
      companyName: response.companyName,
      companyPhoneNumber: response.companyPhoneNumber,
      companyEmail: response.companyEmail,
      companyCountry: response.companyCountry,
      companyRegion: response.companyRegion,
      companyCity: response.companyCity,
      companyAddress: response.companyAddress,
      companyPostalCode: response.companyPostalCode,
      jobTitle: response.jobTitle,
      customData: response.customData,
      ...(channelsInfo && ({
        channelsInfo: {
          fbMessenger: channelsInfo.fbMessengerId?.toString(),
          instagramId: channelsInfo.instagramId?.toString(),
          isViber: channelsInfo.isViber,
          isWhatsapp: channelsInfo.isWhatsapp,
        },
      })),
      _links: response._links,
    };
  }
}
