import { appConfig } from '../../config';
import { StompWsClient } from './stompWsClient';
import { AbstractChatWsConnection } from './abstractChatWsConnection';
import { ChatWsWrapper } from './chatWsWrapper';

class BackendChatWsConnection extends AbstractChatWsConnection {
  constructor(readonly wsClient: StompWsClient) {
    super(wsClient);
  }

  subscribe(customerId: string | number): string {
    return this.wsClient.subscribe<ChatWsWrapper>(
      `/topics/chat/${customerId}`,
      wsMessage => this.notifyMessageListeners(wsMessage),
    );
  }
}

export default new BackendChatWsConnection(
  new StompWsClient({
    wsUrl: appConfig.URL_WS!,
    debugMode: Boolean(appConfig.DEBUG_WS === 'true'),
    accessToken: () => localStorage.getItem('token')!,
    reconnectDelayInMs: 5000,
  })
);
