import viberApi from '../../api/viber/viberApi';
import viberChatConfig from './viberChatConfig';
import dateTimeService from '../../service/dateTimeService';
import { ChatClient, ChatClientType } from '../../types/chatClient';
import {
  ChatAttachment,
  ChatConversationId,
  ChatMessage,
  ChatMessagePayloadType,
  ChatMessagesPage,
} from '../../types/chatMessage';
import { ChatSender } from '../../types/chatSender';
import { ChatApi, FetchChatMessagesRequest } from '../interface';
import { ViberAccount } from '../../types/viber/viberAccount';
import { ViberTarget } from '../../types/viber/viberTarget';
import { Page } from '../../types/page';
import { ViberMessage, ViberMessageType } from '../../types/viber/viberMessage';
import { SupportedChatChannel } from '../supportedChatChannel';

export default class ViberChatApi implements ChatApi {
  private readonly msgTypeMappingTable = {
    [ViberMessageType.TEXT]: ChatMessagePayloadType.TEXT,
    [ViberMessageType.TEXT_BUTTON]: ChatMessagePayloadType.TEXT_BUTTON,
    [ViberMessageType.IMAGE]: ChatMessagePayloadType.IMAGE_BUTTON,
    [ViberMessageType.IMAGE_BUTTON]: ChatMessagePayloadType.IMAGE_BUTTON,
    [ViberMessageType.VIDEO]: ChatMessagePayloadType.VIDEO,
    [ViberMessageType.VIDEO_BUTTON]: ChatMessagePayloadType.UNSUPPORTED,
    [ViberMessageType.DOCUMENT]: ChatMessagePayloadType.DOC,
    [ViberMessageType.TEMPLATE]: ChatMessagePayloadType.UNSUPPORTED,
  };

  isEnabled(): Promise<boolean> {
    return Promise.resolve(true);
  }

  async fetchSenders(customerUuid: string): Promise<ChatSender[]> {
    const viberAccounts: ViberAccount[] = await viberApi.accounts.getAllByTenantId(customerUuid);
    
    return viberAccounts
      .filter(account => !account.disabled)
      .map(account => this.mapViberAccountToChatSender(account));
  }

  private mapViberAccountToChatSender(account: ViberAccount): ChatSender {
    return {
      id: account.id!,
      name: account.name || account.serviceId.toString(),
    };
  }

  async fetchClients(senderId: string): Promise<ChatClient[]> {
    const viberTargets: ViberTarget[] = await viberApi.targets.getAllByAccountId(senderId);

    return viberTargets.map(target => ({
      channel: SupportedChatChannel.VIBER,
      id: target.id,
      type: ChatClientType.NUMERIC,
      number: target.phoneNumber,
      lastActivityTime: target.lastActivityAt,
      senderId,
      foundInContacts: false,
    }));
  }

  async fetchMessages(client: ChatClient, params: FetchChatMessagesRequest): Promise<ChatMessagesPage> {
    const { senderId: accountId, id: targetId } = client;
    const viberMessagePage: Page<ViberMessage> = await viberApi.messages.getPageByTarget({
      accountId,
      targetId,
      page: params.page,
      size: params.size,
      sort: 'createdAt',
      direction: 'desc',
    });

    const content = viberMessagePage.content
      .map(it => this.mapViberMessageToChatMessage(accountId, targetId, it))
      .reverse();

    return {
      content,
      pageNumber: viberMessagePage.pageNumber,
      pageSize: viberMessagePage.pageSize,
      totalElements: viberMessagePage.totalElements,
      totalPages: viberMessagePage.totalPages,
    };
  }

  async fetchUnreadConversationsIds(customerUuid: string): Promise<ChatConversationId[]> {
    const unreadConversationsIds = await viberApi.messages.getUnreadConversationsIds(customerUuid);

    return unreadConversationsIds.map(it => ({
      channel: SupportedChatChannel.VIBER,
      senderId: it.accountId,
      clientId: it.targetId,
    }));
  }

  fetchUnreadMessagesIds(conversationId: ChatConversationId): Promise<string[]> {
    const { senderId, clientId } = conversationId;
    return viberApi.messages.getUnreadMessagesIds(senderId, clientId);
  }

  markMessageAsRead(): Promise<void> {
    // NOTHING to do here - messages are automatically marked as read based on the last fetch time
    return Promise.resolve();
  }

  private mapViberMessageToChatMessage(accountId: string, targetId: string, viberMsg: ViberMessage): ChatMessage {
    const attachments: ChatAttachment[] = [];
    if (viberMsg.mediaURL) {
      attachments.push({
        link: viberMsg.mediaURL,
        filename: viberMsg.fileName,
      });
    }

    return {
      channel: SupportedChatChannel.VIBER,
      senderId: accountId,
      clientId: targetId,
      msgId: viberMsg.id,
      date: dateTimeService.formatTimestamp(viberMsg.createdAt, viberChatConfig.dateFormat),
      message: viberMsg.text,
      outgoing: viberMsg.direction === 'outgoing',
      payloadType: this.mapType(viberMsg.type),
      attachments,
      status: viberMsg.status,
      statusCode: 200,
      error: null,
      button: viberMsg.button,
      statusDetails: viberMsg.statusDetails,
    };
  }

  private mapType(messageType: ViberMessageType): ChatMessagePayloadType {
    return this.msgTypeMappingTable[messageType] || ChatMessagePayloadType.UNSUPPORTED;
  };
}
