import { MetaAccountApi } from './metaAccountApi';
import { MetaTargetApi } from './metaTargetApi';
import { MetaMessageApi } from './metaMessageApi';
import { HttpClient } from '../common/httpClient';
import { AxiosHttpClient } from '../common/axiosHttpClient';
import { appConfig } from '../../config';
import { WhatsappTemplateApi } from './whatsappTemplateApi';
import { WhatsappCdrsApi } from './whatsappCdrsApi';
import { WhatsappPricingApi } from './whatsappPricingApi';
import { WhatsappStatisticsApi } from './whatsappStatisticsApi';
import { WhatsappConfigApi } from './whatsappConfigApi';
import { MetaFileApi } from './metaFileApi';
import { WhatsappPhoneNumberApi } from './whatsappPhoneNumber';

class MetaApi {
  readonly targets: MetaTargetApi;
  readonly accounts: MetaAccountApi;
  readonly messages: MetaMessageApi;
  readonly files: MetaFileApi;
  readonly whatsappTemplates: WhatsappTemplateApi;
  readonly whatsappCdrs: WhatsappCdrsApi;
  readonly whatsappPricing: WhatsappPricingApi;
  readonly whatsappStatistics: WhatsappStatisticsApi;
  readonly whatsappConfig: WhatsappConfigApi;
  readonly whatsappPhoneNumber: WhatsappPhoneNumberApi;

  constructor() {
    const httpClient = this.initHttpClient();

    this.targets = new MetaTargetApi(httpClient);
    this.accounts = new MetaAccountApi(httpClient);
    this.messages = new MetaMessageApi(httpClient);
    this.files = new MetaFileApi(httpClient);
    this.whatsappTemplates = new WhatsappTemplateApi(httpClient);
    this.whatsappCdrs = new WhatsappCdrsApi(httpClient);
    this.whatsappPricing = new WhatsappPricingApi(httpClient);
    this.whatsappStatistics = new WhatsappStatisticsApi(httpClient);
    this.whatsappConfig = new WhatsappConfigApi(httpClient);
    this.whatsappPhoneNumber = new WhatsappPhoneNumberApi(httpClient);
  }

  private initHttpClient(): HttpClient {
    return new AxiosHttpClient({
      baseUrl: appConfig.META_URL,
      accessToken: () => localStorage.getItem('chatToken')!,
    });
  }
}

export default new MetaApi();
