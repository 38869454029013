import delay from 'lodash-es/delay';
import { AnyAction } from 'redux';
import { AppThunkAction } from './index';

export function setTestAccountDrawerShowFlag(flag: $TSFixMe): AnyAction {
  return { type: 'SET_SHOW_TEST_ACCOUNT_DRAWER', payload: flag };
}

export function setTestAccountWelcomeScreenShowFlag(flag: $TSFixMe): AnyAction {
  return { type: 'SET_SHOW_TEST_ACCOUNT_WELCOME_SCREEN', payload: flag };
}

export const handleShowTestAccountWelcomeScreenNextTime = (flag: $TSFixMe): AppThunkAction => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_HIDE_SHOW_TEST_ACCOUNT_WELCOME_SCREEN_NEXT_TIME_LOADING',
      payload: true,
    });
    localStorage.setItem('hideTestAccountWelcomeScreenNextTime', flag);
    delay(() => {
      dispatch({
        type: 'SET_HIDE_SHOW_TEST_ACCOUNT_WELCOME_SCREEN_NEXT_TIME',
        payload: flag,
      });
      dispatch({
        type: 'SET_HIDE_SHOW_TEST_ACCOUNT_WELCOME_SCREEN_NEXT_TIME_LOADING',
        payload: false,
      });
    }, 1000);
  };
};

export function setShowWhitelistedNumbersDialog(flag: $TSFixMe): AnyAction {
  return { type: 'SET_SHOW_WHITELISTED_NUMBERS_DIALOG', payload: flag };
}

export function setNumbers(numbers: $TSFixMe): AnyAction {
  return { type: 'SET_WHITELISTED_NUMBERS', payload: numbers };
}
