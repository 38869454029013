import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

type Props = {
  readonly label: string;
  readonly helperText: string;
  readonly disabled: boolean;
  readonly noMargin: boolean;
  readonly myOnChange: $TSFixMe;
  readonly meta: { touched: boolean, error: $TSFixMe};
  readonly input: { value: string, onChange: (value: $TSFixMe) => void };
};

const RenderSwitchField = ({
  label,
  helperText = '',
  input,
  disabled,
  myOnChange,
  noMargin = false,
}: Props) => {

  const handleChange = (e: $TSFixMe, v: $TSFixMe) => {
    if (myOnChange) myOnChange(v);
    input.onChange(v);
  };
  return (
    <FormControl>
      <FormGroup row style={noMargin ? undefined : { marginTop: 5 }}>
        <FormControlLabel
          control={
            <Switch
              disabled={disabled}
              onChange={handleChange}
              checked={!!input.value}
              color="secondary"
            />
          }
          label={label}
        />
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RenderSwitchField;
